import Vue from 'vue';
import Vuex from 'vuex';

import $bus from '@/platformSettings/bus';
import { supportedLanguages } from '@/lang/languages';
import { loadLanguageAsync } from '@/lang/i18n';
import { PLAN } from '@/platformSettings/billing';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    supportedLanguages,
    maximizeMinimap: false,
    platformLang: 'en',
    platformCompanyName: null,
    platformCompanyPlan: null,
    platformCompanyPermissions: null,
    platformCompanyPlanStartTime: null,
    platformCompanyPlanEndTime: null,
    platformTimezone: '',
    pageHeaderTitle: null,
    pageHeaderAvatar: null,
    pageHeaderNavigation: [],
    sessionExpired: false,
    uploadFilePairPreview: null,

    featureFlags: {},

    tutorialVideos: [],
  },
  getters: {
    isSuspended: state => state.platformCompanyPlan === PLAN.SUSPENDED,
    isStableFeature: state => featureFlag => {
      if (!featureFlag) return true;
      if (!(featureFlag in state.featureFlags)) return true;
      return state.featureFlags[featureFlag];
    },
    isBetaFeature: (state, getters) => featureFlag => !getters.isStableFeature(featureFlag),
  },
  mutations: {
    _setPageHeaderTitle(state, title) {
      state.pageHeaderTitle = title;
    },
    _setMaximizeMinimap(state, maximize) {
      state.maximizeMinimap = maximize;
    },
    _setPlatformLang(state, lang) {
      // backward-compatible. Some language are set to "undefined" as a string by mistake before.

      if (lang == null || lang === 'undefined') {
        lang = 'en';
      }

      state.platformLang = lang;
    },
    _setPlatformTimezone(state, timezone) {
      state.platformTimezone = timezone;
      $bus.$dayjs.tz.setDefault(timezone);
    },
    _setSessionExpired(state, bool) {
      state.sessionExpired = bool;
    },
    _setPlatformCompanyName(state, name) {
      state.platformCompanyName = name;
    },
    _setPlatformCompanyPlan(state, plan) {
      state.platformCompanyPlan = plan;
    },
    _setPlatformCompanyPlanEndTime(state, planEndTime) {
      state.platformCompanyPlanEndTime = planEndTime;
    },
    _setPlatformCompanyPlanStartTime(state, planStartTime) {
      state.platformCompanyPlanStartTime = planStartTime;
    },
    _setPlatformCompanyPermissions(state, permissions) {
      state.platformCompanyPermissions = permissions;
    },
    _setUploadFilePairPreview(state, file) {
      state.uploadFilePairPreview = file;
    },
    _setPageHeaderNavigation(state, routes) {
      state.pageHeaderNavigation = routes;
    },
    _setPageHeaderAvatar(state, details) {
      state.pageHeaderAvatar = details;
    },
    _setFeatureFlags(state, flags) {
      if (typeof flags === 'object' && !Array.isArray(flags)) {
        state.featureFlags = flags;
      } else {
        throw new Error('Feature flags must be an object');
      }

      state.featureFlags = flags;
    },
    _setTutorialVideos(state, tutorials) {
      if (typeof tutorials === 'object' && !Array.isArray(tutorials)) {
        throw new Error(`Expected to receive an Array, but got ${tutorials}`);
      }

      state.tutorialVideos = tutorials;
    },
    _hideTutorialVideoByKey(state, tutorialKey) {
      const tutorialVideoIndex = state.tutorialVideos.findIndex(tutorial => tutorial.key === tutorialKey);

      if (tutorialVideoIndex === -1) {
        throw new Error(`Cannot find tutorial video with key ${tutorialKey}`);
      }

      state.tutorialVideos[tutorialVideoIndex].shouldHide = true;
    },
  },
  actions: {
    cleanPageHeaderTitle({ commit }) {
      commit('_setPageHeaderTitle', null);
    },
    setPageHeaderTitle({ commit }, { companyId, projectId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`amt/${companyId}/project/${projectId}`)
          .then(response => {
            const pageTitleObj = {
              projectName: response.data.name,
              language: response.data.lang,
            };
            commit('_setPageHeaderTitle', pageTitleObj);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    setPageTitle({ commit }, title) {
      commit('_setPageHeaderTitle', title);
    },
    setPageHeaderNavigation({ commit }, routes) {
      commit('_setPageHeaderNavigation', routes);
    },
    setPageHeaderAvatar({ commit }, avatar) {
      commit('_setPageHeaderAvatar', avatar);
    },
    clearPageHeader({ commit }, routes) {
      commit('_setPageHeaderTitle', null);
      commit('_setPageHeaderNavigation', []);
      commit('_setPageHeaderAvatar', null);
    },
    setUpdatedProjectName({ commit, state }, { companyId, projectId, newName }) {
      if (state.pageHeaderTitle.projectName === newName) {
        return;
      }

      $bus.$siniticApi
        .put(`amt/${companyId}/project/${projectId}`, {
          data: {
            name: newName,
          },
        })
        .then(() => {
          const newPageHeaderTitle = { ...state.pageHeaderTitle, projectName: newName };
          commit('_setPageHeaderTitle', newPageHeaderTitle);
        });
    },
    expireSession({ commit }) {
      commit('_setSessionExpired', true);
    },
    renewSession({ commit }) {
      commit('_setSessionExpired', false);
    },
    changeTimezone({ commit }, timezone) {
      commit('_setPlatformTimezone', timezone);
    },
    changeExpandedSidebar({ commit }, expanded) {
      commit('_setExpandedSidebar', expanded);
    },
    changeMaximizeMinimap({ commit }, expanded) {
      commit('_setMaximizeMinimap', expanded);
    },
    async changeLanguage({ commit, state }, lang) {
      if (!supportedLanguages.includes(lang)) {
        new Error(`Unsupported language: ${lang}.`);
      }

      await loadLanguageAsync(lang);

      try {
        const dayjsLocale = lang.replace('_', '-').toLowerCase();
        await import(`dayjs/locale/${dayjsLocale}.js`);
        Vue.dayjs.locale(dayjsLocale);
      } catch (error) {
        // console.error(error);
        Vue.dayjs.locale(state.platformLang);
      }

      commit('_setPlatformLang', lang);
      return lang;
    },
    setPlatformCompany({ commit }, { companyId }) {
      return new Promise((resolve, reject) => {
        Promise.all([
          $bus.$siniticApi.get(`profile/company/${companyId}/name`),
          $bus.$siniticApi.get(`profile/${companyId}/billing/pricing_plan`),
          $bus.$siniticApi.get(`login/i_can_do/company/${companyId}`),
        ])
          .then(([nameResponse, planResponse, permissionResponse]) => {
            commit('_setPlatformCompanyName', nameResponse.data.name);
            commit('_setPlatformCompanyPlan', planResponse.data.pricing_plan);
            commit('_setPlatformCompanyPlanEndTime', planResponse.data.trial_period_ends_at);
            commit('_setPlatformCompanyPlanStartTime', planResponse.data.pricing_plan_started_at);
            commit('_setPlatformCompanyPermissions', permissionResponse.data);
            return resolve({
              permission: permissionResponse.data,
            });
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    clearPlatformCompany({ commit }) {
      return new Promise((resolve, reject) => {
        commit('_setPlatformCompanyName', null);
        commit('_setPlatformCompanyPlan', null);
        commit('_setPlatformCompanyPlanEndTime', null);
        commit('_setPlatformCompanyPlanStartTime', null);
        commit('_setPlatformCompanyPermissions', null);
        return resolve({ permission: null });
      });
    },
    setPreferences({ commit }, preferences) {
      const { platformLang, platformTimezone = $bus.$dayjs.tz.guess() } = preferences;
      if (platformLang) {
        loadLanguageAsync(platformLang);
        commit('_setPlatformLang', platformLang);
      }

      if (platformTimezone) {
        commit('_setPlatformTimezone', platformTimezone);
      }
    },
    mountUploadedFileForPreview({ commit }, file) {
      commit('_setUploadFilePairPreview', file);
    },

    fetchFeatureFlags({ commit }, companyId) {
      $bus.$siniticApi.get(`/profile/v2/company/${companyId}/feature_flags`).then(response => {
        const flagsObj = response.data.reduce((byName, flag) => {
          byName[flag.name] = flag.enabled;
          return byName;
        }, {});
        commit('_setFeatureFlags', flagsObj);
      });
    },

    fetchTutorialVideos({ commit }) {
      return $bus.$siniticApi.get('/profile/tutorial_videos').then(response => {
        commit('_setTutorialVideos', response.data.map(({
          key, embed_url: embedUrl, should_hide: shouldHide,
        }) => ({
          key,
          embedUrl,
          shouldHide,
        })));
      });
    },

    hideTutorialVideo({ commit }, tutorialKey) {
      if (!tutorialKey) throw new Error('Missing required parameters.');

      return $bus.$siniticApi.post('/profile/tutorial_videos/hide', {
        data: {
          key: tutorialKey,
        },
      }).then(resp => {
        console.log(resp.data);
        if (resp.data?.success) {
          commit('_hideTutorialVideoByKey', tutorialKey);
          return true;
        } else {
          return Promise.reject(new Error('Failed to hide the tutorial video, try again.'));
        }
      });
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
