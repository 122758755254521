var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.showToolTip
            ? {
                content: _vm.showToolTipText,
                offset: 10
              }
            : null,
          expression:
            "showToolTip ? {\n    content: showToolTipText,\n    offset: 10,\n  } : null"
        }
      ],
      class: {
        "pui-avatar2": true,
        "pui-avatar2--show-name": _vm.showName,
        "pui-avatar2--normal": _vm.size === "normal",
        "pui-avatar2--large": _vm.size === "large",
        "pui-avatar2--xlarge": _vm.size === "xlarge",
        "pui-avatar2--circular": _vm.trait.circular
      }
    },
    [
      _c("div", { staticClass: "pui-avatar2__inner" }, [
        _c(
          "div",
          { staticClass: "pui-avatar2__image-container" },
          [
            _vm.imageType === "IMAGE"
              ? _c("div", { staticClass: "image-wrapper" }, [
                  _c("img", {
                    staticClass: "uploaded-image",
                    attrs: { src: _vm.imageUrl },
                    on: {
                      load: function($event) {
                        _vm.imageFailedToLoad = false
                      },
                      error: function($event) {
                        _vm.imageFailedToLoad = true
                      }
                    }
                  })
                ])
              : _vm.imageType === "DEFAULT"
              ? _c(
                  "div",
                  {
                    staticClass: "image-wrapper",
                    class:
                      ((_obj = {
                        "image-wrapper-background-yellow":
                          _vm.trait.defaultImageBackgroundColorPolicy ===
                          "YELLOW",
                        "image-wrapper-background-black":
                          _vm.trait.defaultImageBackgroundColorPolicy ===
                          "BLACK"
                      }),
                      (_obj[_vm.randomBackgroundColor] =
                        _vm.trait.defaultImageBackgroundColorPolicy ===
                        "RANDOM"),
                      _obj)
                  },
                  [
                    _vm.trait.defaultImagePolicy === "SUI_ICON"
                      ? _c("div", {
                          staticClass: "bot-image",
                          style: {
                            backgroundImage:
                              "url('" + _vm.trait.defaultImage + "')"
                          }
                        })
                      : _vm.trait.defaultImagePolicy === "FA_ICON"
                      ? _c("font-awesome-icon", {
                          style: { color: "#000000" },
                          attrs: { icon: _vm.trait.defaultImage }
                        })
                      : _vm.trait.defaultImagePolicy === "PAI_ICON"
                      ? _c("svg", { staticClass: "uploaded-image" }, [
                          _c(
                            "use",
                            _vm._b(
                              {},
                              "use",
                              { "xlink:href": "#" + _vm.iconFileName },
                              false
                            )
                          )
                        ])
                      : _vm.trait.defaultImagePolicy === "TWO_INITIALS"
                      ? _c(
                          "div",
                          {
                            staticClass: "acronym-image",
                            class: {
                              "acronym-user":
                                _vm.trait.acronymFontSizePolicy ===
                                "USER_ACRONYM",
                              "acronym-company":
                                _vm.trait.acronymFontSizePolicy ===
                                "COMPANY_ACRONYM",
                              "acronym-team":
                                _vm.trait.acronymFontSizePolicy ===
                                "TEAM_ACRONYM",
                              "acronym-user-black":
                                _vm.trait.acronymFontSizePolicy ===
                                "USER_BLACK_ACRONYM"
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.nameAcronym || "") +
                                "\n        "
                            )
                          ]
                        )
                      : _vm.trait.defaultImagePolicy === "ONE_INITIAL"
                      ? _c(
                          "div",
                          {
                            staticClass: "acronym-image",
                            class: {
                              "acronym-user":
                                _vm.trait.acronymFontSizePolicy ===
                                "USER_ACRONYM",
                              "acronym-company":
                                _vm.trait.acronymFontSizePolicy ===
                                "COMPANY_ACRONYM",
                              "acronym-team":
                                _vm.trait.acronymFontSizePolicy ===
                                "TEAM_ACRONYM",
                              "acronym-user-black":
                                _vm.trait.acronymFontSizePolicy ===
                                "USER_BLACK_ACRONYM"
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.nameAcronym || "") +
                                "\n        "
                            )
                          ]
                        )
                      : void 0
                  ],
                  2
                )
              : _vm.imageType === "FALLBACK"
              ? _c(
                  "div",
                  {
                    staticClass: "image-wrapper",
                    class: {
                      "image-wrapper-background-yellow":
                        _vm.trait.fallbackBackgroundColorPolicy === "YELLOW",
                      "image-wrapper-grey-border": _vm.trait.fallbackGreyBorder
                    }
                  },
                  [
                    _vm.trait.fallbackImagePolicy === "SUI_ICON"
                      ? _c("div", {
                          staticClass: "bot-image",
                          style: {
                            backgroundImage:
                              "url('" + _vm.trait.fallbackImage + "')"
                          }
                        })
                      : _vm.trait.fallbackImagePolicy === "FA_ICON"
                      ? _c("font-awesome-icon", {
                          staticClass: "avatar-image",
                          class: {
                            "image-black":
                              _vm.trait.fallbackImageColor === "BLACK",
                            "image-grey":
                              _vm.trait.fallbackImageColor === "GREY"
                          },
                          attrs: { icon: _vm.trait.fallbackImage }
                        })
                      : void 0
                  ],
                  2
                )
              : void 0
          ],
          2
        ),
        _vm._v(" "),
        _vm.badge && _vm.badge !== "none"
          ? _c("span", {
              staticClass: "pui-avatar2__badge",
              class: [_vm.badgeStatusClass]
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.showName
        ? _c("div", { staticClass: "pui-avatar2__name" }, [
            _vm.name
              ? _c("span", [
                  _vm._v("\n      " + _vm._s(_vm.name) + "\n      "),
                  _vm.isMe
                    ? _c("span", { staticClass: "pui-avatar2__name--is-me" }, [
                        _vm._v(_vm._s(_vm.$t("pui:avatar:you")))
                      ])
                    : _vm._e()
                ])
              : _c("span", { staticClass: "pui-avatar2__name--deleted" }, [
                  _vm._v(_vm._s(_vm.$t("pui:avatar:deleted")))
                ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }