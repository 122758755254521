<template>
  <div class="pui-table__view">
    <div
      ref="table"
      :class="{
        'pui-table--horizontal-scroll': horizontallyScrollable,
        'pui-table--horizontal-scrolled': scrolled,
        'pui-table--has-select-all': hasSelectAll,
      }"
      class="pui-table"
    >
      <slot name="toolbar" />
      <div
        class="pui-table__container"
      >
        <!-- Table header -->
        <div class="pui-table__head">
          <!-- Table header items are object -->
          <PUITableRow
            v-if="$slots['customHeaders']"
            type="header"
            clickable
          >
            <!-- @slot Use this slot to override headers and customize them -->
            <slot name="customHeaders" />
          </PUITableRow>

          <!-- Table header items are purely string -->
          <PUITableRow
            v-else
            type="header"
            clickable
          >
            <PUITableCell
              v-if="hasSelectAll"
              type="header"
              checkbox
              :topBorder="topBorder"
            >
              <PUISelectAllIndicator
                :options="itemsId"
                :checkedOptions="selectedList"
                @onSelectAll="selectAll"
                @onUnselectAll="unSelectAll"
              />
            </PUITableCell>
            <PUITableCell
              v-for="(col, i) in headers"
              :key="i"
              :sticky="i === 0 && fixedFirstColumn"
              type="header"
              :topBorder="topBorder"
            >
              {{ col }}
            </PUITableCell>
          </PUITableRow>
        </div>

        <!-- Table content -->
        <div
          v-if="items.length > 0 && !isLoading"
          :class="{ 'pui-table__body--loading': isLoading }"
          class="pui-table__body"
        >
          <!-- @slot Use this slot to add table rows and contents in the table -->
          <slot />
        </div>
      </div>
      <!-- Loading -->
      <div
        v-if="isLoading"
        class="pui-table-loading"
      >
        <div class="pui-table-loading__wrapper">
          <PUILoader />
        </div>
      </div>
    </div>
    <!-- Pagination -->
    <div
      v-if="items.length > 0 && showPagination"
      class="pui-table__pagination"
    >
      <div class="pui-table__pagination__per-page">
        <div
          v-if="customizableItemsPerPage"
          class="pui-table__pagination__per-page__container"
        >
          <div class="pui-table__pagination__per-page__textfield">
            <PUINumberInput
              v-model="perPage"
              :min="1"
            />
          </div>
          <span class="pui-table__pagination__per-page__text">{{ itemsPerPageText }}</span>
        </div>
      </div>
      <div class="pui-table__pagination__page-numbers">
        <Paginate
          v-if="pagination.pages"
          v-model="pagination.page"
          :pageCount="pagination.pages"
          :clickHandler="clickCallback"
          :prevText="prevText"
          :nextText="nextText"
          containerClass="pui-table__pagination__page-numbers__list"
        />
      </div>
      <div class="pui-table__pagination__text">
        <!-- @slot Use this slot to add a small text beside the pagination controllers,
         usually related to the total number of available items or the current selection-->
        <slot name="paginationText" />
      </div>
    </div>

    <!-- Show no results or dataset -->
    <div
      v-if="showEmptyState"
      class="pui-table__no-results"
    >
      <template>
        <!-- @slot Use this slot to customize what shows up when the table is empty -->
        <slot name="emptyState" />
      </template>
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate';
import { mapGetters } from 'vuex';

const PUISelectAllIndicator = () => import('@/components/PUI/Controls/SelectAllIndicator');
const PUINumberInput = () => import('@/components/PUI/Controls/NumberInput');
const PUILoader = () => import('@/components/PUI/Layout/Loader');
const PUITableRow = () => import('./TableRow.vue');
const PUITableCell = () => import('./TableCell.vue');

export default {
  name: 'PUITable',
  components: {
    Paginate,
    PUINumberInput,
    PUITableRow,
    PUITableCell,
    PUISelectAllIndicator,
    PUILoader,
  },
  props: {
    /**
     * Changes cursor to pointer for rows
     */
    clickable: {
      type: Boolean,
      default: false,
    },
    /**
     * A list of table headers
     *  @values ['header 1', 'header 2', 'header 3']
     */
    headers: {
      type: Array,
      default: () => [],
    },
    /**
     * A list of elements contained within the table
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
       * pagination parameters
       *  @values {
            per_page: 3,
            pages: 3,
            page: 1
          }
        *
        */
    pagination: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Specifies whether table elements are being loaded to
     * show the loading loading
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    topBorder: {
      type: Boolean,
      default: true,
    },
    /**
     * Enables an horizontal scrolling table, columns
     * don't have to fit into a single page view, they can
     * overflow and be scrolled.
     */
    horizontallyScrollable: {
      type: Boolean,
      default: false,
    },
    /**
     * Enables the ability to edit the items that can appear
     * on a single page
     */
    customizableItemsPerPage: {
      type: Boolean,
      default: false,
    },
    /**
     * A string which allows to customize
     * the label of the items per page field
     */
    itemsPerPageText: {
      type: String,
      default: 'Items per page',
    },
    /**
     * Enables to have a header checkbox
     * which allows to select all items in a table
     */
    hasSelectAll: {
      type: Boolean,
      default: false,
    },
    /**
     * A list of items which are currently selected
     */
    selectedItems: {
      type: Array,
      default: () => [],
    },
    /**
     * Disable pagination
     */
    showPagination: {
      type: Boolean,
      default: true,
    },

    fixedFirstColumn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrolled: false,
    };
  },
  computed: {
    ...mapGetters({
      isRTL: 'userv2/isRTL',
    }),
    prevText() {
      if (this.isRTL) {
        return '<div class=\'pui-table__pagination__page-numbers__arrow pui-table__pagination__page-numbers__arrow--right\'/>';
      } else {
        return '<div class=\'pui-table__pagination__page-numbers__arrow pui-table__pagination__page-numbers__arrow--left\'/>';
      }
    },
    nextText() {
      if (this.isRTL) {
        return '<div class=\'pui-table__pagination__page-numbers__arrow pui-table__pagination__page-numbers__arrow--left\'/>';
      } else {
        return '<div class=\'pui-table__pagination__page-numbers__arrow pui-table__pagination__page-numbers__arrow--right\'/>';
      }
    },
    perPage: {
      get() {
        return this.pagination.per_page;
      },
      set(val) {
        /**
         * Triggers when the item per page field is updated
         *
         * @property {number} items new value for the new items allowed on a single page
         */
        this.$emit('updateItemsPerPage', val);
      },
    },
    selectedListItems: {
      get() {
        return this.selectedItems;
      },
      set(val) {
        /**
         * Triggers when the item per page field is updated
         *
         * @property {number} items new value for the new items allowed on a single page
         */
        this.$emit('updateSelected', val);
      },
    },
    itemsId() {
      return this.items.map(item => item.id);
    },
    selectedList() {
      return this.itemsId.reduce((accumulatorObj, currentId) => {
        accumulatorObj[currentId] = this.selectedListItems.includes(currentId);
        return accumulatorObj;
      }, {});
    },
    showEmptyState() {
      return !this.isLoading && this.items.length < 1;
    },
  },
  watch: {
    'pagination.pages': {
      handler(val) {
        if (val > 0 && val < this.pagination.page) {
          this.$emit('currentPage', val);
        }
      },
    },
  },
  mounted() {
    if (this.horizontallyScrollable) {
      this.$refs.table.addEventListener('scroll', this.handleScroll);
    }
  },
  beforeDestroy() {
    if (this.horizontallyScrollable) {
      this.$refs.table.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    clickCallback(pageNumber) {
      /**
       * Triggers when the page number changes
       *
       * @property {number} pageNumber new page number
       */
      this.$emit('currentPage', pageNumber);
    },
    handleScroll(e) {
      this.scrolled = this.$refs.table.scrollLeft > 0;
    },
    selectAll(items) {
      /**
       * Triggers when the select all list items checkbox is checked
       *
       */
      this.$emit('selectAll', items);
      for (const item in items) {
        if (items[item] && !this.selectedListItems.includes(item)) {
          this.selectedListItems.push(item);
        }
      }
    },
    unSelectAll(items) {
      /**
       * Triggers when the select all list items checkbox is unchecked
       *
       */
      this.$emit('unSelectAll', items);
      for (const item in items) {
        this.selectedListItems.splice(this.selectedListItems.indexOf(item), 1);
      }
    },
  },
};
</script>

<style lang="scss">
@import '~styles/pui/pui-variables';

.pui-table-loading {
  &__wrapper {
    width: 100%;
    padding: 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.pui-table {
  display: block;
  width: 100%;
  // overflow: hidden;
  background-color: $color-white;

  &__container {
    display: table;
    width: 100%;
    // border-collapse: collapse;
    // border-spacing: 1px;
    background-color: $color-white;
  }

  &--horizontal-scroll {
    overflow-x: auto;

    .pui-table__container {
      white-space: nowrap;
    }

    .pui-table__cell--sticky {
      position: sticky !important;
      background-color: $color-white;

      top: 0;
      left: 58px;
      z-index: 2;
    }

    .pui-table__cell--checkbox {
      position: sticky !important;
      background-color: $color-white;
      top: 0;
      left: 0;
      z-index: 3;
    }
  }

  &--horizontal-scroll:not(&--has-select-all) {
    .pui-table__cell--sticky {
      left: 0;
    }
  }

  &--horizontal-scrolled {
    .pui-table__cell--sticky {
      box-shadow: 0 15px 40px 0 rgba(39, 59, 110, 0.1);
    }
  }

  &::v-deep .sui2-table__body {
    box-shadow: none;
  }

  &__cell {
    position: relative;
    display: table-cell;
    padding: 10px 30px;
    vertical-align: middle;
    width: auto;

    &--checkbox {
      width: 25px !important;
    }

    &--icon {
      width: 250px;
      text-align: end;
      padding-inline-end: 30px;

      & > span {
        margin: 0 4px;
        cursor: pointer;
      }
    }
  }

  &__no-results {
    padding: 18px 30px;
    color: $color-grey-light;
    font-family: $open-sans;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
  }

  &__row {
    position: relative;
    display: table-row;
    height: 60px;

    &:hover {
      background-color: $color-grey-lighter;

      .pui-table__cell--sticky:not(.pui-table__head__cell),
      .pui-table__cell--checkbox:not(.pui-table__head__cell) {
        background-color: $color-grey-lighter;
      }
    }

    &__clickable {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__head {
    display: table-header-group;
    position: relative;
    font-family: $montserrat;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    color: $color-grey-light;
    text-transform: uppercase;

    &__cell {
      border-top: 1px solid $color-grey-lighter;
      border-bottom: 1px solid $color-grey-lighter;
      &__no-top-border {
        border-top: none;
      }
    }

    &__row {
      height: 45px;
      &:hover {
        cursor: initial;
        background-color: $color-white;
      }
    }
  }

  &--translation {
    display: flex;
    align-items: center;
    padding-inline-start: 10px;
  }

  &--translation-icon {
    margin-inline-start: 10px;
    width: 24px;
  }

  &--translation-total-pairs {
    font-size: 11px;
    color: $color-yellow;

    [dir="rtl"] div {
      float: left;
    }
    div {
      display: flex;
      align-items: center;
      height: 100%;
      float: right;
    }
  }

  &__body {
    display: table-row-group;
    position: relative;
    box-shadow: none;

    &__row {
      position: relative;
      height: 45px;
      display: table-row;

      &:first-child {
        & > .pui-table__body__cell {
          border-top: none;
        }
      }

      &:last-child {
        & > .pui-table__body__cell {
          border-bottom: 1px solid $color-grey-lighter;
        }
      }
    }

    &__cell {
      border-top: 1px solid $color-grey-lighter;
      position: relative;
      font-family: $open-sans;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.85;
      letter-spacing: 0.09px;
      color: $color-black;

      // &:first-child {
      //   width: 0;
      // }

      &--sticky {
        // border-collapse: separate
      }
    }
  }

  &__pagination {
    display: flex;
    margin: 35px 0;
    padding: 0 30px;
    justify-content: space-between;
    align-items: center;
    height: 24px;

    &__per-page {
      width: 33%;
      &__container {
        display: flex;
        align-items: center;
      }

      &__textfield {
        width: 24px;
        height: 24px;
        display: inline-block;

        input {
          padding: unset !important;
          text-align: center;
          font-size: 13px;
        }
      }

      &__text {
        margin-inline-start: 5px;
        font-family: $open-sans;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.09px;
        color: $color-black;
      }
    }

    &__text {
      width: 33%;
      font-family: $montserrat;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.09px;
      text-align: end;
      color: #c6c6c6;
    }

    &__page-numbers {
      text-align: center;
      cursor: pointer;

      &__arrow {
        background-color: $color-grey;
        padding: 0 5px;
        display: inherit;

        &--left {
          mask: url('/static/icons/v2/controls/v2_table_paging_prev.svg') no-repeat center;
        }

        &--right {
          mask: url('/static/icons/v2/controls/v2_table_paging_next.svg') no-repeat center;
        }
      }

      &__list {
        display: flex;
        padding-inline-start: 0;
        border-radius: 4px;
        font-family: $open-sans;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.09px;

        > li:first-child,
        li:last-child {
          background-color: $color-grey-lighter;
          width: 20px;
          padding: 0;
        }

        > li {
          & + li {
            margin-inline-start: 5px;
          }

          > a {
            border-radius: 2px;
            padding: 0 7px;
            height: 20px;
            line-height: 20px;
            color: $color-grey;
            outline: none;
          }
        }

        > li.active {
          background-color: $color-black;

          > a {
            color: $color-white;
          }
        }

        > li.disabled {
          > a {
            cursor: default;
          }
        }
      }
    }
  }
}

.dataTableCellStyle {
  text-transform: uppercase !important;
  font-size: 10px !important;
}
</style>

<docs>

  This is a single cell table component, it allows to align items in a table cell

  ## Usage
  __
  <br />
  ### Simple table

  A table with normal headers

  ```jsx
  <PUITable :headers="['name','address','age','registered at']" :items="[1,2,4]">
    <PUITableRow>
      <PUITableCell>
        John Doe
      </PUITableCell>
      <PUITableCell>
        Jacksonville
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>

    <PUITableRow>
      <PUITableCell>
        Jane Doe
      </PUITableCell>
      <PUITableCell>
        Miami
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>

  </PUITable>
  ```

  ### Simple table with custom headers

  A table with customized headers

  ```jsx
  <PUITable :items="[1,2,4]">

    <template v-slot:customHeaders>
  <PUITableCell
    style="color:white; background-color:black;"
    v-for="(col, i) in ['Custom name','Custom address','Custom age','Custom registered at']"
    :key="i"
    type="header"
  >{{ col }}</PUITableCell>
</template>

    <PUITableRow>
      <PUITableCell>
        John Doe
      </PUITableCell>
      <PUITableCell>
        Jacksonville
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>

    <PUITableRow>
      <PUITableCell>
        Jane Doe
      </PUITableCell>
      <PUITableCell>
        Miami
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>

  </PUITable>
  ```

  ### Table with pagination

  This table has more than one page thus allowing to have multiple page with an option to define the default number
  of items that can appear on a single page, but however the number of items that can appear on one page can not be
  customized by the end user.

  ```jsx
  <PUITable :headers="['name','address','age','registered at']" :items="[1,2,4]"
    :pagination="{ per_page: 3, pages: 3, page: 1}">
    <PUITableRow>
      <PUITableCell>
        John Doe
      </PUITableCell>
      <PUITableCell>
        Jacksonville
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>

    <PUITableRow>
      <PUITableCell>
        Jane Doe
      </PUITableCell>
      <PUITableCell>
        Miami
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>

  </PUITable>
  ```

  ### Table with pagination and a customizable items page option

  This table has a special field which allows a user to customize the number of items that can appear
  on one page

  ```jsx
  <PUITable :headers="['name','address','age','registered at']" :items="[1,2,4]"
    :pagination="{ per_page: 3, pages: 3, page: 1}" customizableItemsPerPage>
    <PUITableRow>
      <PUITableCell>
        John Doe
      </PUITableCell>
      <PUITableCell>
        Jacksonville
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>

    <PUITableRow>
      <PUITableCell>
        Jane Doe
      </PUITableCell>
      <PUITableCell>
        Miami
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>

  </PUITable>
  ```

  ### Horizontal scrollable table

  A table which horizontal scrolls while keeping in place the items having the sticky option

  ```jsx
  <PUITable
    :headers="['name','address','age','department','birth year','native language','spoken languages','registered at']"
    :items="[1,2,4]" :pagination="{ per_page: 3, pages: 3, page: 1}" horizontallyScrollable>
    <PUITableRow>
      <PUITableCell sticky>
        John Doe
      </PUITableCell>
      <PUITableCell>
        Jacksonville
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        Finance
      </PUITableCell>
      <PUITableCell>
        1985
      </PUITableCell>
      <PUITableCell>
        English
      </PUITableCell>
      <PUITableCell>
        English, French
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>

    <PUITableRow>
      <PUITableCell sticky>
        Jane Doe
      </PUITableCell>
      <PUITableCell>
        Miami
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        Human Resources
      </PUITableCell>
      <PUITableCell>
        1990
      </PUITableCell>
      <PUITableCell>
        Spanish
      </PUITableCell>
      <PUITableCell>
        English, Spanish
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>

  </PUITable>
  ```

  ### Selectable row table

  A table having a select all header checkbox

  ```jsx
  let items = [1,2,4,5];
  let selected = [];

  <PUITable
    :headers="['name','address','age','department','birth year','native language','spoken languages','registered at']"
    :items="items" :selectedItems="selected" horizontallyScrollable hasSelectAll>
    <PUITableRow v-for="(item, i) in items" :key="i">
      <PUITableCell checkbox>
        <PUICheckbox :value="item" v-model="selected" />
      </PUITableCell>
      <PUITableCell sticky>
        John Doe
      </PUITableCell>
      <PUITableCell>
        Jacksonville
      </PUITableCell>
      <PUITableCell>
        32
      </PUITableCell>
      <PUITableCell>
        Finance
      </PUITableCell>
      <PUITableCell>
        1985
      </PUITableCell>
      <PUITableCell>
        English
      </PUITableCell>
      <PUITableCell>
        English, French
      </PUITableCell>
      <PUITableCell>
        2019-09-10
      </PUITableCell>
    </PUITableRow>
  </PUITable>
  ```

</docs>
