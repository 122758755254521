export function isValidUrl(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

export function createEnum(object) {
  const namespace = Object.create(null);
  Object.assign(namespace, object);
  return Object.freeze(namespace);
}

export const createMapping = createEnum;

export function hasOwnProperty(object, name) {
  return Object.prototype.hasOwnProperty.call(object, name);
}

export function stringCompareInsensitive(lhs, rhs) {
  lhs = lhs || '';
  rhs = rhs || '';

  const lhsLower = lhs.toLowerCase();
  const rhsLower = rhs.toLowerCase();

  const insensitiveResult = lhsLower.localeCompare(rhsLower);

  if (insensitiveResult !== 0) {
    return insensitiveResult;
  }
  return lhs.localeCompare(rhs);
}

// Play notification sound
export const playAlertSound = (fileUrl = '/static/audio/chin-up.mp3', returnError = false) => {
  try {
    const audio = new Audio(fileUrl);
    audio.play();
    console.log('🔈🔈🔈🔈🔈');
  } catch (error) {
    console.warn(error);
    if (returnError) {
      return error;
    }
  }
};

export const getLocalStorage = () => {
  try {
    const testKey = '__proto_test_random_key__';
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return localStorage;
  } catch (e) {
    return null;
  }
};

export const F = {
  map(func) {
    return arr => arr.map(func);
  },
  objMap(func) {
    return obj => Object.fromEntries(Object.entries(obj).map(([k, v]) => func(k, v, obj)));
  },
};

export const getUtmQueryString = (query, validUtmParams) => {
  // Accepts both query string (window.location.search) and query object (this.$route.query)
  // Returns a encoded query string with only utm params of format:
  // ie: utm_source=paid%21search&utm_medium=Google&utm_campaign=whatsapp-chatbot

  // Create URLSearchParams with full query to fetch utm queries
  const searchParams = new URLSearchParams(query);
  // Only use URLSearchParams for manipulation to maintain encoding
  const utmSearchParams = new URLSearchParams('');

  // Create URLSearchParams with only encoded utm queries
  validUtmParams.forEach(key => {
    const utm_value = searchParams.get(key);
    if (utm_value) {
      // Encode found utm query
      utmSearchParams.set(key, utm_value);
    } else {
      // do nothing, since the utm parameter does not exist in query
    }
  });

  // Return query string composed of encoded utm params
  return utmSearchParams.toString();
};

export const getSampleBotQueryString = (query, prependWithAnd) => {
  // Accepts both query string (window.location.search) and query object (this.$route.query)
  // Returns a encoded query string with only sampleBot param of format:
  // ie: (&)sampleBot=FIN-fx_crypto

  const searchParams = new URLSearchParams(query);
  // Only use URLSearchParams for manipulation to maintain encoding
  const sampleBotSearchParams = new URLSearchParams('');

  // Create URLSearchParams with only encoded sampleBot query
  const samplebot_value = searchParams.get('sampleBot');
  if (samplebot_value) {
    // Encode found sampleBot query
    sampleBotSearchParams.set('sampleBot', samplebot_value);
    if (prependWithAnd) {
      // Include '&' before sampleBot query string if prependWithAnd is true
      // to be used in a query string after other params safely
      return `&${sampleBotSearchParams.toString()}`;
    } else {
      // Return query string composed of encoded sampleBot param
      return sampleBotSearchParams.toString();
    }
  } else {
    // do nothing, since the sampleBot parameter does not exist in query
    // and return the empty query string
    return sampleBotSearchParams.toString();
  }
};

/**
 * This function takes an array and paginates it into chunks of a specified size
 * Return value is an object with keys representing page numbers and values representing
 * the items on that page
 * Example:
 * const paginated = paginateArray([1,2,3,4,5,6,7,8,9,10], 3)
 * paginated // { 1: [1,2,3], 2: [4,5,6], 3: [7,8,9], 4: [10] }
 */
export const paginateArray = (array, itemsPerPage) => {
  if (!Array.isArray(array)) {
    throw new Error('First argument must be an array');
  }

  if (typeof itemsPerPage !== 'number') {
    throw new Error('Second argument must be a number');
  }

  if (itemsPerPage < 1) {
    throw new Error('Second argument must be greater than 0');
  }

  const paginatedObject = {};
  const totalPages = Math.ceil(array.length / itemsPerPage);

  for (let i = 0; i < totalPages; i += 1) {
    const startIndex = i * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsForPage = array.slice(startIndex, endIndex);
    paginatedObject[i + 1] = itemsForPage;
  }

  return paginatedObject;
};
