import { ACTIONS } from '@/webchat2/config/types';
import { MESSAGE_TYPE } from '@/helpers/messageHelper';

import userStatusHandler from './userStatusHandler.js';

import genericMessageHandler from './genericMessageHandler.js';
import conversationAssignToAgentHandler from './conversationAssignToAgentHandler.js';
import conversationTerminatedHandler from './conversationTerminatedHandler.js';
import conversationClosedHandler from './conversationClosedHandler.js';
import transferRequestHandler from './transferRequestHandler.js';
import transferRequestConfirmSatusChangedHandler from './transferRequestConfirmSatusChangedHandler.js';
import messageReadHandler from './messageReadHandler.js';
import gwReceivedHandler from './gwReceivedHandler.js';
import customerSentRatingHandler from './customerSentRatingHandler.js';
import customerActivityStatusChangedHandler from './customerActivityStatusChangedHandler.js';
import agentPermissionChangedHandler from './agentPermissionChangedHandler.js';
import agentIdleTooLongHandler from './agentIdleTooLongHandler.js';

const {
  PONG,
  USER_STATUS,
  CONVERSATION_ASSIGNED_TO_AGENT,
  CONVERSATION_ASSIGNED_TO_AGENT_TEAM,
  CONVERSATION_TERMINATED,
  CONVERSATION_CLOSED,
  TRANSFER_REQUEST,
  TRANSFER_REQUEST_CONFIRM_STATUS_CHANGED,
  MESSAGE_READ,
  GW_RECEIVED,
  CUSTOMER_SENT_RATING,
  CUSTOMER_ACTIVITY_STATUS_CHANGED,
  AGENT_PERMISSION_CHANGED,
  AGENT_IDLE_TOO_LONG,
} = ACTIONS;

export default {
  [MESSAGE_TYPE.TEXT]: genericMessageHandler,
  [MESSAGE_TYPE.AUDIO]: genericMessageHandler,
  [MESSAGE_TYPE.VIDEO]: genericMessageHandler,
  [MESSAGE_TYPE.IMAGE]: genericMessageHandler,
  [MESSAGE_TYPE.FILE]: genericMessageHandler,
  [MESSAGE_TYPE.MANUAL_TRIGGER]: genericMessageHandler,
  [MESSAGE_TYPE.CAROUSEL]: genericMessageHandler,
  [MESSAGE_TYPE.CARD]: genericMessageHandler,
  [MESSAGE_TYPE.BUTTON_CLICKED]: genericMessageHandler,
  [MESSAGE_TYPE.QUICK_REPLIES]: genericMessageHandler,
  [MESSAGE_TYPE.LINE_STICKER]: genericMessageHandler,
  [MESSAGE_TYPE.TEXT_WITH_LINE_EMOJI]: genericMessageHandler,
  [MESSAGE_TYPE.VIBER_STICKER]: genericMessageHandler,
  [MESSAGE_TYPE.TELEGRAM_STICKER]: genericMessageHandler,
  [MESSAGE_TYPE.TELEGRAM_CONTACT]: genericMessageHandler,
  [MESSAGE_TYPE.LOCATION]: genericMessageHandler,
  [MESSAGE_TYPE.HYPERLINK]: genericMessageHandler,
  [MESSAGE_TYPE.METAWHATSAPP_CONTACT]: genericMessageHandler,

  [PONG]: () => {},
  [USER_STATUS]: userStatusHandler,
  [CONVERSATION_ASSIGNED_TO_AGENT]: conversationAssignToAgentHandler,
  [CONVERSATION_ASSIGNED_TO_AGENT_TEAM]: () => {}, // ! Deprecated
  [CONVERSATION_TERMINATED]: conversationTerminatedHandler,
  [CONVERSATION_CLOSED]: conversationClosedHandler,
  [TRANSFER_REQUEST]: transferRequestHandler,
  [TRANSFER_REQUEST_CONFIRM_STATUS_CHANGED]: transferRequestConfirmSatusChangedHandler,
  [MESSAGE_READ]: messageReadHandler,
  [GW_RECEIVED]: gwReceivedHandler,
  [CUSTOMER_SENT_RATING]: customerSentRatingHandler,
  [CUSTOMER_ACTIVITY_STATUS_CHANGED]: customerActivityStatusChangedHandler,
  [AGENT_PERMISSION_CHANGED]: agentPermissionChangedHandler,
  [AGENT_IDLE_TOO_LONG]: agentIdleTooLongHandler,
};
