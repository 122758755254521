import $bus from '@/platformSettings/bus';

export default (msg, $store) => {
  const {
    state, commit, dispatch, rootState, rootGetters,
  } = $store;

  const { company_id } = msg;

  $bus.$siniticApi.get('login/who_am_i').then(resp => {
    const user = resp.data;
    commit('userv2/_setPlatformUser', user, { root: true });
  });

  dispatch('chat/getCompanyTeams', company_id, { root: true }).then(() => {
    if (rootState.chat.currentTeam == null) return;
    if (!(rootState.chat.currentTeam.id in rootGetters['chat/teamsById'])) return;

    const currentTeam = rootGetters['chat/teamsById'][rootState.chat.currentTeam.id];
    // Since `chat.currentTeam` is not sync to `chat.teams`,
    // manually update `chat.currentTeam` if it's not null.
    dispatch(
      'chat/setCurrentTeam',
      {
        companyId: company_id,
        teamId: currentTeam.id,
      },
      { root: true }
    );
  });
};
