import $bus from '@/platformSettings/bus';
import $router from '@/router/platform';
import i18n from '@/lang/i18n';
import { CONVERSATION_STATE } from '@/helpers/livechatConstants.js';
import { getGatewayType } from '@/helpers/gatewayHelper.js';
import getObjValue from 'lodash/get';

export default (msg, $store) => {
  const {
    state, getters, commit, dispatch, rootState, rootGetters,
  } = $store;
  const myUserId = rootState.userv2.user.user_id;

  const isActiveConversation = msg.conversation_id === state.activeConversationId;
  // console.log({ isActiveConversation });

  const wasMyChat = msg.old_agent_id === myUserId;
  const isNewChat = msg.old_agent_id === null;
  const isPendingChat = msg.agent_id === null;
  const isMyChat = msg.agent_id === myUserId;
  const isDueToTransferRequest = !!msg.transfer_request_id;

  const tookOverByOthers = wasMyChat && !isPendingChat && !isMyChat && !isDueToTransferRequest;

  function isAllowToView({ ateam_id, agent_id }) {
    let allowToView = false;

    if (ateam_id) {
      const ateam = rootGetters['chat/visibleTeamsById'][ateam_id];
      if (ateam) {
        // Pending chats are visible
        if (agent_id === null || agent_id === myUserId) {
          allowToView = true;
        } else if (ateam.i_can_do.manage) {
          allowToView = ateam.agents.some(agent => agent.user_id === agent_id);
        }
      }
    }

    return allowToView;
  }

  function isAllowToServe(ateam_id) {
    let allowToServe = false;

    if (ateam_id) {
      const ateam = rootGetters['chat/visibleTeamsById'][ateam_id];
      if (ateam) {
        allowToServe = ateam.i_can_do.serve_customer;
      }
    }

    return allowToServe;
  }

  const allowToView = isAllowToView(msg);
  const allowToServe = isAllowToServe(msg.ateam_id);
  // console.log({ allowToView });
  // console.log({ allowToServe });

  let conversation = state.conversations.find(convo => convo.id === msg.conversation_id);

  // Check if conversation exists
  // If exists update agent_id
  // if not, create new conversation
  if (conversation) {
    commit('_updateConversationAgent', {
      message: msg,
      agentsIdMap: getters.allAgents,
    });
  } else {
    const newConvo = {
      active: true,
      gateway_id: msg.gateway_id,
      gateway_type: msg.gateway_type || getGatewayType(msg.conversation_type),
      id: msg.conversation_id,
      type: msg.conversation_type,
      bot_id: msg.bot_id,
      agent_id: msg.agent_id,
      ateam_id: msg.ateam_id,
      customer_id: msg.customer_id,
      customer_num_visit: msg.customer_num_visit,
      customer_name: msg.customer_name,
      customer_type: msg.customer_type,
      customer_pic_url: msg.customer_pic_url,
      messages: [],
      count_msgs: msg.count_msgs,
      count_unread_msgs: 0,
      last_msg: null,
      last_msg_date: new Date(),
      prop$$: {
        state: CONVERSATION_STATE.NEW,
      },
    };

    commit('_addConversation', newConvo);

    conversation = state.conversations.find(convo => convo.id === msg.conversation_id);
  }

  // Send notification for pending chats and my chats
  if (isNewChat && allowToServe && (isPendingChat || isMyChat)) {
    const notifyTitle = conversation.customer_name || conversation.customer_id.slice(-5);
    const notifyOption = {
      body: i18n.t('chat:livechat.notification.new_conversation', rootState.uiv2.platformLang),
      tag: msg.conversation_id,
    };
    const notifyCallback = {
      onClick: () => {
        if (rootState.route.name === 'LiveChat' && rootState.route.params.conversationId === msg.conversation_id) return;

        $router.push({
          name: 'LiveChat',
          params: {
            conversationId: msg.conversation_id,
          },
        });
      },
    };

    $bus.$notification.show(notifyTitle, notifyOption, notifyCallback);
  }

  if (!allowToView) {
    console.warn('Not allow to view, would be removed.');
    dispatch('_removeConversationFromList', msg.conversation_id);
  }

  // Play notification sound when the convo fulfills these conditions,
  // 1. Not active convo.
  // 2. Allow to serve.
  // 3. Is pending chat or my chat.
  if (!isActiveConversation && allowToServe && (isPendingChat || isMyChat)) {
    dispatch('playNotificationSound');
  }

  if (isMyChat) {
    // To me
    $bus.$emit('CONVERSATION_TRANSFERRED_TO_ME', msg);
  } else if (isPendingChat) {
    // To pending
    $bus.$emit('CONVERSATION_TRANSFERRED_TO_PENDING', msg);
  } else if (tookOverByOthers) {
    // My conversation got took over by other agents
    const customerName = msg.customer_name || msg.customer_id.slice(-5);
    const agentName = getObjValue(getters.allAgents, [msg.agent_id, 'fullname'], msg.agent_id);

    $bus.$notify({
      type: 'info',
      title: i18n.t('chat:livechat.notification.chat_takeover.title'),
      text: i18n.t('chat:livechat.notification.chat_takeover.text', { agentName, customerName }),
    });
  }
};
