var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pui-avatar-container",
      class: { "pui-avatar-container--info": _vm.info }
    },
    [
      _vm.info
        ? _c("div", {
            staticClass: "pui-avatar-info",
            staticStyle: {
              background: "url('/static/icons/PUI/others/group-50.svg')"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "pui-avatar", class: [_vm.sizeClass] }, [
        _vm.name || _vm.url
          ? _c(
              "div",
              {
                staticClass: "pui-avatar-icon",
                class: { "pui-avatar-rounded": _vm.rounded }
              },
              [
                _vm.url && !_vm.imageFailedToLoad
                  ? _c("img", {
                      staticClass: "pui-avatar-picture",
                      attrs: { src: _vm.url },
                      on: {
                        load: function($event) {
                          _vm.imageFailedToLoad = false
                        },
                        error: function($event) {
                          _vm.imageFailedToLoad = true
                        }
                      }
                    })
                  : _c(
                      "span",
                      {
                        staticClass: "pui-avatar-text",
                        class: [_vm.avatarTextColor]
                      },
                      [_vm._v(_vm._s(_vm.nameAcronym))]
                    )
              ]
            )
          : _c("div", {
              staticClass: "pui-avatar-fallback",
              class:
                ((_obj = {
                  "pui-avatar-rounded": _vm.rounded
                }),
                (_obj["pui-avatar-fallback--" + _vm.fallback] = true),
                _obj),
              style: {
                backgroundImage: "url('" + _vm.fallbackIcon + "')",
                backgroundSize: _vm.backgroundSize
                  ? _vm.backgroundSize +
                    " " +
                    _vm.backgroundSize +
                    " !important"
                  : null,
                backgroundColor: _vm.fallbackBackgroundColor,
                padding: _vm.fallbackPadding + " !important"
              }
            }),
        _vm._v(" "),
        _vm.badge
          ? _c("span", {
              staticClass: "pui-avatar-badge",
              class: [_vm.badgeStatusClass]
            })
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }