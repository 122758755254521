// Polyfills for IE (vuex requires a Promise polyfill in IE)
import 'es6-promise/auto';

import '@/styles/sui/sui.scss';
import '@/styles/pui/pui-custom.scss';
import '@/styles/pui/pui-tooltip.scss';
import 'axios-progress-bar/dist/nprogress.css';

// V2 SUI Icons - Channels
import '@/../static/icons/v2/channels/whatsapp.svg';
import '@/../static/icons/v2/channels/twitter.svg';
import '@/../static/icons/v2/channels/twitter-white.svg';
import '@/../static/icons/v2/channels/slack.svg';
import '@/../static/icons/v2/channels/sms.svg';
import '@/../static/icons/v2/channels/messenger.svg';
import '@/../static/icons/v2/channels/line.svg';
import '@/../static/icons/v2/channels/wechat.svg';
import '@/../static/icons/v2/channels/skype.svg';
import '@/../static/icons/v2/channels/website.svg';
import '@/../static/icons/v2/channels/website-square.svg';
import '@/../static/icons/v2/channels/globelabs.svg';
import '@/../static/icons/v2/channels/fb.svg';
import '@/../static/icons/v2/channels/zalo.svg';
import '@/../static/icons/v2/channels/msteams.svg';
import '@/../static/icons/v2/channels/mobilesdk.svg';
import '@/../static/icons/v2/channels/africastalking.svg';
import '@/../static/icons/v2/channels/twiliosms.svg';
import '@/../static/icons/v2/channels/gif.svg';
import '@/../static/icons/v2/channels/v2_sms.svg';
import '@/../static/icons/v2/channels/viber.svg';
import '@/../static/icons/v2/channels/webhook.svg';
import '@/../static/icons/v2/channels/bitrix24.svg';
import '@/../static/icons/v2/channels/telegram.svg';

// V2 SUI Icons - Apps
import '@/../static/icons/v2/apps/intercom.svg';
import '@/../static/icons/v2/apps/zendesk.svg';

// V2 SUI Icons - Controls
import '@/../static/icons/v2/controls/v2_arrow_left2.svg';
import '@/../static/icons/v2/controls/v2_arrow_right2.svg';
import '@/../static/icons/v2/controls/v2_chat_icon.svg';
import '@/../static/icons/v2/controls/v2_ic_alert_yellow.svg';
import '@/../static/icons/v2/controls/v2_table_paging_prev.svg';
import '@/../static/icons/v2/controls/v2_table_paging_next.svg';

// V2 Editor / Canvas icons
import '@/../static/icons/v2/editor/ic-play.svg';
import '@/../static/icons/v2/editor/ic-openai.svg';

// V2 SUI Icons - Bloc Menu
import '@/../static/icons/v2/bloc-menu/event/events.svg';
import '@/../static/icons/v2/bloc-menu/event/media-message.svg';
import '@/../static/icons/v2/bloc-menu/event/text-message-event.svg';
import '@/../static/icons/v2/bloc-menu/event/trigger.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/overridable-events.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/greeting.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/goodbye.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/intro.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/positive.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/negative.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/segway.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/fallback.svg';
import '@/../static/icons/v2/bloc-menu/action/actions.svg';
import '@/../static/icons/v2/bloc-menu/action/pai-takeover.svg';
import '@/../static/icons/v2/bloc-menu/action/card.svg';
import '@/../static/icons/v2/bloc-menu/action/carousel.svg';
import '@/../static/icons/v2/bloc-menu/action/switch-language.svg';
import '@/../static/icons/v2/bloc-menu/action/survey.svg';
import '@/../static/icons/v2/bloc-menu/action/send-email.svg';
import '@/../static/icons/v2/bloc-menu/action/agent-take-over.svg';
import '@/../static/icons/v2/bloc-menu/action/json-api.svg';
import '@/../static/icons/v2/bloc-menu/action/checkpoint.svg';
import '@/../static/icons/v2/bloc-menu/action/media-message-events.svg';
import '@/../static/icons/v2/bloc-menu/action/text-message-action.svg';
import '@/../static/icons/v2/bloc-menu/action/track-action-icon-create-case.svg';
import '@/../static/icons/v2/bloc-menu/action/update-variable.svg';
import '@/../static/icons/v2/bloc-menu/action/branch.svg';
import '@/../static/icons/v2/bloc-menu/action/end.svg';
import '@/../static/icons/v2/bloc-menu/action/import-event.svg';
import '@/../static/icons/v2/bloc-menu/action/jump.svg';
import '@/../static/icons/v2/bloc-menu/action/iframe.svg';
import '@/../static/icons/v2/bloc-menu/action/authenticate.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/collected.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/button-click.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/derived-events.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/not-given.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/api-success.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/api-failed.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/iframe-closed.svg';
import '@/../static/icons/v2/bloc-menu/action/action.svg';
import '@/../static/icons/v2/bloc-menu/event/event.svg';
import '@/../static/icons/v2/bloc-menu/action/hyperlink.svg';

// disabled singers can do this
import '@/../static/icons/v2/bloc-menu/event/events-disabled.svg';
import '@/../static/icons/v2/bloc-menu/event/media-message-disabled.svg';
import '@/../static/icons/v2/bloc-menu/event/trigger-disabled.svg';
import '@/../static/icons/v2/bloc-menu/event/text-message-event-disabled.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/overridable-events-disabled.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/greeting-disabled.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/goodbye-disabled.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/intro-disabled.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/positive-disabled.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/negative-disabled.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/segway-disabled.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/fallback-disabled.svg';
import '@/../static/icons/v2/bloc-menu/overridable_event/filler-disabled.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/collected-disabled.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/button-click-disabled.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/derived-events-disabled.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/not-given-disabled.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/api-success-disabled.svg';
import '@/../static/icons/v2/bloc-menu/derived_event/api-failed-disabled.svg';

// PUI Icons - Navigations
import '@/../static/icons/PUI/menu/icons-build.svg';
import '@/../static/icons/PUI/menu/icons-chat.svg';
import '@/../static/icons/PUI/menu/icons-convert.svg';
import '@/../static/icons/PUI/menu/icons-track.svg';
import '@/../static/icons/PUI/menu/icons-track-hollow.svg';
import '@/../static/icons/PUI/menu/icons-train.svg';
import '@/../static/icons/PUI/menu/icons-translate.svg';
import '@/../static/icons/PUI/menu/icons-human.svg';
import '@/../static/icons/PUI/menu/icons-analytics.svg';
import '@/../static/icons/PUI/menu/icons-data.svg';
import '@/../static/icons/PUI/menu/icons-help.svg';

/* PUI controls */
import '@/../static/icons/PUI/controls/icon-plus.svg';
import '@/../static/icons/PUI/controls/icon-clip.svg';

/* PUI avatar */
import '@/../static/icons/PUI/avatar/avatar-circle.svg';

/* PUI logo */
import '@/../static/icons/PUI/logo/icons-proto.svg';
import '@/../static/icons/PUI/logo/icons-proto-fill.svg';
import '@/../static/icons/PUI/logo/icons-proto_v3.svg';

/* PUI others */
import '@/../static/icons/PUI/others/bot-action-button.svg';

// V2 SUI Icons - Characters
import '@/../static/icons/v2/characters/v2_bot.svg';
import '@/../static/icons/v2/characters/v2_bot_chat.svg';

/* PUI Analytics */
import '@/../static/icons/PUI/analytics/chart_type_bar.svg';
import '@/../static/icons/PUI/analytics/chart_type_line.svg';
import '@/../static/icons/PUI/analytics/chart_type_stacked.svg';
import '@/../static/icons/PUI/analytics/chart_type_table.svg';

/* PUI SSO Login Icons */
import '@/../static/icons/PUI/login/microsoft.svg';

// V2 SUI Icons - Language
import '@/../static/icons/v2/language/ar.svg';
import '@/../static/icons/v2/language/en.svg';
import '@/../static/icons/v2/language/de.svg';
import '@/../static/icons/v2/language/es.svg';
import '@/../static/icons/v2/language/fr.svg';
import '@/../static/icons/v2/language/id.svg';
import '@/../static/icons/v2/language/ig.svg';
import '@/../static/icons/v2/language/ja.svg';
import '@/../static/icons/v2/language/ms.svg';
import '@/../static/icons/v2/language/pt.svg';
import '@/../static/icons/v2/language/sw.svg';
import '@/../static/icons/v2/language/th.svg';
import '@/../static/icons/v2/language/tl.svg';
import '@/../static/icons/v2/language/vi.svg';
import '@/../static/icons/v2/language/ha.svg';
import '@/../static/icons/v2/language/yo.svg';
import '@/../static/icons/v2/language/hi.svg';
import '@/../static/icons/v2/language/mr.svg';
import '@/../static/icons/v2/language/bn.svg';
import '@/../static/icons/v2/language/kqn.svg';
import '@/../static/icons/v2/language/loz.svg';
import '@/../static/icons/v2/language/lue.svg';
import '@/../static/icons/v2/language/lun.svg';
import '@/../static/icons/v2/language/ton.svg';

import '@/../static/icons/v2/language/rw.svg';
import '@/../static/icons/v2/language/tw.svg';
import '@/../static/icons/v2/language/ceb.svg';
import '@/../static/icons/v2/language/bem.svg';
import '@/../static/icons/v2/language/ny.svg';
import '@/../static/icons/v2/language/zh_CN.svg';
import '@/../static/icons/v2/language/zh_TW.svg';

// V2 SUI Icons - Others
import '@/../static/icons/v2/others/v2_suite_case.svg';
import '@/../static/icons/v2/others/colour.svg';

// V2 SUI Icons - History
import '@/../static/icons/v2/history/green-status.svg';
import '@/../static/icons/v2/history/red-status.svg';

// V2 SUI Icons - Chat
import '@/../static/icons/v2/chat/editor-textarea-emoji.svg';
import '@/../static/icons/v2/chat/editor-toolbar-emoji.svg';
import '@/../static/icons/v2/chat/editor-toolbar-clip.svg';
import '@/../static/icons/v2/chat/editor-toolbar-lightning.svg';
import '@/../static/icons/v2/chat/returning-human-black.svg';
import '@/../static/icons/v2/chat/transfer-chat.svg';

// V2 SUI Icons - Account
import '@/../static/icons/v2/account/admins.svg';
import '@/../static/icons/v2/account/managers.svg';
import '@/../static/icons/v2/account/agent.svg';
import '@/../static/icons/v2/account/senior-agent.svg';

// V2 SUI Icons - Billing
import '@/../static/icons/v2/billing/mastercard.svg';
import '@/../static/icons/v2/billing/powered-by-stripe.svg';
import '@/../static/icons/v2/billing/visa.svg';

// V2 SUI Icons - Analytics
import '@/../static/icons/v2/analytics/bidrectional-arrow.svg';
import '@/../static/icons/v2/analytics/download-csv.svg';
import '@/../static/icons/v2/analytics/download-png.svg';

// V2 SUI Icons - Track
import '@/../static/icons/v2/track/track-human.svg';
import '@/../static/icons/v2/track/track-representative.svg';
import '@/../static/icons/v2/track/track-respondent.svg';
import '@/../static/icons/v2/track/track-stranger.svg';

// Proto Icon
import '@/../static/icons/v2/webchat/proto_logo.svg';

import Vue from 'vue';
import $bus from '@/platformSettings/bus';
import VTooltip from 'v-tooltip';
import dayjs from '@/platformSettings/day';
import VueDragscroll from 'vue-dragscroll';
import TextareaAutosize from 'vue-textarea-autosize';
import VueClipboard from 'vue-clipboard2';
import MetaCtrlEnter from 'meta-ctrl-enter';
import animated from 'animate.css';
import PortalVue from 'portal-vue';
import VueShepherd from '@/tutorials/plugin';
import 'shepherd.js/dist/css/shepherd.css';
import '@/lang/i18n';

/* V2 basic UI kit controls */
import Sui2Button from '@/components/v2/SUI/Controls/Button/Button.vue';
// import Sui2Icon from '@/components/v2/SUI/Controls/Icon/Icon.vue';
import Sui2Switch from '@/components/v2/SUI/Controls/Switch/Switch.vue';

/* PUI basic UI kit controls */
import PUITextField from '@/components/PUI/Controls/TextField';
import PUITextArea from '@/components/PUI/Controls/TextArea';
import PUIButton from '@/components/PUI/Controls/Button.vue';
import PUIIconButton from '@/components/PUI/Controls/IconButton.vue';
import PUIDropdown from '@/components/PUI/Controls/Dropdown.vue';
import PUIDropdown2 from '@/components/PUI/Controls/Dropdown2.vue';
import PUICheckbox from '@/components/PUI/Controls/Checkbox.vue';
import PUIRadio from '@/components/PUI/Controls/Radio.vue';
import PUISwitch from '@/components/PUI/Controls/Switch.vue';
import PUIIcon from '@/components/PUI/Controls/Icon.vue';
import PUIFormItem from '@/components/PUI/Controls/FormItem';
import PUIBetaIcon from '@/components/PUI/Controls/BetaIcon';
import PUITable from '@/components/PUI/Layout/Table.vue';
import PUITableRow from '@/components/PUI/Layout/TableRow.vue';
import PUITableCell from '@/components/PUI/Layout/TableCell.vue';
import PUIAvatar from '@/components/PUI/Layout/Avatar.vue';
import PUIAvatar2 from '@/components/PUI/Layout/Avatar2.vue';
import PUIRow from '@/components/PUI/Layout/Row.vue';
import PUICol from '@/components/PUI/Layout/Col.vue';
import PUIHint from '@/components/PUI/Layout/Hint.vue';

import { supportedLanguages } from '@/lang/languages';

import { clickOutsideDirective } from '@/helpers/registerDirectivesHelper';
import { setUpSiniticApi } from '@/helpers/registerComponentHelper';
import registerPluginsHelper from '@/helpers/registerPluginsHelper';
import { timezoneByName } from '@/helpers/timezoneHelper';
import getKBLink from '@/platformSettings/KBLinks';

// Plugins
import Constants from './constants.js';

/* PUI components */
Vue.component('PUITextField', PUITextField);
Vue.component('PUITextArea', PUITextArea);
Vue.component('PUIButton', PUIButton);
Vue.component('PUIIconButton', PUIIconButton);
Vue.component('PUIDropdown', PUIDropdown);
Vue.component('PUIDropdown2', PUIDropdown2);
Vue.component('PUISwitch', PUISwitch);
Vue.component('PUICheckbox', PUICheckbox);
Vue.component('PUIRadio', PUIRadio);
Vue.component('PUIIcon', PUIIcon);
Vue.component('PUIFormItem', PUIFormItem);
Vue.component('PUIBetaIcon', PUIBetaIcon);
Vue.component('PUITable', PUITable);
Vue.component('PUITableRow', PUITableRow);
Vue.component('PUITableCell', PUITableCell);
Vue.component('PUIAvatar', PUIAvatar);
Vue.component('PUIAvatar2', PUIAvatar2);
Vue.component('PUIRow', PUIRow);
Vue.component('PUICol', PUICol);
Vue.component('PUIHint', PUIHint);

/* V2 components */
Vue.component('sui2-button', Sui2Button);
// Vue.component('sui2-icon', Sui2Icon);
Vue.component('sui2-switch', Sui2Switch);

// Vue.use(VueMask);
Vue.use(VueDragscroll);
Vue.use(VTooltip, {
  defaultClass: 'sui-tooltip',
  defaultHtml: false,
  autoHide: false,
  disposeTimeout: 100,
  defaultDelay: 0,
  optimizations: {
    gpu: false,
  },
});

function setupDayJS(Vue, dayjs) {
  Vue.dayjs = dayjs;
  Object.defineProperties(Vue.prototype, {
    $dayjs: {
      get: () => dayjs,
    },
  });
}
setupDayJS(Vue, dayjs);

Vue.use(TextareaAutosize);

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// Trigger Vue methods by pressing cmd+enter, ctrl+enter or windows key+enter
Vue.use(MetaCtrlEnter);
// Use animated.css
Vue.use(animated);
// Use portal-vue
Vue.use(PortalVue);
// Platform constants
Vue.use(Constants);
// Shepherd
Vue.use(VueShepherd);

const supportedLanguagesSet = new Set(supportedLanguages);

Vue.mixin({
  methods: {
    $formatTimeV2(value, format = 'LLL') {
      const savedTimezone = this.$store.state.uiv2.platformTimezone;
      const timezone = timezoneByName.has(savedTimezone) ? savedTimezone : dayjs.tz.guess();
      return dayjs(value).tz(timezone).format(format);
    },
    goToUrl(url) {
      if (url) {
        window.open(url, '_blank');
      }
    },
    getKBLink,
  },
});

// Setup PUIAlert to be utilized with $notify
Vue.prototype.$notify = function ({
  type = 'info',
  multiline = true,
  showDismiss = true,
  duration = 10000,
  title,
  text,
  action1Text,
  action2Text,
  onAction1,
  onAction2,
  onDismiss,
  dismissAfterAction1 = false,
  dismissAfterAction2 = false,
  dismissAfterRouteChanged = true,
}) {
  if (typeof arguments[0] === 'object') {
    $bus.$emit('trigger-pui-notification', {
      type,
      multiline,
      showDismiss,
      duration,
      title,
      text,
      action1Text,
      action2Text,
      onAction1,
      onAction2,
      onDismiss,
      dismissAfterAction1,
      dismissAfterAction2,
      dismissAfterRouteChanged,
    });
  } else {
    throw new Error('<PUIAlert> params must be an object');
  }
};

setUpSiniticApi(Vue);

Vue.directive('click-outside', clickOutsideDirective);
registerPluginsHelper(Vue);

export default Vue;
