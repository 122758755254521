var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pui-radio",
      style: { display: _vm.inline ? "inline-flex" : "flex" }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputVal,
            expression: "inputVal"
          }
        ],
        staticClass: "pui-radio__input",
        attrs: {
          id: _vm.id,
          type: "radio",
          name: _vm.name,
          disabled: _vm.disabled
        },
        domProps: {
          value: _vm.value,
          checked: _vm._q(_vm.inputVal, _vm.value)
        },
        on: {
          change: function($event) {
            _vm.inputVal = _vm.value
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "pui-radio__label",
          attrs: { for: _vm.id, tabindex: "0" }
        },
        [_vm._t("default", [_vm._v(_vm._s(_vm.label))])],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }