var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { class: _vm.classes }, [
    _vm._v("\n  " + _vm._s(_vm.labelBefore) + "\n  "),
    _c(
      "div",
      {
        staticClass: "sui2-switch",
        class: _vm.switchClasses,
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.onClick($event)
          }
        }
      },
      [
        _c("span", { staticClass: "open" }, [_vm._v(_vm._s(_vm.openName))]),
        _vm._v(" "),
        _c("span", { staticClass: "close" }, [_vm._v(_vm._s(_vm.closeName))])
      ]
    ),
    _vm._v("\n  " + _vm._s(_vm.labelAfter) + "\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }