var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pui-table__cell",
      class: {
        "pui-table__body__cell": _vm.type === "body",
        "pui-table__head__cell": _vm.type === "header",
        "pui-table__cell--checkbox": _vm.checkbox,
        "pui-table__cell--sticky": _vm.sticky,
        "pui-table__head__cell__no-top-border": !_vm.topBorder
      }
    },
    [
      _c(
        "div",
        { ref: "editableField" },
        [
          _vm.editable && _vm.editing
            ? _c(
                "PUITextField",
                _vm._g(
                  {
                    attrs: { autofocus: "", theme: "underlined" },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.save($event)
                      }
                    },
                    model: {
                      value: _vm.inputValue,
                      callback: function($$v) {
                        _vm.inputValue =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "inputValue"
                    }
                  },
                  _vm.inputListeners
                )
              )
            : _vm.editable
            ? _c(
                "div",
                _vm._g(
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.showEditor($event)
                      }
                    }
                  },
                  _vm.inputListeners
                ),
                [
                  _vm.text
                    ? _c("span", [_vm._v(_vm._s(_vm.text))])
                    : _vm._t("default", [
                        _c(
                          "span",
                          { staticClass: "pui-table__cell__empty-state" },
                          [_vm._v("-")]
                        )
                      ])
                ],
                2
              )
            : _c(
                "div",
                _vm._g({}, _vm.inputListeners),
                [
                  _vm.text
                    ? _c("span", [_vm._v(_vm._s(_vm.text))])
                    : _vm._t("default", [
                        _c(
                          "span",
                          { staticClass: "pui-table__cell__empty-state" },
                          [_vm._v("-")]
                        )
                      ])
                ],
                2
              )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }