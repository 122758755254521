import Shepherd from 'shepherd.js';
import { mapGetters } from 'vuex';

import $bus from '@/platformSettings/bus';
import { TOUR_KEY } from './tours/tour.js';
import SHARED_STEP_TARGETS from './step_targets.js';

const install = Vue => {
  Vue.prototype.$shepherd = Shepherd;
  Vue.prototype.$tours = TOUR_KEY;
  Vue.prototype.$STEP_TARGET = SHARED_STEP_TARGETS;

  Vue.mixin({
    computed: {
      ...mapGetters({
        $isTourCompleted: 'tutorial/isInteractiveTutorialCompleted',
        $isInTour: 'tutorial/isInInteractiveTour',
      }),
    },
    methods: {
      $tryStartTour(tourKey) {
        $bus.$emit('try-start-tour', tourKey);
      },
    },
  });
};

const plugin = {
  install,
};

export default plugin;
