import axios from 'axios';

export const readVersionAsync = async () => {
  try {
    const { data } = await axios.get('/static/js/version.json');
    return data;
  } catch (err) {
    throw err;
  }
};
