import _ from 'lodash';
import $bus from '@/platformSettings/bus';

export default {
  namespaced: true,
  state: {
    botChannels: [],
    labelList: [],
    checkedLabels: {},
    labelSearchKey: null,
  },

  mutations: {
    setBotChannels(state, payload) {
      state.botChannels = payload;
    },

    setLabelList(state, payload) {
      state.labelList = payload.labelList.sort((a, b) => (a.name > b.name ? 1 : -1));
    },

    setLabelSearchKey(state, payload) {
      state.labelSearchKey = payload;
    },

    checkLabel(state, label) {
      if (!state.checkedLabels[label.id]) {
        state.checkedLabels = { ...state.checkedLabels, [label.id]: label };
      } else {
        state.checkedLabels = { ...state.checkedLabels, [label.id]: null };
      }
    },

    uncheckLabel(state, label_id) {
      state.checkedLabels = { ...state.checkedLabels, [label_id]: null };
    },

    initCheckLabel(state) {
      state.checkedLabels = {};
    },

    checkAllLabels(state) {
      state.labelList.forEach(label => {
        state.checkedLabels = { ...state.checkedLabels, [label.id]: label };
      });
    },

    uncheckAllLabels(state) {
      state.labelList.forEach(label => {
        state.checkedLabels = { ...state.checkedLabels, [label.id]: null };
      });
    },
  },

  getters: {
    labelList(state) {
      return state.labelList;
    },

    checkedLabels(state) {
      return state.checkedLabels;
    },

    labelSearchKey(state) {
      return state.labelSearchKey;
    },

    checkedLabelIds(state) {
      const obj = state.checkedLabels;
      const labelIds = [];
      for (const key in obj) {
        if (obj[key]) labelIds.push(obj[key].id);
      }

      return labelIds;
    },
  },

  actions: {
    getBotChannels({ state, commit }, { bot_id }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get('gateway/', {
            params: {
              bot_id,
            },
          })
          .then(res => {
            const results = res.data;
            commit('setBotChannels', results);
            return resolve(results);
          })
          .catch(err => {
            console.log(err);
            return reject(err);
          });
      });
    },

    updateChannelSettings({ state, dispatch }, { channel_id, data }) {
      const gateway_id = channel_id;
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`gateway/${gateway_id}`, {
            data,
          })
          .then(res => {
            const result = res.data;
            dispatch('getBotChannels', { bot_id: result.bot_id });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getLabelList({ state, commit }, payload) {
      const { bot_id } = payload;

      const url = `gateway/conversation/bot/${bot_id}/labels`;

      $bus.$siniticApi
        .get(url)
        .then(res => {
          commit('setLabelList', {
            labelList: Object.keys(res.data).map(label_id => res.data[label_id]),
          });
        })
        .catch(err => {
          console.log(err);
        });
    },

    postLabel({ dispatch }, payload) {
      const { bot_id, labelName, labelColor } = payload;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`gateway/conversation/bot/${bot_id}/labels`, {
            data: { name: labelName, color: labelColor },
          })
          .then(res => {
            dispatch('getLabelList', { bot_id });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    deleteLabel({ commit, dispatch }, payload) {
      const { bot_id, label_id } = payload;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`gateway/conversation/bot/${bot_id}/labels/${label_id}`)
          .then(res => {
            commit('uncheckLabel', label_id);
            dispatch('getLabelList', { bot_id });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    putLabel({ dispatch }, payload) {
      const {
        bot_id, label_id, updatedName, updatedColor,
      } = payload;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`gateway/conversation/bot/${bot_id}/labels/${label_id}`, {
            data: { name: updatedName, color: updatedColor },
          })
          .then(res => {
            dispatch('getLabelList', { bot_id });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    deleteSelectedLabels({ commit, getters, dispatch }, payload) {
      const { bot_id } = payload;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`gateway/conversation/bot/${bot_id}/labels/batch_delete`, {
            data: { label_ids: getters.checkedLabelIds },
          })
          .then(res => {
            commit('initCheckLabel');
            dispatch('getLabelList', { bot_id });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
};
