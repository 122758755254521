const KBLinks = {
  // Company
  CREATE_COMPANY: 'https://docs.proto.cx/docs/creating-companies',
  ADD_COMPANY_USER: 'https://docs.proto.cx/docs/adding-users',

  // Profile
  CREATE_ALIAS: 'https://docs.proto.cx/docs/creating-aliases',
  CHANGE_PASSWORD: 'https://docs.proto.cx/docs/editing-a-user-profile',

  // Build
  CREATE_BOT: 'https://docs.proto.cx/docs/creating-chatbots',
  NEW_BOT: 'https://docs.proto.cx/docs/creating-chatbots#create-new-bot',
  IMPORT_BOT: 'https://docs.proto.cx/docs/creating-chatbots#import-existing-bot',
  TEMPLATE_BOT: '',
  TEST_CHAT: 'https://docs.proto.cx/docs/testing-chatbots',
  ADD_BOT_LANGUAGES: 'https://docs.proto.cx/docs/adding-languages',
  CREATE_CHANNEL: 'https://docs.proto.cx/docs/creating-channels',

  // Bot blocks (actions)
  BOT_MESSAGE_TEXT: 'https://docs.proto.cx/docs/bot-message-block',
  BOT_MESSAGE_MEDIA: 'https://docs.proto.cx/docs/bot-media-block',
  BOT_COLLECT_INFO: 'https://docs.proto.cx/docs/survey-block',
  BOT_MESSAGE_CAROUSEL: 'https://docs.proto.cx/docs/carousel-block',
  BOT_AGENT_TAKEOVER: 'https://docs.proto.cx/docs/agent-takeover-block',
  BOT_JSON_API: 'https://docs.proto.cx/docs/json-api-block',
  BOT_UPDATE_VARIABLE: 'https://docs.proto.cx/docs/modify-variable-block',
  BOT_UPDATE_LANGUAGE: 'https://docs.proto.cx/docs/switch-language-block',
  BOT_IF_ELSE: 'https://docs.proto.cx/docs/branch-block',
  BOT_EXPORT_INFO: 'https://docs.proto.cx/docs/email-block',
  BOT_ADD_TAG: 'https://docs.proto.cx/docs/checkpoint-block',
  BOT_LINK: 'https://docs.proto.cx/docs/jump-block',
  BOT_AUTHENTICATE: 'https://docs.proto.cx/docs/authenticate-block',
  BOT_CREATE_CASE: 'https://docs.proto.cx/docs/create-case-block',
  BOT_IFRAME: 'https://docs.proto.cx/docs/create-iframe-block',
  BOT_HYPERLINK: 'https://docs.proto.cx/docs/hyperlink-block',
  PAI_TAKEOVER: 'https://docs.proto.cx/docs/apps-takeover-block',

  // Human blocks (events)
  HUMAN_MESSAGE_TEXT: 'https://docs.proto.cx/docs/human-message-block',
  HUMAN_MESSAGE_MEDIA: 'https://docs.proto.cx/docs/human-media-block',
  HUMAN_PROG_TRIGGER: 'https://docs.proto.cx/docs/trigger-block',
  HUMAN_BUILTIN_GREETING: 'https://docs.proto.cx/docs/greeting-block',
  HUMAN_BUILTIN_GOODBYE: 'https://docs.proto.cx/docs/goodbye-block',
  HUMAN_BUILTIN_SELFINTRO: 'https://docs.proto.cx/docs/bio-block',
  HUMAN_BUILTIN_NEGATIVE: 'https://docs.proto.cx/docs/negative-block',
  HUMAN_BUILTIN_POSITIVE: 'https://docs.proto.cx/docs/positive-block',
  HUMAN_BUILTIN_FILLER: 'https://docs.proto.cx/docs/filler-block',
  HUMAN_BUILTIN_FALLBACK: 'https://www.proto.cx/learning-center/confusion-blocks', // ! old doc

  // Chat
  // CHAT
  USE_CANNED_REPLIES: 'https://docs.proto.cx/docs/canned-replies',
  TRANSFER_CHAT: 'https://docs.proto.cx/docs/chat-transfer',
  CREATE_TEAM: 'https://docs.proto.cx/docs/creating-and-editing-teams',
  ADD_AGENT: 'https://docs.proto.cx/docs/adding-agents',
  MANAGE_CANNED_REPLIES: 'https://docs.proto.cx/docs/adding-canned-replies',
  CUSTOMER_NOTES: 'https://docs.proto.cx/docs/customer-notes',

  // Track
  CREATE_CASE_GROUP: 'https://docs.proto.cx/docs/creating-case-groups',
  CREATE_CASE: 'https://docs.proto.cx/docs/creating-cases',
  CREATE_CATEGORY: 'https://docs.proto.cx/docs/adding-categories',
  CREATE_CUSTOM_FIELD: 'https://docs.proto.cx/docs/adding-custom-fields',
  CREATE_RESPONDENT: 'https://docs.proto.cx/docs/adding-respondents',
  CREATE_CASE_NOTIFICATION: 'https://docs.proto.cx/docs/adding-case-notifications',

  // Humans
  CREATE_HUMAN: 'https://docs.proto.cx/docs/creating-a-human-profile',
  EDIT_HUMAN: 'https://docs.proto.cx/docs/editing-human-profiles',
  MANAGE_AUTH_METHODS: 'https://docs.proto.cx/docs/adding-authentication-methods',
  MANAGE_TAGS: 'https://docs.proto.cx/docs/managing-tags',

  EDIT_PROFILE: 'https://docs.proto.cx/docs/editing-your-profile',

  // Channels
  CHANNELS_TELEGRAM: 'https://docs.proto.cx/docs/telegram',
  CHANNELS_METAWHATSAPP: 'https://docs.proto.cx/docs/meta-whatsapp',
};

export default key => KBLinks[key] ?? null;
