import { sync } from 'vuex-router-sync';
import store from '@/store';
import router from '@/router/platform';
import version from '@/version';
// import LogRocket from 'logrocket';
import i18n from '@/lang/i18n';
// import '@stripe/stripe-js';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { ExtraErrorData } from '@sentry/integrations';

import VueDOMPurifyHTML from 'vue-dompurify-html';

import Vue from './sui.js';

// Add all icons to the library so you can use it in your page
library.add(fal, fas, far);
Vue.component('font-awesome-icon', FontAwesomeIcon);

/* Environment and version settings */
const IS_PROD = ['app.sinitic.ai', 'app.proto.cx'].includes(window.location.hostname);
const versionRelease = version.git_tag === '' ? version.git_sha : version.git_tag;

const environment = (function (hostname) {
  switch (hostname) {
    case 'app.proto.cx':
    case 'tr.proto.cx':
      return 'production';

    case 'app.staging.proto.cx':
    case 'tr.staging.proto.cx':
      return 'staging';

    case 'app.dev.proto.cx':
    case 'tr.dev.proto.cx':
      return 'dev';

    default:
      return 'local';
  }
}(window.location.hostname));

const { sentryDsn } = window.$SiniticConfig;

if (sentryDsn && environment !== 'local') {
  Sentry.init({
    Vue,
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing(), new ExtraErrorData({ depth: 7 })],

    normalizeDepth: 8,

    // https://docs.sentry.io/platforms/javascript/guides/vue/
    // Please note that if you enable the SDK, Vue will not call its logError internally.
    // This means that errors occurring in the Vue renderer will not show up in the developer console.
    // If you want to preserve this functionality, make sure to pass the logErrors: true option.
    logErrors: true,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    release: versionRelease,

    environment,
  });

  console.log('Sentry initiated');
} else {
  console.log('Sentry is disabled');
}

/* Sync $router & $route */
sync(store, router);

/* Polyfill for HTMLCollection iterator for Safari */
HTMLCollection.prototype[Symbol.iterator] = Array.prototype[Symbol.iterator];

// A "safe" replacement for the v-html directive
Vue.use(VueDOMPurifyHTML, {
  default: {
    ADD_ATTR: ['target'],
  },
});

new Vue({
  template: '<router-view></router-view>',
  store,
  router,
  i18n,
}).$mount('#sinitic-platform');
