var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      { class: _vm.classes, attrs: { disabled: _vm.disabled } },
      _vm.proxiedListeners
    ),
    [
      _vm.icon
        ? _c("PUIIcon", {
            attrs: {
              name: _vm.icon,
              loading: _vm.loading,
              disabled: _vm.disabled,
              color: _vm.color,
              size: _vm.iconSize
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.text
        ? _c("span", { class: { "sui2-button__has-icon": _vm.icon } }, [
            _vm._v(_vm._s(_vm.text))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }