import { CHANNEL } from '@/platformSettings/channels';

export const CTYPE_GWTYPE_MAP = {
  'line:user': CHANNEL.LINE,
  'fb:user': CHANNEL.FB,
  'ws:thread': CHANNEL.WEBCHAT,
  'ws:debug': 'livechat',
  'globelabs:user': CHANNEL.SMS,
  'whatsapp:user': CHANNEL.WHATSAPP,
  'zalo:user': CHANNEL.ZALO,
  'msteams:user': CHANNEL.MS_TEAMS,
  'webhook:user': 'webhook',
  'africastalking:user': CHANNEL.AFRICAS_TALKING,
  'twiliosms:user': CHANNEL.TWILIO_SMS,
  'viber:user': CHANNEL.VIBER,
  'twitter:user': CHANNEL.TWITTER,
  'bitrix24:user': CHANNEL.BITRIX24,
  'metawhatsapp:user': CHANNEL.META_WHATSAPP,
};

export const getGatewayType = conversationType => CTYPE_GWTYPE_MAP[conversationType] || CHANNEL.WEBCHAT;
