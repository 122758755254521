<template>
  <div class="pui-switch-container">
    <label
      v-if="labelBefore"
      :for="_uid"
      class="pui-switch-label"
      :style="{
        color: labelColor,
      }"
    >{{ labelBefore }}</label>
    <label
      :disabled="disabled"
      :class="{
        'pui-switch': true,
        [`pui-switch--${theme}`]: !!theme,
        'pui-switch--disabled': disabled,
      }"
    >
      <input
        :id="_uid"
        ref="el"
        v-model="inputVal"
        :value="value"
        :disabled="disabled"
        type="checkbox"
      />
      <span class="pui-switch-slider">
        <span
          class="pui-switch-slider-area"
          :style="{
            backgroundColor: isActive ? activeColor : inactiveColor,
          }"
        />
      </span>
    </label>
    <label
      v-if="labelAfter"
      :for="_uid"
      class="pui-switch-label"
      :style="{
        color: labelColor,
      }"
    >
      {{ labelAfter }}
    </label>
    <font-awesome-icon
      v-if="tooltip"
      v-tooltip.top="{
        content: tooltip,
        offset: 10,
        popperOptions: {
          modifiers: {
            preventOverflow: { boundariesElement: tooltipBoundary },
          },
        },
      }"
      :icon="['fal', 'question-circle']"
      class="pui-switch__icon"
      size="lg"
    />
  </div>
</template>

<script>
export default {
  name: 'PUISwitch',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    labelBefore: {
      type: String,
      default: '',
    },
    labelAfter: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '#1e2225',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: [Array, Boolean],
      default: false,
    },
    theme: {
      type: String,
      default: 'light',
      validator: theme => ['light', 'grey-dark'].indexOf(theme) > -1,
    },
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: false,
    },
    activeColor: {
      type: String,
      default: '#f9c927',
    },
    inactiveColor: {
      type: String,
      default: '#e0e0e0',
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tooltipBoundary: 'window',
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    isActive() {
      if (Array.isArray(this.checked)) {
        return this.checked.includes(this.value);
      }

      return this.checked === true;
    },
  },
  mounted() {
    if (document.querySelector('.pui-platform-page__content')) {
      this.tooltipBoundary = document.querySelector('.pui-platform-page__content');
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';

.pui-switch-container {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.pui-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  border: 2px solid $color-white;
  vertical-align: middle;
  cursor: pointer;

  &--grey-dark {
    border: 2px solid $color-grey-dark;
  }

  &.pui-switch--disabled {
    cursor: not-allowed;
  }

  &:not(&.pui-switch--disabled) {
    &:hover,
    &:focus {
      & > .pui-switch-slider:before {
        box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.4);
      }
    }

    &:focus,
    &:active {
      background-color: $color-grey-lightest;
    }

    &:active {
      & > .pui-switch-slider:before {
        box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__icon {
    outline: none;
    position: relative;
    line-height: normal;
    margin-top: 4px;
    color: $color-grey;
    font-size: 14px;
    &:hover {
      color: $color-black;
    }
  }
}

.pui-switch input {
  opacity: 0;
  width: 0;
  height: 0;
  outline: none;

  &:checked + .pui-switch-slider {
    & > .pui-switch-slider-area {
      background-color: $color-yellow;
    }

    &:before {
      transform: translateX(22px);
    }

    [dir="rtl"] &:before {
      transform: translateX(-22px);
    }
  }

  &:disabled {
    & + .pui-switch-slider {
      // cursor: default;

      &:before {
        background-color: $color-white;
        box-shadow: none;
      }

      & > .pui-switch-slider-area {
        opacity: 0.41;
      }
    }
  }
}

.pui-switch-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;

  &:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    z-index: 1;
    top: 3px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    background-color: $color-white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    transform: translateX(3px);
    box-sizing: content-box;
  }

  [dir="rtl"] &:before {
    transform: translateX(-3px);
  }

  & > .pui-switch-slider-area {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: $color-greyer;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 14px;
    color: $color-black;
    vertical-align: middle;
    text-transform: uppercase;
    letter-spacing: 0.06px;
    text-align: end;
  }
}

.pui-switch-label {
  position: relative;
  cursor: pointer;
  font-family: $open-sans;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  color: $color-grey-dark;
}
</style>
