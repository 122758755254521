import { loadProgressBar } from 'axios-progress-bar';

import $bus from '@/platformSettings/bus.js';
import SiniticApi from './siniticApi.js';

export const setUpNotification = (Vue, notification) => {
  Vue.component('sui-notification', notification);
  const notify = params => {
    if (typeof params === 'string') {
      params = { text: params };
    }

    if (typeof params === 'object') {
      $bus.$emit('trigger-notification', params);
    }
  };

  Vue.prototype.$notify = notify;
  Vue.notify = notify;
};

export const setUpNotification2 = (Vue, notification) => {
  Vue.component('sui2-notification', notification);
  const notify = params => {
    if (typeof params === 'string') {
      params = { text: params };
    }

    if (typeof params === 'object') {
      $bus.$emit('trigger-notification', params);
    }
  };

  Vue.prototype.$notify = notify;
  Vue.notify = notify;
};

const setupAxiosProgressBar = (instance, config = {}) => {
  const nprogressConfig = {
    ...config,
    showSpinner: false,
  };
  loadProgressBar(nprogressConfig, instance);
};

export const setUpSiniticApi = Vue => {
  let baseURL = null;

  if (typeof window.$SiniticConfig !== 'undefined') {
    baseURL = window.$SiniticConfig.base_url;
  } else {
    baseURL = `${location.host}/api`;
  }

  const $siniticApi = new SiniticApi({
    baseURL,
  });

  // setupAxiosProgressBar($siniticApi.instance);

  window.$siniticApi = $siniticApi;
  Vue.prototype.$siniticApi = $siniticApi;
  Vue.siniticApi = $siniticApi;
};

export const setUpSweetAlert = (Vue, swal) => {
  const _swal = typeof window !== 'undefined'
    ? swal.mixin({
      showCancelButton: true,
      confirmButtonClass: 'sui-button sui-is-neutral',
      cancelButtonClass: 'sui-button sui-is-red',
      reverseButtons: true,
      heightAuto: false,
    })
    : function () {
      return Promise.resolve();
    };

  Vue.prototype.$swal = _swal;
  Vue.swal = _swal;
};
