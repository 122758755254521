/* eslint no-param-reassign: 0 */
const Notification = window.Notification || window.webkitNotification;

const onerror = () => {};

const onclick = () => {};

const onclose = () => {};

const onshow = () => {};

const defaultEvents = {
  onerror,
  onclick,
  onclose,
  onshow,
};

// Plugin
const VueNativeNotification = {
  install(Vue, options = {}) {
    Vue.prototype.$notification = {};

    if (!('Notification' in window)) {
      console.warn('This browser does not support notification');

      const noOp = function () {};
      Vue.prototype.$notification.requestPermission = noOp;
      Vue.prototype.$notification.show = noOp;
    } else {
      // Manual permission request
      const requestPermission = () => Notification.requestPermission();

      Vue.prototype.$notification.requestPermission = requestPermission;

      // Show function
      const show = function (title, opts, e = {}) {
        if (!e.onError) e.onError = () => {};
        if (!e.onClick) e.onClick = () => {};
        if (!e.onClose) e.onClose = () => {};
        if (!e.onShow) e.onShow = () => {};

        return Promise.resolve()
          .then(() => {
            /*
          Notification.permission => 'default' | 'granted' | 'denied' | 'undefined' (old browser)
          */
            if (options.requestOnNotify && Notification.permission !== 'granted') {
              return requestPermission();
            }

            return Notification.permission;
          })
          .then(permission => {
            // "default" doesn't mean "denied"
            // It means the user has dismissed the request
            if (permission === 'denied') {
              console.warn('No permission to show notification');
              return permission;
            }

            const bindOnError = function (event) {
              defaultEvents.onerror(event);
              e.onError(event);
            };

            const bindOnClick = function (event) {
              defaultEvents.onclick(event);
              e.onClick(event);
            };

            const bindOnClose = function (event) {
              defaultEvents.onclose(event);
              e.onClose(event);
            };

            const bindOnShow = function (event) {
              defaultEvents.onshow(event);
              e.onShow(event);
            };

            // Create Notification object
            try {
              const notification = new Notification(title, {
                icon: `${window.location.origin}/static/icons/favicon.png`,
                renotify: true,
                requestOnNotify: true,
                silent: false,
                ...opts,
              });

              notification.onerror = bindOnError;
              notification.onclick = bindOnClick;
              notification.onclose = bindOnClose;
              notification.onshow = bindOnShow;

              return notification;
            } catch (error) {
              console.warn(error);
              return error;
            }
          });
      };

      Vue.prototype.$notification.show = show;
    }
  },
};

export default VueNativeNotification;
