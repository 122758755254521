import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import Vue from 'vue';
import Vuex from 'vuex';

import $bus from '@/platformSettings/bus';
import { RESOURCE_STATE } from '@/constants.js';
import { ACTIONS } from '@/webchat2/config/types';
import { makeMessageByType } from '@/store/modules/v2/websocket.js';
import { VIEW_CHAT_MESSAGES_PERMISSION } from '@/helpers/chatHistoryConstants.js';

import axios from 'axios';

const { CancelToken } = axios;

const {
  CONVERSATION_ASSIGNED_TO_AGENT,
  CONVERSATION_TERMINATED,
  CONVERSATION_CLOSED,
  TRANSFER_REQUEST,
  MESSAGE_READ,
  GW_RECEIVED,
  CUSTOMER_SENT_RATING,
  CUSTOMER_ACTIVITY_STATUS_CHANGED,
} = ACTIONS;

Vue.use(Vuex);

let cancelLoadHistoryChat; let
  cancelLoadHistoryChatToken;

export default {
  namespaced: true,
  state: {
    bots: [],
    // Store selected bot id in Chat > History filter, for other components to access
    selectedBotId: '',
    // Store selected chat id
    selectedChatId: '',
    // Store chat list
    historyChatList: [],
    // Store pagination info
    paging: {
      total: 0,
      currentPage: 0,
      totalPages: 0,
      perPage: 15,
    },

    fetchingHistoryChatsState: RESOURCE_STATE.IDLE,
    fetchingHistoryChatContentState: RESOURCE_STATE.IDLE,

    historyChatsAreFetched: false,

    viewMessagesPermission: null,
    selectedMessages: [],

    selectedUserInfo: {},

    selectedNotes: [],

    browsingHistory: {},

    selectedMessageIds: {},

    isSearching: false,
    /**
     * Store parameters of filter
     *
     * Example:
     * {
     *   keyword: '',
     *   customerId: '',
     *   start: '',
     *   end: '',
     *   channelId: '',
     *   teamId: '',
     *   agentId: '',
     * }
     */
    filterParams: {},
  },

  getters: {
    selectedHistoryChat: state => {
      if (!state.selectedChatId) return null;

      return state.historyChatList.find(chat => chat.id === state.selectedChatId) || null;
    },

    selectedHistoryChatIndex: state => state.historyChatList.findIndex(chat => chat.id === state.selectedChatId),

    hasPreviousChat: (state, getters) => state.historyChatList.length > 0 && getters.selectedHistoryChatIndex > 0,

    hasNextChat: (state, getters) => state.historyChatList.length > 0 && getters.selectedHistoryChatIndex < state.historyChatList.length - 1,
  },

  mutations: {
    setBots(state, bots) {
      if (!Array.isArray(bots)) {
        throw Error(`Invalid payload: Expected Array, got ${typeof bots} with value ${bots}.`);
      }

      state.bots = bots;
    },

    clearBots(state) {
      state.bots = [];
    },

    setSelectedBotId(state, botId) {
      if ((botId !== null && botId !== undefined) && typeof botId !== 'string') {
        throw Error(
          `Invalid payload: type check failed for payload "botId". Expected String, got ${typeof botId} with value ${botId}.`
        );
      }

      state.selectedBotId = botId;
    },

    setFetchingHistoryChatsState(state, resourceState) {
      const {
        IDLE, PENDING, RESOLVED, REJECTED,
      } = RESOURCE_STATE;

      if (![IDLE, PENDING, RESOLVED, REJECTED].includes(resourceState)) {
        throw Error(
          `Invalid payload: only IDLE, PENDING, RESOLVED, and REJECTED are allowed, got ${resourceState}.`
        );
      }

      state.fetchingHistoryChatsState = resourceState;
    },
    setFetchingHistoryChatContentState(state, resourceState) {
      const {
        IDLE, PENDING, RESOLVED, REJECTED,
      } = RESOURCE_STATE;

      if (![IDLE, PENDING, RESOLVED, REJECTED].includes(resourceState)) {
        throw Error(
          `Invalid payload: only IDLE, PENDING, RESOLVED, and REJECTED are allowed, got ${resourceState}.`
        );
      }

      state.fetchingHistoryChatContentState = resourceState;
    },

    setHistoryChatsAreFetched(state, areFetched) {
      if (typeof areFetched !== 'boolean') {
        throw Error(
          `Invalid payload: type check failed for payload "areFetched". Expected Boolean, got ${typeof areFetched} with value ${areFetched}.`
        );
      }

      state.historyChatsAreFetched = Boolean(areFetched);
    },

    setHistoryChatList(state, chatList) {
      if (!Array.isArray(chatList)) return;

      state.historyChatList = chatList;
    },

    resetHistoryChatList(state) {
      state.historyChatList = [];
      state.historyChatsAreFetched = false;
    },

    setPaging(state, paging) {
      const parseIntValues = Object.fromEntries(
        Object.entries(paging).map(([k, v]) => [k, v && !Number.isNaN(parseInt(v, 10)) ? parseInt(v, 10) : v])
      );

      state.paging = {
        ...state.paging,
        ...parseIntValues,
      };
    },

    resetPaging(state) {
      state.paging = {
        ...state.paging,
        total: 0,
        currentPage: 0,
        totalPages: 0,
      };
    },

    setFilterParams(state, payload) {
      state.filterParams = payload;
    },

    updateFilterParams(state, payload) {
      const _payload = {
        ...state.filterParams,
        ...payload,
      };

      state.filterParams = Object.fromEntries(Object.entries(_payload).filter(([_, v]) => v != null && v !== ''));
    },

    clearFilterParams(state) {
      state.filterParams = {};
    },

    setIsSearching(state, payload) {
      state.isSearching = payload;
    },

    setSelectedMessages(state, payload) {
      state.selectedMessages = payload;
    },

    resetSelectedChatHistory(state) {
      state.selectedMessages = [];
      state.selectedUserInfo = {};
      state.selectedNotes = [];
      state.browsingHistory = {};
      state.selectedMessageIds = {};
      state.viewMessagesPermission = null;
    },

    setSelectedChatId(state, convoId) {
      state.selectedChatId = convoId || '';
    },

    setUserInfo(state, payload) {
      state.selectedUserInfo = payload;
    },

    setCustomerNotes(state, payload) {
      state.selectedNotes = payload;
    },

    setBrowsingHistory(state, payload) {
      state.browsingHistory = payload;
    },

    setSelectedMessageIds(state, convoId) {
      state.selectedMessageIds = { [convoId]: true };
    },

    setViewMessagesPermission(state, permission) {
      state.viewMessagesPermission = permission;
    },
  },

  actions: {
    getBots({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        if (!companyId) {
          return reject(new Error('Missing company id'));
        }

        $bus.$siniticApi
          .get(`/bot/v2/${companyId}/`)
          .then(response => {
            const bots = response.data;
            commit('setBots', bots);
            return resolve(bots);
          })
          .catch(error => {
            commit('setBots', []);
            console.error(error);
            return reject(error);
          });
      });
    },

    cancelRequests() {
      // Cancel unfinished requests
      if (cancelLoadHistoryChat) {
        cancelLoadHistoryChat();
        cancelLoadHistoryChat = null;
        cancelLoadHistoryChatToken = null;
      }
    },

    loadHistoryChat({ state, commit, dispatch }, { companyId, botId, chatId }) {
      // Cancel unfinished requests
      if (cancelLoadHistoryChat) {
        cancelLoadHistoryChat();
        cancelLoadHistoryChat = null;
        cancelLoadHistoryChatToken = null;
      }

      commit('setFetchingHistoryChatContentState', RESOURCE_STATE.PENDING);

      if (!chatId) {
        commit('setFetchingHistoryChatContentState', RESOURCE_STATE.RESOLVED);
        return;
      }

      commit('resetSelectedChatHistory');

      const chat = state.historyChatList.find(chat => chat.id === chatId);

      if (!chat) {
        commit('setFetchingHistoryChatContentState', RESOURCE_STATE.RESOLVED);
        return;
      }

      const [gwId, convoType, convoId, customerId] = [
        chat.gateway_id,
        chat.type,
        chat.id,
        chat.customer_id,
      ];

      // Generate cancel token
      cancelLoadHistoryChatToken = new CancelToken(c => {
        cancelLoadHistoryChat = c;
      });

      commit('setSelectedMessageIds', convoId);

      Promise.allSettled([
        dispatch('getGatewayProfile', { companyId, gwId, convoId }),
        dispatch('getCustomerNotes', { companyId, conversationId: convoId }),
        dispatch('getBrowsingHistory', { gwId, customerId }),
        dispatch('getChatHistoryMessages', {
          botId, gwId, convoType, convoId,
        }),
      ])
        .then(() => {
          commit('setFetchingHistoryChatContentState', RESOURCE_STATE.RESOLVED);
        })
        .catch(() => {
          commit('setFetchingHistoryChatContentState', RESOURCE_STATE.REJECTED);
        });
    },

    getChatHistory({ commit, rootState, state }, companyId) {
      if (!state.selectedBotId) return;

      commit('setFetchingHistoryChatsState', RESOURCE_STATE.PENDING);

      if (!isEmpty(state.filterParams)) {
        commit('setIsSearching', true);
      } else {
        commit('setIsSearching', false);
      }

      const filters = cloneDeep(state.filterParams);

      function renameKey(obj, oldKey, newKey) {
        if (!obj[oldKey] || newKey === oldKey) return;

        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      }

      renameKey(filters, 'channelId', 'gateway_id');
      renameKey(filters, 'customerId', 'customer_id');
      renameKey(filters, 'teamId', 'ateam_id');
      renameKey(filters, 'agentId', 'agent_id');

      const formatting = 'YYYY-MM-DD';
      if (state.filterParams.start) {
        filters.start = Vue.dayjs(state.filterParams.start).format(formatting);
        renameKey(filters, 'start', 'date_begin');
      }
      if (state.filterParams.end) {
        filters.end = Vue.dayjs(state.filterParams.end).format(formatting);
        renameKey(filters, 'end', 'date_end');
      }

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/livechatgateway/conversation/company/${companyId}/bot/${state.selectedBotId}/query_conversations`, {
            data: {
              ...filters,
              timezone: rootState.uiv2.platformTimezone,
              agent_only: true,
              page: state.paging.currentPage || 1,
              per_page: state.paging.perPage,
            },
          })
          .then(res => {
            const {
              count, items, n_pages, page,
            } = res.data;
            commit('setHistoryChatList', items);
            commit('setPaging', {
              total: count,
              currentPage: page,
              totalPages: n_pages,
            });
            commit('setHistoryChatsAreFetched', true);
            commit('setFetchingHistoryChatsState', RESOURCE_STATE.RESOLVED);

            resolve(res.data);
          })
          .catch(err => {
            console.log(err);
            commit('setHistoryChatsAreFetched', false);
            commit('setFetchingHistoryChatsState', RESOURCE_STATE.REJECTED);

            reject(err);
          });
      });
    },

    getChatHistoryMessages({ commit, rootState, rootGetters }, params) {
      const {
        convoType, convoId, gwId, botId,
      } = params;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`/livechatgateway/chat_history/${botId}/conversation/${gwId}/${convoType}/${convoId}/messages`, {
            cancelToken: cancelLoadHistoryChatToken,
          })
          .then(res => {
            const messages = res.data
              .filter(msg => [
                ...rootState.websocketv2._allowDisplayMsgTypes,
                CONVERSATION_ASSIGNED_TO_AGENT,
                CONVERSATION_TERMINATED,
                CONVERSATION_CLOSED,
                CUSTOMER_SENT_RATING,
              ].includes(msg.type))
              .map(msg => makeMessageByType(msg.type, msg, {
                agentsIdMap: rootGetters['websocketv2/allAgents'],
              }));
            commit('setSelectedMessages', messages);
            commit('setViewMessagesPermission', VIEW_CHAT_MESSAGES_PERMISSION.ALLOWED);
            resolve(messages);
          })
          .catch(err => {
            console.log({ err });
            if (err.code === 'FORBIDDEN') {
              commit('setViewMessagesPermission', VIEW_CHAT_MESSAGES_PERMISSION.DENIED);
            }

            reject(err);
          });
      });
    },

    getGatewayProfile({ commit }, { companyId, gwId, convoId }) {
      return new Promise((resolve, reject) => {
        if (!convoId) {
          commit('setUserInfo', {});
          reject();
          return;
        }

        $bus.$siniticApi
          .get(`/livechatgateway/${companyId}/crm/gateway_profile/${gwId}/${convoId}`, {
            cancelToken: cancelLoadHistoryChatToken,
          })
          .then(res => {
            commit('setUserInfo', res.data);
            resolve(res.data);
          })
          .catch(err => {
            commit('setUserInfo', {});
            reject(err);
          });
      });
    },

    getCustomerNotes({ commit }, params) {
      const { companyId, conversationId } = params;

      return new Promise((resolve, reject) => {
        if (!conversationId) {
          commit('setCustomerNotes', []);
          reject();
          return;
        }
        $bus.$siniticApi
          .get(`/livechatgateway/notes/${companyId}/conversation/${encodeURIComponent(conversationId)}`, {
            cancelToken: cancelLoadHistoryChatToken,
          })
          .then(res => {
            commit('setCustomerNotes', res.data);
            resolve(res.data);
          })
          .catch(err => {
            commit('setCustomerNotes', []);
            reject(err);
          });
      });
    },

    getBrowsingHistory({ commit }, params) {
      const { gwId, customerId } = params;

      return new Promise((resolve, reject) => {
        if (!gwId || !customerId) {
          commit('setBrowsingHistory', {});
          reject();
          return;
        }

        $bus.$siniticApi
          .get(`/livechatgateway/${gwId}/t/${encodeURIComponent(customerId)}`, {
            cancelToken: cancelLoadHistoryChatToken,
          })
          .then(res => {
            commit('setBrowsingHistory', res.data);
            resolve(res.data);
          })
          .catch(err => {
            commit('setBrowsingHistory', {});
            reject(err);
          });
      });
    },
  },
};
