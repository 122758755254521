import isEmpty from 'lodash/isEmpty';

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '@/router/platform';
import { ACTIONS } from '@/webchat2/config/types';
import $bus from '@/platformSettings/bus';
import { makeMessageByType } from '@/store/modules/v2/websocket.js';
import { RESOURCE_STATE } from '@/constants.js';

const {
  CONVERSATION_ASSIGNED_TO_AGENT,
  CONVERSATION_TERMINATED,
  CONVERSATION_CLOSED,
  TRANSFER_REQUEST,
  MESSAGE_READ,
  GW_RECEIVED,
  CUSTOMER_SENT_RATING,
  CUSTOMER_ACTIVITY_STATUS_CHANGED,
} = ACTIONS;

Vue.use(Vuex);

const { CancelToken } = axios;
let cancelGetChat;
let cancelGetChats;

export default {
  namespaced: true,
  state: {
    currentChat: null,
    chatError: null,
    chats: [],
    chatCurrentPage: 1,
    chatPerPage: 15,
    chatPages: 0,
    chatAmount: 0,
    chatOrderBy: 'desc',
    chatFilter: null,
    chatMessages: [],
    browsingHistory: {},
    customerNotes: [],
    userInfo: {},
    fetchingChatsState: RESOURCE_STATE.IDLE,
    fetchingChatContentState: RESOURCE_STATE.IDLE,
    companyId: null,
    botId: null,
  },
  getters: {
    currentChatIndex(state) {
      if (state.currentChat) {
        return state.chats.findIndex(item => item.id === state.currentChat.id);
      }
      return null;
    },
    customerName(state) {
      if (!state.currentChat) return null;
      return state.currentChat.customer_name || null;
    },
  },
  mutations: {
    _setCompanyId(state, companyId) {
      state.companyId = companyId;
    },
    _setBotId(state, botId) {
      state.botId = botId;
    },
    _setCurrentChat(state, chat) {
      state.currentChat = Object.freeze(chat);
    },
    _setChatLoading(state, bool) {
      state.chatLoading = bool;
    },
    _setChatError(state, error) {
      state.chatError = error;
    },
    _setChats(state, chats) {
      state.chats = chats;
    },
    _setChatCurrentPage(state, currentPage) {
      state.chatCurrentPage = currentPage;
    },
    _setChatPerPage(state, perPage) {
      state.chatPerPage = perPage;
    },
    _setChatPages(state, pages) {
      state.chatPages = pages;
    },
    _setChatAmount(state, amount) {
      state.chatAmount = amount;
    },
    _setChatOrderBy(state, orderBy) {
      state.chatOrderBy = orderBy;
    },
    _setChatFilter(state, filter) {
      state.chatFilter = filter;
    },
    _setBrowsingHistory(state, value) {
      state.browsingHistory = value;
    },
    _setCustomerNotes(state, valueArr) {
      state.customerNotes = valueArr;
    },
    _setChatMessages(state, valueArr) {
      state.chatMessages = valueArr;
    },
    _setUserInfo(state, valueObj) {
      state.userInfo = valueObj;
    },
    _setFetchingChatsState(state, resourceState) {
      const {
        IDLE, PENDING, RESOLVED, REJECTED,
      } = RESOURCE_STATE;

      if (![IDLE, PENDING, RESOLVED, REJECTED].includes(resourceState)) {
        throw Error(
          `Invalid payload: only IDLE, PENDING, RESOLVED, and REJECTED are allowed, got ${resourceState}.`
        );
      }

      state.fetchingChatsState = resourceState;
    },
    _setFetchingChatContentState(state, resourceState) {
      const {
        IDLE, PENDING, RESOLVED, REJECTED,
      } = RESOURCE_STATE;

      if (![IDLE, PENDING, RESOLVED, REJECTED].includes(resourceState)) {
        throw Error(
          `Invalid payload: only IDLE, PENDING, RESOLVED, and REJECTED are allowed, got ${resourceState}.`
        );
      }

      state.fetchingChatContentState = resourceState;
    },
  },
  actions: {
    setCurrentChatByIndex({ state, commit, dispatch }, index) {
      commit('_setFetchingChatContentState', RESOURCE_STATE.PENDING);

      if (index < 0 || index >= state.chats.length) {
        commit('_setFetchingChatContentState', RESOURCE_STATE.REJECTED);
        throw new Error('Invalid index');
      }

      if (state.chats.length > 0) {
        const currentChat = state.chats[index];
        commit('_setCurrentChat', currentChat);

        Promise.allSettled([
          dispatch('getChatMessages', {
            gwId: currentChat.gateway_id,
            convoId: currentChat.id,
            convoType: currentChat.type,
          }),
          dispatch('getGatewayProfile'),
          dispatch('getBrowsingHistory'),
          dispatch('getCustomerNotes'),
          router
            .push({
              name: 'BotHistory',
              params: {
                companyId: state.companyId,
                botId: state.botId,
                history: 'chat',
                selectedId: currentChat.id,
              },
              query: state.chatFilter,
            })
            .catch(() => { }),
        ])
          .then(() => {
            commit('_setFetchingChatContentState', RESOURCE_STATE.RESOLVED);
          })
          .catch(err => {
            console.error(err);
            commit('_setFetchingChatContentState', RESOURCE_STATE.REJECTED);
          });
      }
    },
    getPreviousChat({
      state, rootState, getters, dispatch,
    }) {
      // Is first in not-first page
      if (state.chatCurrentPage > 1 && getters.currentChatIndex === 0) {
        // Reduce page number
        dispatch('setChatCurrentPage', state.chatCurrentPage - 1);
        // Get chat
        const { companyId, botId } = rootState.route.params;
        dispatch('getChats', { companyId, botId }).then(() => {
          // Set last one as current
          dispatch('setCurrentChatByIndex', state.chats.length - 1);
        });
      } else if (getters.currentChatIndex > 0 && getters.currentChatIndex < state.chatPerPage) {
        // Set previous as current
        dispatch('setCurrentChatByIndex', getters.currentChatIndex - 1);
      }
    },
    getNextChat({
      state, rootState, getters, dispatch,
    }) {
      if (
        state.chatCurrentPage < state.chatPages
        && state.chatCurrentPage > 0
        && getters.currentChatIndex === state.chats.length - 1
      ) {
        // Increase page number
        dispatch('setChatCurrentPage', state.chatCurrentPage + 1);
        // Get chat
        const { companyId, botId } = rootState.route.params;
        dispatch('getChats', { companyId, botId }).then(() => {
          // Set first one as current
          dispatch('setCurrentChatByIndex', 0);
        });
      } else if (state.chats[getters.currentChatIndex + 1]) {
        // Set next as current
        dispatch('setCurrentChatByIndex', getters.currentChatIndex + 1);
      }
    },
    getChats({ state, rootState, commit }, { companyId, botId }) {
      commit('_setFetchingChatsState', RESOURCE_STATE.PENDING);

      return new Promise((resolve, reject) => {
        // Call api if date range is ready
        if (state.chatFilter && (!state.chatFilter.date_begin || !state.chatFilter.date_end)) {
          commit('_setFetchingChatsState', RESOURCE_STATE.RESOLVED);
          return resolve();
        }

        if (cancelGetChats) {
          cancelGetChats();
        }

        const formatting = 'YYYY-MM-DD';
        const dateQuery = {
          timezone: rootState.uiv2.platformTimezone,
        };

        if (state.chatFilter && state.chatFilter.date_begin) {
          dateQuery.date_begin = Vue.dayjs(state.chatFilter.date_begin).format(formatting);
        }

        if (state.chatFilter && state.chatFilter.date_end) {
          dateQuery.date_end = Vue.dayjs(state.chatFilter.date_end).format(formatting);
        }

        const filters = {};
        const newFilter = state.chatFilter || {};
        Object.entries(newFilter).forEach(([key, val]) => {
          if (val !== null && val !== '') {
            filters[key] = val;
          }
        });

        $bus.$siniticApi
          .post(`/gateway/conversation/company/${companyId}/bot/${botId}/query_conversations`, {
            data: {
              page: state.chatCurrentPage,
              per_page: state.chatPerPage,
              sort: state.chatOrderBy,
              ...filters,
              ...dateQuery,
            },
            cancelToken: new CancelToken(c => {
              cancelGetChats = c;
            }),
          })
          .then(response => {
            const results = response.data;
            commit('_setChats', results.items);
            commit('_setChatPages', results.n_pages);
            commit('_setChatAmount', results.count);
            commit('_setFetchingChatsState', RESOURCE_STATE.RESOLVED);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            commit('_setFetchingChatsState', RESOURCE_STATE.REJECTED);
            return reject(error);
          });
      });
    },

    getChatMessages({
      commit, state, rootState, rootGetters,
    }, { gwId, convoId, convoType }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`/livechatgateway/chat_history/${state.botId}/conversation/${gwId}/${convoType}/${convoId}/messages`)
          .then(res => {
            const messages = res.data
              .filter(msg => [
                ...rootState.websocketv2._allowDisplayMsgTypes,
                CONVERSATION_ASSIGNED_TO_AGENT,
                CONVERSATION_TERMINATED,
                CONVERSATION_CLOSED,
                CUSTOMER_SENT_RATING,
              ].includes(msg.type))
              .map(msg => makeMessageByType(msg.type, msg, {
                agentsIdMap: rootGetters['websocketv2/allAgents'],
              }));

            commit('_setChatMessages', messages);
            return resolve(messages);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getBrowsingHistory({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (!state.currentChat) {
          commit('_setBrowsingHistory', {});
          reject();
          return;
        }

        const gwId = state.currentChat.gateway_id;
        const customerId = state.currentChat.customer_id;

        if (!gwId || !customerId) {
          commit('_setBrowsingHistory', {});
          reject();
          return;
        }

        $bus.$siniticApi
          .get(`/gateway3/${gwId}/t/${encodeURIComponent(customerId)}`)
          .then(res => {
            const results = res.data;
            commit('_setBrowsingHistory', results);
            resolve(results);
          })
          .catch(err => {
            commit('_setBrowsingHistory', {});
            reject(err);
          });
      });
    },

    getCustomerNotes({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (!state.currentChat) {
          commit('_setCustomerNotes', []);
          reject();
          return;
        }

        const conversationId = state.currentChat.id;

        if (!conversationId) {
          commit('_setCustomerNotes', []);
          reject();
          return;
        }

        $bus.$siniticApi
          .get(`/gateway3/notes/${state.companyId}/conversation/${encodeURIComponent(conversationId)}`)
          .then(res => {
            const results = res.data;
            commit('_setCustomerNotes', results);
            resolve(results);
          })
          .catch(err => {
            commit('_setCustomerNotes', []);
            reject(err);
          });
      });
    },

    getGatewayProfile({ state, rootState, commit }) {
      return new Promise((resolve, reject) => {
        if (!state.currentChat) {
          commit('_setUserInfo', {});
          reject();
          return;
        }

        const { gateway_id, id } = state.currentChat;

        $bus.$siniticApi
          .get(`/gateway/${state.companyId}/crm/gateway_profile/${gateway_id}/${id}`)
          .then(res => {
            const results = res.data;
            commit('_setUserInfo', results);
            resolve(results);
          })
          .catch(err => {
            commit('_setUserInfo', {});
            reject(err);
          });
      });
    },

    clearChats({ commit }) {
      commit('_setChatCurrentPage', 1);
      commit('_setChats', []);
    },
    resetChat({ commit, dispatch }) {
      commit('_setChats', []);
      commit('_setChatCurrentPage', 1);
      commit('_setChatPages', 0);
      commit('_setChatAmount', 0);
      commit('_setChatOrderBy', 'desc');
      commit('_setChatFilter', null);
      commit('_setFetchingChatsState', RESOURCE_STATE.IDLE);

      dispatch('clearCurrentChat');
    },
    clearCurrentChat({ commit }) {
      commit('_setCurrentChat', null);
      commit('_setChatMessages', []);
      commit('_setUserInfo', {});
      commit('_setBrowsingHistory', {});
      commit('_setCustomerNotes', []);
      commit('_setFetchingChatContentState', RESOURCE_STATE.IDLE);
    },
    setChatCurrentPage({
      state, commit, dispatch,
    }, page) {
      if (page < 0 || page > state.chatPages) {
        throw new Error('Invalid page');
      }

      commit('_setChatCurrentPage', page);

      dispatch('getChats', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data?.count > 0) {
          dispatch('setCurrentChatByIndex', 0);
        }
      });
    },
    setChatPerPage({ state, commit, dispatch }, perPage) {
      dispatch('clearCurrentChat');
      commit('_setChatPerPage', perPage);
      commit('_setChatCurrentPage', 1);

      dispatch('getChats', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data?.count > 0) {
          dispatch('setCurrentChatByIndex', 0);
        }
      });
    },
    setChatFilter({
      state, getters, commit, dispatch,
    }, filter) {
      dispatch('clearCurrentChat');
      commit('_setChatFilter', filter);
      commit('_setChatCurrentPage', 1);

      dispatch('getChats', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data?.count > 0) {
          dispatch('setCurrentChatByIndex', getters.currentChatIndex ?? 0);
        }
      });
    },
    setChatOrderBy({ state, commit, dispatch }, orderBy) {
      dispatch('clearCurrentChat');
      commit('_setChatOrderBy', orderBy);
      commit('_setChatCurrentPage', 1);

      dispatch('getChats', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data?.count > 0) {
          dispatch('setCurrentChatByIndex', 0);
        }
      });
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
