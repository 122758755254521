import Vue from 'vue';
import Vuex from 'vuex';

import $bus from '@/platformSettings/bus';

Vue.use(Vuex);

const MUTATION = {
  SET_ENABLED: 'SET_ENABLED',
};

export default {
  namespaced: true,

  state: {
    enabled: false,
  },

  getters: {},

  mutations: {
    [MUTATION.SET_ENABLED](state, enabled) {
      state.enabled = enabled;
    },
  },

  actions: {
    getByodSettings({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        if (!companyId) reject(new Error('companyId is required'));

        $bus.$siniticApi
          .get(`dds/${companyId}/byod`)
          .then(response => {
            const result = response.data;
            commit(MUTATION.SET_ENABLED, result.enabled);
            resolve(result);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateByodSettings({ commit }, { companyId, payload }) {
      return new Promise((resolve, reject) => {
        if (!companyId) reject(new Error('companyId is required'));

        $bus.$siniticApi
          .post(`dds/${companyId}/byod`, {
            data: payload,
          })
          .then(response => {
            const result = response.data;
            commit(MUTATION.SET_ENABLED, result.enabled);
            resolve(result);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    validateByodSettings(_, { companyId, payload }) {
      return new Promise((resolve, reject) => {
        if (!companyId) reject(new Error('companyId is required'));

        $bus.$siniticApi
          .post(`dds/${companyId}/byod/validate`, {
            data: payload,
          })
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    byodHealthCheck(_, companyId) {
      return new Promise((resolve, reject) => {
        if (!companyId) reject(new Error('companyId is required'));

        $bus.$siniticApi
          .get(`dds/${companyId}/byod/healthz`)
          .then(response => {
            const result = response.data;
            resolve(result);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    upgradeByodSchema(_, companyId) {
      return new Promise((resolve, reject) => {
        if (!companyId) reject(new Error('companyId is required'));

        $bus.$siniticApi
          .post(`dds/${companyId}/byod/upgrade_schema`)
          .then(() => {
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getByodErrorLogs(_, { companyId, params }) {
      return new Promise((resolve, reject) => {
        if (!companyId) reject(new Error('companyId is required'));

        $bus.$siniticApi
          .get(`dds/${companyId}/byod/log`, { params })
          .then(response => {
            const results = response.data;
            resolve(results);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};
