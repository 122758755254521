import maxBy from 'lodash/maxBy';

import {
  MESSAGE_TYPE, SEND_STATUS, isVisibleMessage, isUnreadMessageFromBotOrAgent,
} from '@/helpers/messageHelper';

export default (message, $store, { MUTATION, CONVERSATION_TYPE }) => {
  const { state, commit, dispatch } = $store;

  const { conversations } = message;

  const convs = conversations[CONVERSATION_TYPE];

  let conversationId;

  if (message.new_conversation) {
    conversationId = message.new_conversation;
  } else {
    conversationId = maxBy(Object.keys(convs), cid => {
      const _chat = convs[cid];
      if (_chat.active && _chat.chat_history.some(isVisibleMessage)) {
        return new Date(_chat.updated_at || _chat.creation_date).getTime();
      }
      return -1;
    });

    Object.values(convs).forEach(item => {
      const unreadMessagesCount = item.chat_history.filter(
        msg => isVisibleMessage(msg) && isUnreadMessageFromBotOrAgent(msg)
      ).length;

      commit(MUTATION.increaseChatUnreadMessagesCount, {
        chatId: item.id,
        num: unreadMessagesCount,
      });
    });
  }

  let numUnreadMsg = 0;
  const unreadMessages = [];
  let lastQuickReply = null;

  const modifiedConvs = convs[conversationId].chat_history
    .map(msg => {
      let sendStatus;
      if (msg.read_at !== null) {
        sendStatus = SEND_STATUS.read;
      } else {
        sendStatus = SEND_STATUS.received;
      }
      msg.sendStatus = sendStatus;

      // Set quick replies
      if (msg.type === MESSAGE_TYPE.QUICK_REPLIES) {
        lastQuickReply = msg;
      }

      if (isUnreadMessageFromBotOrAgent(msg)) {
        numUnreadMsg += 1;
        unreadMessages.push(msg);
      }

      return msg;
    })
    .filter(msg => isVisibleMessage(msg) || msg.type === MESSAGE_TYPE.QUICK_REPLIES);

  if (lastQuickReply) {
    commit(MUTATION.setQuickReply, { message: lastQuickReply });
  }

  convs[conversationId].chat_history = modifiedConvs;
  commit(MUTATION.setChats, convs);

  commit(MUTATION.setActiveChatId, conversationId);

  if (!state.webchatState) {
    commit(MUTATION.setWebchatState);
  }

  if (state.uiSettings.enable_sneakpeek) {
    dispatch('getSneakPeekByUrl', state.parentPageURL)
      .then(data => {
        if (data) {
          if (data.display_after_sec > 0) {
            setTimeout(() => {
              dispatch('sneakPeek/set', data, { root: true });
              dispatch('showSneakPeek');
            }, data.display_after_sec * 1000);
          } else if (data.display_after_sec === 0) {
            dispatch('sneakPeek/set', data, { root: true });
            dispatch('showSneakPeek');
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
};
