import Vue from 'vue';
import Vuex from 'vuex';

import $bus from '@/platformSettings/bus';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    allAlias: [],
    detailsAlias: [],
  },
  mutations: {
    _setAllAlias(state, data) {
      state.allAlias = data;
    },
    _setDetailsAlias(state, data) {
      state.detailsAlias = data;
    },
    _deleteAlias(state, { aliasId }) {
      const index = state.allAlias.findIndex(alias => alias.id === aliasId);
      state.allAlias.splice(index, 1);
    },
  },
  actions: {
    getAllAlias({ commit }, { userId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`profile/user/${userId}/alias`)
          .then(response => {
            const results = response.data;
            if (results.length === 0) {
              return resolve();
            }
            commit('_setAllAlias', results);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    getDetailsAlias({ commit }, { userId, aliasId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`profile/user/${userId}/alias/${aliasId}`)
          .then(response => {
            const results = response.data;
            if (results.length === 0) {
              return resolve();
            }
            commit('_setDetailsAlias', results);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    createAlias({ dispatch }, {
      userId, fullName, avatar, isActive,
    }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();

        formData.set('fullname', fullName);
        formData.set('is_active', isActive);

        if (avatar !== undefined) {
          formData.append('profile_pic', avatar);
        }

        $bus.$siniticApi
          .post(`profile/user/${userId}/alias`, {
            data: formData,
          })
          .then(() => {
            dispatch('getAllAlias', { userId });
            dispatch('userv2/getAliases', userId, { root: true });
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateAlias({ dispatch }, {
      userId, aliasId, fullName, avatar, isActive, remove_profile_pic,
    }) {
      const formData = new FormData();

      if (fullName !== undefined) {
        formData.append('fullname', fullName);
      }
      if (isActive !== undefined) {
        formData.append('is_active', isActive);
      }
      if (avatar !== undefined) {
        formData.append('profile_pic', avatar);
      }
      if (remove_profile_pic !== undefined) {
        formData.append('remove_profile_pic', remove_profile_pic);
      }

      return $bus.$siniticApi
        .put(`/profile/user/${userId}/alias/${aliasId}`, {
          data: formData,
        })
        .then(() => {
          dispatch('getAllAlias', { userId });
          dispatch('userv2/getAliases', userId, { root: true });
        });
    },
    deleteAlias({ commit, dispatch, rootState }, { userId, aliasId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`/profile/user/${userId}/alias/${aliasId}`)
          .then(() => {
            commit('_deleteAlias', { aliasId });
            dispatch('userv2/getAliases', userId, { root: true });
            if (rootState.userv2.currentAlias === aliasId) {
              dispatch('userv2/setCurrentAlias', null, { root: true });
            }
            return resolve();
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
