import { CONVERSATION_STATE } from '@/helpers/livechatConstants';
import { getGatewayType } from '@/helpers/gatewayHelper.js';

export default (msg, $store) => {
  const {
    state, commit, dispatch, rootState, rootGetters,
  } = $store;
  const myUserId = rootState.userv2.user.user_id;

  function isAllowToView({ ateam_id, agent_id }) {
    let allowToView = false;

    if (ateam_id) {
      const ateam = rootGetters['chat/visibleTeamsById'][ateam_id];
      if (ateam) {
        // Pending chats are visible
        if (agent_id === null || agent_id === myUserId) {
          allowToView = true;
        } else if (ateam.i_can_do.manage) {
          allowToView = ateam.agents.some(agent => agent.user_id === agent_id);
        }
      }
    }

    return allowToView;
  }

  const allowToView = isAllowToView(msg);
  console.log({ allowToView });

  let conversation = state.conversations.find(
    c => c.id === msg.cid && c.type === msg.ctype && c.gateway_id === msg.gwid
  );

  if (!allowToView) {
    if (conversation) {
      console.warn('Not allow to view, would be removed.');
      dispatch('_removeConversationFromList', msg.conversation_id);
    }
    return;
  }

  if (!conversation) {
    // New conversation
    if (msg.ateam_id === undefined) msg.ateam_id = null;
    if (msg.agent_id === undefined) msg.agent_id = null;

    conversation = {
      active: true,
      gateway_id: msg.gwid,
      gateway_type: getGatewayType(msg.ctype),
      id: msg.cid,
      type: msg.ctype,
      bot_id: msg.bid,
      ateam_id: msg.ateam_id,
      agent_id: msg.agent_id,
      customer_id: msg.customer_id,
      customer_type: msg.customer_type,
      customer_num_visit: msg.customer_num_visit,
      customer_name: msg.customer_name,
      customer_pic_url: msg.customer_pic_url,
      messages: [],
      count_msgs: 0,
      count_unread_msgs: 0,
      last_msg: null,
      last_msg_date: null,
      prop$$: {
        state: CONVERSATION_STATE.NEW,
      },
    };

    commit('_addConversation', conversation);
  }

  // Add message to existing conversation
  dispatch('addConversationMessage', msg);
};
