import { createEnum } from '@/utils.js';

export const CONVERSATION_STATE = createEnum({
  NORMAL: 'NORMAL',
  NEW: 'NEW',
});

export const CONVERSATION_PERMISSION = createEnum({
  FULL: 'FULL',
  READONLY: 'READONLY',
  DENIED: 'DENIED',
  EMPTY: 'EMPTY',
});

export const MESSAGE_SEND_STATUS = createEnum({
  SENDING: 'SENDING',
  RECEIVED: 'RECEIVED',
  FAILED: 'FAILED',
});
