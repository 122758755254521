/* eslint-disable no-underscore-dangle */

// Websocket handles communication with the backend, as well as keep track of conversation histories

import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import throttle from 'lodash/throttle';
import getObjValue from 'lodash/get';

import $bus from '@/platformSettings/bus';
import i18n from '@/lang/i18n';
import $router from '@/router/platform';
import { CUSTOMER_STATUS, ACTIONS } from '@/webchat2/config/types';
import { playAlertSound } from '@/utils';
import { CONVERSATION_STATE, MESSAGE_SEND_STATUS } from '@/helpers/livechatConstants';
import {
  MESSAGE_TYPE, AUTHOR_TYPE, ALERTABLE_MESSAGE_TYPE, handleLineEmojiMsg, stripHtml,
} from '@/helpers/messageHelper';

import handlerDispatcher from './livechatHandlers/index.js';

// Set the name of the hidden property and the change event for visibility
let hidden;
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
}

let socket = null;
const {
  PONG,
  CONVERSATION_ASSIGNED_TO_AGENT,
  CONVERSATION_TERMINATED,
  CONVERSATION_CLOSED,
  TRANSFER_REQUEST,
  TRANSFER_REQUEST_CONFIRM_STATUS_CHANGED,
  MESSAGE_READ,
  GW_RECEIVED,
  CUSTOMER_SENT_RATING,
  CUSTOMER_ACTIVITY_STATUS_CHANGED,
} = ACTIONS;

export const makeMessageByType = (type, msg = {}, { agentsIdMap } = {}) => {
  switch (type) {
    case CONVERSATION_TERMINATED:
      return {
        ...msg,
        text: i18n.t('chatcenter:conversation_terminated_by_customer'),
      };

    case CONVERSATION_CLOSED: {
      let agentName = '';

      switch (msg.closed_by_user_id) {
        case 'platform:api':
          agentName = 'The system';
          break;

        default:
          agentName = getObjValue(agentsIdMap, [msg.closed_by_user_id, 'fullname'], msg.closed_by_user_id);
          break;
      }

      return {
        ...msg,
        text: i18n.t('chatcenter:conversation_closed', [agentName]),
      };
    }

    case CONVERSATION_ASSIGNED_TO_AGENT: {
      const isUnassigned = msg.agent_id == null;

      const oldAgentName = msg.old_agent_id
        ? getObjValue(agentsIdMap, [msg.old_agent_id, 'fullname'], msg.old_agent_id)
        : i18n.t('platform:conversations.tabs.unassigned');
      const newAgentName = getObjValue(agentsIdMap, [msg.agent_id, 'fullname'], msg.agent_id);

      return {
        ...msg,
        text: isUnassigned
          ? i18n.t('chatcenter:conversation_transferred_to', null, [i18n.t('platform:conversations.tabs.unassigned')])
          : i18n.t('chatcenter:conversation_transferred_from_to', null, [oldAgentName, newAgentName]),
      };
    }

    case MESSAGE_TYPE.THIRDPARTY_AGENT_TAKEOVER:
      return {
        ...msg,
        text: 'Chat is transferred to the third-party channel.',
      };

    case MESSAGE_TYPE.AUDIO:
    case MESSAGE_TYPE.VIDEO:
    case MESSAGE_TYPE.IMAGE:
    case MESSAGE_TYPE.FILE:
      return {
        ...msg,
        text: msg.text || i18n.t('webchat:file_received'),
      };

    case MESSAGE_TYPE.HYPERLINK:
      return {
        ...msg,
        text: msg.label || msg.link,
      };

    case MESSAGE_TYPE.TEXT:
    default:
      return msg;
  }
};

export default {
  namespaced: true,
  state: {
    companyId: null,
    agents: {},
    // All the agent teams for the existing company (for whom the user can see messages)
    agentsTeams: [],
    // All the agent teams for the existing company (without restriction)
    allAgentsTeams: [],
    // Status of all the agents involved in the company - key is user_id
    // All conversations for Special Takeover UI
    // [{ agents, ateam_id, ateam_name, bot_id }]
    conversations: [],
    // Store transfer requests from another agents
    transferRequests: [],
    // Store conversations ids for
    // which welcome message has been sent
    // welcomeMessagesSent: {},
    // We need to know which conversation is opened
    activeConversationId: null,
    // Store archive conversation timeout
    // Sample:
    // {
    //   [conversation_id]: [countdown_seconds]
    // }
    archiveConversationTimeout: {},
    // Store archive conversation interval
    // Sample:
    // {
    //   [conversation_id]: [interval_id]
    // }
    archiveConversationInterval: {},
    archiveConversationDelayMs: 30 * 1000,
    closeConversationDelayMs: 5 * 1000,

    sourceTypes: ['all', 'ws:thread'],
    connected: false,
    messagesQueue: [],

    // backoff delay for retrying to connect
    connectionRetrying: false,

    isSending: false,
    socketStatus: 'Close',

    _connectionRetryTimeout: null,

    // 1, 3, 5, 10, 15, 20, 30 seconds
    _connectionRetryDelay: [1000, 3000, 5000, 10000, 15000, 20000, 30000],
    _connectionRetryAttempts: 0,
    _pingMsg: 'ping',
    _pingTimeout: 10000,
    _allowDisplayMsgTypes: [
      MESSAGE_TYPE.TEXT,
      MESSAGE_TYPE.AUDIO,
      MESSAGE_TYPE.VIDEO,
      MESSAGE_TYPE.IMAGE,
      MESSAGE_TYPE.FILE,
      MESSAGE_TYPE.MANUAL_TRIGGER,
      MESSAGE_TYPE.CAROUSEL,
      MESSAGE_TYPE.BUTTON_CLICKED,
      MESSAGE_TYPE.QUICK_REPLIES,
      MESSAGE_TYPE.LINE_STICKER,
      MESSAGE_TYPE.TEXT_WITH_LINE_EMOJI,
      MESSAGE_TYPE.VIBER_STICKER,
      MESSAGE_TYPE.TELEGRAM_STICKER,
      MESSAGE_TYPE.TELEGRAM_CONTACT,
      MESSAGE_TYPE.LOCATION,
      MESSAGE_TYPE.HYPERLINK,
      MESSAGE_TYPE.THIRDPARTY_AGENT_TAKEOVER,
      MESSAGE_TYPE.METAWHATSAPP_CONTACT,
    ],
  },
  getters: {
    activeConversation(state) {
      if (state.activeConversationId === null) return null;

      return state.conversations.find(convo => convo.id === state.activeConversationId) ?? null;
    },
    visibleConversationMessages(state, getters) {
      if (!getters.activeConversation || !getters.activeConversation.messages) return [];

      return getters.activeConversation.messages.filter(message => {
        if (!message.hasOwnProperty('old_agent_id') || !message.hasOwnProperty('agent_id')) return true;

        return message.old_agent_id !== message.agent_id;
      });
    },
    allAgents(state) {
      return state.allAgentsTeams
        .map(team => team.users)
        .reduce((arr, agents) => {
          arr = [...arr, ...agents];
          return arr;
        }, [])
        .reduce((map, agent) => {
          map[agent.user_id] = agent;
          return map;
        }, {});
    },
  },
  mutations: {
    _setConversations(state, c) {
      state.conversations = c;
    },
    _setAgents(state, agents) {
      state.agents = {
        ...agents,
      };
    },
    _setTransferRequests(state, requests) {
      state.transferRequests = requests;
    },
    _setConnectionRetryAttempts(state, attempts) {
      state._connectionRetryAttempts = attempts;
    },
    _setRetryTimeout(state, timeout) {
      state._connectionRetryTimeout = timeout;
    },
    _clearRetryTimeout(state) {
      if (state._connectionRetryTimeout) {
        window.clearTimeout(state._connectionRetryTimeout);
        state._connectionRetryTimeout = null;
      }
    },
    _addConversation(state, conversation) {
      state.conversations.unshift(conversation);
    },
    _closeConversation(state, conversation) {
      const filtered = state.conversations.filter(convo => convo.id !== conversation.id);

      state.conversations = filtered;
    },
    _setActiveConversation(state, conversationId) {
      if (conversationId === undefined) conversationId = null;
      state.activeConversationId = conversationId;
    },
    _updateConversation(state, { conversationId, data }) {
      const convoIndex = state.conversations.findIndex(convo => convo.id === conversationId);

      if (convoIndex === -1) return;

      const updatedConvo = {
        ...state.conversations[convoIndex],
        ...data,
      };

      Vue.set(state.conversations, convoIndex, updatedConvo);
    },
    _pushMessageInConversation(state, { conversationId, newMessage }) {
      const convo = state.conversations.find(convo => convo.id === conversationId);

      if (!convo) return;

      convo.messages.push(newMessage);
    },
    _updateMessageByIndex(state, { conversationId, messageIndex, data }) {
      const convo = state.conversations.find(convo => convo.id === conversationId);

      if (!convo || !convo.messages[messageIndex]) return;

      Vue.set(convo.messages, messageIndex, {
        ...convo.messages[messageIndex],
        ...data,
      });
    },
    updateMessageSendStatusByRef(state, { conversationId, ref, sendStatus }) {
      const convo = state.conversations.find(convo => convo.id === conversationId);
      if (!convo) return;

      const messageIndex = convo.messages.findIndex(msg => msg.ref === ref);
      if (messageIndex === -1) return;

      if (!Object.keys(MESSAGE_SEND_STATUS).includes(sendStatus)) return;

      const message = convo.messages[messageIndex];

      Vue.set(convo.messages, messageIndex, {
        ...message,
        prop$$: {
          ...message.prop$$,
          sendStatus,
        },
      });
    },
    updateMessageSendProgressByRef(state, {
      conversationId, ref, loaded: uploadLoaded, total: uploadTotal,
    }) {
      const convo = state.conversations.find(convo => convo.id === conversationId);
      if (!convo) return;

      const messageIndex = convo.messages.findIndex(msg => msg.ref === ref);
      if (messageIndex === -1) return;

      const message = convo.messages[messageIndex];

      Vue.set(convo.messages, messageIndex, {
        ...message,
        prop$$: {
          ...message.prop$$,
          uploadLoaded,
          uploadTotal,
        },
      });
    },
    removeMessageByRef(state, { conversationId, ref }) {
      const convo = state.conversations.find(convo => convo.id === conversationId);
      if (!convo) return;
      const messageIndex = convo.messages.findIndex(msg => msg.ref === ref);
      if (messageIndex === -1) return;
      Vue.delete(convo.messages, messageIndex);
    },
    _setConversationTerminated(state, message) {
      state.conversations = state.conversations.map(c => {
        const newConversation = { ...c };
        if (
          newConversation.id === message.conversation_id
          && newConversation.type === message.conversation_type
          && newConversation.gateway_id === message.gateway_id
        ) {
          newConversation.active = false;
          newConversation.messages.push(makeMessageByType(CONVERSATION_TERMINATED, message));
        }
        return newConversation;
      });
    },
    _setConversationClosed(state, { message, agentsIdMap }) {
      const conversation = state.conversations.find(conversation => (
        conversation.id === message.conversation_id
        && conversation.type === message.conversation_type
        && conversation.gateway_id === message.gateway_id
      ));

      // If conversation is already deactivated, don't push duplicated message.
      if (!conversation || conversation.active === false) return;

      // Deactivate conversation
      conversation.active = false;
      // Push CONVERSATION_CLOSED message
      conversation.messages.push(makeMessageByType(CONVERSATION_CLOSED, message, { agentsIdMap }));
    },
    _getCustomerRating(state, message) {
      state.conversations = state.conversations.map(c => {
        const newConversation = { ...c };
        if (
          newConversation.id === message.conversation_id
          && newConversation.type === message.conversation_type
          && newConversation.gateway_id === message.gateway_id
        ) {
          newConversation.active = false;
          newConversation.messages.push(makeMessageByType(CUSTOMER_SENT_RATING, message));
        }
        return newConversation;
      });
    },
    _updateConversationAgent(state, { message, agentsIdMap }) {
      const convo = state.conversations.find(convo => convo.id === message.conversation_id);

      if (!convo) return;

      convo.agent_id = message.agent_id;
      convo.ateam_id = message.ateam_id;
      convo.customer_id = message.customer_id;
      convo.messages.push(makeMessageByType(CONVERSATION_ASSIGNED_TO_AGENT, message, { agentsIdMap }));

      state.conversations = [...state.conversations.filter(convo => convo.id !== message.conversation_id), convo];
    },
    _removeConversation(state, conversation_id) {
      if (!conversation_id) return;

      state.conversations = state.conversations.filter(convo => convo.id !== conversation_id);
    },
    _updateAgentsStatus(state, newAgentStatus) {
      const oldAgent = state.agents[newAgentStatus.id];
      let newAgent = {};
      if (!oldAgent) {
        // New Agent
        newAgent = {
          ...newAgentStatus,
        };
      } else if (new Date(newAgentStatus.ts) > new Date(oldAgent.ts)) {
        // Update by new info
        newAgent = {
          ...oldAgent,
          ...newAgentStatus,
        };
      } else {
        // Use Old info
        newAgent = oldAgent;
      }
      state.agents = {
        ...state.agents,
        [newAgent.id]: newAgent,
      };
    },
    _setAgentsTeams(state, newAgentsTeams) {
      state.agentsTeams = newAgentsTeams;
    },
    _setAllAgentsTeams(state, newAgentsTeams) {
      state.allAgentsTeams = newAgentsTeams;
    },
    _setMessagesQueue(state, messagesQueue) {
      state.messagesQueue = messagesQueue;
    },
    _setSourceTypes(state, sourceTypes) {
      state.sourceTypes = sourceTypes;
    },
    _setConnected(state, bool) {
      state.connected = bool;
    },
    _addMessageToQueue(state, message) {
      const existingMsgIndex = state.messagesQueue.findIndex(msg => msg.ref === message.ref);

      if (existingMsgIndex >= 0) {
        state.messagesQueue.splice(existingMsgIndex, 1, message);
      } else {
        state.messagesQueue.push(message);
      }
    },
    _setRetrying(state, bool) {
      state.connectionRetrying = bool;
    },
    _setSending(state, isSending) {
      state.isSending = isSending;
    },
    _setSocketStatus(state, status) {
      state.socketStatus = status;
    },
    _setArchiveConversationTimeout(state, { conversation_id, timeout }) {
      Vue.set(state.archiveConversationTimeout, conversation_id, timeout);
    },
    _setArchiveConversationInterval(state, { conversation_id, intervalId }) {
      Vue.set(state.archiveConversationInterval, conversation_id, intervalId);
    },
    _removeArchiveConversationTimeout(state, conversation_id) {
      Vue.delete(state.archiveConversationTimeout, conversation_id);
    },
    _removeArchiveConversationInterval(state, conversation_id) {
      Vue.delete(state.archiveConversationInterval, conversation_id);
    },
    _setConversationState(state, { conversationId, newState }) {
      const convo = state.conversations.find(convo => convo.id === conversationId);

      if (!convo) return;

      convo.prop$$ = {
        ...convo.prop$$,
        state: newState,
      };
    },
  },
  actions: {
    watchBot({ dispatch }, botId) {
      dispatch('sendMessage', {
        type: 'watch_bot',
        botId,
      });
    },
    startListening($store, { companyId }) {
      if (socket) return;

      const { state, commit, dispatch } = $store;

      commit('_clearRetryTimeout');

      try {
        const baseUrl = `${window.$SiniticConfig.ws_url}/platform/V1/?`
          + `company_id=${companyId}&`
          + `token=${$bus.$siniticApi.getToken('X-SINITIC-TOKEN')}`;

        console.log('Websocket:: Starting listening to conversations');
        socket = new WebSocket(baseUrl);

        socket.onopen = () => {
          console.log('Websocket:: onopen ✅');
          if (state.connectionRetrying) {
            $bus.$emit('WS_RECONNECTED');
          }
          commit('_setConnected', true);
          commit('_setRetrying', false);
          commit('_setConnectionRetryAttempts', 0);
          commit('_setSocketStatus', 'Open');
          dispatch('sendMessage', {
            type: 'get_status',
          });
        };

        socket.onmessage = e => {
          const msg = JSON.parse(e.data);

          if (msg.type !== PONG) {
            console.log('Websocket:: Text message received', msg.type, msg);
          }

          // Send message on the bus
          $bus.$emit(`ws:${msg.type}`, msg);

          // Get message handler by type
          const handler = handlerDispatcher[msg.type];

          if (handler) {
            // Execute handler
            handler(msg, $store, { companyId });
          } else {
            console.error(`No handler for ${msg.type}`);
          }
        };

        socket.onclose = closeEvent => {
          console.log('Websocket:: onclose ❌');

          if (closeEvent.code === 4003) {
            commit('_setSocketStatus', 'Forbidden');
          }

          socket = null;
          commit('_setConnected', false);
          commit('_setRetrying', false);
          commit('_setSocketStatus', 'Close');

          // Returns a Boolean that Indicates whether or not the connection was cleanly closed.
          if (!closeEvent.wasClean) {
            // In case of accidental disconnect, try to reconnect (using the same conversationId)
            const delayArrayIndex = state._connectionRetryAttempts >= state._connectionRetryDelay.length
              ? state._connectionRetryDelay.length - 1
              : state._connectionRetryAttempts;
            const exponentialBackoff = Math.floor(Math.random() * 1000 + state._connectionRetryDelay[delayArrayIndex]);

            commit('_setConnectionRetryAttempts', state._connectionRetryAttempts + 1);

            $bus.$emit('WS_DISCONNECTED', { retryDelay: exponentialBackoff });

            console.warn(
              `Websocket connection closed - trying to reconnect in ${exponentialBackoff / 1000} seconds`,
              closeEvent
            );

            dispatch('startRetryTimeout', {
              companyId,
              ms: exponentialBackoff,
            });
          } else {
            console.warn('Websocket connection closed cleanly.');
          }
        };
      } catch (err) {
        throw new Error(err);
      }
    },
    startRetryTimeout({ commit, dispatch }, { ms, companyId }) {
      commit(
        '_setRetryTimeout',
        setTimeout(() => {
          console.warn('Reconnect triggered by timeout.');
          dispatch('startReconnect', { companyId });
        }, ms)
      );
    },
    startReconnect({ commit, dispatch }, { companyId }) {
      commit('_setRetrying', true);
      $bus.$emit('WS_RECONNECTING');

      // Wait for 1 second and try establish new connection,
      // so user could see the reconnecting notification.
      setTimeout(() => {
        dispatch('startListening', { companyId });
      }, 1000);
    },
    stopListening({ commit }) {
      if (socket != null) {
        console.log('Websocket:: Stop listening to conversations');

        commit('_setConnected', false);
        commit('_setRetrying', false);
        socket.close();
        socket = null;
      }
    },
    restartWebsocket({ dispatch }) {
      dispatch('stopListening');
      setTimeout(() => {
        console.log('restarted');
        dispatch('startListening');
        // Arbitrary timeout
      }, 2000);
    },
    async addConversationMessage({
      state, commit, dispatch, rootState, rootGetters,
    }, message) {
      const myUserId = rootState.userv2.user.user_id;

      const convo = state.conversations.find(
        c => c.id === message.cid && c.type === message.ctype && c.gateway_id === message.gwid
      );

      if (!convo) return;

      /* Don't add duplicated messages */
      const presentIndex = convo.messages.findIndex(
        msg => (msg.ref && msg.ref === message.ref) || (msg.id && msg.id === message.id)
      );

      if (presentIndex > -1) {
        // Update message with same id/ref
        commit('_updateMessageByIndex', {
          conversationId: convo.id,
          messageIndex: presentIndex,
          data: message,
        });

        commit('updateMessageSendStatusByRef', {
          conversationId: convo.id,
          ref: message.ref,
          sendStatus: MESSAGE_SEND_STATUS.RECEIVED,
        });

        return;
      }

      const isActiveConversation = convo.id === state.activeConversationId;
      const isPendingChat = convo.agent_id == null;
      const isMyChat = convo.agent_id === myUserId;
      const isHumanMessage = message.author && message.author.type === AUTHOR_TYPE.HUMAN;
      const isAlertableMessage = ALERTABLE_MESSAGE_TYPE.has(message.type);
      const isMyTeam = message.ateam_id in rootGetters['chat/visibleTeamsById'];
      const isMyMessage = convo.agent_id === rootState.userv2.user.user_id;
      const isInLiveChat = rootState.route.name === 'LiveChat';

      function isAllowToServe(ateam_id) {
        if (!ateam_id || !(ateam_id in rootGetters['chat/visibleTeamsById'])) return false;

        const ateam = rootGetters['chat/visibleTeamsById'][ateam_id];

        return !!ateam?.i_can_do?.serve_customer;
      }
      const allowToServe = isAllowToServe(message.ateam_id);
      console.log({ allowToServe });

      const updateData = {
        last_msg: makeMessageByType(message.type, message),
        last_msg_date: message.ts,
        count_msgs: convo.count_msgs + 1,
      };

      if (isHumanMessage) {
        updateData.count_unread_msgs = convo.count_unread_msgs + 1;
      }

      commit('_updateConversation', {
        conversationId: convo.id,
        data: updateData,
      });

      commit('_pushMessageInConversation', {
        conversationId: convo.id,
        newMessage: {
          read_at: null,
          ...message,
          prop$$: {
            ...message.prop$$,
            sendStatus: MESSAGE_SEND_STATUS.RECEIVED,
          },
        },
      });

      if (isPendingChat || !(isMyChat && isActiveConversation)) {
        commit('_setConversationState', {
          conversationId: convo.id,
          newState: CONVERSATION_STATE.NEW,
        });
      }

      const shouldSendAlert = isHumanMessage
        && isAlertableMessage
        && (isPendingChat || isMyMessage)
        && isMyTeam
        && allowToServe
        && !(document.hasFocus() && !document[hidden] && isInLiveChat && isActiveConversation);

      console.log('shouldSendAlert', shouldSendAlert);

      if (shouldSendAlert) {
        if (!rootState.chat.enableBrowserNotification) {
          console.warn('Browser notification is disabled.');
        } else {
          const getMessageSummary = function (msg) {
            if (!msg) return null;

            switch (msg.type) {
              case 'file':
                return i18n.t('platform:message_summary.file');

              case 'metawhatsapp_contact':
              case 'telegram_contact':
                return i18n.t('platform:message_summary.contact');

              case 'image':
                return i18n.t('platform:message_summary.image');

              case 'line_sticker':
              case 'viber_sticker':
              case 'telegram_sticker':
                return i18n.t('platform:message_summary.sticker');

              case 'video':
                return i18n.t('platform:message_summary.video');

              case 'audio':
                return i18n.t('platform:message_summary.audio');

              case 'carousel':
                return i18n.t('platform:message_summary.carousel');

              case 'location':
                return i18n.t('platform:message_summary.location');

              case 'hyperlink':
                return msg.label;

              case 'text_with_line_emoji':
                return handleLineEmojiMsg(msg);

              case 'text':
                return stripHtml(msg.text);

              default:
                return null;
            }
          };

          dispatch('_notifyIfNotInLiveChat', {
            title: convo.customer_name || convo.customer_id.slice(-5),
            options: {
              body: getMessageSummary(message),
              tag: convo.id,
            },
            callback: {
              onClick: () => {
                if (rootState.route.name === 'LiveChat' && rootState.route.params.conversationId === convo.id) return;

                $router.push({
                  name: 'LiveChat',
                  params: {
                    conversationId: convo.id,
                  },
                });
              },
            },
          });

          dispatch('playNotificationSound');
        }
      }

      if (
        isHumanMessage
        && isMyChat
        && isActiveConversation
        && isInLiveChat
        && document.hasFocus()
        && !document[hidden]
      ) {
        // Read message
        await dispatch('readUnreadMessages', {
          conversationId: convo.id,
          messageIds: [message.id],
        });
      }
    },
    changeMyStatus({ dispatch }, { status, statusMessage }) {
      return dispatch('sendMessage', {
        type: 'change_status',
        status,
        status_message: statusMessage,
      });
    },
    retryMessageTimeout({ state, dispatch, commit }, ref) {
      return new Promise((resolve, reject) => {
        const q_msg = state.messagesQueue.find(msg => msg.ref === ref);

        if (!q_msg) {
          console.warn('Websocket:: Message not found', ref);
          return reject(new Error('Message not found'));
        }

        if (q_msg.prop$$.sendStatus === MESSAGE_SEND_STATUS.FAILED) {
          const unMarkedMsg = {
            ...q_msg,
            prop$$: {
              ...q_msg.prop$$,
              sendStatus: MESSAGE_SEND_STATUS.SENDING,
            },
          };
          commit('_addMessageToQueue', unMarkedMsg);
        }

        dispatch('sendMessage', q_msg)
          .then(res => {
            console.log('Retrying true');
            const updatedQueueMsgs = state.messagesQueue.filter(qm => qm.ref !== ref);
            commit('_setMessagesQueue', updatedQueueMsgs);
            return resolve(res);
          })
          .catch(err => {
            console.warn('Websocket:: Message failed to send', q_msg);
            dispatch('markFailed', q_msg.ref);
            return reject(err);
          });
      });
    },
    sendMessage({ state, dispatch, commit }, message) {
      return new Promise((resolve, reject) => {
        // First, we add it to the conversation, so that the UI shows it immediately
        // dispatch('addMessage', message);

        if (!socket) {
          commit('_addMessageToQueue', message);
          return reject(new Error('Socket is not created'));
        }

        if (state.connected) {
          // Websocket heartbeat check
          if (this.heartbeat) {
            clearTimeout(this.heartbeat);
            this.heartbeat = null;
          }

          socket.send(JSON.stringify(message));

          this.heartbeat = setTimeout(() => {
            dispatch('_sendPingMessage');
          }, state._pingTimeout);

          const totalBytes = socket.bufferedAmount;
          /* Track message progress (for file uploads) */
          // TODO track progress in state
          let progress = 0;
          const interval = setInterval(() => {
            if (socket == null) {
              // Connection dropped during the transfer
              clearInterval(interval);
              return reject();
            }
            progress = 100 - Math.round((socket.bufferedAmount / totalBytes) * 100);
            if (progress === 100) {
              clearInterval(interval);
              if (message.type !== 'ping') console.log('Websocket:: Message sent', message);
              return resolve(true);
            }
          }, 100);
        } else {
          commit('_addMessageToQueue', message);
          dispatch('retryMessageTimeout', message.ref);
          reject(new Error('Cannot send message, the websocket connection not opened'));
        }
      });
    },
    addMessage({ dispatch }, message) {
      const newMessage = null;

      switch (message.type) {
        /* Mark read message */
        case MESSAGE_READ:
          dispatch('markMessage', {
            cId: message.cid,
            message,
            markType: MESSAGE_READ,
          });
          break;
        /* Normal Text, Audio, Video, Image, File, Carousel messages */
        /* These "standard" messages are taken as-is from the gateway */
        case 'text':
        case 'audio':
        case 'video':
        case 'image':
        case 'file':
        case 'carousel':
        case 'manual_trigger':
        case 'quickreplies':
          dispatch('addConversationMessage', message);
          dispatch('setMessageSendStatus', {
            conversationId: message.cid,
            ref: message.ref,
            sendStatus: MESSAGE_SEND_STATUS.SENDING,
          });
          break;
        default:
          break;
      }
    },
    setMessageSendStatus({ commit }, { conversationId, ref, sendStatus }) {
      commit('updateMessageSendStatusByRef', { conversationId, ref, sendStatus });
    },
    removeMessage({ commit }, { conversationId, ref }) {
      commit('removeMessageByRef', { conversationId, ref });
    },
    setMessageSendProgress({ commit }, {
      conversationId, ref, loaded, total,
    }) {
      commit('updateMessageSendProgressByRef', {
        conversationId, ref, loaded, total,
      });
    },
    /* TODO Unify (markRead) and (markReceivedMessageRead)
      ->change backend fields
          message_id to ref
          conversation_id to cid
    */
    markFailed({ state, commit }, ref) {
      const messages = cloneDeep(state.messagesQueue);
      const newMessages = messages.map(message => {
        // console.log('Websocket:: Checking failed', message.ref, ref);
        const newMsg = { ...message };
        if (newMsg.ref === ref) {
          newMsg.prop$$ = {
            ...newMsg.prop$$,
            sendStatus: MESSAGE_SEND_STATUS.FAILED,
          };
          console.log('Websocket:: Marking failed', message);
        }
        return newMsg;
      });
      commit('_setMessagesQueue', newMessages);
    },
    // FIXME: refactor MESSAGE_READ handler
    markMessage(
      { state, commit },
      {
        cId,
        message,
        markType, // MESSAGE_READ
      }
    ) {
      const convo = state.conversations.find(c => c.id === cId);
      if (!convo) return;

      let newMessages = [];

      switch (markType) {
        case MESSAGE_READ:
          newMessages = convo.messages.map(msg => {
            // message.message_id used to be ref_id, but it was changed to real message ID later
            // We check both for backward compatibility
            if (msg.ref === message.message_id || msg.id === message.message_id) {
              return {
                ...msg,
                read_at: message.read_at,
                message_read: true,
              };
            }
            return msg;
          });
          break;

        default:
          return;
      }

      commit('_updateConversation', {
        conversationId: convo.id,
        data: {
          messages: newMessages,
        },
      });
    },
    loadConversationMessages({ dispatch, state, commit }, { botId, conversation }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchChatHistory', {
          botId,
          gatewayId: conversation.gateway_id,
          cType: conversation.type,
          cId: conversation.id,
        })
          .then(messages => {
            const convertedMessages = messages.map(message => {
              message.prop$$ = {
                sendStatus: MESSAGE_SEND_STATUS.RECEIVED,
              };

              if (message.type === CONVERSATION_ASSIGNED_TO_AGENT) {
                return makeMessageByType(CONVERSATION_ASSIGNED_TO_AGENT, message, {
                  agentsIdMap: state.agents,
                });
              }
              if (message.type === CONVERSATION_TERMINATED) {
                return makeMessageByType(CONVERSATION_TERMINATED, message);
              }
              return message;
            });
            commit('_updateConversation', {
              conversationId: conversation.id,
              data: {
                messages: convertedMessages,
              },
            });
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    closeConversation({ commit }, { conversation }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/livechatgateway/conversation/${conversation.gateway_id}/${conversation.type}/${conversation.id}/close`)
          .then(response => {
            console.log('Close conversation', response);
            resolve(response);
          })
          .catch(err => {
            console.log('Error when closing conversation', err);
            reject(err);
          });
      });
    },
    fetchAgents({ commit }, { companyId = null, ateamId = null, showOnlyAvailable = false }) {
      const params = {};
      if (companyId) params.company_id = companyId;
      if (ateamId) params.ateam_id = ateamId;
      if (showOnlyAvailable) params.show_only_available = true;
      $bus.$siniticApi
        .get('/livechatgateway/agent/agents_live_status', {
          params,
        })
        .then(({ data }) => {
          const agents = data.reduce(
            (result, obj) => ({
              ...result,
              [obj.profile.user_id]: {
                id: obj.profile.user_id,
                ...pick(obj.profile, ['email', 'fullname', 'profile_pic_url']),
                ...pick(obj, ['status', 'ts']),
              },
            }),
            {}
          );
          commit('_setAgents', agents);
        });
    },
    fetchAgentTeams({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        Promise.all([
          $bus.$siniticApi.get(`/livechatgateway/agent/company/${companyId}/teams?only_who_i_can_see`),
          $bus.$siniticApi.get(`/livechatgateway/agent/company/${companyId}/teams`),
        ])
          .then(([resp1, resp2]) => {
            commit(
              '_setAgentsTeams',
              (resp1.data || []).filter(ateam => ateam.is_v2bot)
            );
            commit(
              '_setAllAgentsTeams',
              (resp2.data || []).filter(ateam => ateam.is_v2bot)
            );
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fetchConversations({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`/livechatgateway/conversation/company/${companyId}`, { notification: false })
          .then(response => {
            const newConversations = response.data.map(conversation => ({
              ...conversation,
              messages: [],
              last_msg: conversation.last_msg
                ? makeMessageByType(conversation.last_msg.type, conversation.last_msg)
                : null,
              prop$$: {
                state: conversation.count_unread_msgs > 0 ? CONVERSATION_STATE.NEW : CONVERSATION_STATE.NORMAL,
              },
            }));

            commit('_setConversations', newConversations);
            resolve(newConversations);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fetchTransferRequests({ commit }, companyId) {
      return $bus.$siniticApi.get(`/livechatgateway/conversation/company/${companyId}/transfer_requests`).then(res => {
        commit('_setTransferRequests', sortBy(res.data, ['expired_at']));
        return res.data;
      });
    },
    removeTransferRequest({ state, commit }, transferRequestId) {
      const index = state.transferRequests.findIndex(tr => tr.tr_id === transferRequestId);
      const cloned = cloneDeep(state.transferRequests);
      cloned.splice(index, 1);
      commit('_setTransferRequests', cloned);
    },
    acceptTransferRequest({ state, commit }, {
      gatewayId, conversationType, conversationId, transferRequestId,
    }) {
      return new Promise((resolve, reject) => $bus.$siniticApi
        .post(
          `/livechatgateway/conversation/${gatewayId}/${conversationType}/${conversationId}/accept_transfer_request/${transferRequestId}`
        )
        .then(res => {
          if ('status' in res.data && res.data.status === 'accepted') {
            // Remove transfer request from list
            commit(
              '_setTransferRequests',
              state.transferRequests.filter(tr => tr.tr_id !== transferRequestId)
            );
            return resolve(res.data);
          }
          reject(res.data);
        })
        .catch(err => {
          console.error(err);
          reject(err);
        }));
    },
    declineTransferRequest({ state, commit }, {
      gatewayId, conversationType, conversationId, transferRequestId,
    }) {
      return new Promise((resolve, reject) => $bus.$siniticApi
        .post(
          `/gateway/conversation/${gatewayId}/${conversationType}/${conversationId}/decline_transfer_request/${transferRequestId}`
        )
        .then(res => {
          if ('status' in res.data && res.data.status === 'declined') {
            // Remove transfer request from list
            commit(
              '_setTransferRequests',
              state.transferRequests.filter(tr => tr.tr_id !== transferRequestId)
            );
            return resolve(res.data);
          }
          reject(res.data);
        })
        .catch(err => {
          console.error(err);
          reject(err);
        }));
    },
    fetchChatHistory({ state }, {
      botId, gatewayId, cType, cId,
    }) {
      return $bus.$siniticApi
        .get(`livechatgateway/chat_history/${botId}/conversation/${gatewayId}/${cType}/${cId}/messages`)
        .then(r => r.data.filter(msg => [
          ...state._allowDisplayMsgTypes,
          CONVERSATION_ASSIGNED_TO_AGENT,
          CONVERSATION_TERMINATED,
          CONVERSATION_CLOSED,
          CUSTOMER_SENT_RATING,
        ].includes(msg.type)));
    },
    deleteRequest({ state, commit }, id) {
      const newRequests = state.transferRequests.filter(req => req.tr_id !== id);
      commit('_setTransferRequests', newRequests);
    },
    setActiveConversation({ commit }, conversationId) {
      commit('_setActiveConversation', conversationId);
    },
    setIsSending({ commit }, isSending) {
      /**
        Because we use sendMessage for all WebSocket action,
        But 'isSending' state just use for text,file... and other visible message
        So I create this action to control it.
      */
      commit('_setSending', isSending);
    },
    _sendPingMessage({ state, dispatch }) {
      if (state.connected) {
        dispatch('sendMessage', {
          type: state._pingMsg,
        });
      }
    },
    _notifyIfNotInLiveChat({ rootState }, { title, options, callback = {} }) {
      if (rootState.route.name !== 'LiveChat' || !document.hasFocus()) {
        console.log(`send browser notification ${rootState.route.name} ${document.hasFocus()}`);
        $bus.$notification.show(title, options, callback);
      } else {
        console.log(`ignore browser notification ${rootState.route.name} ${document.hasFocus()}`);
      }
    },
    sendTransferRequest(_, {
      gatewayId, conversationType, conversationId, ateamId, agentId,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(
            `/livechatgateway/conversation/${gatewayId}/${conversationType}/${conversationId}/transfer_request/${ateamId}/${agentId}`
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            console.error('transfer conversation failed', err);
            return reject(err);
          });
      });
    },
    forceTransferConversation({ state, commit }, {
      gatewayId, conversationType, conversationId, ateamId, agentId,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(
            `/gateway/conversation/${gatewayId}/${conversationType}/${conversationId}/force_assign_agent/${ateamId}/${agentId}`
          )
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            console.error('transfer conversation failed', err);
            return reject(err);
          });
      });
    },
    takeoverConversation({ dispatch }, {
      gatewayId, conversationType, conversationId, agentId,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/livechatgateway/conversation/${gatewayId}/${conversationType}/${conversationId}/assign_agent/${agentId}`)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            console.log('takeover conversation failed', err);
            return reject(err);
          });
      });
    },
    archiveConversation({ state, commit }, conversationId) {
      // Close conversation if it's active.
      if (state.activeConversationId === conversationId) {
        commit('_setActiveConversation', null);
      }
      // Clear timer and interval
      if (conversationId in state.archiveConversationInterval || conversationId in state.archiveConversationTimeout) {
        clearInterval(state.archiveConversationInterval[conversationId]);
        commit('_removeArchiveConversationTimeout', conversationId);
        commit('_removeArchiveConversationInterval', conversationId);
      }
      // Remove conversation from list
      commit('_removeConversation', conversationId);
      // Notify components
      $bus.$emit('CONVERSATION_ARCHIVED', conversationId);
    },
    setArchiveConversationTimer({ state, commit, dispatch }, msg) {
      const { conversation_id } = msg;
      // Store initial countdown seconds
      commit('_setArchiveConversationTimeout', {
        conversation_id,
        timeout: state.archiveConversationDelayMs / 1000,
      });
      // Set a countdown, when it counts to 0, archive the conversation.
      commit('_setArchiveConversationInterval', {
        conversation_id,
        intervalId: setInterval(() => {
          if (!(conversation_id in state.archiveConversationInterval)) return;

          if (
            conversation_id in state.archiveConversationTimeout
            && state.archiveConversationTimeout[conversation_id] > 0
          ) {
            commit('_setArchiveConversationTimeout', {
              conversation_id,
              timeout: state.archiveConversationTimeout[conversation_id] - 1,
            });
          } else {
            dispatch('archiveConversation', msg.conversation_id);
          }
        }, 1000),
      });
    },
    readUnreadMessages({ state, commit }, { conversationId, messageIds }) {
      return new Promise((resolve, reject) => {
        const conversation = state.conversations.find(conversation => conversation.id === conversationId);
        if (!conversation) reject(new Error(`Conversation not found, conversation id: ${conversationId}`));

        $bus.$siniticApi
          .post(`gateway/conversation/${conversation.gateway_id}/${conversation.type}/${conversationId}/read_message`, {
            data: { message_ids: messageIds },
          })
          .then(res => {
            console.log(`Read unread messages in conversation, conversation id: ${conversationId}`);
            commit('_updateConversation', {
              conversationId: conversation.id,
              data: {
                count_unread_msgs: Math.max(conversation.count_unread_msgs - messageIds.length, 0),
              },
            });

            commit('_setConversationState', {
              conversationId,
              newState: CONVERSATION_STATE.NORMAL,
            });

            resolve(res.data);
          })
          .catch(err => {
            console.error(err);
            reject(err);
          });
      });
    },
    _removeConversationIfNotAllowToView({ dispatch, rootGetters }, msg) {
      let isVisible = false;

      if (msg.ateam_id) {
        const ateam = rootGetters['chat/visibleTeamsById'][msg.ateam_id];
        if (ateam) {
          // Pending chats are visible
          if (msg.agent_id === null) {
            isVisible = true;
          } else if (ateam.i_can_do.manage) {
            isVisible = ateam.agents.some(agent => agent.user_id === msg.agent_id);
          }
        }
      }

      if (!isVisible) {
        dispatch('_removeConversationFromList', msg.conversation_id);
      }
    },
    _removeConversationFromList({ state, commit }, conversationId) {
      try {
        const index = state.conversations.findIndex(conversation => conversation.id === conversationId);
        const cloned = cloneDeep(state.conversations);
        cloned.splice(index, 1);
        commit('_setConversations', cloned);
      } catch (error) {
        console.error(`Error occurred when removing Conversation<${conversationId}> from list.`, error);
      }
    },

    playNotificationSound({ rootState }) {
      if (rootState.chat.enableBrowserNotification) {
        throttle(playAlertSound, 1000)();
      } else {
        console.warn('Browser notification is not enabled.');
      }
    },
  },
};
