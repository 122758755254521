<template>
  <div
    class="pui-form-item"
    :style="{
      marginBottom,
    }"
    :class="{
      'pui-form-item--disabled': disabled,
      'pui-form-item--required': required,
      'pui-form-item--error': !!error,
    }"
  >
    <label
      v-if="label"
      class="pui-form-item__label"
      :for="labelFor"
      :style="{
        paddingBottom: labelGap,
        fontSize: labelFontSize,
        lineHeight: labelLineHeight,
      }"
    >{{ label }}<slot name="label-suffix" /></label>

    <div class="pui-form-item__content">
      <slot />
    </div>

    <div
      v-if="error"
      class="pui-form-item__hint is-danger"
      :style="{
        marginTop: errorGap,
      }"
    >
      <span class="pui-form-item__hint__text">{{ error }}</span>
      <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
    </div>

    <div
      v-else-if="success"
      class="pui-form-item__hint is-success"
      :style="{
        marginTop: hintGap,
      }"
    >
      <span class="pui-form-item__hint__text">{{ success }}</span>
      <font-awesome-icon :icon="['fas', 'check-circle']" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PUIFormItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    labelFor: {
      type: String,
      default: null,
    },
    labelGap: {
      type: String,
      default: '10px',
    },
    labelFontSize: {
      type: String,
      default: '12px',
    },
    labelLineHeight: {
      type: String,
      default: '15px',
    },
    marginBottom: {
      type: String,
      default: '16px',
    },
    error: {
      type: String,
      default: null,
    },
    success: {
      type: String,
      default: null,
    },
    errorGap: {
      type: String,
      default: '10px',
    },
    hintGap: {
      type: String,
      default: '10px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';

.pui-form-item {
  margin-bottom: 16px;
  line-height: normal;

  &--disabled {
    .pui-form-item__label {
      color: #cccccc;
    }
  }

  &--required {
    .pui-form-item__label::after {
      content: '*';
      margin-inline-start: 3px;
    }
  }

  &__label {
    display: inline-block;
    padding: 0;
    font-size: 12px;
    line-height: 15px;
    font-family: $montserrat;
    color: $color-black;
  }

  &__content {
    position: relative;
    font-size: 14px;
  }

  &__hint {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $open-sans;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;

    &.is-danger{
      color: $color-error;
    }

    &.is-success{
      color: $color-green;
    }

    &__text:first-letter {
      text-transform: uppercase;
    }

    svg {
      width: 14px;
      height: 12px;
    }
  }
}
</style>
