<template>
  <div
    :class="{
      'pui-table__body__row': type === 'body',
      'pui-table__head__row': type === 'header',
      'pui-table__row__clickable': clickable,
    }"
    class="pui-table__row"
    v-on="inputListeners"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <!-- @slot Use this slot to place the row content -->
    <slot :hovered="hovered" />
  </div>
</template>

<script>
export default {
  name: 'PUITableRow',
  props: {
    /**
     * Defines the type of the row
     *  @values body, header
     */
    type: {
      type: String,
      default: 'body',
      validator: type => ['body', 'header'].indexOf(type) > -1,
    },
    /**
     * Changes cursor to pointer for rows
     */
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
  computed: {
    inputListeners() {
      const vm = this;
      // `Object.assign` merges objects together to form a new object
      return {

        // We add all the listeners from the parent
        ...this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.

      };
    },
  },
};
</script>
<style lang="scss" scoped></style>

<docs>
This is a single row table component, it allows to align items in a table row

## Usage

Header row:

```jsx
<PUITableRow type="header">
  // row content
</PUITableRow>
```

body row:

```jsx
<PUITableRow type="body">
  // row content
</PUITableRow>
```

// or

```jsx
<PUITableRow>
  // row content
</PUITableRow>
```

</docs>
