var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "pui-textarea",
        "pui-textarea--" + _vm.theme,
        { "is-disabled": _vm.disabled }
      ]
    },
    [
      _vm.label || (_vm.max && _vm.showMax)
        ? _c("div", { staticClass: "pui-textarea__header" }, [
            _vm.label
              ? _c("label", { staticClass: "pui-textarea__label" }, [
                  _vm._v(_vm._s(_vm.label) + _vm._s(_vm.required ? "*" : ""))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.max && _vm.showMax
              ? _c("label", { staticClass: "pui-textarea__max-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("pui:textfield:max_characters", { max: _vm.max })
                    )
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "pui-textarea__main",
          style: {
            padding: _vm.$slots["addons"] ? "1px 11px" : "",
            position: _vm.$slots["icon"] ? "relative" : ""
          }
        },
        [
          _c(
            "TextareaAutosize",
            _vm._g(
              {
                ref: "textarea",
                staticClass: "pui-textarea__input",
                class: {
                  "pui-textarea__input--italic": _vm.italic,
                  "has-error": !!_vm.error || _vm.errorstate
                },
                style: {
                  resize: _vm.resize,
                  minHeight: _vm.minHeight ? _vm.minHeight + "px" : "40px",
                  maxHeight: _vm.maxHeight ? _vm.maxHeight + "px" : "auto"
                },
                attrs: {
                  placeholder: _vm.placeholder,
                  autosize: _vm.autosize,
                  autofocus: _vm.autofocus,
                  minHeight: _vm.minHeight,
                  maxHeight: _vm.maxHeight,
                  rows: _vm.rows,
                  maxlength: _vm.max,
                  disabled: _vm.disabled,
                  captureEnterEvent: _vm.captureEnterEvent
                },
                model: {
                  value: _vm.inputValue,
                  callback: function($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue"
                }
              },
              _vm.inputListeners
            )
          ),
          _vm._v(" "),
          _vm._t("addons"),
          _vm._v(" "),
          _vm._t("icon")
        ],
        2
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            { staticClass: "pui-textarea__error help is-danger" },
            [
              _c("span", [_vm._v(_vm._s(_vm.error))]),
              _vm._v(" "),
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "exclamation-triangle"] }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }