var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pui-form-item",
      class: {
        "pui-form-item--disabled": _vm.disabled,
        "pui-form-item--required": _vm.required,
        "pui-form-item--error": !!_vm.error
      },
      style: {
        marginBottom: _vm.marginBottom
      }
    },
    [
      _vm.label
        ? _c(
            "label",
            {
              staticClass: "pui-form-item__label",
              style: {
                paddingBottom: _vm.labelGap,
                fontSize: _vm.labelFontSize,
                lineHeight: _vm.labelLineHeight
              },
              attrs: { for: _vm.labelFor }
            },
            [_vm._v(_vm._s(_vm.label)), _vm._t("label-suffix")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pui-form-item__content" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            {
              staticClass: "pui-form-item__hint is-danger",
              style: {
                marginTop: _vm.errorGap
              }
            },
            [
              _c("span", { staticClass: "pui-form-item__hint__text" }, [
                _vm._v(_vm._s(_vm.error))
              ]),
              _vm._v(" "),
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "exclamation-triangle"] }
              })
            ],
            1
          )
        : _vm.success
        ? _c(
            "div",
            {
              staticClass: "pui-form-item__hint is-success",
              style: {
                marginTop: _vm.hintGap
              }
            },
            [
              _c("span", { staticClass: "pui-form-item__hint__text" }, [
                _vm._v(_vm._s(_vm.success))
              ]),
              _vm._v(" "),
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "check-circle"] }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }