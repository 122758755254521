import isEmpty from 'lodash/isEmpty';

import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router/platform';

import $bus from '@/platformSettings/bus';
import { RESOURCE_STATE } from '@/constants.js';
import { paginateArray } from '@/utils.js';

Vue.use(Vuex);

let cancelGetCheckpoints;

export default {
  namespaced: true,
  state: {
    currentCheckpoint: null,
    checkpointError: null,
    checkpoints: [],
    checkpointCurrentPage: 1,
    checkpointPerPage: 15,
    checkpointFilter: null,
    browsingHistory: {},
    customerNotes: [],
    userInfo: {},
    isSearching: false,
    checkpointBlocks: [],
    checkpointMessages: [],
    fetchingCheckpointState: RESOURCE_STATE.IDLE,
    fetchingCheckpointContentState: RESOURCE_STATE.IDLE,
    companyId: null,
    botId: null,
  },
  getters: {
    checkpointAmount: state => state.checkpoints.length,
    checkpointPages: state => Math.ceil(state.checkpoints.length / state.checkpointPerPage),
    paginatedCheckpoints: state => paginateArray(state.checkpoints, state.checkpointPerPage),
    currentPageCheckpoints: (state, getters) => getters.paginatedCheckpoints[state.checkpointCurrentPage] ?? [],
    currentCheckpointIndex(state, getters) {
      if (!state.currentCheckpoint) {
        return null;
      }

      return getters.currentPageCheckpoints.findIndex(item => item.id === state.currentCheckpoint.id);
    },
    checkpointMessageAmount: state => state.checkpointMessages.length,
  },
  mutations: {
    _setCompanyId(state, companyId) {
      state.companyId = companyId;
    },
    _setBotId(state, botId) {
      state.botId = botId;
    },
    _setCurrentCheckpoint(state, checkpoint) {
      state.currentCheckpoint = Object.freeze(checkpoint);
    },
    _setCheckpointError(state, error) {
      state.checkpointError = error;
    },
    _setCheckpoints(state, checkpoints) {
      state.checkpoints = checkpoints;
    },
    _setCheckpointCurrentPage(state, currentPage) {
      if (currentPage < 1) {
        throw new Error('Invalid page number', currentPage);
      }
      state.checkpointCurrentPage = currentPage;
    },
    _setCheckpointPerPage(state, perPage) {
      if (perPage < 1) {
        throw new Error('Invalid per page number', perPage);
      }

      state.checkpointPerPage = Math.max(perPage, 1);
    },
    _setCheckpointFilter(state, filter) {
      state.checkpointFilter = filter;
    },
    _setBrowsingHistory(state, value) {
      state.browsingHistory = value;
    },
    _setCustomerNotes(state, valueArr) {
      state.customerNotes = valueArr;
    },
    _setUserInfo(state, valueObj) {
      state.userInfo = valueObj;
    },
    _setIsSearching(state, payload) {
      state.isSearching = payload;
    },
    _setCheckpointBlocks(state, payload) {
      state.checkpointBlocks = payload;
    },
    _setCheckpointMessages(state, valueArr) {
      state.checkpointMessages = valueArr;
    },
    _setFetchingCheckpointState(state, resourceState) {
      const {
        IDLE, PENDING, RESOLVED, REJECTED,
      } = RESOURCE_STATE;

      if (![IDLE, PENDING, RESOLVED, REJECTED].includes(resourceState)) {
        throw Error(
          `Invalid payload: only IDLE, PENDING, RESOLVED, and REJECTED are allowed, got ${resourceState}.`
        );
      }

      state.fetchingCheckpointState = resourceState;
    },
    _setFetchingCheckpointContentState(state, resourceState) {
      const {
        IDLE, PENDING, RESOLVED, REJECTED,
      } = RESOURCE_STATE;

      if (![IDLE, PENDING, RESOLVED, REJECTED].includes(resourceState)) {
        throw Error(
          `Invalid payload: only IDLE, PENDING, RESOLVED, and REJECTED are allowed, got ${resourceState}.`
        );
      }

      state.fetchingCheckpointContentState = resourceState;
    },
  },
  actions: {
    getBlocks({ commit }, { companyId, botId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/action:ADD_TAG`)
          .then(response => {
            const blocks = response.data;
            commit('_setCheckpointBlocks', blocks);
            return resolve(blocks);
          })
          .catch(err => {
            console.error(err);
            return reject(err);
          });
      });
    },
    setCurrentCheckpointByIndex({
      state, getters, commit, dispatch,
    }, index) {
      commit('_setFetchingCheckpointContentState', RESOURCE_STATE.PENDING);

      const currentCheckpoint = getters.currentPageCheckpoints[index];

      if (currentCheckpoint === undefined) {
        dispatch('clearCurrentCheckpoint');
        commit('_setFetchingCheckpointContentState', RESOURCE_STATE.REJECTED);
        throw new Error('Invalid index');
      }

      commit('_setCurrentCheckpoint', currentCheckpoint);

      Promise.allSettled([
        dispatch('getCheckPointMessage', {
          gwId: currentCheckpoint.gateway_id,
          convoId: currentCheckpoint.conversation_id,
          convoType: currentCheckpoint.conversation_type,
          messageId: currentCheckpoint.message_id,
        }),
        dispatch('getGatewayProfile', {
          gwId: currentCheckpoint.gateway_id,
          customerType: currentCheckpoint.customer_type,
        }),
        dispatch('getBrowsingHistory'),
        dispatch('getCustomerNotes'),
        router
          .push({
            name: 'BotHistory',
            params: {
              companyId: state.companyId,
              botId: state.botId,
              history: 'checkpoint',
              selectedId: currentCheckpoint.id,
            },
            query: state.checkpointFilter,
          })
          .catch(() => { }),
      ])
        .then(() => {
          commit('_setFetchingCheckpointContentState', RESOURCE_STATE.RESOLVED);
        })
        .catch(() => {
          commit('_setFetchingCheckpointContentState', RESOURCE_STATE.REJECTED);
        });
    },
    getGatewayProfile({ state, rootState, commit }) {
      return new Promise((resolve, reject) => {
        if (!state.currentCheckpoint) {
          commit('_setUserInfo', {});
          reject();
          return;
        }

        const { gateway_id, conversation_id } = state.currentCheckpoint;

        $bus.$siniticApi
          .get(`/gateway/${state.companyId}/crm/gateway_profile/${gateway_id}/${conversation_id}`)
          .then(res => {
            const results = res.data;
            commit('_setUserInfo', results);
            resolve(results);
          })
          .catch(err => {
            commit('_setUserInfo', {});
            reject(err);
          });
      });
    },
    getCheckPointMessage({
      commit, state,
    }, {
      gwId, convoId, convoType, messageId,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`/livechatgateway/chat_history/${state.botId}/conversation/${gwId}/${convoType}/${convoId}/messages`)
          .then(res => {
            const messages = res.data;

            commit('_setCheckpointMessages', messages);
            return resolve(messages);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getPreviousCheckpoint({
      state, getters, dispatch, commit,
    }) {
      // Firstly not in first page
      // secondly at the first index of current page
      if (
        state.checkpointCurrentPage > 1
        && getters.currentCheckpointIndex === 0
      ) {
        // Reduce page number
        commit('_setCheckpointCurrentPage', state.checkpointCurrentPage - 1);
        dispatch('setCurrentCheckpointByIndex', state.checkpointPerPage - 1);
      } else if (getters.currentCheckpointIndex > 0) {
        // Set previous as current
        dispatch('setCurrentCheckpointByIndex', getters.currentCheckpointIndex - 1);
      }
    },
    getNextCheckpoint({
      state, getters, commit, dispatch,
    }) {
      if (
        state.checkpointCurrentPage < getters.checkpointPages
        && getters.currentCheckpointIndex === state.checkpointPerPage - 1
      ) {
        // Increase page number and set index to 0
        commit('_setCheckpointCurrentPage', state.checkpointCurrentPage + 1);
        dispatch('setCurrentCheckpointByIndex', 0);
      } else if (getters.currentPageCheckpoints[getters.currentCheckpointIndex + 1]) {
        // Set next as current
        dispatch('setCurrentCheckpointByIndex', getters.currentCheckpointIndex + 1);
      }
    },
    getCheckpoints({ state, rootState, commit }, { companyId, botId }) {
      commit('_setFetchingCheckpointState', RESOURCE_STATE.PENDING);

      return new Promise((resolve, reject) => {
        // Call api if date range is ready
        if (state.checkpointFilter && (!state.checkpointFilter.date_begin || !state.checkpointFilter.date_end)) {
          commit('_setFetchingCheckpointState', RESOURCE_STATE.RESOLVED);
          return resolve();
        }

        if (cancelGetCheckpoints) {
          cancelGetCheckpoints();
        }

        const formatting = 'YYYY-MM-DD';
        const dateQuery = {
          timezone: rootState.uiv2.platformTimezone,
        };

        if (state.checkpointFilter && state.checkpointFilter.date_begin) {
          dateQuery.date_begin = Vue.dayjs(state.checkpointFilter.date_begin).format(formatting);
        }

        if (state.checkpointFilter && state.checkpointFilter.date_end) {
          dateQuery.date_end = Vue.dayjs(state.checkpointFilter.date_end).format(formatting);
        }

        const filters = {};
        const newFilter = state.checkpointFilter || {};
        Object.entries(newFilter).forEach(([key, val]) => {
          if (val !== null && val !== '') {
            filters[key] = val;
          }
        });

        if (!isEmpty(filters)) {
          commit('_setIsSearching', true);
        } else {
          commit('_setIsSearching', false);
        }

        $bus.$siniticApi
          .post(`gateway/conversation/company/${companyId}/bot/${botId}/query_checkpoint_logs`, {
            data: {
              ...filters,
              ...dateQuery,
            },
          })
          .then(response => {
            const results = response.data;

            commit('_setCheckpoints', results);
            commit('_setFetchingCheckpointState', RESOURCE_STATE.RESOLVED);
            resolve(results);
          })
          .catch(error => {
            console.log(error);
            commit('_setFetchingCheckpointState', RESOURCE_STATE.REJECTED);
            reject(error);
          });
      });
    },
    clearCheckpoints({ commit }) {
      commit('_setCheckpointCurrentPage', 1);
      commit('_setCheckpoints', []);
    },
    resetCheckpoint({ commit, dispatch }) {
      commit('_setCheckpoints', []);
      commit('_setCheckpointCurrentPage', 1);
      commit('_setCheckpointFilter', null);
      commit('_setFetchingCheckpointState', RESOURCE_STATE.IDLE);

      commit('_setCheckpointBlocks', []);

      dispatch('clearCurrentCheckpoint');
    },
    clearCurrentCheckpoint({ commit }) {
      commit('_setCurrentCheckpoint', null);
      commit('_setCheckpointMessages', []);
      commit('_setUserInfo', {});
      commit('_setBrowsingHistory', {});
      commit('_setCustomerNotes', []);
      commit('_setFetchingCheckpointContentState', RESOURCE_STATE.IDLE);
    },
    setCheckpointCurrentPage({
      state, getters, commit, dispatch,
    }, page) {
      if (page < 0 || page > getters.checkpointPages) {
        throw new Error('Invalid page');
      }

      commit('_setCheckpointCurrentPage', page);

      dispatch('getCheckpoints', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.length > 0) {
          dispatch('setCurrentCheckpointByIndex', 0);
        }
      });
    },
    setCheckpointFilter({
      state, getters, commit, dispatch,
    }, filter) {
      commit('_setCurrentCheckpoint', null);
      commit('_setCheckpointFilter', filter);
      commit('_setCheckpointCurrentPage', 1);

      dispatch('getCheckpoints', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.length > 0) {
          dispatch('setCurrentCheckpointByIndex', getters.currentCheckpointIndex ?? 0);
        }
      });
    },

    getBrowsingHistory({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (!state.currentCheckpoint) {
          commit('_setBrowsingHistory', {});
          reject();
          return;
        }

        const gwId = state.currentCheckpoint.gateway_id;
        const customerId = state.currentCheckpoint.customer_id;

        if (!gwId || !customerId) {
          commit('_setBrowsingHistory', {});
          reject();
          return;
        }

        $bus.$siniticApi
          .get(`/gateway3/${gwId}/t/${encodeURIComponent(customerId)}`)
          .then(res => {
            commit('_setBrowsingHistory', res.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            commit('_setBrowsingHistory', {});
            reject();
          });
      });
    },

    getCustomerNotes({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (!state.currentCheckpoint) {
          commit('_setCustomerNotes', []);
          reject();
          return;
        }
        const conversationId = state.currentCheckpoint.conversation_id;

        if (!conversationId) {
          commit('_setCustomerNotes', []);
          reject();
          return;
        }

        $bus.$siniticApi
          .get(`/gateway3/notes/${state.companyId}/conversation/${encodeURIComponent(conversationId)}`)
          .then(res => {
            commit('_setCustomerNotes', res.data);
            resolve();
          })
          .catch(err => {
            console.log(err);
            commit('_setCustomerNotes', []);
            reject();
          });
      });
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
