import {
  SEND_STATUS, AUTHOR_TYPE, isUnreadMessageFromBotOrAgent, MESSAGE_TYPE,
} from '@/helpers/messageHelper';
import { WEBCHAT_STATE } from '@/webchat2/config';
import { PAI_AUTHOR_TYPES } from '@/platformSettings/pai';

export default (message, $store, { MUTATION }) => {
  const { state, commit, dispatch } = $store;

  const chat = state.chats[message.cid];

  if (!chat) return;

  if ([AUTHOR_TYPE.BOT, AUTHOR_TYPE.AGENT, ...PAI_AUTHOR_TYPES].includes(message.author.type)) {
    commit(MUTATION.addMessageToChat, {
      chatId: message.cid,
      message,
      status: SEND_STATUS.received,
    });

    // Update unread message
    if (isUnreadMessageFromBotOrAgent(message)) {
      // chat window is opened and is in chat main page of the chat of this message
      if (
        state.isChatWindowOpen
        && state.webchatState === WEBCHAT_STATE.STARTED
        && state.activeChatId === message.cid
      ) {
        dispatch('updateReadConversation', { messageIds: [message.id], toCommit: false });
        message.read_at = true;
      } else {
        // increase unread message count of chat
        commit(MUTATION.increaseChatUnreadMessagesCount, {
          chatId: message.cid,
          num: 1,
        });
      }
    }
  } else if (message.author.type === AUTHOR_TYPE.HUMAN) {
    // Update status when message come from human
    const msg = chat.chat_history.find(item => item.ref === message.ref);

    // console.log('msg for human', msg);

    if (msg) {
      commit(MUTATION.updateMessageToChat, {
        chatId: message.cid,
        ref: message.ref,
        status: SEND_STATUS.received,
        url: message.url,
      });
    } else {
      /* This message is sent from the end-user of another tab or browser */
      commit(MUTATION.addMessageToChat, {
        chatId: message.cid,
        message,
        status: SEND_STATUS.received,
      });
    }
  }

  // if the message is proactive (due to manual trigger)
  if (message.trigger_by) {
    commit(MUTATION.setActiveChatId, message.cid);
    commit(MUTATION.setWebchatState, WEBCHAT_STATE.STARTED);
    if (message.is_sneakpeek) {
      /* TODO show sneakpeek */
    } else {
      commit(MUTATION.toggleChatWindow, true);
    }
  }

  if (message.is_sneakpeek && !state.isChatWindowOpen) {
    switch (message.type) {
      case MESSAGE_TYPE.TEXT:
        dispatch('sneakPeek/set', {
          chatId: message.cid,
          message: message.text,
          buttons: [],
        }, { root: true });
        break;

      default:
        break;
    }
    dispatch('showSneakPeek');
  }

  // update updated_at for conversation
  commit(MUTATION.setConvUpdatedAt, message.cid);
};
