export const makeId = l => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < l; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

const OriginPattern = new RegExp('^https?://(www.)?[-a-zA-Z0-9:._]{2,256}[-a-zA-Z0-9]');

export const validURL = str => {
  if (str === '*') return true;
  const result = OriginPattern.exec(str);
  // Nothing matched
  if (!result) {
    return false;
  }
  // Some part matched
  const [match] = result;
  const { input } = result;
  if (match === input) return true;
  return match;
};
