import { createMapping, F } from '@/utils';

export const PAI_TRAITS = createMapping({
  INTERCOM: {
    name: 'Intercom',
    type: 'intercom',
    iconFileName: 'intercom',
    authorType: 'pat:intercom',
    authorFallbackIcon: 'intercom',
    featureFlag: null,
  },
  PROTO_V3: {
    name: 'Proto V3',
    type: 'proto_v3',
    iconFileName: 'icons-proto_v3',
    authorType: 'pat:proto_v3',
    authorFallbackIcon: 'icons-proto_v3',
    featureFlag: null,
  },
  ZENDESK: {
    name: 'Zendesk',
    type: 'zendesk',
    iconFileName: 'zendesk',
    authorType: 'pat:zendesk',
    authorFallbackIcon: 'zendesk',
    featureFlag: null,
  },
});

export const PAI = PAI_TRAITS |> F.objMap((key, value) => [key, value.type]);

export const PAI_LIST = Object.values(PAI_TRAITS);

export const PAI_TYPES = Object.values(PAI);

export const PAI_TRAITS_BY_TYPE = PAI_TRAITS |> F.objMap((key, value) => [value.type, value]);

export const PAI_TRAITS_BY_AUTHOR_TYPE = PAI_TRAITS |> F.objMap((key, value) => [value.authorType, value]);

export const PAI_AUTHOR_TYPES = PAI_LIST.map(pai => pai.authorType);
