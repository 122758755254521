<template>
  <div
    class="pui-lang-icon"
    :class="classLangIcon"
  >
    <div
      class="lang-code"
      :class="classLangCode"
    >
      {{ langCode || '' }}
    </div>
  </div>
</template>

<script>
import { languageCodes } from '@/lang/languages.js';

export default {
  name: 'PUILangIcon',
  props: {
    /**
    * Sets the background color of the language icon.
    *
    * It must belong to one of 'primary' and 'grey'.
    * This prop is optional. The default value is 'primary'.
    *
    * 'primary': background is black
    * 'grey': background is grey
    */
    color: {
      type: String,
      default: 'primary',
      validator: value => [
        'primary',
        'grey',
      ].includes(value),
    },

    /**
    * Language code to be displayed in the icon
    *
    * This prop is required. It must be valid ISO 639-1, 639-2, and 639-3 language code.
    * It is case insensitive. Any characters will be transformed to lower character and displayed.
    *
    * In the case that the value is
    *
    *   - zh_TW => it will be displayed as zht
    *
    *   - zh_CN => it will be displayed as zhs
    */
    lang: {
      type: String,
      default: '',
      required: true,
      validator: value => languageCodes.some(l => l.toLowerCase() === value.toLowerCase())
    },

    /**
    * Sets the size of the language icon.
    *
    * It must belong to one of 'xsmall, 'small', 'medium', 'large'.
    * This prop is optional. The default value is 'small'.
    *
    * 'xsmall': 18px x 18px,
    * 'small': 24px x 24px,
    * 'medium': 36px x 36px,
    * 'large': 60px x 60px,
    */
    size: {
      type: String,
      default: 'small',
      validator: value => ['xsmall', 'small', 'medium', 'large'].includes(value),
    },
  },
  data() { return {}; },
  computed: {
    langInLowerCase() {
      return this.lang.toLowerCase().trim();
    },
    langCode() {
      switch (this.langInLowerCase) {
        case 'zh_tw':
          return 'zht';
        case 'zh_cn':
          return 'zhs';
        default:
          return this.langInLowerCase;
      }
    },
    classLangIcon() {
      const langIconClass = {
        [`pui-lang-icon--${this.color}`]: this.color,
        [`pui-lang-icon--${this.size}`]: this.size,
      };

      return langIconClass;
    },
    classLangCode() {
      return {
        [`lang-code--${this.size}-${this.langCode.length}-chars`]: this.size,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';
$xsmall-icon-size: 18px;
$small-icon-size: 24px;
$medium-icon-size: 36px;
$large-icon-size: 60px;

$xsmall-font-size-2-chars: 9px;
$small-font-size-2-chars: 12px;
$medium-font-size-2-chars: 16px;
$large-font-size-2-chars: 26px;

$xsmall-font-size-3-chars: 7px;
$small-font-size-3-chars: 9px;
$medium-font-size-3-chars: 16px;
$large-font-size-3-chars: 26px;

.pui-lang-icon {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  &--primary {
    background-color: $color-black;
  }

  &--grey {
    background-color: $color-grey-light;
  }

  &--xsmall {
    width: $xsmall-icon-size;
    height: $xsmall-icon-size;
  }

  &--small {
    width: $small-icon-size;
    height: $small-icon-size;
  }

  &--medium {
    width: $medium-icon-size;
    height: $medium-icon-size;
  }

  &--large {
    width: $large-icon-size;
    height: $large-icon-size;
  }

  .lang-code {
    text-transform: lowercase;
    text-align: center;
    font-family: $montserrat;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.1px;
    color: white;

    &--xsmall-2-chars {
      font-size: $xsmall-font-size-2-chars;
    }

    &--small-2-chars {
      font-size: $small-font-size-2-chars;
    }

    &--medium-2-chars {
      font-size: $medium-font-size-2-chars;
    }

    &--large-2-chars {
      font-size: $large-font-size-2-chars;
    }

    &--xsmall-3-chars {
      font-size: $xsmall-font-size-3-chars;
    }

    &--small-3-chars {
      font-size: $small-font-size-3-chars;
    }

    &--medium-3-chars {
      font-size: $medium-font-size-3-chars;
    }

    &--large-3-chars {
      font-size: $large-font-size-3-chars;
    }
  }
}
</style>
