var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "pui-textfield",
        "pui-textfield--" + _vm.theme,
        {
          "pui-textfield--required": _vm.required,
          "pui-textfield--removable": _vm.removable
        }
      ]
    },
    [
      _vm.label || (_vm.max && _vm.showMax)
        ? _c("div", { staticClass: "pui-textfield__header" }, [
            _vm.label
              ? _c(
                  "label",
                  {
                    staticClass: "pui-textfield__label",
                    class: { "pui-textfield__label--bold": _vm.boldLabel },
                    attrs: { disabled: _vm.disabled }
                  },
                  [_vm._v(_vm._s(_vm.label) + _vm._s(_vm.required ? "*" : ""))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.max && _vm.showMax
              ? _c(
                  "label",
                  {
                    staticClass: "pui-textfield__max-label",
                    class: { "pui-textfield__max-label--bold": _vm.boldLabel }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t("pui:textfield:max_characters", {
                            max: _vm.max
                          })
                        ) +
                        "\n    "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.clearable
              ? _c(
                  "span",
                  {
                    staticClass: "pui-textfield__clear",
                    on: { click: _vm.clearValue }
                  },
                  [_vm._v(_vm._s(_vm.$t("platform:generic.clear")))]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.description
        ? _c("div", { staticClass: "pui-textfield__header" }, [
            _c(
              "label",
              {
                staticClass: "pui-textfield__help-text",
                attrs: { disabled: _vm.disabled }
              },
              [_vm._v(_vm._s(_vm.description))]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "pui-textfield__inner",
          class: {
            "pui-textfield--prefix": !!_vm.prefixIcon,
            "pui-textfield--suffix": !!_vm.suffixIcon
          }
        },
        [
          _vm.prefixIcon
            ? [
                _vm.prefixIconType === "fa" || _vm.prefixIconType === "fal"
                  ? _c("font-awesome-icon", {
                      staticClass: "pui-textfield__prefix",
                      class: { "pui-textfield__prefix--focused": _vm.focused },
                      attrs: { icon: ["fal", _vm.prefixIcon], size: "lg" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("prefixIconClick")
                        }
                      }
                    })
                  : _c("PUIIcon", {
                      staticClass: "pui-textfield__prefix",
                      class: { "pui-textfield__prefix--focused": _vm.focused },
                      attrs: { name: _vm.prefixIcon, size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("prefixIconClick")
                        }
                      }
                    })
              ]
            : _vm._e(),
          _vm._v(" "),
          (_vm.type === "phone" || _vm.type === "number"
            ? "text"
            : _vm.type) === "checkbox"
            ? _c(
                "input",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputValue,
                          expression: "inputValue"
                        }
                      ],
                      ref: "inputBox",
                      staticClass: "pui-textfield__input",
                      class: {
                        "has-error": _vm.error || _vm.errorstate,
                        "is-italic": _vm.italic
                      },
                      style: Object.assign(
                        {},
                        {
                          height: _vm.height,
                          paddingLeft: _vm.prefixIcon ? "28px" : _vm.padding,
                          fontSize: _vm.fontSize,
                          paddingRight: _vm.suffixIcon ? "28px" : _vm.padding
                        },
                        _vm.textStyle
                      ),
                      attrs: {
                        dir: "auto",
                        placeholder: _vm.placeholder,
                        required: _vm.required,
                        min: _vm.min,
                        disabled: _vm.disabled,
                        readonly: _vm.readonly,
                        autocomplete: _vm.autocomplete,
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.inputValue)
                          ? _vm._i(_vm.inputValue, null) > -1
                          : _vm.inputValue
                      },
                      on: {
                        focus: function($event) {
                          return _vm.onInputFocus()
                        },
                        blur: function($event) {
                          return _vm.onInputBlur()
                        },
                        change: function($event) {
                          var $$a = _vm.inputValue,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.inputValue = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.inputValue = $$c
                          }
                        }
                      }
                    },
                    "input",
                    Object.assign({}, _vm.$attrs, _vm.maxProp),
                    false
                  ),
                  _vm.inputListeners
                )
              )
            : (_vm.type === "phone" || _vm.type === "number"
                ? "text"
                : _vm.type) === "radio"
            ? _c(
                "input",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputValue,
                          expression: "inputValue"
                        }
                      ],
                      ref: "inputBox",
                      staticClass: "pui-textfield__input",
                      class: {
                        "has-error": _vm.error || _vm.errorstate,
                        "is-italic": _vm.italic
                      },
                      style: Object.assign(
                        {},
                        {
                          height: _vm.height,
                          paddingLeft: _vm.prefixIcon ? "28px" : _vm.padding,
                          fontSize: _vm.fontSize,
                          paddingRight: _vm.suffixIcon ? "28px" : _vm.padding
                        },
                        _vm.textStyle
                      ),
                      attrs: {
                        dir: "auto",
                        placeholder: _vm.placeholder,
                        required: _vm.required,
                        min: _vm.min,
                        disabled: _vm.disabled,
                        readonly: _vm.readonly,
                        autocomplete: _vm.autocomplete,
                        type: "radio"
                      },
                      domProps: { checked: _vm._q(_vm.inputValue, null) },
                      on: {
                        focus: function($event) {
                          return _vm.onInputFocus()
                        },
                        blur: function($event) {
                          return _vm.onInputBlur()
                        },
                        change: function($event) {
                          _vm.inputValue = null
                        }
                      }
                    },
                    "input",
                    Object.assign({}, _vm.$attrs, _vm.maxProp),
                    false
                  ),
                  _vm.inputListeners
                )
              )
            : _c(
                "input",
                _vm._g(
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputValue,
                          expression: "inputValue"
                        }
                      ],
                      ref: "inputBox",
                      staticClass: "pui-textfield__input",
                      class: {
                        "has-error": _vm.error || _vm.errorstate,
                        "is-italic": _vm.italic
                      },
                      style: Object.assign(
                        {},
                        {
                          height: _vm.height,
                          paddingLeft: _vm.prefixIcon ? "28px" : _vm.padding,
                          fontSize: _vm.fontSize,
                          paddingRight: _vm.suffixIcon ? "28px" : _vm.padding
                        },
                        _vm.textStyle
                      ),
                      attrs: {
                        dir: "auto",
                        placeholder: _vm.placeholder,
                        required: _vm.required,
                        min: _vm.min,
                        disabled: _vm.disabled,
                        readonly: _vm.readonly,
                        autocomplete: _vm.autocomplete,
                        type:
                          _vm.type === "phone" || _vm.type === "number"
                            ? "text"
                            : _vm.type
                      },
                      domProps: { value: _vm.inputValue },
                      on: {
                        focus: function($event) {
                          return _vm.onInputFocus()
                        },
                        blur: function($event) {
                          return _vm.onInputBlur()
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.inputValue = $event.target.value
                        }
                      }
                    },
                    "input",
                    Object.assign({}, _vm.$attrs, _vm.maxProp),
                    false
                  ),
                  _vm.inputListeners
                )
              ),
          _vm._v(" "),
          _vm.removable
            ? _c("font-awesome-icon", {
                staticClass: "pui-textfield__suffix pui-textfield__delete",
                attrs: { size: "sm", icon: ["fal", "trash-alt"] },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("delete")
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.suffixIcon
            ? [
                _vm.suffixIconType === "fa" || _vm.suffixIconType === "fal"
                  ? _c("font-awesome-icon", {
                      staticClass: "pui-textfield__suffix",
                      class: { "pui-textfield__suffix--focused": _vm.focused },
                      attrs: {
                        icon: [_vm.suffixIconType, _vm.suffixIcon],
                        size: "lg",
                        disabled: _vm.disabled
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("suffixIconClick")
                        }
                      }
                    })
                  : _c("PUIIcon", {
                      staticClass: "pui-textfield__suffix",
                      class: { "pui-textfield__suffix--focused": _vm.focused },
                      attrs: {
                        name: _vm.suffixIcon,
                        size: "small",
                        disabled: _vm.disabled
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$emit("suffixIconClick")
                        }
                      }
                    })
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.hasOptions
        ? _c(
            "div",
            { ref: "dropdown_field", staticClass: "pui-textfield__options" },
            [
              _vm.showOptions
                ? _c("DropdownOptions", {
                    attrs: {
                      value: _vm.inputValue,
                      textKey: _vm.optionTextKey,
                      valueKey: _vm.optionValueKey,
                      options: _vm.filteredOptions,
                      absoluteTop: _vm.height,
                      valueType: "value"
                    },
                    on: { change: _vm.optionSelected },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return _vm._t("option", null, {
                              option: scope.option,
                              index: scope.index
                            })
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            { staticClass: "pui-textfield__error help is-danger" },
            [
              _c("span", { staticClass: "pui-textfield__error__text" }, [
                _vm._v(_vm._s(_vm.error))
              ]),
              _vm._v(" "),
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "exclamation-triangle"] }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }