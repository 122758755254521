/* eslint-disable max-classes-per-file */

import { createEnum } from '@/utils.js';

export const SNEAK_PEEK_BUTTON_TYPE = createEnum({
  QUICK_REPLY: 'quickreply',
  TRIGGER: 'trigger',
  HYPERLINK: 'hyperlink',
});

export const SneakPeekButtonTypes = Object.values(SNEAK_PEEK_BUTTON_TYPE);

export const CONFIG = {
  SNEAK_PEEK_DEFAULT_ENABLED: false,
  SNEAK_PEEK_MAX_GROUPS: 5,
  SNEAK_PEEK_GROUP_NAME_MAXLENGTH: 30,
  SNEAK_PEEK_GROUP_NAME_PATTERN: /^[a-z0-9-]+$/,
  SNEAK_PEEK_MESSAGE_MAXLENGTH: 200,
  SNEAK_PEEK_GROUP_MIN_BUTTONS: 1,
  SNEAK_PEEK_GROUP_MAX_BUTTONS: 3,
  SNEAK_PEEK_DEFAULT_BUTTON_TYPE: SNEAK_PEEK_BUTTON_TYPE.QUICK_REPLY,
  SNEAK_PEEK_BUTTON_LABEL_MAXLENGTH: 20,
};

export const TIMING_VALUE = createEnum({
  IMMEDIATE: 'IMMEDIATE',
  AFTER_N_SECS: 'AFTER_N_SECS',
  CONTROL_BY_API: 'CONTROL_BY_API',
});

export const SNEAK_PEEK_GROUP_STATUS = createEnum({
  DRAFT: 'DRAFT',
  PREVIEW: 'PREVIEW',
  EDIT: 'EDIT',
});

export class SneakPeekGroup {
  constructor({
    name = '',
    url_rules = [],
    display_after_sec = 0,
    message = {},
    buttons = [],
  }) {
    this._id = Math.random();
    this._status = SNEAK_PEEK_GROUP_STATUS.PREVIEW;
    this.name = name;
    this.url_rules = url_rules;
    this.display_after_sec = display_after_sec;
    this.message = message;
    this.buttons = buttons;
  }

  setPreview() {
    this._status = SNEAK_PEEK_GROUP_STATUS.PREVIEW;
    return this;
  }

  setDraft() {
    this._status = SNEAK_PEEK_GROUP_STATUS.DRAFT;
    return this;
  }

  setEdit() {
    this._status = SNEAK_PEEK_GROUP_STATUS.EDIT;
    return this;
  }

  isDraft() {
    return this._status === SNEAK_PEEK_GROUP_STATUS.DRAFT;
  }

  isEdit() {
    return this._status === SNEAK_PEEK_GROUP_STATUS.EDIT;
  }

  isEditing() {
    return [SNEAK_PEEK_GROUP_STATUS.DRAFT, SNEAK_PEEK_GROUP_STATUS.EDIT].includes(this._status);
  }

  toJSON() {
    return {
      name: this.name,
      url_rules: this.url_rules,
      display_after_sec: this.display_after_sec,
      message: Object.fromEntries(
        Object.entries(this.message)
          .filter(([lang, value]) => !!value)
      ),
      buttons: JSON.parse(JSON.stringify(this.buttons)),
    };
  }
}

export class SneakPeekButton {
  constructor({ type, label = {} }) {
    if (!SneakPeekButtonTypes.includes(type)) throw new Error(`Unknown button type: ${type}.`);
    if (!(typeof label === 'object' && !Array.isArray(label))) throw new Error(`label should be an object, got: ${label}.`);

    this._id = Math.random();
    this.type = type;
    this.label = label;
  }

  toJSON() {
    return {
      type: this.type,
      label: Object.fromEntries(
        Object.entries(this.label)
          .filter(([lang, value]) => !!value)
      ),
    };
  }
}

export class SneakPeekQuickReplyButton extends SneakPeekButton {
  constructor({ label, send_text = {} }) {
    super({ type: SNEAK_PEEK_BUTTON_TYPE.QUICK_REPLY, label });

    if (!(typeof send_text === 'object' && !Array.isArray(send_text))) throw new Error(`send_text should be an object, got: ${send_text}.`);

    this.send_text = send_text;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      send_text: Object.fromEntries(
        Object.entries(this.send_text)
          .filter(([lang, value]) => !!value)
      ),
    };
  }
}

export class SneakPeekTriggerButton extends SneakPeekButton {
  constructor({ label, trigger_name = '' }) {
    super({ type: SNEAK_PEEK_BUTTON_TYPE.TRIGGER, label });

    if (typeof trigger_name !== 'string') throw new Error(`trigger_name should be a string, got: ${trigger_name}.`);

    this.trigger_name = trigger_name;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      trigger_name: this.trigger_name,
    };
  }
}

export class SneakPeekHyperlinkButton extends SneakPeekButton {
  constructor({ label, url = '' }) {
    super({ type: SNEAK_PEEK_BUTTON_TYPE.HYPERLINK, label });

    if (typeof url !== 'string') throw new Error(`url should be a string, got: ${url}.`);

    this.url = url;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      url: this.url,
    };
  }
}
