import $bus from '@/platformSettings/bus';
import $router from '@/router/platform';
import i18n from '@/lang/i18n';

export default (msg, $store, { companyId }) => {
  const { dispatch, rootState } = $store;

  dispatch('fetchTransferRequests', companyId).then(transferRequests => {
    const transferRequest = transferRequests.find(tr => tr.tr_id === msg.tr_id);
    if (transferRequest) {
      $bus.$emit('TRANSFER_REQUEST', transferRequest);
      // Play alert sound
      dispatch('playNotificationSound');

      const notifyTitle = `${msg.old_agent_name} | ${msg.old_ateam_name}`;
      const notifyOption = {
        body: i18n.t(
          'chat:transfer_request.notification.message',
          rootState.uiv2.platformLang,
          { name: msg.customer_name || msg.customer_id?.slice(-5) }
        ),
        tag: msg.conversation_id,
      };
      const notifyCallback = {
        onClick: () => {
          if (rootState.route.name === 'LiveChat' && rootState.route.params.conversationId === msg.conversation_id) return;

          $router.push({
            name: 'LiveChat',
            params: {
              conversationId: msg.conversation_id,
            },
          });
        },
      };

      $bus.$notification.show(notifyTitle, notifyOption, notifyCallback);
    }
  });
};
