<template>
  <div
    v-click-outside="closeDropdownMenu"
    :class="[`dropdown2--${theme}`]"
    class="dropdown2"
  >
    <div
      ref="dropdown_field"
      class="dropdown2__container"
    >
      <div
        ref="reference"
        :class="[{ 'has-error': error || errorstate }, { active: isOpen }]"
        class="dropdown2__box"
        :disabled="disabled"
        @click="toggleDropdownMenu"
      >
        <div
          v-if="value"
          class="dropdown2__inner"
        >
          <div class="dropdown2__select">
            <slot
              name="label"
              :option="value"
            >
              <slot
                name="option"
                :option="value"
              >
                <span>{{ value }}</span>
              </slot>
            </slot>
          </div>
        </div>

        <div
          v-else
          class="dropdown2__inner"
        >
          <div class="dropdown2__select d-flex align-items-center">
            <span class="dropdown2__placeholder"> {{ placeholder || $t('platform:generic.select') }} </span>
          </div>
        </div>

        <font-awesome-icon
          :icon="['fal', 'angle-down']"
          :class="{ active: isOpen }"
          class="dropdown2__arrow"
        />
      </div>
      <!-- Dropdown options -->
      <div
        v-if="isOpen"
        ref="popper"
        class="dropdown2-option"
        :style="{ width: `${width}px` }"
      >
        <div
          v-if="!options.length"
          class="dropdown2-option__info"
        >
          <span>{{ $t('platform:generic.no-results') }}</span>
        </div>
        <template>
          <span
            v-for="(option, index) in options"
            :id="option"
            :key="option"
            class="dropdown2-option__item"
            :class="[{ 'dropdown2-option__item--selected': isSelected(option) }]"
            @click.stop="select(option)"
          >
            <div class="dropdown2-option__item__inner">
              <slot
                name="option"
                :option="option"
                :index="index"
              >
                <div class="dropdown2-option__text">{{ option }}</div>
              </slot>
            </div>
          </span>
        </template>
      </div>
    </div>
    <template v-if="error">
      <slot
        name="error"
        :option="value"
        :error="error"
      >
        <div class="dropdown2__error help is-danger">
          <span>{{ error }}</span>
          <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
        </div>
      </slot>
    </template>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
  name: 'PUIDropdown2',
  components: {
  },
  model: {
    prop: 'value',
  },
  props: {
    /**
    * Represents the value selected by the dropdown
    * @property {VALUE} value - The currently selected value
    */
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
    * Text placeholder displayed before a value has been a selected
    * @property {PLACEHOLDER} placeholder - Placeholder string
    */
    placeholder: {
      type: String,
      default: '',
    },
    /**
    * Array of selectable options displayed in the dropdown
    * @property {OPTIONS} options - Dropdown options
    */
    options: {
      type: Array,
      default: null,
      required: false,
    },
    /**
    * The visual theme of the dropdown, allows for styling of dropdown via several colours and designs
    * @property {THEME} theme - Selected dropdown theme
    */
    theme: {
      type: String,
      default: 'light',
      validator: theme => ['light', 'dark', 'grey-dark', 'grey-light', 'dashed-light', 'primary-outline'].includes(theme),
    },
    /**
    * Error message that will appear under the dropdown, unless user chooses to use the 'error' slot and provide separate info
    * If provided, will add red outline to dropdown regardless of value of {@link ERRORSTATE} prop
    * @property {ERROR} error - Error message associated with dropdown
    */
    error: {
      type: String,
      default: null,
    },
    /**
    * Used in place of {@link ERROR} prop if no error message but error error styling is required
    * If provided, will add red outline to dropdown regardless of value of {@link ERROR} prop
    * @property {ERRORSTATE} errorstate - Whether or not to display error styling
    */
    errorstate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      popperInstance: null,
      width: null,
    };
  },
  watch: {
    isOpen: {
      immediate: true,
      async handler(val) {
        await this.$nextTick();
        if (val) {
          this.popperInstance = createPopper(
            this.$refs.reference,
            this.$refs.popper,
            {
              placement: 'bottom-start',
              strategy: 'fixed',
              onFirstUpdate: () => {
                this.width = this.$el.getBoundingClientRect().width;
              },
            }
          );
        } else {
          this.popperInstance?.destroy();
          this.popperInstance = null;
        }
      },
    },
  },
  methods: {
    closeDropdownMenu() {
      this.isOpen = false;
    },
    toggleDropdownMenu() {
      if (this.disabled) return;
      this.isOpen = !this.isOpen;
    },
    select(option) {
      this.isOpen = false;
      this.$emit('input', option);
    },
    isSelected(option) {
      return this.value === option;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';

@mixin dropdown2(
  $bgc,
  $bgc-disabled,
  $bgc-invalid,
  $font,
  $font-disabled,
  $font-invalid,
  $label,
  $label-disabled,
  $label-invalid,
  $border-color,
  $border-type,
  $border-active,
  $border-disabled,
  $border-invalid,
  $placeholder-color
) {
  .dropdown2__header {
    margin-bottom: 7px;
  }

  .dropdown2__label {
    display: block;
    height: 14px;
    font-family: $montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    font-size: 12px;
    color: $label;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .dropdown2__clear {
    font-family: $open-sans;
    font-size: 11px;
    line-height: 15px;
    color: #979797;
    cursor: pointer;
  }

  .dropdown2__placeholder {
    color: $placeholder-color;
    font-family: $open-sans;
  }

  .dropdown2__label[disabled] {
    display: block;
    height: 14px;
    font-family: $montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    font-size: 12px;
    opacity: 0.5;
  }

  .dropdown2 {
    &__container {
      position: relative;
    }

    &__box {
      min-height: 40px;
      position: relative;
      border: 1px $border-type $border-color;
      padding: 0;
      padding-inline-end: 24px;
      padding-inline-start: 10px;
      background: $bgc;
      color: $font;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.has-tags {
        padding: 5px;
        padding-inline-end: 30px;
      }

      &.has-error {
        border: solid 1px $color-error;
      }

      &.active {
        border-color: $border-active;
      }
    }

    &__box[disabled] {
      border: 1px solid $color-grey-light;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &__inner {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    &__select {
      width: 100%;
      font-family: $open-sans;
      display: inline-flex;
      flex-wrap: wrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &--image {
        width: 18px;
        height: 18px;
        margin-inline-end: 8px;
      }

      span {
        font-size: 13px;
        white-space: nowrap;
      }
    }

    &__arrow {
      position: absolute;
      inset-inline-end: 9px;
      font-size: 16px;
      color: $border-color;

      &.active {
        color: $border-active;
      }
    }

    &__error {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $open-sans;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      color: $color-error;
      margin-top: 10px;

      svg {
        width: 14px;
        height: 12px;
      }
    }
  }
}

$placeholder-color: #c6c6c6;
$border-solid: solid;
$border-dashed: dashed;

.dropdown2--light {
  @include dropdown2(
    $color-white,
    $color-white-like,
    $color-white,
    $color-black,
    $color-white,
    $color-grey-like,
    $color-black,
    $color-grey-like,
    $color-grey-light,
    $color-grey,
    $border-solid,
    $color-black,
    $color-white-like,
    $color-cinnabar-red,
    $placeholder-color
  );
}

.dropdown2--grey-light {
  @include dropdown2(
    $color-white,
    $color-white-like,
    $color-white,
    $color-black,
    $color-white,
    $color-grey-like,
    $color-grey-light-darker,
    $color-grey-like,
    $color-grey-light,
    $color-grey-light,
    $border-solid,
    $color-grey-light,
    $color-white-like,
    $color-cinnabar-red,
    $placeholder-color
  );
}

.dropdown2--dashed-light {
  @include dropdown2(
    $color-white,
    $color-white-like,
    $color-white,
    $color-black,
    $color-white,
    $color-grey-like,
    $color-black,
    $color-grey-like,
    $color-grey-light,
    $color-grey,
    $border-dashed,
    $border-dashed,
    $color-white-like,
    $color-cinnabar-red,
    $placeholder-color
  );
}

.dropdown2--grey-dark {
  @include dropdown2(
    $color-grey-dark,
    $color-white-like,
    $color-black,
    $color-grey-light,
    $color-white,
    $color-grey-like,
    $color-white,
    $color-grey-like,
    $color-grey-light,
    $color-grey,
    $border-solid,
    $color-grey-light,
    $color-white-like,
    $color-cinnabar-red,
    $placeholder-color
  );
}

.dropdown2--primary-outline {
  @include dropdown2(
    $color-white,
    $color-white-like,
    $color-white,
    $color-black,
    $color-white,
    $color-grey-like,
    $color-black,
    $color-grey-like,
    $color-grey-light,
    $color-black,
    $border-solid,
    $color-black,
    $color-white-like,
    $color-cinnabar-red,
    $color-black
  );
}

.dropdown2--dark {
  @include dropdown2(
    $color-black,
    $color-white-like,
    $color-black,
    $color-grey-light,
    $color-white,
    $color-grey-like,
    $color-white,
    $color-grey-like,
    $color-grey-light,
    $color-grey,
    $border-solid,
    $color-grey-light,
    $color-white-like,
    $color-cinnabar-red,
    $placeholder-color
  );
}

.dropdown2-option {
  $root: &;
  // width: 100%;
  position: absolute;
  background-color: $color-white;
  z-index: 10;
  max-height: 193px;
  overflow-x: hidden;
  overflow-y: auto;
  border: solid 1px $color-greyer;

  &__group {
    &__label {
      display: flex;
      align-items: center;
      height: 34px;
      padding: 0 15px;
      font-family: $montserrat;
      font-size: 12px;
      line-height: 15px;
      color: $color-black-like;
      border-bottom: solid 1px $color-greyer;
    }

    #{$root}__item + #{$root}__item {
      border-top: unset !important;
    }

    #{$root}__item {
      padding-inline-start: 30px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    font-family: $open-sans;
    font-size: 13px;
    color: $color-black-like;
    border-inline-start: solid 4px $color-white;
    padding: 0 14px;
    height: 40px;
  }

  &__item {
    $item: &;

    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    font-family: $open-sans;
    font-size: 13px;
    letter-spacing: 0.09px;
    color: $color-black-like;
    border-inline-start: solid 4px $color-white;
    padding: 0 14px;
    cursor: pointer;
    border-bottom: solid 1px $color-greyer;

    &--selected {
      background-color: $color-whiter;
      border-inline-start-color: $color-yellow;
    }

    &--disabled {
      cursor: not-allowed;
      color: $color-grey;
    }

    &:hover:not(#{$item}--selected):not(#{$item}--disabled) {
      background-color: $color-whiter;
      border-color: $color-whiter;
    }

    + .dropdown2-option__item {
      border-top: 1px solid #e7e7e7;
    }

    &__inner {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &__checkbox {
      margin-inline-end: 10px;
      min-width: auto;
    }

    &__icon {
      margin-inline-end: 11px;
    }

    &__avatar {
      margin-inline-end: 8px;
    }
  }

  &__text {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__icon{
    width: 11px;
    height: 20px;
    color: #d1d1d1;
  }

  &__side {
    text-align: center;
    letter-spacing: 0.08px;
    color: #afafaf;
    border-radius: 2px;
    font-size: 10px;
    background-color: #fafafa;
    padding: 3px 5px;
  }
}
</style>
