var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clickOutsideDropdown,
          expression: "clickOutsideDropdown"
        }
      ],
      staticClass: "pui-dropdown",
      class: ["pui-dropdown--" + _vm.theme]
    },
    [
      _vm.label
        ? _c(
            "div",
            {
              staticClass:
                "pui-dropdown__header d-flex justify-content-between align-items-center"
            },
            [
              _c(
                "label",
                {
                  staticClass: "pui-dropdown__label",
                  attrs: { disabled: _vm.disabled }
                },
                [_vm._v(_vm._s(_vm.label) + _vm._s(_vm.required ? "*" : ""))]
              ),
              _vm._v(" "),
              _vm.clearable
                ? _c(
                    "span",
                    {
                      staticClass: "pui-dropdown__clear",
                      on: { click: _vm.clear }
                    },
                    [_vm._v(_vm._s(_vm.$t("platform:generic.clear")))]
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { ref: "dropdown_field", staticClass: "pui-dropdown__container" },
        [
          _c(
            "div",
            {
              ref: "reference",
              staticClass: "pui-dropdown__box",
              class: {
                "has-tags": _vm.hasValue && _vm.multiple,
                "has-error": _vm.error || _vm.errorstate,
                active: _vm.isOpen
              },
              style: [
                _vm.multiple
                  ? { minHeight: _vm.height }
                  : { height: _vm.height },
                { "--padding-inline-end": _vm.showInlineClearButton ? 2 : 1 }
              ],
              attrs: { disabled: _vm.disabled },
              on: { click: _vm.toggleOpenClose }
            },
            [
              _vm.hasValue && !_vm.hideSelection
                ? _c("div", { staticClass: "pui-dropdown__inner" }, [
                    _c(
                      "div",
                      { staticClass: "pui-dropdown__select" },
                      [
                        _vm.multiple
                          ? _vm._l(_vm.selectedList, function(selected) {
                              return _c(
                                "div",
                                {
                                  key: selected[_vm.valueKey],
                                  staticClass: "pui-dropdown-tag"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pui-dropdown-tag__wrapper"
                                    },
                                    [
                                      _vm._t(
                                        "tag",
                                        [
                                          selected.imgSrc === "avatar" &&
                                          selected.avatar
                                            ? _c(
                                                "PUIAvatar",
                                                _vm._b(
                                                  {},
                                                  "PUIAvatar",
                                                  selected.avatar,
                                                  false
                                                )
                                              )
                                            : selected.icon
                                            ? [
                                                selected.imgSrc === "icon"
                                                  ? _c("PUIIcon", {
                                                      attrs: {
                                                        name: selected.icon,
                                                        size: "small"
                                                      }
                                                    })
                                                  : selected.imgSrc ===
                                                    "langIcon"
                                                  ? _c("PUILangIcon", {
                                                      attrs: {
                                                        lang: selected.value,
                                                        size: "small",
                                                        color: "primary"
                                                      }
                                                    })
                                                  : _c("img", {
                                                      staticClass:
                                                        "pui-dropdown-tag__image",
                                                      attrs: {
                                                        src: selected.icon
                                                      }
                                                    })
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip.top",
                                                  value: _vm.vTooltip
                                                    ? Object.assign(
                                                        {},
                                                        {
                                                          content:
                                                            selected[
                                                              _vm.textKey
                                                            ]
                                                        },
                                                        _vm.vTooltip
                                                      )
                                                    : undefined,
                                                  expression:
                                                    "vTooltip ? {\n                      content: selected[textKey],\n                      ...vTooltip,\n                    } : undefined",
                                                  modifiers: { top: true }
                                                }
                                              ],
                                              staticClass:
                                                "pui-dropdown-tag__content",
                                              style: { fontSize: _vm.fontSize }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(selected[_vm.textKey]) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        { data: selected }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pui-dropdown-tag__remove-container",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.removeTag(
                                                selected[_vm.valueKey]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass:
                                              "pui-dropdown-tag__remove",
                                            attrs: { icon: ["fal", "times"] }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                ]
                              )
                            })
                          : _vm._t(
                              "selection",
                              [
                                _vm.showAvatar
                                  ? _c("PUIAvatar2", {
                                      staticClass: "pui-dropdown-tag__avatar",
                                      attrs: {
                                        type: _vm.avatarType,
                                        name: _vm.selectedList[_vm.textKey],
                                        size: "normal"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.selectedList.icon
                                  ? [
                                      _vm.selectedList.imgSrc === "icon"
                                        ? _c("PUIIcon", {
                                            staticClass:
                                              "pui-dropdown-tag__icon",
                                            attrs: {
                                              name: _vm.selectedList.icon,
                                              size: "small"
                                            }
                                          })
                                        : _vm.selectedList.imgSrc === "langIcon"
                                        ? _c("PUILangIcon", {
                                            staticStyle: {
                                              "margin-inline-end": "11px"
                                            },
                                            attrs: {
                                              lang: _vm.selectedList.value,
                                              size: "small",
                                              color: "primary"
                                            }
                                          })
                                        : _c("img", {
                                            staticClass:
                                              "pui-dropdown-tag__image",
                                            attrs: {
                                              src: _vm.selectedList.icon
                                            }
                                          })
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.top",
                                        value: _vm.vTooltip
                                          ? Object.assign(
                                              {},
                                              {
                                                content:
                                                  _vm.selectedList[_vm.textKey]
                                              },
                                              _vm.vTooltip
                                            )
                                          : undefined,
                                        expression:
                                          "vTooltip ? {\n                content: selectedList[textKey],\n                ...vTooltip,\n              } : undefined",
                                        modifiers: { top: true }
                                      }
                                    ],
                                    style: { fontSize: _vm.fontSize }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.selectedList[_vm.textKey]) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              { selected: _vm.selectedList }
                            )
                      ],
                      2
                    )
                  ])
                : _c("div", { staticClass: "pui-dropdown__inner" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pui-dropdown__select d-flex align-items-center"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "pui-dropdown__placeholder",
                            style: { fontSize: _vm.fontSize }
                          },
                          [_vm._v(_vm._s(_vm.placeholder))]
                        )
                      ]
                    )
                  ]),
              _vm._v(" "),
              _vm.showInlineClearButton
                ? _c("PUIIconButton", {
                    staticClass: "pui-dropdown__clear-select",
                    attrs: { icon: ["fal", "times"] },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.clear($event)
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("PUIIconButton", {
                staticClass: "pui-dropdown__arrow",
                class: { active: _vm.isOpen },
                attrs: {
                  icon: [
                    "fal",
                    _vm.optionPosition === "top" ? "angle-up" : "angle-down"
                  ]
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.isOpen
            ? _c("DropdownOptions", {
                ref: "popper",
                attrs: {
                  value: _vm.selectedOption,
                  valueType: _vm.valueType,
                  textKey: _vm.textKey,
                  valueKey: _vm.valueKey,
                  options: _vm.renderedOptions,
                  grouped: _vm.grouped,
                  searchable: _vm.searchable,
                  searchKeyword: _vm.innerSearchKeyword,
                  multiple: _vm.multiple,
                  multipleLimit: _vm.multipleLimit,
                  searchPlaceholder: _vm.searchPlaceholder,
                  noResultPlaceholderGetter: _vm.noResultPlaceholderGetter,
                  addOption: _vm.addOption,
                  customClass: _vm.optionClass,
                  fontSize: _vm.fontSize,
                  showAvatar: _vm.showAvatar,
                  vTooltip: _vm.vTooltip,
                  remote: _vm.remote,
                  loading: _vm.loading,
                  loadingText: _vm.loadingText,
                  loadingLoader: _vm.loadingLoader,
                  isOpen: _vm.isOpen,
                  lazyLoad: _vm.lazyLoad,
                  isLoadingMore: _vm.isLoadingMore
                },
                on: {
                  "update:searchKeyword": function($event) {
                    _vm.innerSearchKeyword = $event
                  },
                  "update:search-keyword": function($event) {
                    _vm.innerSearchKeyword = $event
                  },
                  esc: function($event) {
                    _vm.isOpen = false
                  },
                  change: _vm.updateSelection,
                  "update-options": _vm.updateOptions,
                  "query-changed": _vm.handleQueryChanged,
                  "scroll-to-end": _vm.handleScrollToEnd
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return _vm._t("option", null, {
                          option: scope.option,
                          index: scope.index
                        })
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.error
        ? _c(
            "div",
            { staticClass: "pui-dropdown__error help is-danger" },
            [
              _c("span", [_vm._v(_vm._s(_vm.error))]),
              _vm._v(" "),
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "exclamation-triangle"] }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }