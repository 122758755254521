<template>
  <div
    ref="dropdown"
    class="pui-dropdown-option"
    @scroll="detectScrollToEnd"
    @keyup.esc="$emit('esc')"
  >
    <span
      v-if="searchable"
      class="pui-dropdown-option__search"
      @click.stop
    >
      <font-awesome-icon
        :icon="['fal', icon]"
        size="xs"
        class="pui-dropdown-option__search-icon"
      />
      <input
        ref="searchInput"
        v-model="bindSearchKeyword"
        dir="auto"
        :placeholder="searchPlaceholder"
        class="pui-dropdown-option__search-field"
        type="text"
        @keyup.enter.stop
      />
    </span>

    <div
      v-if="loading"
      class="pui-dropdown-option__loading"
    >
      <span>{{ loadingText }}</span>
      <PUILoader
        v-if="loadingLoader"
        size="small"
      />
    </div>

    <div
      v-else-if="!filteredOptions.length"
      class="pui-dropdown-option__info"
    >
      <span v-if="addOption">
        {{ noResultPlaceholder.text }}
        <a @click="addOptionToggle">{{ noResultPlaceholder.action }}</a>
      </span>
      <span v-else>{{ noResultPlaceholder.text }}</span>
    </div>

    <template v-if="!loading">
      <!-- Grouped -->
      <template v-if="grouped">
        <div
          v-for="group in filteredOptions"
          :key="group.label"
          class="pui-dropdown-option__group"
        >
          <div class="pui-dropdown-option__group__label">
            <span class="pui-dropdown-option__text">{{ group.label }}</span>
            <!-- <font-awesome-icon
              :icon="['fal', 'angle-down']"
              size="md"
              class="pui-dropdown-option__icon"
            /> -->
          </div>

          <span
            v-for="item in group.options"
            :id="item[valueKey]"
            :key="item[valueKey]"
            :class="[
              {
                'pui-dropdown-option__item--active': getIndexOfAllOptions(item[valueKey]) === activeIndex,
                'pui-dropdown-option__item--selected': isSelected(item),
                'pui-dropdown-option__item--disabled': isDisabled(item),
              },
              customClass,
            ]"
            class="pui-dropdown-option__item"
            @click.stop="select(item)"
            @mouseover="activeIndex = getIndexOfAllOptions(item[valueKey])"
          >
            <div class="pui-dropdown-option__item__inner">
              <PUICheckbox
                v-if="multiple"
                :checked="selectedOption"
                :value="item[valueKey]"
                :disabled="isDisabled(item)"
                class="pui-dropdown-option__item__checkbox"
                @change="() => select(item)"
                @click.native.stop="() => {}"
              />
              <slot
                :option="item"
                :index="getIndexOfAllOptions(item[valueKey])"
              >
                <div
                  v-if="item.avatar || item.icon || showAvatar"
                  class="pui-dropdown-option__item__icon"
                >
                  <PUIAvatar
                    v-if="
                      (item.imgSrc === 'avatar' &&
                        item.avatar.avatarId) ||
                        (item.imgSrc === 'avatarUrl' &&
                          item.avatar.avatarUrl) ||
                        (item.imgSrc === 'fallback' &&
                          item.avatar.fallback) ||
                        (item.imgSrc === 'name' &&
                          item.avatar.name)
                    "
                    v-bind="item.avatar"
                  />
                  <template v-else-if="item.icon">
                    <PUIIcon
                      v-if="item.imgSrc === 'icon'"
                      :name="item.icon"
                    />
                    <img
                      v-else
                      :src="item.icon"
                    />
                  </template>
                </div>
                <div
                  v-tooltip="vTooltip ? {
                    content: item[textKey],
                    ...vTooltip,
                  } : undefined"
                  class="pui-dropdown-option__text"
                  :title="item[textKey]"
                >{{ item[textKey] }}
                </div>
              </slot>
            </div>
          </span>
        </div>
      </template>
      <!-- Un-grouped -->
      <template v-else>
        <span
          v-for="(item, index) in filteredOptions"
          :id="item[valueKey]"
          :key="item[valueKey]"
          :class="[
            {
              'pui-dropdown-option__item--active': index === activeIndex,
              'pui-dropdown-option__item--selected': isSelected(item),
              'pui-dropdown-option__item--disabled': isDisabled(item),
            },
            customClass,
          ]"
          class="pui-dropdown-option__item"
          @click.stop="!item.disabled && select(item)"
          @mouseover="activeIndex = index"
        >
          <div class="pui-dropdown-option__item__inner">
            <PUICheckbox
              v-if="multiple"
              :checked="selectedOption"
              :value="item[valueKey]"
              :disabled="isDisabled(item)"
              class="pui-dropdown-option__item__checkbox"
              @change="() => select(item)"
              @click.native.stop="() => {}"
            />
            <slot
              :option="item"
              :index="index"
            >
              <div
                v-if="item.avatar || item.icon || showAvatar"
                class="pui-dropdown-option__item__icon"
              >
                <PUIAvatar
                  v-if="item.imgSrc === 'avatar' && item.avatar"
                  v-bind="item.avatar"
                />
                <template v-else-if="item.icon">
                  <PUIIcon
                    v-if="item.imgSrc === 'icon'"
                    :name="item.icon"
                  />
                  <PUILangIcon
                    v-else-if="item.imgSrc === 'langIcon'"
                    :lang="item.value"
                    size="small"
                    color="primary"
                  />
                  <img
                    v-else
                    :src="item.icon"
                  />
                </template>
                <PUIAvatar2
                  v-else-if="showAvatar"
                  :name="item[textKey]"
                  :type="avatarType"
                  size="normal"
                />
              </div>
              <div
                v-tooltip="vTooltip ? {
                  content: item[textKey],
                  ...vTooltip,
                } : undefined"
                class="pui-dropdown-option__text"
                :style="{ fontSize }"
                :title="item[textKey]"
              >{{ item[textKey] }}</div>
              <div
                v-if="item.numeric_id"
                class="pui-dropdown-option__side"
              >{{ item.numeric_id }}</div>
            </slot>
          </div>
        </span>
      </template>
    </template>

    <!-- Loading more -->
    <span
      v-if="innerIsLoadingMore"
      class="pui-dropdown-option__loading-more"
    >
      <span>{{ $t('platform:generic.loading') }} more</span>
      <PUILoader size="small" />
    </span>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import union from 'lodash/union';
import PUIAvatar2 from '@/components/PUI/Layout/Avatar2.vue';
import PUILangIcon from '@/components/PUI/Controls/LangIcon.vue';
import PUICheckbox from './Checkbox.vue';
import PUILoader from '../Layout/Loader.vue';

const PUIAvatar = () => import('@/components/PUI/Layout/Avatar');

export default {
  name: 'DropdownOptions',
  components: {
    PUICheckbox,
    PUIAvatar,
    PUIAvatar2,
    PUILoader,
    PUILangIcon,
  },
  props: {
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    grouped: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchKeyword: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    multipleLimit: {
      type: Number,
      default: 0,
    },
    addOption: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default() {
        return this.$t('platform:generic.search');
      },
    },
    noResultPlaceholderGetter: {
      type: Function,
      default: null,
    },
    textKey: {
      type: String,
      default: 'text',
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    valueType: {
      type: String,
      default: 'option',
      validator: val => ['option', 'value'].indexOf(val) > -1,
    },
    customClass: {
      type: String,
      default: null,
    },
    fontSize: {
      type: String,
      default: '13px',
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    avatarType: {
      type: String,
      default: 'user',
    },
    vTooltip: {
      type: Object,
      default: null,
    },
    /** 3
     * whether options are loaded from server
     *
     * set to `true` so `query-change` will be triggered when value in search field changes
     */
    remote: {
      type: Boolean,
      default: false,
    },
    /**
     * whether Dropdown is loading data from server
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * displayed text while loading data from server
     */
    loadingText: {
      type: String,
      default() {
        return this.$t('platform:generic.loading');
      },
    },
    /**
     * whether show loader while loading
     */
    loadingLoader: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    lazyLoad: {
      type: Boolean,
      default: false,
    },
    isLoadingMore: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the width would be set to the same of the parent component
    */
    respectParentWidth: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      array: this.options,
      activeIndex: -1,
      innerSearchKeyword: null,
      addOptionNow: false,
      addOptionData: null,
      icon: 'search',
      noResultPlaceholder: {
        text: this.$t('platform:generic.no-results'),
        action: this.$t('platform:generic.dropdown.no-results.action'),
      },

      width: null,
    };
  },
  computed: {
    bindSearchKeyword: {
      get() {
        return this.searchKeyword ?? this.innerSearchKeyword;
      },
      set(val) {
        this.innerSearchKeyword = val;
        this.$emit('update:searchKeyword', val);
        this.$emit('query-changed', val ?? '');
      },
    },
    selectedOption: {
      get() {
        if (this.valueType === 'value') {
          return this.value;
        }

        if (this.multiple) {
          return this.value.map(i => i[this.valueKey]);
        }

        return this.value ? this.value[this.valueKey] : null;
      },
      set(val) {
        if (this.multiple) {
          if (this.valueType === 'value') {
            this.$emit(
              'input',
              this.value.filter(i => val.includes(i[this.valueKey]))
            );
          } else {
            this.$emit(
              'input',
              this.value.filter(i => val.includes(i))
            );
          }
        } else if (this.valueType === 'value') {
          this.$emit(
            'input',
            this.value.filter(i => i[this.valueKey] === val)
          );
        } else {
          this.$emit(
            'input',
            this.value.filter(i => i === val)
          );
        }
      },
    },
    allOptions() {
      if (this.grouped) {
        return union(
          ...this.options.map(group => group.options)
        );
      }

      return cloneDeep(this.options);
    },
    filteredOptions() {
      if (this.remote) return this.options;

      if (this.bindSearchKeyword) {
        if (this.grouped) {
          return this.array
            .map(group => ({
              label: group.label,
              options: group.options.filter(item => item[this.textKey].toLowerCase().indexOf(this.bindSearchKeyword.toLowerCase()) > -1),
            }))
            .filter(group => group.options.length > 0);
        }

        return this.array.filter(item => item[this.textKey].toLowerCase().indexOf(this.bindSearchKeyword.toLowerCase()) > -1);
      }
      return this.options;
    },
    parentHeight() {
      return this.$parent.$refs.dropdown_field.clientHeight;
    },
    reachMultipleLimit() {
      return this.multipleLimit > 0 && this.selectedOption.length >= this.multipleLimit;
    },
    innerIsLoadingMore: {
      get() {
        return this.isLoadingMore;
      },
      set() {},
    },
  },
  watch: {
    // TODO: Check grouped
    filteredOptions(val) {
      this.activeIndex = -1;

      if (val.length === 0 && this.addOption) {
        this.icon = 'plus';
      } else {
        this.icon = 'search';
      }
    },
    '$parent.width': {
      handler() {
        this.updateWidth();
      },
    },
    options: {
      deep: true,
      handler() {
        if (this.lazyLoad) {
          this.$nextTick(() => {
            this.detectScrollToEnd();
          });
        }
      },
    },
  },
  mounted() {
    // noResultPlaceholderGetter
    if (typeof this.noResultPlaceholderGetter === typeof Function) {
      this.noResultPlaceholder = this.noResultPlaceholderGetter();
    }
    if (this.$parent.value) {
      const parentSelectedValue = this.valueType === 'value' ? this.$parent.value : this.$parent.value[this.valueKey];

      if (parentSelectedValue !== null) {
        this.activeIndex = this.allOptions.findIndex(option => option[this.valueKey] === parentSelectedValue);
        this.scrollView();
      }
    }

    this.updateWidth();

    if (this.lazyLoad) {
      this.detectScrollToEnd();
    }

    if (this.$refs.searchInput) {
      this.$refs.searchInput.focus();
    }
  },
  beforeMount() {
    document.addEventListener('keyup', this.captureKey);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.captureKey);
  },
  methods: {
    updateWidth() {
      this.$el.style.minWidth = `${this.$parent.$el.getBoundingClientRect().width}px`;
    },
    captureKey(e) {
      const { key } = e;
      if (this.isOpen) {
        if (key === 'ArrowDown') this.step(1);
        else if (key === 'ArrowUp') this.step(-1);
        else if (key === 'Enter') this.selectOptionWithEnter();
      }
    },
    step(step) {
      const max = this.allOptions.length - 1;
      const min = 0;

      this.activeIndex += step;

      if (this.activeIndex < min) {
        this.activeIndex = max;
      } else if (this.activeIndex > max) {
        this.activeIndex = min;
      }

      this.scrollView();
    },
    selectOptionWithEnter() {
      if (this.activeIndex > -1 && this.activeIndex < this.allOptions.length) {
        const selectedOption = this.allOptions[this.activeIndex];
        this.emitChange(selectedOption);
      }
    },
    select(item) {
      if (!this.isSelected(item) && this.reachMultipleLimit) return;
      if (item.disabled) return;

      const selectedOption = this.allOptions.find(p => p[this.valueKey] === item[this.valueKey]);
      this.emitChange(selectedOption);
    },
    emitChange(selectedOption) {
      if (this.valueType === 'value') {
        this.$emit('change', selectedOption[this.valueKey]);
      } else {
        this.$emit('change', selectedOption);
      }
    },
    scrollView() {
      if (this.activeIndex > -1 && this.activeIndex < this.allOptions.length) {
        const element = document.getElementById(this.allOptions[this.activeIndex][this.valueKey]);
        if (element) {
          element.scrollIntoView({
            block: 'nearest',
            inline: 'start',
          });
        }
      }
    },
    addOptionToggle() {
      this.addOptionNow = true;
      this.searchable = false;
      this.$emit('update-options', this.bindSearchKeyword);
      this.bindSearchKeyword = '';
    },
    getIndexOfAllOptions(value) {
      return this.allOptions.findIndex(option => option[this.valueKey] === value);
    },
    isSelected(item) {
      if (this.multiple && Array.isArray(this.selectedOption)) {
        return this.selectedOption.some(element => element === item[this.valueKey]);
      }
      return this.selectedOption && this.selectedOption === item[this.valueKey];
    },
    isDisabled(item) {
      if (item.disabled) return true;

      return !this.isSelected(item) && this.reachMultipleLimit;
    },
    detectScrollToEnd: debounce(function () {
      /**
       * https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled
       */

      if (this.loading) return;

      const { scrollTop, clientHeight, scrollHeight } = this.$el;
      if ((Math.abs(scrollHeight - clientHeight - scrollTop) < 1) && scrollTop > 0) {
        this.innerIsLoadingMore = true;
        // Scrolled to end
        this.$emit('scroll-to-end');
      }
    }, 200),
  },
};
</script>

<style scoped lang="scss">
@import '~styles/pui/pui-variables.scss';

.pui-dropdown-option {
  $root: &;

  position: absolute;
  background-color: $color-white;
  z-index: 10;
  max-height: 193px;
  overflow-x: hidden;
  overflow-y: auto;
  border: solid 1px $color-greyer;

  // &[data-popper-reference-hidden] {
  //   visibility: hidden;
  //   pointer-events: none;
  // }

  &__group {
    &__label {
      display: flex;
      align-items: center;
      height: 34px;
      padding: 0 15px;
      font-family: $montserrat;
      font-size: 12px;
      line-height: 15px;
      color: $color-black-like;
      border-bottom: solid 1px $color-greyer;
    }

    #{$root}__item + #{$root}__item {
      border-top: unset !important;
    }

    #{$root}__item {
      padding-inline-start: 30px;
    }
  }

  &__loading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $open-sans;
    font-size: 13px;
    color: $color-black-like;
    border-inline-start: solid 4px $color-white;
    padding: 0 14px;
    height: 40px;
  }

  &__loading-more {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $open-sans;
    font-size: 13px;
    color: $color-black-like;
    border-inline-start: solid 4px $color-white;
    padding: 0 14px;
    height: 40px;
  }

  &__info {
    display: flex;
    align-items: center;
    font-family: $open-sans;
    font-size: 13px;
    color: $color-black-like;
    border-inline-start: solid 4px $color-white;
    padding: 0 14px;
    height: 40px;
  }

  &__item {
    $item: &;

    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    font-family: $open-sans;
    font-size: 13px;
    letter-spacing: 0.09px;
    color: $color-black-like;
    border-inline-start: solid 4px $color-white;
    padding: 0 14px;
    cursor: pointer;
    border-bottom: solid 1px $color-greyer;

    &--selected {
      background-color: $color-whiter;
      border-inline-start-color: $color-yellow;
    }

    &--disabled {
      cursor: not-allowed;
      color: $color-grey;
    }

    &--active:not(#{$item}--selected):not(#{$item}--disabled) {
      background-color: $color-whiter;
      border-color: $color-whiter;
    }

    + .pui-dropdown-option__item {
      border-top: 1px solid #e7e7e7;
    }

    &__inner {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &__checkbox {
      margin-inline-end: 10px;
      min-width: auto;
    }

    &__icon {
      margin-inline-end: 11px;
    }

    &__avatar {
      margin-inline-end: 8px;
    }
  }

  .pui-dropdown-option__search {
    display: flex;
    align-items: center;
    width: 100%;
    font-family: $open-sans;
    font-size: 13px;
    color: $color-black-like;
    border-bottom: solid 1px $color-greyer;
    height: 40px;
    padding: 0 15px;

    &.active {
      background-color: $color-whiter;
    }

    .pui-dropdown-option__search-icon {
      width: 20px;
      font-size: 25px;
      margin: 0;
      // margin-inline-start: 15px;
      margin-inline-end: 7px;
    }

    .pui-dropdown-option__search-field {
      flex: 1;
    }
  }

  .pui-dropdown-option-add {
    display: flex;
    width: inherit;
    font-family: $open-sans;
    font-size: 13px;
    letter-spacing: 0.09px;
    color: $color-black-like;
    border: solid 1px $color-grey-like;

    &.active {
      background-color: $color-whiter;
    }

    .pui-dropdown-option-add-icon {
      font-size: 25px;
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }

    .pui-dropdown-option-add-field {
      flex: 10;
    }
  }

  .pui-dropdown-option__search-field {
    width: inherit;
    border: none;
    outline: none;
  }

  .pui-dropdown-option-add-field {
    width: inherit;
    border: none;
  }

  &__text {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-items: center
  }

  &__icon{
    width: 11px;
    height: 20px;
    color: #d1d1d1;
  }

  &__side {
    text-align: center;
    letter-spacing: 0.08px;
    color: #afafaf;
    border-radius: 2px;
    font-size: 10px;
    background-color: #fafafa;
    padding: 3px 5px;
  }
}

[dir="rtl"] input[type='text']::placeholder
{
    text-align: right;      /* for Chrome, Firefox, Opera */
}
input[type='text']::placeholder
{
    text-align: left;      /* for Chrome, Firefox, Opera */
}
[dir="rtl"] :-ms-input-placeholder
{
    text-align: right;      /* for IE 10-11 */
}
:-ms-input-placeholder
{
    text-align: left;      /* for IE 10-11 */
}
[dir="rtl"] ::-webkit-input-placeholder
{
    text-align: right;      /* for IE Edge */
}
::-webkit-input-placeholder
{
    text-align: left;      /* for IE Edge */
}
</style>
