import $bus from '@/platformSettings/bus';
import $router from '@/router/platform';
import i18n from '@/lang/i18n';

export default (msg, $store) => {
  const { dispatch, rootState } = $store;

  const notifyTitle = msg.customer_name || msg.customer_id.slice(-5);
  const notifyOption = {
    body: i18n.t(
      'chat:livechat.notification.agent_idle_too_long',
      rootState.uiv2.platformLang,
      { customerName: notifyTitle },
    ),
    tag: msg.conversation_id,
  };
  const notifyCallback = {
    onClick: () => {
      if (
        rootState.route.name === 'LiveChat'
        && rootState.route.params.conversationId === msg.conversation_id
      ) { return; }

      $router.push({
        name: 'LiveChat',
        params: {
          conversationId: msg.conversation_id,
        },
      });
    },
  };

  $bus.$notification.show(notifyTitle, notifyOption, notifyCallback);

  dispatch('playNotificationSound');
};
