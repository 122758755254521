import Vue from 'vue';
import Vuex from 'vuex';

import {
  getExponentialBackoff,
  HEARTBEAT_INTERVAL,
  AUTO_RECONNECT_MAX_ATTEMPTS,
  WS_STATUS,
  WEBCHAT_STATE,
  DEFAULT_STYLE,
} from '@/webchat2/config';

import { makeId } from '@/helpers/idHelper';

import querystring from 'querystring';
import Rpc from '@/helpers/pmrpc';
import $bus from '@/platformSettings/bus';
import rtlLangs from '@/platformSettings/rtlLangs';
import {
  SneakPeekQuickReplyButton, SneakPeekTriggerButton, SneakPeekHyperlinkButton, SNEAK_PEEK_BUTTON_TYPE,
} from '@/platformSettings/sneakPeek';

import {
  isSameGroup,
  AUTHOR_TYPE,
  MESSAGE_TYPE,
  MESSAGE_POSITION,
  SEND_STATUS,
  DISPLAYABLE_MESSAGE_TYPE,
  MSG_TYPE_FEEDBACK,

  // Functions
  isVisibleMessage,
  isUnreadMessageFromBotOrAgent,
  isMessageFromHuman,
} from '@/helpers/messageHelper';
import { ACTIONS } from '@/webchat2/config/types';

import VueDOMPurifyHTML from 'vue-dompurify-html';
import { PAI_AUTHOR_TYPES } from '@/platformSettings/pai';
import handlerDispatcher from './webchatHandlers/index.js';

Vue.use(Vuex);

Vue.use(VueDOMPurifyHTML, {
  default: {
    ADD_ATTR: ['target'],
  },
});

let socket = null;
const rpc = new Rpc({ targetWindow: null, targetOrigin: '*' });

function getMediaMessageType(mimetype) {
  const fileTypeStr = mimetype.toString();

  switch (true) {
    case fileTypeStr.startsWith('image/'):
      return 'image';

    case fileTypeStr.startsWith('audio/'):
      return 'audio';

    case fileTypeStr.startsWith('video/'):
      return 'video';

    default:
      return 'file';
  }
}

export const MUTATION = {
  setParentPageURL: 'setParentPageURL',
  setConnectionInfo: 'setConnectionInfo',
  setTimeoutIds: 'setTimeoutIds',
  setIntervalIds: 'setIntervalIds',
  decreaseWaitingTime: 'decreaseWaitingTime',
  updateUiSettings: 'updateUiSettings',
  resetUiSettings: 'resetUiSettings',
  setLang: 'setLang',
  toggleChatWindow: 'toggleChatWindow',
  toggleSneakPeek: 'toggleSneakPeek',
  setChats: 'setChats',
  setActiveChatId: 'setActiveChatId',
  setWebchatState: 'setWebchatState',
  addMessageToChat: 'addMessageToChat',
  addUserMessageToChat: 'addUserMessageToChat',
  addUserMediaMessageToChat: 'addUserMediaMessageToChat',
  updateMessageToChat: 'updateMessageToChat',
  deleteMessageToChat: 'deleteMessageToChat',
  setQuickReply: 'setQuickReply',
  removeAllBotTyping: 'removeAllBotTyping',
  increaseChatUnreadMessagesCount: 'increaseChatUnreadMessagesCount',
  setMessagesReadByHuman: 'setMessagesReadByHuman',
  setConvUpdatedAt: 'setConvUpdatedAt',
  setConversationClosed: 'setConversationClosed',
  surveySent: 'surveySent',
  setIsPreviewMode: 'setIsPreviewMode',
  showChatInput: 'showChatInput',
  hideChatInput: 'hideChatInput',
  setConversationIframeModal: 'setConversationIframeModal',
  resetConversationIframeModal: 'resetConversationIframeModal',
};

const CONVERSATION_TYPE = 'ws:thread';

function getChunkLabel(prevMsg, thisMsg, nextMsg) {
  const isSameGroupAsPrev = isSameGroup(prevMsg, thisMsg);
  const isSameGroupAsNext = isSameGroup(thisMsg, nextMsg);

  if (isSameGroupAsPrev) {
    if (isSameGroupAsNext) return MESSAGE_POSITION.MIDDLE;
    return MESSAGE_POSITION.LAST;
  }
  if (isSameGroupAsNext) return MESSAGE_POSITION.FIRST;
  return MESSAGE_POSITION.SINGLE;
}

const uiSettingsDefault = {
  title: {},
  subtitle: {},
  cards: [],
  cards_title: {},
  start_chat_btn: '',
  postchat_rating_enabled: false,
  position: 'BR',
  start_chat_placeholder: {},
  hide_floating_button: false,
  hide_chatroom_header: false,
};

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  state: {
    parentPageURL: '',

    isPreviewMode: false,
    isConnected: false,
    isReconnecting: false,
    numConnectAttempts: 0,

    numContinualDisconnects: 0,
    numAllDisconnects: 0,

    timeWaitingReconnect: 0,
    reconnectTimeoutId: null,

    countdownIntervalId: null,
    heartbeatIntervalId: null,

    isChatWindowOpen: false,
    uiSettings: uiSettingsDefault,
    currentLang: null,
    chats: {},

    webchatState: null,
    chatUnreadMessagesCount: {},
    activeChatId: null,
    lastMsgWithQr: {},
    // botTypingMsg: null
    isConversationClosed: {},

    isSurveySent: {},

    /**
     * Store conversation which chat input is hidden
     *
     * {
     *    [conversationId]: true
     * }
     */
    hiddenChatInputByConversationId: {},

    /**
     * Store iframe modal attributes
     *
     * {
     *    [conversationId]: {
     *      src: null,
     *      iframeId: null,
     *      onClose: false,
     *    }
     * }
     */
    iframeModalByConversationId: {},
  },

  getters: {
    rpcClient(state) {
      return rpc;
    },

    availableChats(state) {
      /* Get all non-empty chats */
      return Object.values(state.chats)
        .filter(chat => chat.chat_history.some(isVisibleMessage))
        .sort((lhs, rhs) => new Date(rhs.updated_at).getTime() - new Date(lhs.updated_at).getTime())
        .slice(0, 3);
    },

    activeChatMessagesMeta(state, getters) {
      return getters.activeChatMessages.map((msg, idx, arr) => {
        const chunkLabel = getChunkLabel(arr[idx - 1], arr[idx], arr[idx + 1]);
        const day = Vue.dayjs(msg.ts);
        const isHuman = msg.author && msg.author.type === AUTHOR_TYPE.HUMAN;
        const isAgent = msg.author && [AUTHOR_TYPE.AGENT, ...PAI_AUTHOR_TYPES].includes(msg.author.type);
        const isBot = msg.author && msg.author.type === AUTHOR_TYPE.BOT;
        return {
          isHuman,
          isAgent,
          isBot,
          chunkLabel,
          timeString: day.format('LT'),
          dateString: day.format('ll'),
          showMessageFeedback: !isHuman && MSG_TYPE_FEEDBACK.has(msg.type),
        };
      });
    },

    isUiReady(state) {
      return state.uiSettings != null && Object.keys(state.uiSettings).length > 0;
    },

    uiPosition(state) {
      return (state.uiSettings && state.uiSettings.position) || 'BR';
    },

    langCode(state) {
      return state.currentLang || 'en';
    },

    wsStatus(state) {
      if (!socket) {
        return WS_STATUS.CLOSED;
      }

      if (state.isConnected) {
        return WS_STATUS.OPENED;
      }
      return WS_STATUS.OPENING;
    },

    isChatStarted(state) {
      if (!state.activeChatId) {
        return false;
      }

      const chat = state.chats[state.activeChatId];

      if (!chat) {
        return false;
      }

      const isStarted = chat.chat_history.length > 0;

      if (isStarted) {
        return true;
      }
      return false;
    },

    allowAutoReconnect(state) {
      return state.numContinualDisconnects < AUTO_RECONNECT_MAX_ATTEMPTS;
    },

    activeChatMessages(state) {
      const chat = state.chats[state.activeChatId];

      if (!chat) return [];

      const now = new Date();

      console.log(chat.chat_history.length, chat.chat_history[chat.chat_history.length - 1]?.type);

      return chat.chat_history.filter(msg => DISPLAYABLE_MESSAGE_TYPE.has(msg.type));
    },

    shouldShowPostChatSurvey(state) {
      const chat = state.chats[state.activeChatId];

      if (!chat) return false;

      return state.isConversationClosed[state.activeChatId] && state.uiSettings.postchat_rating_enabled;
    },

    shouldShowQuickReplies(state) {
      const chat = state.chats[state.activeChatId];

      if (!chat) return false;

      if (!state.lastMsgWithQr || state.lastMsgWithQr.cid !== state.activeChatId) return false;

      if (state.isConversationClosed[state.activeChatId]) return false;

      const messages = chat.chat_history;

      if (!messages || messages.length === 0) return false;

      // Last visible message
      for (let idx = messages.length - 1; idx >= 0; idx -= 1) {
        const msg = messages[idx];

        if (isVisibleMessage(msg) && isMessageFromHuman(msg)) return false;

        if (msg.id === state.lastMsgWithQr.id) return true;
      }

      return false;
    },

    totalUnreadMessagesCount(state) {
      return Object.values(state.chatUnreadMessagesCount).reduce((total, cur) => total + cur, 0);
    },

    shouldHideChatInput(state) {
      return state.activeChatId && (state.activeChatId in state.hiddenChatInputByConversationId);
    },

    activeChatIframeModal(state) {
      return state.iframeModalByConversationId?.[state.activeChatId];
    },

    isRTL(state, getters) {
      // TODO: uncomment this when we have Arabic for the bot language
      // return rtlLangs.includes(getters.langCode);

      // demonstrate right-to-left for webchat when use Thai language
      // TODO: remove this when we have Arabic or other right-to-left language
      return false; // getters.langCode === 'th';
    },
  },

  mutations: {
    [MUTATION.setParentPageURL](state, url) {
      state.parentPageURL = url;
    },

    // [MUTATION.setBotTyping](state, { message }) {
    //   state.botTypingMsg = message;
    // },
    [MUTATION.setIsPreviewMode](state, value) {
      state.isPreviewMode = value;
    },

    [MUTATION.surveySent](state, chatId) {
      if (chatId) {
        Vue.set(state.isSurveySent, chatId, true);
      }
    },

    [MUTATION.setConvUpdatedAt](state, chatId) {
      const chat = state.chats[chatId];
      if (chat) {
        chat.updated_at = new Date().toISOString();
      }
    },

    [MUTATION.increaseChatUnreadMessagesCount](state, { chatId, num = 0 }) {
      const currentNum = state.chatUnreadMessagesCount[chatId] ?? 0;

      state.chatUnreadMessagesCount = {
        ...state.chatUnreadMessagesCount,
        [chatId]: currentNum + num,
      };
    },

    [MUTATION.setMessagesReadByHuman](state, { chatId, readMessageIds }) {
      if (!readMessageIds || readMessageIds.length === 0) {
        return;
      }

      const chat = state.chats[chatId];

      if (!chat) {
        return;
      }

      const readMessageIdMapping = Object.fromEntries(readMessageIds.map(id => [id, true]));

      let unreadMessagesCount = 0;

      chat.chat_history.forEach(msg => {
        if (isUnreadMessageFromBotOrAgent(msg)) {
          if (readMessageIdMapping[msg.id]) {
            msg.read_at = true;
          } else {
            unreadMessagesCount += 1;
          }
        }
      });

      state.chatUnreadMessagesCount = {
        ...state.chatUnreadMessagesCount,
        [chatId]: unreadMessagesCount,
      };
    },

    [MUTATION.setConnectionInfo](state, info) {
      const {
        isConnected, isReconnecting, isWaitingReconnect, timeWaitingReconnect, numContinualDisconnects,
      } = info;
      if (isConnected !== undefined) {
        state.isConnected = !!isConnected;
      }
      if (isReconnecting !== undefined) {
        state.isReconnecting = !!isReconnecting;
      }
      if (isWaitingReconnect !== undefined) {
        state.isWaitingReconnect = !!isWaitingReconnect;
      }
      if (numContinualDisconnects !== undefined) {
        state.numContinualDisconnects = numContinualDisconnects;
      }
      if (timeWaitingReconnect !== undefined) {
        state.timeWaitingReconnect = timeWaitingReconnect;
      }
    },

    [MUTATION.toggleChatWindow](state, toOpen) {
      toOpen = toOpen ?? !state.isChatWindowOpen;
      toOpen = !!toOpen;

      state.isChatWindowOpen = toOpen;

      Vue.nextTick(() => {
        rpc.call('toggleChatWindow', toOpen);
      });
    },

    [MUTATION.toggleSneakPeek](state, toShow) {
      rpc.call('toggleSneakPeek', toShow);
    },

    [MUTATION.decreaseWaitingTime](state) {
      if (state.timeWaitingReconnect > 0) {
        state.timeWaitingReconnect -= 1000;
      }
    },

    [MUTATION.setTimeoutIds](state, { reconnectTimeoutId }) {
      if (reconnectTimeoutId !== undefined) {
        state.reconnectTimeoutId = reconnectTimeoutId;
      }
    },

    [MUTATION.setIntervalIds](state, { countdownIntervalId, heartbeatIntervalId }) {
      if (countdownIntervalId !== undefined) {
        state.countdownIntervalId = countdownIntervalId;
      }

      if (heartbeatIntervalId !== undefined) {
        state.heartbeatIntervalId = heartbeatIntervalId;
      }
    },

    [MUTATION.updateUiSettings](state, uiSettings) {
      if (uiSettings != null) {
        const settings = {
          ...state.uiSettings,
          ...uiSettings,
        };

        state.uiSettings = {
          ...settings,

          /* set default value */

          color: settings.color || DEFAULT_STYLE.BRANDING_COLOR,
          color_btn: settings.color_btn || DEFAULT_STYLE.MAIN_BUTTON_COLOR,
          color_quick_reply: settings.color_quick_reply || DEFAULT_STYLE.QUICK_REPLY_COLOR,
          color_notification: settings.color_notification || DEFAULT_STYLE.NOTIFICATION_COLOR,
          position: settings.position || DEFAULT_STYLE.POSITION,
          enable_emojis: !!(settings.enable_emojis ?? DEFAULT_STYLE.ENABLE_EMOJIS),
          mobile_width: settings.mobile_width || DEFAULT_STYLE.MOBILE_WIDTH,
          mobile_height: settings.mobile_height || DEFAULT_STYLE.MOBILE_HEIGHT,
        };

        if (!state.currentLang && uiSettings.bot_langs) {
          state.currentLang = uiSettings.bot_langs[0] || 'en';
          const dayjsLocale = state.currentLang.replace('_', '-').toLowerCase();
          import(`dayjs/locale/${dayjsLocale}.js`).then(res => null).catch(err => {
            console.error(err);
          });
        }
      }
    },

    [MUTATION.resetUiSettings](state) {
      state.uiSettings = uiSettingsDefault;
    },

    [MUTATION.setLang](state, lang) {
      if (lang !== undefined) {
        state.currentLang = lang;
        const dayjsLocale = lang.replace('_', '-').toLowerCase();
        import(`dayjs/locale/${dayjsLocale}.js`).then(res => null).catch(err => {
          console.error(err);
        });
      }
    },

    [MUTATION.setChats](state, chats) {
      if (chats !== undefined) {
        state.chats = chats;
      }
    },
    [MUTATION.setActiveChatId](state, chatId) {
      if (chatId !== undefined) {
        console.log('Webchat:: Use conversation id', chatId);
        state.activeChatId = chatId;
      }
    },
    [MUTATION.setWebchatState](state, webchatState) {
      webchatState = WEBCHAT_STATE[webchatState];

      if (webchatState == null) {
        if (!state.activeChatId) {
          webchatState = WEBCHAT_STATE.INITIALIZING;
        }

        const chat = state.chats[state.activeChatId];

        if (!chat) {
          webchatState = WEBCHAT_STATE.INITIALIZING;
        }

        const isStarted = chat.chat_history.length > 0;

        const timeDiff = new Date().getTime() - new Date(chat.updated_at).getTime();

        if (isStarted && timeDiff <= 5 * 60 * 1000) {
          webchatState = WEBCHAT_STATE.STARTED;
        } else {
          webchatState = WEBCHAT_STATE.READY;
        }
      }

      console.log('webchatState', webchatState);

      state.webchatState = webchatState;
    },

    [MUTATION.addMessageToChat](state, { chatId, message, status }) {
      const chat = state.chats[chatId];

      if (!chat) throw Error(`Cannot find chat with ID: ${chatId}.`);

      message.sendStatus = status;
      message.receivedAt = new Date();

      chat.chat_history.push(message);

      $bus.$emit('NEW_MESSAGE_ADDED', message);
    },

    [MUTATION.updateMessageToChat](state, {
      chatId, ref, status, url, liked, error,
    }) {
      const chat = state.chats[chatId];
      if (!chat) {
        return;
      }

      const message = chat.chat_history.find(msg => msg.ref === ref || msg.id === ref);
      if (!message) {
        return;
      }

      if (url) {
        message.url = url;
      }

      if (liked !== undefined) {
        message.liked = liked;
      }

      message.sendStatus = status;

      if (error !== undefined) {
        message.error = error;
      }
    },

    [MUTATION.deleteMessageToChat](state, { chatId, ref }) {
      const chats = state.chats[chatId];
      if (!chats) {
        return;
      }

      chats.chat_history = chats.chat_history.filter(msg => msg.ref !== ref);
    },

    [MUTATION.setQuickReply](state, { message }) {
      state.lastMsgWithQr = message;
    },

    [MUTATION.removeAllBotTyping](state, { chatId, messageId = null }) {
      const chat = state.chats[chatId];
      if (!chat) {
        return;
      }

      chat.chat_history = chat.chat_history.filter(
        msg => msg.type !== MESSAGE_TYPE.BOT_TYPING_ON && (messageId == null || messageId !== msg.id)
      );
    },

    [MUTATION.setConversationClosed](state, { chatId }) {
      Vue.set(state.isConversationClosed, chatId, true);
      Vue.set(state.chats[chatId], 'active', false);
    },

    [MUTATION.showChatInput](state, conversationId) {
      Vue.delete(state.hiddenChatInputByConversationId, conversationId);
    },

    [MUTATION.hideChatInput](state, conversationId) {
      Vue.set(state.hiddenChatInputByConversationId, conversationId, true);
    },

    [MUTATION.setConversationIframeModal](state, {
      conversationId, src, iframeId, onClose,
    }) {
      Vue.set(state.iframeModalByConversationId, conversationId, {
        src,
        iframeId,
        onClose,
      });
    },

    [MUTATION.resetConversationIframeModal](state, conversationId) {
      Vue.delete(state.iframeModalByConversationId, conversationId);
    },
  },

  actions: {
    setParentPageURL({ commit }, url) {
      commit(MUTATION.setParentPageURL, url);
    },

    openWs(context, wsConfig) {
      const {
        state, commit, getters, dispatch,
      } = context;
      const {
        wsURL, gatewayId, ateamId, anonToken, humanUid,
      } = wsConfig;

      const baseUrl = `${wsURL}/${gatewayId}`;

      const queryString = querystring.stringify({
        ateam: ateamId,
        anon_token: anonToken || null,
        human_uid: humanUid,
      });

      console.log('Webchat:: Starting');

      // commit(MUTATION.setConnectionInfo, {
      //   numConnectAttempts: state.numConnectAttempts + 1
      // });

      socket = new WebSocket(`${baseUrl}?${queryString}`);

      socket.addEventListener('message', evt => {
        if (typeof evt.data === 'string') {
          const msg = JSON.parse(evt.data);
          // console.log('ws << %s', data.type, data);
          dispatch('handleWsMessage', msg);
        } else {
          console.log('ws << unknown format');
        }
      });

      socket.addEventListener('open', evt => {
        console.log('✅ Webchat:: WS opened', evt);

        if (state.numContinualDisconnects > 0) {
          console.log('ws reconnected');
          $bus.$emit('WS_RECONNECTED');
        } else {
          console.log('ws connected');
          $bus.$emit('WS_CONNECTED');
        }

        commit(MUTATION.setConnectionInfo, {
          isConnected: true,
          isReconnecting: false,
          numContinualDisconnects: 0,
        });
      });

      socket.addEventListener('close', closeEvent => {
        console.log('❌ Webchat:: WS closed', closeEvent);

        this.socket = null;

        commit(MUTATION.setConnectionInfo, {
          isConnected: false,
          isReconnecting: false,
        });

        if (state.heartbeatIntervalId !== null) {
          clearInterval(state.heartbeatIntervalId);
          commit(MUTATION.setIntervalIds, {
            heartbeatIntervalId: null,
          });
        }

        if (!closeEvent.wasClean) {
          if (getters.allowAutoReconnect) {
            const reconnectWaitingTime = getExponentialBackoff(state.numContinualDisconnects);

            commit(MUTATION.setConnectionInfo, {
              timeWaitingReconnect: reconnectWaitingTime,
            });

            console.warn(`Trying to reconnect in ${reconnectWaitingTime / 1000} seconds`);

            const reconnectTimeoutId = setTimeout(() => {
              dispatch('reconnectWs', wsConfig);
            }, reconnectWaitingTime);

            commit(MUTATION.setTimeoutIds, {
              reconnectTimeoutId,
            });
          }
        }

        $bus.$emit('WS_DISCONNECTED');
      });
    },

    initWs({ state, commit, getters }) {
      let countdownIntervalId = null;
      let heartbeatIntervalId = null;

      if (state.countdownIntervalId == null) {
        countdownIntervalId = setInterval(() => {
          if (state.timeWaitingReconnect > 0) {
            commit(MUTATION.decreaseWaitingTime);
          }
        }, 1000);
      }

      if (state.heartbeatIntervalId == null) {
        heartbeatIntervalId = setInterval(() => {
          if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send('{"type": "ping"}');
          }
        }, HEARTBEAT_INTERVAL);
      }

      commit(MUTATION.setIntervalIds, {
        countdownIntervalId,
        heartbeatIntervalId,
      });
    },

    reconnectWs({ state, commit, dispatch }, wsConfig) {
      commit(MUTATION.setConnectionInfo, {
        isReconnecting: true,
        timeWaitingReconnect: 0,
        numContinualDisconnects: state.numContinualDisconnects + 1,
      });

      if (state.reconnectTimeoutId !== null) {
        clearTimeout(state.reconnectTimeoutId);
        commit(MUTATION.setTimeoutIds, {
          reconnectTimeoutId: null,
        });
      }

      // Show "Reconnecting..." text for 1 second first than try to reconnect.
      setTimeout(() => {
        dispatch('openWs', wsConfig);
      }, 1000);
    },

    handleWsMessage($store, message) {
      const { dispatch } = $store;

      if (message.type !== ACTIONS.PONG) {
        let timeDiff;
        const now = new Date();
        if (message.ts) {
          timeDiff = now.getTime() - new Date(message.ts).getTime();
        } else {
          timeDiff = -1;
        }
        console.log(
          'ws message handled. Author=%s, type=%s, ref=%s [%f] %s %s',
          message.author?.type,
          message.type,
          message.ref,
          timeDiff,
          message.ts,
          now.toJSON(),
          message,
        );
      }

      if (DISPLAYABLE_MESSAGE_TYPE.has(message.type) && message?.author?.type === AUTHOR_TYPE.BOT) {
        dispatch('removeBotTypingOfChat', message.cid);
      }

      // Get message handler by type
      const handler = handlerDispatcher[message.type];

      if (handler) {
        // Execute handler
        handler(message, $store, { MUTATION, CONVERSATION_TYPE });
      } else {
        console.error(`No handler for ${message.type}`, message);
      }
    },

    createNewConversation({
      state, commit, dispatch, getters,
    }) {
      const chat = state.chats[state.activeChatId];
      if (chat && ((chat.chat_history && chat.chat_history.some(isVisibleMessage)) || !chat.active)) {
        console.log('Create a new conversation');
        /* create a new one and send v2_start_conversation */

        // To avoid user sees the messages of previous chat
        // activeChatId will be set again upon websocket receiving 'list_conversation' with 'new_conversation' field
        commit(MUTATION.setActiveChatId, null);

        return dispatch('sendMessage', {
          type: 'create_conversation',
          lang: state.currentLang ? state.currentLang.trim() : null,
        });
      }
      /* send v2_start_conversation' */
      console.log('send v2_start_conversation');
      return dispatch('sendStartChatMsg');
    },

    sendMessage({ state, commit }, msg) {
      return new Promise((resolve, reject) => {
        if (socket && state.isConnected) {
          socket.send(JSON.stringify(msg));
          const totalBytes = socket.bufferedAmount;

          /* Track message progress (for file uploads) */
          const interval = setInterval(() => {
            const progress = Math.max(1, Math.min(1 - socket.bufferedAmount / totalBytes, 0));

            if (socket.bufferedAmount === 0) {
              console.log('Webchat:: Message sent', msg.type);
              clearInterval(interval);
              resolve(msg);
            } else {
              console.log('Webchat:: Message sending %f', progress);
            }
          }, 100);
        } else {
          if (msg.cid && msg.ref) {
            commit(MUTATION.updateMessageToChat, {
              chatId: msg.cid,
              ref: msg.ref,
              status: SEND_STATUS.failed,
            });
          }

          reject(new Error('Cannot send message, the websocket connection not opened'));
        }
      });
    },

    sendButtonClickedMsg({ state, dispatch, commit }, btnAction) {
      const ref = makeId(6);

      const msg = {
        type: 'text',
        text: btnAction.text || btnAction.label || '',
        payload: btnAction.postback_data,
        ref,
        cid: state.activeChatId,
        author: {
          type: 'human',
        },
        ts: new Date().toISOString(),
      };

      commit(MUTATION.addMessageToChat, {
        chatId: msg.cid,
        message: msg,
        status: SEND_STATUS.sending,
      });

      return dispatch('sendMessage', msg);
    },

    sendStartChatMsg({ state, dispatch }) {
      return dispatch('sendMessage', {
        type: 'v2_start_conversation',
        conversationId: state.activeChatId,
        conversationType: CONVERSATION_TYPE,
        lang: state.currentLang ? state.currentLang.trim() : null,
      });
    },

    sendManualTrigger({ state, dispatch }, { name, sneakpeek = false }) {
      return dispatch('sendMessage', {
        type: 'manual_trigger',
        triggerName: name,
        cid: state.activeChatId,
        sneakpeek,
      });
    },

    sendLikeDislike({ commit }, { chatId, msgId, isLiked }) {
      isLiked = !!isLiked;

      const { gatewayId } = window.$SiniticConfig;
      const sendLikeEndpoint = `gateway/conversation/${gatewayId}/${msgId}/like`;
      const sendDislikeEndpoint = `gateway/conversation/${gatewayId}/${msgId}/dislike`;

      if (msgId) {
        window.$siniticApi
          .post(isLiked ? sendLikeEndpoint : sendDislikeEndpoint)
          .then(() => {
            commit(MUTATION.updateMessageToChat, {
              chatId,
              ref: msgId,
              liked: isLiked,
            });
            console.log(`Message ${msgId} ${isLiked} is updated...`);
          })
          .catch(err => console.log(err));
      }
    },

    sendPreChatForm({ state, commit, dispatch }, {
      inquiryType, email, firstField, secondField, uiLang,
    }) {
      const conversationId = state.activeChatId;

      const result = {
        email_or_username: email,
        first_field: firstField,
        second_field: secondField,
      };

      if (inquiryType) {
        result.inquiry_type = inquiryType;
      }

      // console.log('result', result);

      window.$siniticApi
        .post(`gateway/prechat_form/v2/${conversationId}`, {
          data: {
            prechat_form_inputs: result,
            ui_lang: uiLang,
          },
        })
        .then(res => {
          console.log(res.result);
          dispatch('createNewConversation');
          commit(MUTATION.setWebchatState, WEBCHAT_STATE.STARTED);
        })
        .catch(err => console.log(err));
    },

    sendTextMessage({ state, commit, dispatch }, text) {
      text = text || '';
      text = text.trim();

      if (!(text && state.activeChatId)) {
        return;
      }

      const ref = makeId(6);

      const msg = {
        type: 'text',
        text,
        payload: null,
        ref,
        cid: state.activeChatId,
        author: {
          type: 'human',
        },
        ts: new Date().toISOString(),
      };

      commit(MUTATION.addMessageToChat, {
        chatId: msg.cid,
        message: msg,
        status: SEND_STATUS.sending,
      });

      dispatch('sendMessage', msg);
    },

    async uploadFileAndSendMessage({ state, commit, dispatch }, file) {
      const { ref, message: msg } = await dispatch('addUserMediaMessageToChat', {
        chatId: state.activeChatId,
        file,
      });

      const formData = new FormData();
      formData.append('filename', file.name);
      formData.append('file', file, file.name);

      const { gatewayId } = window.$SiniticConfig;

      // Upload attachment
      try {
        const { data: attachment } = await $bus.$siniticApi
          .post(`/gateway/attachment/${gatewayId}/ws:thread/${state.activeChatId}/upload`, {
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
              console.log(progressEvent);
            },
          });

        console.log('Webchat:: File uploaded', attachment);

        msg.url = attachment.url;
        msg.id = attachment.id;
        msg.contentType = attachment.mimetype;
        msg.fileName = attachment.filename;
        msg.type = getMediaMessageType(attachment.mimetype);

        dispatch('sendMessage', msg);

        return Promise.resolve();
      } catch (err) {
        console.error('Webchat:: File upload failed', err);

        const payload = {
          chatId: msg.cid,
          ref,
          status: SEND_STATUS.failed,
          error: err?.response?.data || err.message,
        };

        commit(MUTATION.updateMessageToChat, payload);

        return Promise.reject(payload);
      }
    },

    sendFileMessage({ state, commit, dispatch }, file) {
      if (!(file && state.activeChatId)) {
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const ref = makeId(6);

        const msg = {
          type: getMediaMessageType(file.type),
          content: reader.result,
          fileName: file.name,
          contentType: file.type,
          ref,
          cid: state.activeChatId,
          author: {
            type: 'human',
          },
          ts: new Date().toISOString(),
        };

        commit(MUTATION.addMessageToChat, {
          chatId: msg.cid,
          message: msg,
          status: SEND_STATUS.sending,
        });

        return dispatch('sendMessage', msg);
      };
      reader.onerror = err => {
        console.log('Webchat:: File reading error: ', err);
      };
    },
    sendGifMessage({ state, commit, dispatch }, gifUrl) {
      if (!(gifUrl && state.activeChatId)) {
        return;
      }
      const ref = makeId(6);

      const msg = {
        type: 'image',
        contentType: 'image/gif',
        url: gifUrl,
        ref,
        cid: state.activeChatId,
        author: {
          type: 'human',
        },
        ts: new Date().toISOString(),
      };

      commit(MUTATION.addMessageToChat, {
        chatId: msg.cid,
        message: msg,
        status: SEND_STATUS.sending,
      });

      dispatch('sendMessage', msg);
    },

    setWebchatMobileSize({ state }) {
      const { mobile_width: width, mobile_height: height } = state.uiSettings;
      rpc.call('setIframeMobileSize', { width, height });
    },

    setWebchatPosition({ state }) {
      rpc.call('setIframePosition', state.uiSettings.position || 'BR');
    },

    updateReadConversation({ state, commit }, { messageIds, toCommit = true }) {
      const { gatewayId } = window.$SiniticConfig;
      const convType = CONVERSATION_TYPE;
      const convId = state.activeChatId;

      if (messageIds && messageIds.length > 0) {
        window.$siniticApi
          .post(`gateway/conversation/${gatewayId}/${convType}/${convId}/read_message`, {
            data: { message_ids: messageIds },
          })
          .then(() => {
            if (toCommit) {
              commit(MUTATION.setMessagesReadByHuman, {
                chatId: convId,
                readMessageIds: messageIds,
              });
            }
          })
          .catch(err => console.log(err));
      }
      console.log(`${messageIds.length} messages are marked as read`);
    },
    sendPostChatSurvey({ state, commit }, { rating, comment }) {
      const { gatewayId } = window.$SiniticConfig;
      const conversationId = state.activeChatId;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/gateway/conversation/${gatewayId}/rating`, {
            data: {
              comment: comment == null ? '' : comment.trim(),
              conversation_id: conversationId,
              conversation_type: CONVERSATION_TYPE,
              value: rating,
            },
          })
          .then(res => {
            commit(MUTATION.surveySent, conversationId);
            resolve(res.data);
          })
          .catch(err => {
            console.error('Send Post-chat Survey failed', err);
            return reject(err);
          });
      });
    },

    showChatInput({ commit }, conversationId) {
      commit(MUTATION.showChatInput, conversationId);
    },

    hideChatInput({ commit }, conversationId) {
      commit(MUTATION.hideChatInput, conversationId);
    },

    setConversationIframeModal({ commit }, {
      conversationId, src, iframeId, onClose,
    }) {
      commit(MUTATION.setConversationIframeModal, {
        conversationId,
        src,
        iframeId,
        onClose,
      });
    },

    handleIframeModalClosed({
      state, getters, commit, dispatch,
    }) {
      if (getters?.activeChatIframeModal?.onClose) {
        dispatch('sendIframeClosedMessage', {
          conversationId: state.activeChatId,
          iframeId: getters.activeChatIframeModal.iframeId,
        });
      }

      commit(MUTATION.resetConversationIframeModal, state.activeChatId);

      dispatch('showChatInput', state.activeChatId);
    },

    sendIframeClosedMessage({ dispatch }, { conversationId, iframeId }) {
      dispatch('sendMessage', {
        type: 'iframe_closed',
        iframe_id: iframeId,
        cid: conversationId,
      });
    },

    openChatById({ state, commit }, chatId) {
      if (!(chatId in state.chats)) throw new Error(`Chat not found: ${chatId}`);

      commit(MUTATION.setActiveChatId, chatId);
      commit(MUTATION.setWebchatState, WEBCHAT_STATE.STARTED);
      commit(MUTATION.toggleChatWindow, true);
    },
    getSneakPeekByName({ dispatch }, name) {
      if (!name) throw Error('group name is required.');

      return dispatch('getSneakPeek', { name });
    },
    getSneakPeekByUrl({ dispatch }, url) {
      if (!url) throw Error('url is required.');

      return dispatch('getSneakPeek', { url });
    },
    getSneakPeek({ state }, params) {
      return new Promise((resolve, reject) => {
        if (!params) throw Error('name or url is required.');
        const getButtons = buttons => buttons.map(btn => {
          let sneakpeekBtn = null;
          switch (btn.type) {
            case SNEAK_PEEK_BUTTON_TYPE.QUICK_REPLY:
              sneakpeekBtn = new SneakPeekQuickReplyButton(btn);
              break;

            case SNEAK_PEEK_BUTTON_TYPE.TRIGGER:
              sneakpeekBtn = new SneakPeekTriggerButton(btn);
              break;

            case SNEAK_PEEK_BUTTON_TYPE.HYPERLINK:
              sneakpeekBtn = new SneakPeekHyperlinkButton(btn);
              break;

            default:
              break;
          }
          return sneakpeekBtn;
        });

        const { gatewayId } = window.$SiniticConfig;

        window.$siniticApi
          .get(`/webchatgateway/${gatewayId}/sneakpeek`, { params })
          .then(({ data: { group } }) => {
            console.log('fetch sneakpeek', group);
            if (!group) {
              resolve(null);
            } else {
              const sneakpeek = {
                chatId: state.activeChatId,
                display_after_sec: group.display_after_sec,
                message: group.message,
                buttons: getButtons(group.buttons),
              };
              resolve(sneakpeek);
            }
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    showSneakPeek({ state, commit }) {
      if (state.isChatWindowOpen) {
        console.log('Chat window is opened, sneak peek would not show.');
      } else {
        commit(MUTATION.toggleSneakPeek, true);
      }
    },
    hideSneakPeek({ commit, dispatch }) {
      commit(MUTATION.toggleSneakPeek, false);
      dispatch('sneakPeek/clear', null, { root: true });
    },

    addMessageToChat({ commit }, { chatId, messageObj }) {
      const ref = makeId(6);

      const message = {
        ...messageObj,
        ref,
        cid: chatId,
        ts: new Date().toISOString(),
      };

      commit(MUTATION.addMessageToChat, {
        chatId,
        message,
        status: SEND_STATUS.sending,
      });

      return { ref, message };
    },

    addUserMessageToChat({ dispatch }, { chatId, messageObj }) {
      return dispatch('addMessageToChat', {
        chatId,
        messageObj: {
          ...messageObj,
          author: {
            type: 'human',
          },
        },
      });
    },

    addUserMediaMessageToChat({ dispatch }, { chatId, file }) {
      return dispatch('addUserMessageToChat', {
        chatId,
        messageObj: {
          type: getMediaMessageType(file.type),
          fileSize: file.size,
          fileName: file.name,
        },
      });
    },

    emailTranscript({ state }, { convId, email }) {
      const { gatewayId } = window.$SiniticConfig;
      if (!email) {
        return;
      }
      if (!convId) {
        convId = state.activeChatId;
      }
      window.$siniticApi
        .post(`gateway/conversation/${gatewayId}/${convId}/email_transcript`, {
          data: { email },
        })
        .then(response => {
          console.log('Email transcript is sent!');
        })
        .catch(err => console.log(err));
    },

    resetUISettings({ commit }) {
      commit(MUTATION.resetUiSettings);
    },

    removeBotTypingOfChat({ commit }, chatId) {
      commit(MUTATION.removeAllBotTyping, { chatId });
    },
  },
};
