var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pui-lang-icon", class: _vm.classLangIcon }, [
    _c("div", { staticClass: "lang-code", class: _vm.classLangCode }, [
      _vm._v("\n    " + _vm._s(_vm.langCode || "") + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }