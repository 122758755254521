/* eslint-disable prefer-destructuring */
import tools from '@/platformSettings/tools';
import constants from '@/platformSettings/editorStaticVerbiages';

export const recursiveFindMetaByType = (type, blockType) => {
  let title = null;
  let icon = null;
  let group = null;

  const properties = tools.block_types.properties.blocks.filter(p => p.types.map(_type => _type.toLowerCase()).indexOf(type.toLowerCase()) > -1);

  if (properties.length > 0) {
    const block = properties[0].properties.filter(p => p.type.toLowerCase() === blockType.toLowerCase());

    if (block.length > 0) {
      title = block[0].title;
      icon = block[0].icon;
      group = block[0].group;
    }
  }
  return { title, icon, group };
};

export const recursiveFindComponent = (type, blockType) => {
  const properties = tools.block_types.properties.blocks.filter(p => p.types.map(_type => _type.toLowerCase()).indexOf(type.toLowerCase()) > -1);

  if (properties.length > 0) {
    const block = properties[0].properties.filter(p => p.type.toLowerCase() === blockType.toLowerCase());

    if (block.length > 0) {
      return block[0];
    }
  }
  return {};
};

export const recursiveFindMetaByGroup = (type, group) => {
  let title = null;
  let icon = null;
  let display = true;

  const properties = tools.block_types.properties.blocks.filter(p => p.types.map(_type => _type.toLowerCase()).indexOf(type.toLowerCase()) > -1);

  if (properties.length > 0) {
    const _group = properties[0].group_meta[group];
    if (_group) {
      title = _group.title;
      icon = _group.icon;

      if (Object.keys(_group).indexOf('display') !== -1) {
        display = _group.display;
      }
    }
  }
  return { title, icon, display };
};

export const createBlockMapOfState = botState => {
  // Store all events under the state
  const allEvents = [botState.source_event].concat(botState.events).concat(
    botState.events
      .filter(event => event.target_state !== null)
      .map(event => event.target_state.events)
      .flat()
  );

  // Store all actions under the state
  const allActions = [];

  // Push all actions under all events in a queue
  let checkActionQueue = allEvents.reduce((actions, event) => {
    actions = actions.concat(event.actions || []);
    return actions;
  }, []);

  // Loop through queue and find related actions
  while (checkActionQueue.length > 0) {
    const checkAction = checkActionQueue[0];

    for (let i = 0; i < checkAction.relations.length; i += 1) {
      const relatedActions = checkAction.relations[i];
      checkActionQueue = checkActionQueue.concat(relatedActions);
    }

    // Push checked action into all actions array
    allActions.push(checkAction);

    // Remove from queue
    checkActionQueue.splice(0, 1);
  }

  // Combine all blocks include events and actions
  const allBlocks = allEvents.concat(allActions);

  return allBlocks.reduce((map, block) => {
    map.set(block.id, block);
    return map;
  }, new Map());
};

export const findBlockFromState = (blockId, botState) => {
  // Map block with its id
  const blockMap = createBlockMapOfState(botState);

  // Find block by blockId
  return blockMap.get(blockId) || null;
};

export const blurAllNodes = () => {
  document.querySelectorAll('.pui-event-node').forEach(el => {
    el.classList.add('pui-event-node--blur');
  });

  document.querySelectorAll('.pui-action-node').forEach(el => {
    el.classList.add('pui-action-node--blur');
  });

  document.querySelectorAll('.pui-node-header').forEach(el => {
    el.classList.remove('pui-node-header--active');
    el.classList.remove('pui-node-header--focused');
  });
};

export const unblurAllNodes = () => {
  document.querySelectorAll('.pui-event-node').forEach(el => {
    el.classList.remove('pui-event-node--blur');
  });

  document.querySelectorAll('.pui-action-node').forEach(el => {
    el.classList.remove('pui-action-node--blur');
  });

  document.querySelectorAll('.pui-node-header').forEach(el => {
    el.classList.remove('pui-node-header--active');
    el.classList.remove('pui-node-header--focused');
  });
};

export const blurTree = treeEl => {
  // make nodes in current tree blur
  treeEl.querySelectorAll('.pui-event-node').forEach(el => {
    el.classList.add('pui-event-node--blur');
  });
  treeEl.querySelectorAll('.pui-action-node').forEach(el => {
    el.classList.add('pui-action-node--blur');
  });
};

export const focusEventNode = eventNodeEl => {
  blurAllNodes();

  const currentNodeHeader = eventNodeEl.querySelector('.pui-node-header');

  // make current node active
  currentNodeHeader.classList.add('pui-node-header--active');

  // make current node focused
  currentNodeHeader.classList.add('pui-node-header--focused');

  // make current node and its children not blur
  eventNodeEl.classList.remove('pui-event-node--blur');
  eventNodeEl.querySelectorAll('.pui-action-node').forEach(el => {
    el.classList.remove('pui-action-node--blur');
  });
};

export const focusActionNode = (actionNodeEl, blurChildren = true) => {
  blurAllNodes();

  /**
   * *******************
   * handle current node
   * *******************
   */
  // make current node not blur
  actionNodeEl.classList.remove('pui-action-node--blur');

  // make current node focused
  actionNodeEl.querySelector('.pui-node-header').classList.add('pui-node-header--focused');

  /**
   * *********************
   * handle children nodes
   * *********************
   */
  // make all child nodes not blur
  if (blurChildren) {
    actionNodeEl.querySelectorAll('.pui-action-node').forEach(el => {
      el.classList.remove('pui-action-node--blur');
    });
  }

  /**
   * *******************
   * handle parent nodes
   * *******************
   */
  let parentActionNode = actionNodeEl.parentElement.closest('.pui-action-node');
  while (parentActionNode) {
    parentActionNode.classList.remove('pui-action-node--blur');
    parentActionNode = parentActionNode.parentElement.closest('.pui-action-node');
  }

  /**
   * ******************
   * handled event node
   * ******************
   */
  const eventNode = actionNodeEl.closest('.pui-event-node');
  if (eventNode) {
    eventNode.classList.remove('pui-event-node--blur');
    const eventNodeHeader = eventNode.querySelector('.pui-node-header');

    if (eventNodeHeader) {
      eventNodeHeader.classList.add('pui-node-header--active');
    }
  }
};
