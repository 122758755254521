function getPsudeoUniqueId(n) {
  n = n || 1;
  const array = new Uint32Array(n);
  const Crypto = window.crypto || window.msCrypto || { getRandomValues: require('polyfill-crypto.getrandomvalues') }; // for IE 11 // Polyfill
  Crypto.getRandomValues(array);
  return Array.prototype.map.call(array, x => x.toString(16)).join('-');
}

const RPC_SND_IDENTIFIER = '__SINITIC_RPC_SND__';
const RPC_RET_IDENTIFIER = '__SINITIC_RPC_RET__';

export default class Rpc {
  constructor({ serverWindow, targetWindow, targetOrigin }) {
    // console.log('rpc setup with ', { serverWindow, targetWindow, targetOrigin });
    this.serverWindow = serverWindow || window;
    this.targetWindow = targetWindow;
    this.targetOrigin = targetOrigin;

    this.commands = new Map();
    this.callbacks = new Map();

    this.uid = getPsudeoUniqueId();

    this._listener = this.onMessage.bind(this);

    this.serverWindow.addEventListener('message', this._listener);
  }

  setTargetWindow(targetWindow) {
    this.targetWindow = targetWindow;
  }

  close() {
    this.serverWindow.removeEventListener('message', this._listener);
  }

  onMessage(msg) {
    const { data } = msg;

    if (data[RPC_SND_IDENTIFIER]) {
      const {
        [RPC_SND_IDENTIFIER]: rpcId, id: reqId, method, params,
      } = data;

      const func = this.commands.get(method);

      if (func) {
        const returnValue = func.call(null, params, msg.source);

        msg.source.postMessage(
          {
            [RPC_RET_IDENTIFIER]: rpcId,
            id: reqId,
            method,
            status: 'success',
            params: {
              returnValue,
            },
          },
          '*'
        );
        // console.log(`method '${method}' executed`);
      } else {
        // console.error(`command not found ${method}`);
      }
    } else if (data[RPC_RET_IDENTIFIER] === this.uid) {
      const { id: reqId, params, status } = data;

      const [resolve, reject] = this.callbacks.get(reqId);

      if (status === 'success' && resolve) {
        resolve(params.returnValue);
      } else if (status === 'failure' && reject) {
        reject(params.returnValue);
      }

      this.callbacks.delete(reqId);
    }
  }

  add(methodName, func) {
    if (this.commands.has(methodName)) {
      throw new Error(`${methodName} is already defined`);
    }

    this.commands.set(methodName, func);

    // console.log('registered %s', methodName);
  }

  removeCommands() {
    for (const methodName of this.commands.keys()) {
      this.commands.delete(methodName);
    }
  }

  call(methodName, params) {
    const reqId = getPsudeoUniqueId(3);

    this.targetWindow.postMessage(
      {
        [RPC_SND_IDENTIFIER]: this.uid,
        id: reqId,
        method: methodName,
        params,
      },
      this.targetOrigin
    );

    return new Promise((resolve, reject) => {
      this.callbacks.set(reqId, [resolve, reject]);
    });
  }
}
