<template>
  <button
    :style="{ height: height }"
    :class="classes"
    :disabled="isDisabled"
    type="button"
    v-on="inputListeners"
  >
    <span
      v-if="!loading"
      class="button-inner"
    >
      <font-awesome-icon
        v-if="icon"
        :icon="['fas', icon]"
        class="button-icon"
      />
      <label
        v-if="text"
        class="button-label"
      >{{ text }}</label>
      <label
        v-else
        class="button-label"
      >
        <slot />
      </label>
    </span>
    <PUILoader
      v-if="loading || loadingInternal"
      size="small"
    />
  </button>
</template>

<script>
import PUILoader from '../Layout/Loader.vue';

export default {
  name: 'PUIButton',
  components: {
    PUILoader,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: val => [
        'primary',
        'primary-yellow',
        'primary-outline',
        'text',
        'negative',
        'negative-outline',
        'positive',
        'positive-outline',
      ].includes(val),
    },
    block: {
      type: Boolean,
      default: false,
      description: 'Button width would be set to 100%.',
    },
    status: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Control the initial disabled state when component first render,
     *    could be these 3 values,
     *    - null: does not require to set initial value
     *    - true: render in disabled state
     *    - false: render in not disabled state
     */
    initiallyDisabled: {
      type: Boolean,
      default: null,
      validator: val => [null, true, false].includes(val),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '40px',
    },
  },
  data() {
    return {
      isDisabled: this.initiallyDisabled ?? this.disabled,
      loadingInternal: false,
    };
  },
  computed: {
    classes() {
      const cls = {
        'pui-button': true,
        'pui-button--loading': this.loading || this.loadingInternal,
        'pui-button--block': this.block,
      };

      if (this.type) {
        cls[`is-${this.type}`] = true;
      }

      if (this.status) {
        cls[`is-${this.status}`] = true;
      }

      return cls;
    },
    inputListeners() {
      const vm = this;
      // `Object.assign` merges objects together to form a new object
      return {

        // We add all the listeners from the parent
        ...this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        // This ensures that the component works with v-model
        input(event) {
          vm.$emit('input', event.target.value);
        },
        click(event) {
          // Block `click` event when button is disabled or loading.
          if (vm.isDisabled || vm.loading) {
            // console.log(vm.$el, `is loading, click event would be blocked`);
            return;
          }
          vm.$emit('click', event);
        },
      };
    },
  },
  watch: {
    disabled: {
      handler(val) {
        this.isDisabled = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';

@mixin pui-button() {
  position: relative;
  padding: 2px 15px;
  height: 40px;
  outline: none;
  border: none;
  text-align: center;
  display: inline-block;
  border-radius: 0;
  font-size: 13px;
  cursor: pointer;

  & .button-label {
    display: inline-block;
    font-family: $open-sans;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }

  .button-icon {
    display: inline-block;
  }

  .button-inner {
    position: relative;
    width: 100%;
  }

  &[disabled] {
    cursor: not-allowed;

    & .button-label {
      cursor: not-allowed;
    }
  }

  &.pui-button--loading {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }

  &.pui-button--block {
    display: block;
    width: 100%;
  }
}

// PRIMARY
.pui-button.is-primary {
  @include pui-button();
  background: $color-black;
  color: $color-white;
  border: 1px solid $color-black;

  &:not(.pui-button--loading):enabled:hover,
  &:not(.pui-button--loading):enabled:focus {
    background: $color-yellow;
    color: $color-black;
    border: 1px solid $color-yellow;
  }

  &:not(.pui-button--loading):enabled:active {
    background: $color-yellow-dark;
    color: $color-black;
    border: 1px solid $color-yellow-dark;
  }

  &.is-hover {
    background: $color-yellow;
    color: $color-white;
    border: 1px solid $color-yellow;
  }

  &.is-active {
    background: $color-yellow-dark;
    color: $color-white;
    border: 1px solid $color-yellow-dark;
  }

  &[disabled] {
    background: $color-white-like;
    color: $color-grey-light;
    border: 1px solid $color-white-like;
  }
}

// PRIMARY
.pui-button.is-primary-yellow {
  @include pui-button();
  background: $color-yellow;
  color: $color-black;
  border: 1px solid $color-yellow;

  &:not(.pui-button--loading):enabled:hover,
  &:not(.pui-button--loading):enabled:focus {
    background: $color-yellow-dark;
    color: $color-white;
    border: 1px solid $color-yellow-dark;
  }

  &.is-hover {
    background: $color-yellow-dark;
    color: $color-black;
    border: 1px solid $color-yellow-dark;
  }
  &.is-active {
    background: $color-yellow-dark;
    color: $color-black;
    border: 1px solid $color-yellow-dark;
  }

  &[disabled],
  &.pui-button--loading {
    background: #1e2225;
    color: $color-white;
    border: 1px solid #1e2225;
    opacity: 0.5;
  }
}

// PRIMARY-OUTLINE
.pui-button.is-primary-outline {
  @include pui-button();
  background: none;
  color: #0e0e0e;
  border: 1px solid #010101;

  &:not(.pui-button--loading):enabled:hover,
  &:not(.pui-button--loading):enabled:focus {
    background: $color-yellow;
    color: #0e0e0e;
    border: 1px solid $color-yellow;
  }

  &.is-hover {
    background: $color-yellow;
    color: #0e0e0e;
    border: 1px solid $color-yellow;
  }

  &.is-active {
    background: $color-yellow-dark;
    color: #0e0e0e;
    border: 1px solid $color-yellow-dark;
  }

  &[disabled] {
    background: none;
    color: $color-grey;
    border: 1px solid $color-grey-light;
  }
}

// TEXT
.pui-button.is-text {
  @include pui-button();
  background: none;
  color: #979797;

  &:not(.pui-button--loading):enabled:hover,
  &:not(.pui-button--loading):enabled:focus {
    color: #000;
  }

  &.is-hover {
    color: #000;
  }

  &.is-active {
    color: #000;
  }

  &[disabled] {
    color: $color-grey;
    border: none;
  }
}

// NEGATIVE
.pui-button.is-negative {
  @include pui-button();
  background: $color-red-light;
  color: $color-white;
  border: 1px solid $color-cinnabar-red;

  &:not(.pui-button--loading):enabled:hover,
  &:not(.pui-button--loading):enabled:focus {
    background: $color-red;
    color: $color-white;
    border: 1px solid #b30001;
  }

  &:not(.pui-button--loading):enabled:active {
    background: $color-red-dark;
    color: $color-white;
    border: 1px solid $color-cinnabar-red;
  }

  &.is-hover {
    background: $color-red-light;
    color: $color-white;
    border: 1px solid $color-red-light;
  }

  &.is-active {
    background: $color-red-dark;
    color: $color-white;
    border: 1px solid $color-red-dark;
  }

  &[disabled] {
    background: $color-grey-light;
    color: $color-grey;
    border: 1px solid $color-grey-light;
  }
}

// NEGATIVE-OUTLINE
.pui-button.is-negative-outline {
  @include pui-button();
  background: $color-white;
  color: $color-red-light;
  border: 1px solid $color-red-light;

  &:not(.pui-button--loading):enabled:hover,
  &:not(.pui-button--loading):enabled:focus {
    background: $color-red-light;
    color: $color-white;
    border: 1px solid $color-red-light;
  }

  &.is-hover {
    background: $color-red-light;
    color: $color-white;
    border: 1px solid $color-red-light;
  }

  &.is-active {
    background: $color-red-dark;
    color: $color-white;
    border: 1px solid $color-red-dark;
  }

  &[disabled] {
    background: none;
    color: $color-grey;
    border: 1px solid $color-grey-light;
  }
}

// POSITIVE
.pui-button.is-positive {
  @include pui-button();
  background: $color-green-light;
  color: $color-white;
  border: 1px solid $color-green-light;

  &:not(.pui-button--loading):enabled:hover,
  &:not(.pui-button--loading):enabled:focus {
    background: $color-green-light;
    color: $color-white;
    border: 1px solid $color-green-light;
  }

  &.is-hover {
    background: $color-green-light;
    color: $color-white;
    border: 1px solid $color-green-light;
  }

  &.is-active {
    background: $color-green-dark;
    color: $color-white;
    border: 1px solid $color-green-dark;
  }

  &[disabled] {
    background: $color-grey-light;
    color: $color-grey;
    border: 1px solid $color-grey-light;
  }
}

// POSITIVE-OUTLINE
.pui-button.is-positive-outline {
  @include pui-button();
  background: $color-white;
  color: $color-green-light;
  border: 1px solid $color-green-light;

  &:not(.pui-button--loading):enabled:hover,
  &:not(.pui-button--loading):enabled:focus {
    background: $color-green-light;
    color: $color-white;
    border: 1px solid $color-green-light;
  }

  &.is-hover {
    background: $color-green-light;
    color: $color-white;
    border: 1px solid $color-green-light;
  }

  &.is-active {
    background: $color-green-dark;
    color: $color-white;
    border: 1px solid $color-green-dark;
  }

  &[disabled] {
    background: none;
    color: $color-grey;
    border: 1px solid $color-grey-light;
  }
}
</style>
