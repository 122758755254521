var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "font-awesome-icon",
    _vm._g(
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip.top",
            value: {
              content: _vm.content,
              offset: _vm.offset,
              popperOptions: {
                modifiers: {
                  preventOverflow: { boundariesElement: _vm.boundariesElement }
                }
              }
            },
            expression:
              "{\n    content: content,\n    offset: offset,\n    popperOptions: {\n      modifiers: {\n        preventOverflow: { boundariesElement: boundariesElement },\n      },\n    },\n  }",
            modifiers: { top: true }
          }
        ],
        staticClass: "pui-hint",
        style: {
          color: _vm.color,
          "--hover-color": _vm.hoverColor,
          "--focus-color": _vm.focusColor
        },
        attrs: { tabindex: "0", icon: ["fal", "question-circle"] }
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }