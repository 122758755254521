import { ACTIONS } from '@/webchat2/config/types';
import { MESSAGE_TYPE } from '@/helpers/messageHelper';

import genericMessageHandler from './genericMessageHandler.js';
import quickReplyHandler from './quickReplyHandler.js';
import conversationClosedHandler from './conversationClosedHandler.js';
import botTypingOnHandler from './botTypingOnHandler.js';
import botTypingOffHandler from './botTypingOffHandler.js';
import listConversationsHandler from './listConversationsHandler.js';
import iframeHandler from './iframeHandler.js';

export default {
  [ACTIONS.PONG]: () => {}, // DO NOTHING
  [ACTIONS.LIST_CONVERSATIONS]: listConversationsHandler,
  [ACTIONS.CONVERSATION_CLOSED]: conversationClosedHandler,

  // TODO: should be handled
  [ACTIONS.GW_RECEIVED]: () => {},
  [ACTIONS.MESSAGE_READ]: () => {},

  [MESSAGE_TYPE.TEXT]: genericMessageHandler,
  [MESSAGE_TYPE.AUDIO]: genericMessageHandler,
  [MESSAGE_TYPE.VIDEO]: genericMessageHandler,
  [MESSAGE_TYPE.IMAGE]: genericMessageHandler,
  [MESSAGE_TYPE.FILE]: genericMessageHandler,
  [MESSAGE_TYPE.CAROUSEL]: genericMessageHandler,
  [MESSAGE_TYPE.BUTTON_CLICKED]: genericMessageHandler,

  [MESSAGE_TYPE.QUICK_REPLIES]: quickReplyHandler,
  [MESSAGE_TYPE.BOT_TYPING_ON]: botTypingOnHandler,
  [MESSAGE_TYPE.BOT_TYPING_OFF]: botTypingOffHandler,
  [MESSAGE_TYPE.IFRAME]: iframeHandler,
  [MESSAGE_TYPE.HYPERLINK]: genericMessageHandler,

  [MESSAGE_TYPE.MANUAL_TRIGGER]: () => {},

};
