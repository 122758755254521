import isEmpty from 'lodash/isEmpty';

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '@/router/platform';

import $bus from '@/platformSettings/bus';
import { RESOURCE_STATE } from '@/constants.js';

Vue.use(Vuex);

const { CancelToken } = axios;
let cancelGetEmail;
let cancelGetEmails;

export default {
  namespaced: true,
  state: {
    currentEmail: null,
    emailError: null,
    emails: [],
    emailCurrentPage: 1,
    emailPerPage: 15,
    emailPages: 0,
    emailAmount: 0,
    emailOrderBy: 'desc',
    emailFilter: null,

    isSearching: false,
    emailBlocks: [],
    fetchingEmailState: RESOURCE_STATE.IDLE,
    companyId: null,
    botId: null,
  },
  getters: {
    currentEmailIndex(state) {
      if (state.currentEmail) {
        return state.emails.findIndex(item => item.id === state.currentEmail.id);
      }
      return null;
    },
  },
  mutations: {
    _setCompanyId(state, companyId) {
      state.companyId = companyId;
    },
    _setBotId(state, botId) {
      state.botId = botId;
    },
    _setCurrentEmail(state, email) {
      state.currentEmail = Object.freeze(email);
    },
    _setEmailError(state, error) {
      state.emailError = error;
    },
    _setEmails(state, emails) {
      state.emails = emails;
    },
    _setEmailCurrentPage(state, currentPage) {
      state.emailCurrentPage = currentPage;
    },
    _setEmailPerPage(state, perPage) {
      state.emailPerPage = perPage;
    },
    _setEmailPages(state, pages) {
      state.emailPages = pages;
    },
    _setEmailAmount(state, amount) {
      state.emailAmount = amount;
    },
    _setEmailOrderBy(state, orderBy) {
      state.emailOrderBy = orderBy;
    },
    _setEmailFilter(state, filter) {
      state.emailFilter = filter;
    },

    _setIsSearching(state, payload) {
      state.isSearching = payload;
    },
    _setEmailBlocks(state, payload) {
      state.emailBlocks = payload;
    },
    _setFetchingEmailState(state, resourceState) {
      const {
        IDLE, PENDING, RESOLVED, REJECTED,
      } = RESOURCE_STATE;

      if (![IDLE, PENDING, RESOLVED, REJECTED].includes(resourceState)) {
        throw Error(
          `Invalid payload: only IDLE, PENDING, RESOLVED, and REJECTED are allowed, got ${resourceState}.`
        );
      }

      state.fetchingEmailState = resourceState;
    },
  },
  actions: {
    getBlocks({ commit }, { companyId, botId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/action:EXPORT_INFO`)
          .then(response => {
            const blocks = response.data;
            commit('_setEmailBlocks', blocks);
            return resolve(blocks);
          })
          .catch(err => {
            console.error(err);
            return reject(err);
          });
      });
    },
    getEmail(_, { companyId, botId, emailId }) {
      if (cancelGetEmail) {
        cancelGetEmail();
      }

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/email_log/${emailId}`, {
            cancelToken: new CancelToken(c => {
              cancelGetEmail = c;
            }),
          })
          .then(response => {
            const email = response.data;
            if (!email) {
              const err = {};
              err.code = 404;
              return reject(err);
            }
            return resolve(email);
          });
      });
    },
    setCurrentEmailByIndex({ state, commit }, index) {
      if (index < 0 || index >= state.emails.length) {
        return new Error('Invalid index');
      }

      if (state.emails.length > 0) {
        const currentEmail = state.emails[index];
        commit('_setCurrentEmail', currentEmail);

        router
          .push({
            name: 'BotHistory',
            params: {
              companyId: state.companyId,
              botId: state.botId,
              history: 'email',
              selectedId: currentEmail.id,
            },
            query: state.emailFilter,
          })
          .catch(() => { });
      }
    },
    getPreviousEmail({
      state, rootState, getters, dispatch,
    }) {
      // Is first in not-first page
      if (state.emailCurrentPage > 1 && getters.currentEmailIndex === 0) {
        // Reduce page number
        dispatch('setEmailCurrentPage', state.emailCurrentPage - 1);
        // Get email
        const { companyId, botId } = rootState.route.params;
        dispatch('getEmails', { companyId, botId }).then(() => {
          // Set last one as current
          dispatch('setCurrentEmailByIndex', state.emails.length - 1);
        });
      } else if (getters.currentEmailIndex > 0 && getters.currentEmailIndex < state.emailPerPage) {
        // Set previous as current
        dispatch('setCurrentEmailByIndex', getters.currentEmailIndex - 1);
      }
    },
    getNextEmail({
      state, rootState, getters, dispatch,
    }) {
      if (
        state.emailCurrentPage < state.emailPages
        && state.emailCurrentPage > 0
        && getters.currentEmailIndex === state.emails.length - 1
      ) {
        // Increase page number
        dispatch('setEmailCurrentPage', state.emailCurrentPage + 1);
        // Get email
        const { companyId, botId } = rootState.route.params;
        dispatch('getEmails', { companyId, botId }).then(() => {
          // Set first one as current
          dispatch('setCurrentEmailByIndex', 0);
        });
      } else if (state.emails[getters.currentEmailIndex + 1]) {
        // Set next as current
        dispatch('setCurrentEmailByIndex', getters.currentEmailIndex + 1);
      }
    },
    getEmails({ state, rootState, commit }, { companyId, botId }) {
      commit('_setFetchingEmailState', RESOURCE_STATE.PENDING);

      return new Promise((resolve, reject) => {
        // Call api if date range is ready
        if (state.emailFilter && (!state.emailFilter.start_date || !state.emailFilter.end_date)) {
          return resolve();
        }

        if (cancelGetEmails) {
          cancelGetEmails();
        }

        const formatting = 'YYYY-MM-DD';
        const dateQuery = {
          timezone: rootState.uiv2.platformTimezone,
        };

        if (state.emailFilter && state.emailFilter.start_date) {
          dateQuery.start_date = Vue.dayjs(state.emailFilter.start_date).format(formatting);
        }

        if (state.emailFilter && state.emailFilter.end_date) {
          dateQuery.end_date = Vue.dayjs(state.emailFilter.end_date).format(formatting);
        }

        const filters = {};
        const newFilter = state.emailFilter || {};
        Object.entries(newFilter).forEach(([key, val]) => {
          if (val !== null && val !== '') {
            filters[key] = val;
          }
        });

        if (!isEmpty(filters)) {
          commit('_setIsSearching', true);
        } else {
          commit('_setIsSearching', false);
        }

        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/email_logs`, {
            params: {
              page: state.emailCurrentPage,
              items_per_page: state.emailPerPage,
              order_by: state.emailOrderBy,
              ...state.emailFilter,
              ...dateQuery,
            },
            cancelToken: new CancelToken(c => {
              cancelGetEmails = c;
            }),
          })
          .then(response => {
            const results = response.data;
            const nonDuplicateItems = Object.values(
              results.items.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
            );
            commit('_setEmails', nonDuplicateItems);
            commit('_setEmailPages', results.n_pages);
            commit('_setEmailAmount', results.total);
            commit('_setFetchingEmailState', RESOURCE_STATE.RESOLVED);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            commit('_setFetchingEmailState', RESOURCE_STATE.REJECTED);
            return reject(error);
          });
      });
    },
    clearEmails({ commit }) {
      commit('_setEmailCurrentPage', 1);
      commit('_setEmails', []);
    },
    resetEmail({ commit }) {
      commit('_setCurrentEmail', null);
      commit('_setEmails', []);
      commit('_setEmailCurrentPage', 1);
      commit('_setEmailPages', 0);
      commit('_setEmailAmount', 0);
      commit('_setEmailOrderBy', 'desc');
      commit('_setEmailFilter', null);
    },
    setEmailCurrentPage({
      state, commit, dispatch,
    }, page) {
      if (page < 0 || page > state.emailPages) {
        return new Error('Invalid page');
      }

      commit('_setEmailCurrentPage', page);

      dispatch('getEmails', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total && data.total > 0) {
          dispatch('setCurrentEmailByIndex', 0);
        }
      });
    },
    setEmailPerPage({ state, commit, dispatch }, perPage) {
      commit('_setCurrentEmail', null);
      commit('_setEmailPerPage', perPage);
      commit('_setEmailCurrentPage', 1);

      dispatch('getEmails', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total && data.total > 0) {
          dispatch('setCurrentEmailByIndex', 0);
        }
      });
    },
    setEmailFilter({
      state, getters, commit, dispatch,
    }, filter) {
      commit('_setCurrentEmail', null);
      commit('_setEmailFilter', filter);
      commit('_setEmailCurrentPage', 1);

      dispatch('getEmails', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total && data.total > 0) {
          dispatch('setCurrentEmailByIndex', getters.currentEmailIndex ?? 0);
        }
      });
    },
    setEmailOrderBy({ state, commit, dispatch }, orderBy) {
      commit('_setCurrentEmail', null);
      commit('_setEmailOrderBy', orderBy);
      commit('_setEmailCurrentPage', 1);

      dispatch('getEmails', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total && data.total > 0) {
          dispatch('setCurrentEmailByIndex', 0);
        }
      });
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
