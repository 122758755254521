<template>
  <div
    v-tooltip="showToolTip ? {
      content: showToolTipText,
      offset: 10,
    } : null"
    :class="{
      'pui-avatar2': true,
      'pui-avatar2--show-name': showName,
      'pui-avatar2--normal': size === 'normal',
      'pui-avatar2--large': size === 'large',
      'pui-avatar2--xlarge': size === 'xlarge',
      'pui-avatar2--circular': trait.circular,
    }"
  >
    <!-- Image and badge container -->
    <div class="pui-avatar2__inner">
      <div class="pui-avatar2__image-container">
        <!-- Uploaded image -->
        <div
          v-if="imageType === 'IMAGE'"
          class="image-wrapper"
        >
          <img
            :src="imageUrl"
            class="uploaded-image"
            @load="imageFailedToLoad = false"
            @error="imageFailedToLoad = true"
          />
        </div>
        <!-- Default image -->
        <div
          v-else-if="imageType === 'DEFAULT'"
          class="image-wrapper"
          :class="{
            'image-wrapper-background-yellow': trait.defaultImageBackgroundColorPolicy === 'YELLOW',
            'image-wrapper-background-black': trait.defaultImageBackgroundColorPolicy === 'BLACK',
            [randomBackgroundColor]: trait.defaultImageBackgroundColorPolicy === 'RANDOM',
          }"
        >
          <div
            v-if="trait.defaultImagePolicy === 'SUI_ICON'"
            class="bot-image"
            :style="{ backgroundImage: `url('${trait.defaultImage}')` }"
          />
          <font-awesome-icon
            v-else-if="trait.defaultImagePolicy === 'FA_ICON'"
            :icon="trait.defaultImage"
            :style="{color: '#000000'}"
          />
          <svg
            v-else-if="trait.defaultImagePolicy === 'PAI_ICON'"
            class="uploaded-image"
          >
            <use v-bind="{ 'xlink:href': '#' + iconFileName }" />
          </svg>
          <div
            v-else-if="trait.defaultImagePolicy === 'TWO_INITIALS'"
            class="acronym-image"
            :class="{
              'acronym-user': trait.acronymFontSizePolicy === 'USER_ACRONYM',
              'acronym-company': trait.acronymFontSizePolicy === 'COMPANY_ACRONYM',
              'acronym-team': trait.acronymFontSizePolicy === 'TEAM_ACRONYM',
              'acronym-user-black': trait.acronymFontSizePolicy === 'USER_BLACK_ACRONYM',
            }"
          >
            {{ nameAcronym || '' }}
          </div>
          <div
            v-else-if="trait.defaultImagePolicy === 'ONE_INITIAL'"
            class="acronym-image"
            :class="{
              'acronym-user': trait.acronymFontSizePolicy === 'USER_ACRONYM',
              'acronym-company': trait.acronymFontSizePolicy === 'COMPANY_ACRONYM',
              'acronym-team': trait.acronymFontSizePolicy === 'TEAM_ACRONYM',
              'acronym-user-black': trait.acronymFontSizePolicy === 'USER_BLACK_ACRONYM',
            }"
          >
            {{ nameAcronym || '' }}
          </div>
          <template v-else>
            <!-- This should never happen -->
          </template>
        </div>
        <!-- Fallback image -->
        <div
          v-else-if="imageType === 'FALLBACK'"
          class="image-wrapper"
          :class="{
            'image-wrapper-background-yellow': trait.fallbackBackgroundColorPolicy === 'YELLOW',
            'image-wrapper-grey-border': trait.fallbackGreyBorder,
          }"
        >
          <div
            v-if="trait.fallbackImagePolicy === 'SUI_ICON'"
            class="bot-image"
            :style="{ backgroundImage: `url('${trait.fallbackImage}')` }"
          />
          <font-awesome-icon
            v-else-if="trait.fallbackImagePolicy === 'FA_ICON'"
            class="avatar-image"
            :class="{
              'image-black': trait.fallbackImageColor === 'BLACK',
              'image-grey': trait.fallbackImageColor === 'GREY',
            }"
            :icon="trait.fallbackImage"
          />
          <template v-else>
            <!-- This should never happen -->
          </template>
        </div>
        <template v-else>
          <!-- This should never happen -->
        </template>
        <!-- Status badge -->
      </div>
      <span
        v-if="badge && badge !== 'none'"
        class="pui-avatar2__badge"
        :class="[badgeStatusClass]"
      />
    </div>
    <!-- Show name label -->
    <div
      v-if="showName"
      class="pui-avatar2__name"
    >
      <span v-if="name">
        {{ name }}
        <span
          v-if="isMe"
          class="pui-avatar2__name--is-me"
        >{{ $t('pui:avatar:you') }}</span>
      </span>
      <span
        v-else
        class="pui-avatar2__name--deleted"
      >{{ $t('pui:avatar:deleted') }}</span>
    </div>
  </div>
</template>

<script>

const STYLE_TRAITS = {
  /*
    TRAIT TEMPLATE:
      type: {
        // Decides if shape of avatar image should be circular or square.
        circular: Boolean,

        // Indicates the type of default avatar image the trait should use.
        defaultImagePolicy: 'ONE_INITIAL'/'TWO_INITIALS'/'SUI_ICON'/'FA_ICON',

        // Indicates the background color of the default avatar image.
        defaultImageBackgroundColorPolicy: 'YELLOW'/'RANDOM',

        // Indicates the font size and color for text whithin avatar image
        // acronymFontSizePolicy: 'USER_ACRONYM'/'USER_BLACK_ACRONYM'/'TEAM_ACRONYM'

        // The list of colors the background of the avatar image could be.
        defaultImageBackgroundColorPool: [hex colors],

        // The source or specification of the default avatar image.
        defaultImage: 'image url'/[fontawesome params],

        // Indicates the type of fallback avatar image the trait should use.
        fallbackImagePolicy: 'FA_ICON'/'SUI_ICON',

        // Indicates the color of the fallback avatar image
        fallbackImageColor: 'GREY'/'BLACK',

        // Indicates the background color of the fallback avatar image
        fallbackBackgroundColorPolicy: 'YELLOW'/'NONE',

        // Decides if the avatar image should have a grey border
        fallbackGreyBorder: Boolean,

        // The source or specifications of the fallback avatar image
        fallbackImage: 'image url'/[fontawesome params],
      }
  */
  user: {
    circular: true,

    defaultImagePolicy: 'TWO_INITIALS',
    defaultImageBackgroundColorPolicy: 'YELLOW',
    acronymFontSizePolicy: 'USER_ACRONYM',

    fallbackImagePolicy: 'FA_ICON',
    fallbackImageColor: 'GREY',
    fallbackBackgroundColorPolicy: 'NONE',
    fallbackGreyBorder: true,
    fallbackImage: ['fal', 'user'],
  },
  'user-black': {
    circular: true,

    defaultImagePolicy: 'TWO_INITIALS',
    defaultImageBackgroundColorPolicy: 'BLACK',
    acronymFontSizePolicy: 'USER_BLACK_ACRONYM',

    fallbackImagePolicy: 'FA_ICON',
    fallbackImageColor: 'GREY',
    fallbackBackgroundColorPolicy: 'NONE',
    fallbackGreyBorder: true,
    fallbackImage: ['fal', 'user'],
  },
  company: {
    circular: false,

    defaultImagePolicy: 'FA_ICON',
    defaultImageBackgroundColorPolicy: 'YELLOW',
    defaultImage: ['fas', 'building'],

    fallbackImagePolicy: 'FA_ICON',
    fallbackImageColor: 'GREY',
    fallbackBackgroundColorPolicy: 'NONE',
    fallbackGreyBorder: true,
    fallbackImage: ['fal', 'building'],
  },
  bot: {
    circular: false,

    defaultImagePolicy: 'SUI_ICON',
    defaultImageBackgroundColorPolicy: 'YELLOW',
    defaultImage: '/static/icons/PUI/characters/bot-black-icon.svg',

    fallbackImagePolicy: 'SUI_ICON',
    fallbackImageColor: 'BLACK',
    fallbackBackgroundColorPolicy: 'YELLOW',
    fallbackGreyBorder: false,
    fallbackImage: '/static/icons/PUI/characters/bot-black-icon.svg',
  },
  team: {
    circular: false,

    defaultImagePolicy: 'TWO_INITIALS',
    defaultImageBackgroundColorPolicy: 'RANDOM',
    defaultImageBackgroundColorPool: ['49ace1', '83f8ff', 'ff8383', 'f9c927', 'b3d668', '38e0a5', 'ff7ec4'],
    acronymFontSizePolicy: 'TEAM_ACRONYM',

    fallbackImagePolicy: 'FA_ICON',
    fallbackImageColor: 'GREY',
    fallbackBackgroundColorPolicy: 'NONE',
    fallbackGreyBorder: true,
    fallbackImage: ['fal', 'user-friends'],
  },
  group: {
    circular: false,

    defaultImagePolicy: 'FA_ICON',
    defaultImageBackgroundColorPolicy: 'YELLOW',
    defaultImage: ['fas', 'briefcase'],

    fallbackImagePolicy: 'FA_ICON',
    fallbackImageColor: 'BLACK',
    fallbackBackgroundColorPolicy: 'YELLOW',
    fallbackGreyBorder: false,
    fallbackImage: ['fas', 'briefcase'],
  },
  pai: {
    circular: true,

    defaultImagePolicy: 'PAI_ICON',
    defaultImageBackgroundColorPolicy: 'GREY',
    acronymFontSizePolicy: 'USER_ACRONYM',

    fallbackImagePolicy: 'FA_ICON',
    fallbackImageColor: 'GREY',
    fallbackBackgroundColorPolicy: 'NONE',
    fallbackGreyBorder: true,
    fallbackImage: ['fal', 'user'],
  },
};

export default {
  name: 'PUIAvatar2',
  props: {
    /**
    * Sets the type of the avatar, which decides its shape, and the default/fallback image.
    *
    * This prop is required, and must belong to one of 'user', 'user-black', 'company', 'bot', 'team', or 'group'.
    * 'user-black' refers to incoming messages that are sent the application
    *
    * @property {AVATAR_TYPES} type - The type of avatar.
    */
    type: {
      type: String,
      required: true,
      validator: val => [
        'user',
        'user-black',
        'company',
        'bot',
        'team',
        'group',
        'pai',
      ].includes(val),
    },
    /**
    * Sets the size of the avatar image.
    *
    * This prop is required, and must belong to one of 'normal', 'large', 'xlarge'.
    *
    * 'normal' is suitable for profile pages, 12px x 12px.
    * 'large' is appropriate for custom menus and visualizations, 18px x 18px.
    * 'xlarge' is easily embedded in a list and similar repetitive scenarios, 26px x 26px.
    *
    * If {@link SHOW_NAME} is TRUE, the {@link AVATAR_SIZE} of the avatar image is fixed to 24px x 24px.
    *
    * @property {AVATAR_SIZE} size - The size of the avatar image.
    */
    size: {
      type: String,
      required: true,
      validator: val => [
        'normal',
        'large',
        'xlarge',
      ].includes(val),
    },
    /**
    * The name of the resource associated to the avatar image.
    *
    * If {@link AVATAR_NAME} is provided, and {@link IMAGE_URL} is non-existant/fails to load, the default image is displayed instead.
    *
    * If {@link AVATAR_NAME} is NOT provided, the fallback image is displayed instead.
    *
    * If {@link SHOW_NAME} is TRUE, the {@link AVATAR_NAME} will be displayed to the right of the avatar image.
    *
    * If {@link SHOW_NAME} is TRUE, and {@link AVATAR_NAME} is not provided,{@link AVATAR_NAME} will be displayed
    * as 'Deleted' to the right of the avatar image.
    *
    * If {@link SHOW_TOOLTIP} is TRUE, the {@link TOOLTIP_TEXT} will be displayed as a tooltip upon hovering over the avatar image.
    *
    * If {@link SHOW_TOOLTIP} is TRUE, and {@link AVATAR_NAME} does not exist, the tooltip should display 'Deleted' as its tooltip content.
    *
    * @property {AVATAR_NAME} name - The name of the resource.
    */
    name: {
      type: String,
      default: '',
    },
    /**
    * Indicates whether or not the name of the resource should be displayed with the avatar image.
    *
    * If {@link SHOW_NAME} is TRUE, the {@link AVATAR_NAME} will be displayed to the right of the avatar image.
    *
    * If {@link SHOW_NAME} is TRUE, and {@link AVATAR_NAME} is not provided,{@link AVATAR_NAME} will be displayed
    * as 'Deleted' to the right of the avatar image.
    *
    * @property {SHOW_NAME} showName - Indicator for displaying the name of the resource with the image.
    */
    showName: {
      type: Boolean,
      default: false,
    },
    /**
     * Indicates whether or not a grey text "(you)" should appear beside the avatar name
     *
     * If {@link IS_ME} is TRUE, {@link SHOW_NAME} is TRUE, and {@link AVATAR_NAME} is not empty,
     * "(you)" will appear to the right of {@link AVATAR_NAME}
     *
     * @property {IS_ME} isMe - Indicator for displaying a grey text "(you)" beside the avatar name
    */
    isMe: {
      type: Boolean,
      default: false,
    },
    /**
    * The source url for the avatar image.
    *
    * If {@link IMAGE_URL} is provided, and loads successfully, the image using {@link IMAGE_URL} as its source will be displayed.
    *
    * If {@link IMAGE_URL} fails to load, or is not provided, the avatar image will be replaced with a
    * default/fallback image depending on the existance of {@link AVATAR_NAME}.
    *
    * @property {IMAGE_URL} imageUrl - The source url for the avatar image.
    */
    imageUrl: {
      type: String,
      default: '',
    },
    iconFileName: {
      type: String,
      default: '',
    },
    /**
    * Indicates whether or not {@link AVATAR_NAME} should be displayed as a tooltip upon hovering over the avatar image.
    *
    * If {@link SHOW_TOOLTIP} is TRUE, {@link TOOLTIP_TEXT} will appear upon hovering over the avatar image.
    *
    * If {@link SHOW_TOOLTIP} is TRUE, and {@link AVATAR_NAME} does not exist, the tooltip should display 'Deleted' as its tooltip content.
    *
    * @property {SHOW_TOOLTIP} showToolTip - Indicator for displaying the name of the resource as a tooltip on hover.
    */
    showToolTip: {
      type: Boolean,
      default: false,
    },
    /**
    * The text content of the displayed tooltip on hover.
    *
    * If {@link SHOW_TOOLTIP} is TRUE, {@link TOOLTIP_TEXT} will appear on hovering over the avatar image.
    *
    * If {@link SHOW_TOOLTIP} is TRUE, and {@link AVATAR_NAME} does not exist, the tooltip should display 'Deleted' as its tooltip content.
    *
    * @property {TOOLTIP_TEXT} toolTipText - The text content of the displayed tooltip on hover.
    */
    toolTipText: {
      type: String,
      default: '',
    },
    /**
    * The badge type indicating the status of the resource.
    *
    * If provided, {@link AVATAR_BADGE} must belong to one of 'online', 'away', 'offline',
    * and the corresponding badge will appear over the bottom right of the avatar image.
    *
    * If {@link AVATAR_BADGE} is not provided or is empty, no badge will appear.
    *
    * @property {AVATAR_BADGE} badge - The badge type indicating the status of the resource.
    */
    badge: {
      type: String,
      default: 'none',
    },
  },
  data() {
    return {
      imageFailedToLoad: false,
    };
  },
  computed: {
    trait() {
      return STYLE_TRAITS[this.type];
    },
    imageType() {
      // Should return one of 'IMAGE', 'DEFAULT', 'FALLBACK'
      if (this.name) {
        if (this.imageUrl) {
          if (this.imageFailedToLoad) {
            return 'DEFAULT';
          } else {
            return 'IMAGE';
          }
        } else {
          return 'DEFAULT';
        }
      } else {
        return 'FALLBACK';
      }
    },
    randomBackgroundColor() {
      const pool = STYLE_TRAITS.team.defaultImageBackgroundColorPool;
      const colorIndex = this.name.split('').reduce((acc, char) => acc + char.codePointAt(), 0) % pool.length;
      return `image-wrapper-background-${pool[colorIndex]}`;
    },
    nameAcronym() {
      if (this.name) {
        // Return two letter initial
        if (this.trait.defaultImagePolicy === 'TWO_INITIALS') {
          // Create array of the first letter of each word in prop name
          const matches = this.name.match(/\b(\w)/g);
          if (Array.isArray(matches)) {
            return matches.slice(0, 2).join('');
          } else {
            // This happens when `this.name` is not English.
            return this.name[0];
          }
        } else {
          // Return one letter initial
          return this.name[0];
        }
      } else {
        return null;
      }
    },
    showToolTipText() {
      return this.name ? this.toolTipText : null;
    },
    badgeStatusClass() {
      if (this.badge) {
        return `pui-avatar2__badge--${this.badge}`;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';

.pui-avatar2 {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  min-width: fit-content;

  &--show-name {
    border: 1px solid $color-grey-lighter;
    padding: 10px 30px 10px 30px;

    .pui-avatar2__image-container {
      margin: 0;
      margin-inline-end: 10px;
    }
  }

  &__inner {
    position: relative;
  }

  @mixin pui-avatar2__theme(
    $image-container-min-width,
    $image-container-min-height,
    $image-container-width,
    $image-container-height,
    $image-container-font-size,

    $acronym-user-font-size,
    $acronym-company-font-size,
    $acronym-team-font-size,

    $badge-width,
    $badge-height,
    $badge-border-width,
  ) {
    .pui-avatar2__image-container {
      min-width: $image-container-min-width;
      min-height: $image-container-min-height;
      width: $image-container-width;
      height: $image-container-height;
      font-size: $image-container-font-size;
    }

    .acronym-user {
      font-size: $acronym-user-font-size;
      color: $color-black;
    }

    .acronym-user-black {
      font-size: $acronym-user-font-size;
      color: $color-white;
    }

    .acronym-company {
      font-size: $acronym-company-font-size;
      color: $color-black;
    }

    .acronym-team {
      font-size: $acronym-team-font-size;
      color: $color-black;
    }

    .pui-avatar2__badge {
      position: absolute;
      border-radius: 100%;
      width: $badge-width;
      height: $badge-height;
      border: solid $badge-border-width #ffffff;
      inset-inline-start: calc(#{$image-container-width} - #{$badge-width});
      bottom: -1px;

      &--online {
        background-color: #7ed321;
      }

      &--away {
        background-color: #f5a623;
      }

      &--offline {
        background-color: #c9c9c9;
      }
    }
  }

  &--normal {
    @include pui-avatar2__theme(
      $image-container-min-width: 24px,
      $image-container-min-height: 24px,
      $image-container-width: 24px,
      $image-container-height: 24px,
      $image-container-font-size: 12px,

      $acronym-user-font-size: 12px,
      $acronym-company-font-size: 13px,
      $acronym-team-font-size: 12px,

      $badge-width: 6px,
      $badge-height: 6px,
      $badge-border-width: 0.5px
    )
  }

  &--large {
    @include pui-avatar2__theme(
      $image-container-min-width: 40px,
      $image-container-min-height: 40px,
      $image-container-width: 40px,
      $image-container-height: 40px,
      $image-container-font-size: 18px,

      $acronym-user-font-size: 18px,
      $acronym-company-font-size: 28px,
      $acronym-team-font-size: 18px,

      $badge-width: 10px,
      $badge-height: 10px,
      $badge-border-width: 0.75px
    )
  }

  &--xlarge {
    @include pui-avatar2__theme(
      $image-container-min-width: 60px,
      $image-container-min-height: 60px,
      $image-container-width: 60px,
      $image-container-height: 60px,
      $image-container-font-size: 26px,

      $acronym-user-font-size: 26px,
      $acronym-company-font-size: 40px,
      $acronym-team-font-size: 28px,

      $badge-width: 14px,
      $badge-height: 14px,
      $badge-border-width: 1px
    )
  }

  &--circular {
    .pui-avatar2__image-container {
      border-radius: 100%;
      overflow: hidden;
    }
  }

  .pui-avatar2__image-container {
    position: relative;
    cursor: default;

    .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: relative;

      border-radius: inherit;

      width: 100%;
      height: 100%;

      background-color: $color-white;

      &.image-wrapper-background-yellow {
        background-color: $color-yellow;
      }

      &.image-wrapper-background-black {
        background-color: $color-black;
      }

      &.image-wrapper-grey-border {
        border: 1px solid #c6c6c6;
      }

      &.image-wrapper-background-49ace1 {
        background-color: #49ace1;
      }

      &.image-wrapper-background-83f8ff {
        background-color: #83f8ff;
      }

      &.image-wrapper-background-ff8383 {
        background-color: #ff8383;
      }

      &.image-wrapper-background-f9c927 {
        background-color: #f9c927;
      }

      &.image-wrapper-background-b3d668 {
        background-color: #b3d668;
      }

      &.image-wrapper-background-38e0a5 {
        background-color: #38e0a5;
      }

      &.image-wrapper-background-ff7ec4 {
        background-color: #ff7ec4;
      }

      .uploaded-image {
        display: flex;
        align-self: center;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .bot-image {
        width: 100%;
        height: 100%;
        background-size: 75%!important;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .acronym-image {
        text-transform: uppercase;
        text-align: center;
        font-family: $montserrat;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 0.1px;
      }

      .image-grey {
        color: #c6c6c6;
      }

      .image-black {
        color: #000000;
      }
    }
  }
}

.pui-avatar2__name {
  font-family: $open-sans;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.1px;

  &--deleted {
    color: $color-grey-light;
  }

  &--is-me {
    color: #979797;
  }
}
</style>
