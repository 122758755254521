<script>
export default {
  name: 'PUIRow',
  componentName: 'PUIRow',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    gutter: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: null,
      validator: val => !val || ['flex'].includes(val),
    },
    justify: {
      type: String,
      default: 'start',
      validator: val => ['start', 'end', 'center', 'space-around', 'space-between'].includes(val),
    },
    align: {
      type: String,
      default: 'top',
      validator: val => ['top', 'middle', 'bottom'].includes(val),
    },
  },

  computed: {
    style() {
      const ret = {};

      if (this.gutter) {
        ret.marginLeft = `-${this.gutter / 2}px`;
        ret.marginRight = ret.marginLeft;
      }

      return ret;
    },
  },

  render(h) {
    return h(
      this.tag,
      {
        class: [
          'pui-row',
          this.justify !== 'start' ? `is-justify-${this.justify}` : '',
          this.align !== 'top' ? `is-align-${this.align}` : '',
          { 'pui-row--flex': this.type === 'flex' },
        ],
        style: this.style,
      },
      this.$slots.default
    );
  },
};
</script>

<style lang="scss" scoped>
.pui-row {
  &--flex {
    display: flex;

    &.is-justify-end {
      justify-content: flex-end;
    }

    &.is-justify-center {
      justify-content: center;
    }

    &.is-justify-space-around {
      justify-content: space-around;
    }

    &.is-justify-space-between {
      justify-content: space-between;
    }

    &.is-align-middle {
      align-items: center;
    }

    &.is-align-bottom {
      align-items: flex-end;
    }
  }
}
</style>
