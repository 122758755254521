import Vue from 'vue';
import Vuex from 'vuex';
// import LogRocket from 'logrocket';
import $bus from '@/platformSettings/bus';

Vue.use(Vuex);

const defaultPlatformLang = 'en';

export default {
  namespaced: true,
  state: {
    tutorial: null,
    tutorialStepsDeployChatbot: null,
    sampleBotId: null,
  },
  getters: {

  },
  mutations: {
    _setTutorial(state, tutorial) {
      state.tutorial = tutorial;
    },
    _setTutorialStepsDeployChatbot(state, tutorialDeployChatbot) {
      Vue.set(state, 'tutorialStepsDeployChatbot', tutorialDeployChatbot);
    },
    _setSampleBotId(state, sampleBotId) {
      state.sampleBotId = sampleBotId;
    },
  },
  actions: {
    init() { },
    getTutorial({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi.get(`lightbox/tutorial/${companyId}`)
          .then(response => {
            commit('_setTutorial', response.data.tutorial);
            commit('_setTutorialStepsDeployChatbot', response.data.tutorial_step_deploy_chatbot);
            return resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    startNewTutorial({ commit }, { companyId, category, subcategory }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi.post(`lightbox/tutorial/${companyId}`, { data: { category, subcategory } })
          .then(response => {
            const newTutorial = response.data;
            commit('_setTutorial', newTutorial);
            commit('_setTutorialStepsDeployChatbot', null);
            return resolve(newTutorial);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    updateTutorialSteps({ commit }, {
      companyId, tutorialId, name, avatarId, languages, chatbotTested,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi.patch(`lightbox/tutorial/deploy_chatbot/${companyId}/${tutorialId}`,
          {
            data: {
              name, avatar_id: avatarId, languages, chatbot_tested: chatbotTested,
            },
          })
          .then(response => {
            commit('_setTutorialStepsDeployChatbot', response.data);
            return resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    createChatbot({ commit }, {
      companyId, tutorialId,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi.post(`lightbox/tutorial/deploy_chatbot/${companyId}/${tutorialId}`)
          .then(response => {
            commit('_setSampleBotId', response.data.bot_id);
            return resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    resetTutorialData({ commit }) {
      commit('_setTutorial', null);
      commit('_setTutorialStepsDeployChatbot', null);
      commit('_setSampleBotId', null);
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
