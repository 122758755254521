import { ACTIONS } from '@/webchat2/config/types';
import { createEnum } from '@/utils';

export const MESSAGE_POSITION = createEnum({
  FIRST: 'first',
  SINGLE: 'single',
  MIDDLE: 'middle',
  LAST: 'last',
});

// Strip HTML tags
// Refer to: https://stackoverflow.com/a/17980070
export const stripHtml = html => {
  if (!html) return null;

  const tmp = document.implementation.createHTMLDocument().body;
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const MESSAGE_TYPE = createEnum({
  TEXT: 'text',
  AUDIO: 'audio',
  VIDEO: 'video',
  IMAGE: 'image',
  FILE: 'file',
  MANUAL_TRIGGER: 'manual_trigger',
  CAROUSEL: 'carousel',
  CARD: 'card',
  BUTTON_CLICKED: 'button_clicked',
  QUICK_REPLIES: 'quickreplies',
  LINE_STICKER: 'line_sticker',
  TEXT_WITH_LINE_EMOJI: 'text_with_line_emoji',
  VIBER_STICKER: 'viber_sticker',
  TELEGRAM_STICKER: 'telegram_sticker',
  TELEGRAM_CONTACT: 'telegram_contact',
  LOCATION: 'location',
  CUSTOMER_SENT_RATING: 'customer_sent_rating',
  BOT_TYPING_ON: 'bot_typing_on',
  BOT_TYPING_OFF: 'bot_typing_off',
  IFRAME: 'iframe',
  IFRAME_CLOSED: 'iframe_closed',
  HYPERLINK: 'hyperlink',
  THIRDPARTY_AGENT_TAKEOVER: 'thirdparty_agent_takeover',
  METAWHATSAPP_CONTACT: 'metawhatsapp_contact',
});

export const AUTHOR_TYPE = createEnum({
  BOT: 'bot',
  AGENT: 'agent',
  HUMAN: 'human',
});

export const PLATFORM_AUTHOR_TYPES = Object.values(AUTHOR_TYPE);

export const DISPLAYABLE_MESSAGE_TYPE = new Set([
  MESSAGE_TYPE.TEXT,
  MESSAGE_TYPE.AUDIO,
  MESSAGE_TYPE.VIDEO,
  MESSAGE_TYPE.IMAGE,
  MESSAGE_TYPE.FILE,
  MESSAGE_TYPE.CAROUSEL,
  MESSAGE_TYPE.BUTTON_CLICKED,
  MESSAGE_TYPE.BOT_TYPING_ON,
  MESSAGE_TYPE.HYPERLINK,
  MESSAGE_TYPE.QUICK_REPLIES,
  MESSAGE_TYPE.THIRDPARTY_AGENT_TAKEOVER,
  ACTIONS.CONVERSATION_CLOSED,
]);

export const ALERTABLE_MESSAGE_TYPE = new Set([
  MESSAGE_TYPE.TEXT,
  MESSAGE_TYPE.AUDIO,
  MESSAGE_TYPE.VIDEO,
  MESSAGE_TYPE.IMAGE,
  MESSAGE_TYPE.FILE,
  MESSAGE_TYPE.LINE_STICKER,
  MESSAGE_TYPE.TEXT_WITH_LINE_EMOJI,
  MESSAGE_TYPE.VIBER_STICKER,
  MESSAGE_TYPE.TELEGRAM_STICKER,
  MESSAGE_TYPE.TELEGRAM_CONTACT,
  MESSAGE_TYPE.LOCATION,
  MESSAGE_TYPE.METAWHATSAPP_CONTACT,
]);

export const SEND_STATUS = createEnum({
  sending: 'sending',
  received: 'received',
  failed: 'failed',
  read: 'read',
});

export const MSG_TYPE_FEEDBACK = new Set([
  MESSAGE_TYPE.TEXT,
  MESSAGE_TYPE.AUDIO,
  MESSAGE_TYPE.VIDEO,
  MESSAGE_TYPE.IMAGE,
  MESSAGE_TYPE.FILE,
]);

// Message types that can be rendered as HTML, render with v-dompurify-html to prevent XSS
export const RENDER_HTML_MESSAGE_TYPE = new Set([
  MESSAGE_TYPE.TEXT_WITH_LINE_EMOJI,
]);

const { CONVERSATION_ASSIGNED_TO_AGENT, CONVERSATION_TERMINATED, CONVERSATION_CLOSED } = ACTIONS;

export const isNotification = msg => [
  CONVERSATION_ASSIGNED_TO_AGENT,
  CONVERSATION_TERMINATED,
  CONVERSATION_CLOSED,
  MESSAGE_TYPE.THIRDPARTY_AGENT_TAKEOVER,
].includes(msg.type);

const DIFFERENT_GROUP_MSG_TYPES = [MESSAGE_TYPE.CAROUSEL, MESSAGE_TYPE.QUICK_REPLIES];

/* prev and current are message objects */
export const isSameGroup = (prev, current, prevTs = null, currentTs = null) => {
  if (!prev || !current) {
    return false;
  }

  if (DIFFERENT_GROUP_MSG_TYPES.includes(current.type) || DIFFERENT_GROUP_MSG_TYPES.includes(prev.type)) {
    return false;
  }

  prevTs = prevTs || new Date(prev.ts);
  currentTs = currentTs || new Date(current.ts);

  const secondDiff = Math.abs(prevTs.getTime() - currentTs.getTime());

  if (secondDiff >= 60 * 1000) {
    return false;
  }

  if (prevTs.getDate() !== currentTs.getDate()) {
    return false;
  }

  if (!prev.author || !current.author) {
    return false;
  }

  if (prev.author.type !== current.author.type) {
    return false;
  }

  // if this message is received by backend.
  if (prev.id && current.id) {
    /* Don't use !== here because we need compare null and undefined */
    if (prev.author.full_name !== current.author.full_name) {
      return false;
    }
    if (prev.author.profile_pic_url !== current.author.profile_pic_url) {
      return false;
    }
  }

  return true;
};

export const isVisibleMessage = msg => msg && DISPLAYABLE_MESSAGE_TYPE.has(msg.type);

export const isUnreadMessageFromBotOrAgent = msg => msg?.author?.type !== AUTHOR_TYPE.HUMAN && !msg.read_at && isVisibleMessage(msg);

export const isMessageFromHuman = msg => msg?.author?.type === AUTHOR_TYPE.HUMAN;

export const handleLineEmojiMsg = ({ text, emojis }) => {
  if (!text) return '';

  const textTotalLength = text.length;
  const textWithEmojiEl = document.createElement('span');
  textWithEmojiEl.className = 'chat-message--text-with-line-emoji';

  let pointer = 0;
  emojis.forEach(emoji => {
    // get text
    if (pointer < emoji.index) {
      const textEl = document.createElement('span');
      textEl.innerText = text.slice(pointer, emoji.index);
      textWithEmojiEl.appendChild(textEl);
    }

    // get emoji
    const imgEl = document.createElement('img');
    imgEl.src = `https://stickershop.line-scdn.net/sticonshop/v1/sticon/${emoji.product_id}/android/${emoji.emoji_id}.png`;
    textWithEmojiEl.appendChild(imgEl);

    pointer = (emoji.index + emoji.length);
  });

  if (pointer < textTotalLength) {
    // get the rest of text
    const textEl = document.createElement('span');
    textEl.innerText = text.slice(pointer, textTotalLength);
    textWithEmojiEl.appendChild(textEl);
  }

  return textWithEmojiEl.outerHTML;
};
