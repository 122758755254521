<template>
  <font-awesome-icon
    v-tooltip.top="{
      content: content,
      offset: offset,
      popperOptions: {
        modifiers: {
          preventOverflow: { boundariesElement: boundariesElement },
        },
      },
    }"
    tabindex="0"
    :icon="['fal', 'question-circle']"
    class="pui-hint"
    :style="{
      color,
      '--hover-color': hoverColor,
      '--focus-color': focusColor,
    }"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'PUIHint',
  props: {
    content: {
      type: String,
      required: true,
    },
    offset: {
      type: String,
      default: '10px',
    },
    boundary: {
      type: [String, HTMLElement],
      default: 'window',
    },
    color: {
      type: String,
      default: '#979797',
    },
    hoverColor: {
      type: String,
      default: 'black',
    },
    focusColor: {
      type: String,
      default: 'black',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    boundariesElement() {
      return this.boundary;
    },
  },
};
</script>

<style lang="scss" scoped>
.pui-hint {
  outline: unset;

  &:focus {
    color: var(--focus-color) !important;
  }

  &:hover {
    color: var(--hover-color) !important;
  }
}
</style>
