var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    _vm._g(
      {
        class: _vm.classes,
        style: { height: _vm.height },
        attrs: { disabled: _vm.isDisabled, type: "button" }
      },
      _vm.inputListeners
    ),
    [
      !_vm.loading
        ? _c(
            "span",
            { staticClass: "button-inner" },
            [
              _vm.icon
                ? _c("font-awesome-icon", {
                    staticClass: "button-icon",
                    attrs: { icon: ["fas", _vm.icon] }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.text
                ? _c("label", { staticClass: "button-label" }, [
                    _vm._v(_vm._s(_vm.text))
                  ])
                : _c(
                    "label",
                    { staticClass: "button-label" },
                    [_vm._t("default")],
                    2
                  )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading || _vm.loadingInternal
        ? _c("PUILoader", { attrs: { size: "small" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }