import Vue from 'vue';
import Vuex from 'vuex';

import editorv2 from '@/store/modules/v2/editor';
import botv2 from '@/store/modules/v2/bot';
import uiv2 from '@/store/modules/v2/ui';
import userv2 from '@/store/modules/v2/user';
import websocketv2 from '@/store/modules/v2/websocket';
import humanv2 from '@/store/modules/v2/human';
import track from '@/store/modules/v2/track';
import entitySettings from '@/store/modules/v2/entitySettings';
import historySurvey from '@/store/modules/v2/historySurvey';
import historyCheckpoint from '@/store/modules/v2/historyCheckpoint';
import historyEmail from '@/store/modules/v2/historyEmail';
import historyWebhook from '@/store/modules/v2/historyWebhook';
import historyChat from '@/store/modules/v2/historyChat';
import chat from '@/store/modules/v2/chat';
import chatHistory from '@/store/modules/v2/chatHistory';
import botSettings from '@/store/modules/v2/botSettings';
import analytics from '@/store/modules/v2/analytics';
import alias from '@/store/modules/v2/alias';
import version from '@/store/modules/v2/version';
import webchatv2 from '@/store/modules/v2/webchat';
import channels from '@/store/modules/v2/channels';
import caseCategory from '@/store/modules/v2/caseCategory';
import tutorial from '@/store/modules/v2/tutorial';
import byod from '@/store/modules/v2/byod';
import lightbox from '@/store/modules/v2/lightbox';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    editorv2,
    botv2,
    uiv2,
    userv2,
    websocketv2,
    humanv2,
    entitySettings,
    historySurvey,
    historyCheckpoint,
    historyEmail,
    historyWebhook,
    historyChat,
    track,
    alias,
    chat,
    chatHistory,
    botSettings,
    analytics,
    version,
    webchatv2,
    channels,
    caseCategory,
    tutorial,
    byod,
    lightbox,
  },
  strict: process.env.NODE_ENV !== 'production',
});

export default store;
