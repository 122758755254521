import axios from 'axios';
import $bus from '@/platformSettings/bus.js';
import i18n from '@/lang/i18n';

import { getLocalStorage, getUtmQueryString } from '@/utils';
import { VALID_UTM_PARAMS } from '@/constants.js';

const { getOwnPropertyNames } = Object;

const storage = getLocalStorage();

class SiniticApi {
  constructor({ baseURL, tokenKey = 'token', autoLoadToken = true }) {
    this.tokenKey = tokenKey;

    this.instance = axios.create({
      baseURL,
      withCredentials: true,
      transformResponse: [
        res => {
          try {
            const response = JSON.parse(res);
            // console.log(res, "=>", response)
            if (typeof response.error !== 'undefined') {
              return response.error;
            }
            if (typeof response.result !== 'undefined') {
              return response.result;
            }
            if (typeof response.results !== 'undefined') {
              return response.results;
            }
            return response;
          } catch (err) {
            return res;
          }
        },
      ],
    });

    if (autoLoadToken) {
      const token = storage?.getItem?.(this.tokenKey) || '';
      console.log('load token in localStorage', token);
      if (token) {
        this.setToken('X-SINITIC-TOKEN', token);
      }
    }
  }

  setBaseURL(newUrl) {
    this.instance.defaults.baseURL = newUrl;
  }

  setToken(tokenName, token) {
    storage?.setItem?.(this.tokenKey, token);
    this.instance.defaults.headers.common[tokenName] = token;
    console.log('token set', tokenName, storage?.getItem?.(this.tokenKey));
  }

  getToken(tokenName = 'X-SINITIC-TOKEN') {
    return this.instance.defaults.headers.common[tokenName];
  }

  onError(error, config, reject) {
    if (error && error.response) {
      if (error.response.status === 401) {
        console.log('Login error', error);
        if (window.location.pathname === '/') {
          // Don't show the message Session Expired if the path is /
          // Because if the user is trying to access the home page,
          // it's probably because it's its first time logging on

          // Retain query parameters, ie: utm parameters for marketing
          window.location.href = `/v2/login?${getUtmQueryString(window.location.search, VALID_UTM_PARAMS)}`;
        } else {
          if (window.location.search) {
            // eslint-disable-next-line max-len
            window.location.href = `/v2/login?${getUtmQueryString(window.location.search, VALID_UTM_PARAMS)}&message=SESSION_EXPIRED&redirectTo=${window.location.pathname}`;
          } else {
            window.location.href = `/v2/login?message=SESSION_EXPIRED&redirectTo=${window.location.pathname}`;
          }
        }
      } else if (error.response.status === 413) {
        if (reject) {
          reject(error.response);
        } else {
          $bus.$notify({
            type: 'error',
            text: i18n.t('platform:notification.error.FILE_SIZE_LIMIT_EXCEEDED'),
          });
        }
      } else if (config.notification !== false) {
        console.log('API error', { error });
        const errorObj = error.response.data;

        if (reject) {
          reject({
            ...errorObj,
            $response: error.response,
          });
        } else {
          const text = errorObj.code
            ? errorObj.message || i18n.t(`platform:notification.error.${errorObj.code}`) || errorObj.code
            : error.response.message || error.message;
          $bus.$notify({
            type: 'error',
            text,
          });
        }
      }
    } else {
      if (!axios.isCancel(error)) {
        console.error({ error });
      }
      if (reject) {
        reject(error);
      } else {
        $bus.$notify({
          type: 'error',
          text: error.message,
        });
      }
    }
  }

  request(
    method,
    url,
    config = {
      notification: true,
    }
  ) {
    const { params, data } = {
      params: '',
      ...config,
    };

    return new Promise((resolve, reject) => {
      this.instance
        .request({
          method,
          url,
          params,
          data,
          ...config,
        })
        .then(resolve)
        .catch(error => {
          this.onError(error, config, reject);
        });
    });
  }

  get(url, config) {
    return this.request('get', url, config);
  }

  post(url, config) {
    return this.request('post', url, config);
  }

  delete(url, config) {
    return this.request('delete', url, config);
  }

  put(url, config) {
    return this.request('put', url, config);
  }

  patch(url, config) {
    return this.request('patch', url, config);
  }
}

export default SiniticApi;
