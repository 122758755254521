import $bus from '@/platformSettings/bus';

// const timezoneConfig = $bus.$dayjs.tz.names();
// prettier-ignore
const timezoneConfig = [
  { value: 'Africa/Algiers', display: '(UTC+01:00) West Central Africa' },
  { value: 'Africa/Cairo', display: '(UTC+02:00) Cairo' },
  { value: 'Africa/Casablanca', display: '(UTC) Casablanca' },
  { value: 'Africa/Harare', display: '(UTC+02:00) Harare, Pretoria' },
  { value: 'Africa/Monrovia', display: '(UTC) Monrovia, Reykjavik' },
  { value: 'Africa/Nairobi', display: '(UTC+03:00) Nairobi' },
  { value: 'Africa/Tripoli', display: '(UTC+02:00) Tripoli' },
  { value: 'Africa/Windhoek', display: '(UTC+01:00) Windhoek' },
  { value: 'America/Adak', display: '(UTC-10:00) Aleutian Islands' },
  { value: 'America/Anchorage', display: '(UTC-09:00) Alaska' },
  { value: 'America/Asuncion', display: '(UTC-04:00) Asuncion' },
  { value: 'America/Bahia', display: '(UTC-03:00) Salvador' },
  { value: 'America/Belize', display: '(UTC-06:00) Central America' },
  { value: 'America/Bogota', display: '(UTC-05:00) Bogota, Lima, Quito' },
  { value: 'America/Buenos_Aires', display: '(UTC-03:00) City of Buenos Aires' },
  { value: 'America/Cancun', display: '(UTC-05:00) Chetumal' },
  { value: 'America/Caracas', display: '(UTC-04:00) Caracas' },
  { value: 'America/Cayenne', display: '(UTC-03:00) Cayenne, Fortaleza' },
  { value: 'America/Chicago', display: '(UTC-06:00) Central Time (US and Canada)' },
  { value: 'America/Chihuahua', display: '(UTC-07:00) Chihuahua, La Paz, Mazatlan' },
  { value: 'America/Cuiaba', display: '(UTC-04:00) Cuiaba' },
  { value: 'America/Denver', display: '(UTC-07:00) Mountain Time (US and Canada)' },
  { value: 'America/Fortaleza', display: '(UTC-03:00) Araguaina' },
  { value: 'America/Godthab', display: '(UTC-03:00) Greenland' },
  { value: 'America/Grand_Turk', display: '(UTC-04:00) Turks and Caicos' },
  { value: 'America/Halifax', display: '(UTC-04:00) Atlantic Time (Canada)' },
  { value: 'America/Havana', display: '(UTC-05:00) Havana' },
  { value: 'America/Los_Angeles', display: '(UTC-08:00) Pacific Time (US and Canada)' },
  { value: 'America/Manaus', display: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan' },
  { value: 'America/Mexico_City', display: '(UTC-06:00) Guadalajara, Mexico City, Monterrey' },
  { value: 'America/Miquelon', display: '(UTC-03:00) Saint Pierre and Miquelon' },
  { value: 'America/Montevideo', display: '(UTC-03:00) Montevideo' },
  { value: 'America/New_York', display: '(UTC-05:00) Eastern Time (US and Canada)' },
  { value: 'America/Noronha', display: '(UTC-02:00) Fernando de Noronha' },
  { value: 'America/Phoenix', display: '(UTC-07:00) Arizona' },
  { value: 'America/Regina', display: '(UTC-06:00) Saskatchewan' },
  { value: 'America/Santiago', display: '(UTC-04:00) Santiago' },
  { value: 'America/Sao_Paulo', display: '(UTC-03:00) Brasilia' },
  { value: 'America/St_Johns', display: '(UTC-03:30) Newfoundland' },
  { value: 'America/Tijuana', display: '(UTC-08:00) Baja California' },
  { value: 'Asia/Almaty', display: '(UTC+06:00) Astana' },
  { value: 'Asia/Amman', display: '(UTC+02:00) Amman' },
  { value: 'Asia/Anadyr', display: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky' },
  { value: 'Asia/Baghdad', display: '(UTC+03:00) Baghdad' },
  { value: 'Asia/Baku', display: '(UTC+04:00) Baku' },
  { value: 'Asia/Bangkok', display: '(UTC+07:00) Bangkok, Hanoi, Jakarta' },
  { value: 'Asia/Barnaul', display: '(UTC+07:00) Barnaul, Gorno-Altaysk' },
  { value: 'Asia/Beirut', display: '(UTC+02:00) Beirut' },
  { value: 'Asia/Chita', display: '(UTC+09:00) Chita' },
  { value: 'Asia/Colombo', display: '(UTC+05:30) Sri Jayawardenepura' },
  { value: 'Asia/Damascus', display: '(UTC+02:00) Damascus' },
  { value: 'Asia/Dhaka', display: '(UTC+06:00) Dhaka' },
  { value: 'Asia/Gaza', display: '(UTC+02:00) Gaza, Hebron' },
  { value: 'Asia/Hovd', display: '(UTC+07:00) Hovd' },
  { value: 'Asia/Irkutsk', display: '(UTC+08:00) Irkutsk' },
  { value: 'Asia/Istanbul', display: '(UTC+03:00) Istanbul' },
  { value: 'Asia/Jerusalem', display: '(UTC+02:00) Jerusalem' },
  { value: 'Asia/Kabul', display: '(UTC+04:30) Kabul' },
  { value: 'Asia/Karachi', display: '(UTC+05:00) Islamabad, Karachi' },
  { value: 'Asia/Kathmandu', display: '(UTC+05:45) Kathmandu' },
  { value: 'Asia/Kolkata', display: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
  { value: 'Asia/Krasnoyarsk', display: '(UTC+07:00) Krasnoyarsk' },
  { value: 'Asia/Kuala_Lumpur', display: '(UTC+08:00) Kuala Lumpur, Singapore' },
  { value: 'Asia/Kuwait', display: '(UTC+03:00) Kuwait, Riyadh' },
  { value: 'Asia/Magadan', display: '(UTC+11:00) Magadan' },
  { value: 'Asia/Muscat', display: '(UTC+04:00) Abu Dhabi, Muscat' },
  { value: 'Asia/Novosibirsk', display: '(UTC+07:00) Novosibirsk' },
  { value: 'Asia/Pyongyang', display: '(UTC+08:30) Pyongyang' },
  { value: 'Asia/Rangoon', display: '(UTC+06:30) Yangon (Rangoon)' },
  { value: 'Asia/Sakhalin', display: '(UTC+11:00) Sakhalin' },
  { value: 'Asia/Seoul', display: '(UTC+09:00) Seoul' },
  { value: 'Asia/Shanghai', display: '(UTC+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi' },
  { value: 'Asia/Srednekolymsk', display: '(UTC+11:00) Chokirdakh' },
  { value: 'Asia/Taipei', display: '(UTC+08:00) Taipei' },
  { value: 'Asia/Tashkent', display: '(UTC+05:00) Tashkent, Ashgabat' },
  { value: 'Asia/Tbilisi', display: '(UTC+04:00) Tbilisi' },
  { value: 'Asia/Tehran', display: '(UTC+03:30) Tehran' },
  { value: 'Asia/Tokyo', display: '(UTC+09:00) Osaka, Sapporo, Tokyo' },
  { value: 'Asia/Tomsk', display: '(UTC+07:00) Tomsk' },
  { value: 'Asia/Ulaanbaatar', display: '(UTC+08:00) Ulaanbaatar' },
  { value: 'Asia/Vladivostok', display: '(UTC+10:00) Vladivostok' },
  { value: 'Asia/Yakutsk', display: '(UTC+09:00) Yakutsk' },
  { value: 'Asia/Yekaterinburg', display: '(UTC+05:00) Ekaterinburg' },
  { value: 'Asia/Yerevan', display: '(UTC+04:00) Yerevan' },
  { value: 'Atlantic/Azores', display: '(UTC-01:00) Azores' },
  { value: 'Atlantic/Cape_Verde', display: '(UTC-01:00) Cabo Verde Islands' },
  { value: 'Australia/Adelaide', display: '(UTC+09:30) Adelaide' },
  { value: 'Australia/Brisbane', display: '(UTC+10:00) Brisbane' },
  { value: 'Australia/Canberra', display: '(UTC+10:00) Canberra, Melbourne, Sydney' },
  { value: 'Australia/Darwin', display: '(UTC+09:30) Darwin' },
  { value: 'Australia/Eucla', display: '(UTC+08:45) Eucla' },
  { value: 'Australia/Hobart', display: '(UTC+10:00) Hobart' },
  { value: 'Australia/Lord_Howe', display: '(UTC+10:30) Lord Howe Island' },
  { value: 'Australia/Perth', display: '(UTC+08:00) Perth' },
  { value: 'Europe/Amsterdam', display: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
  { value: 'Europe/Astrakhan', display: '(UTC+04:00) Astrakhan, Ulyanovsk' },
  { value: 'Europe/Athens', display: '(UTC+02:00) Athens, Bucharest' },
  { value: 'Europe/Belgrade', display: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' },
  { value: 'Europe/Brussels', display: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris' },
  { value: 'Europe/Helsinki', display: '(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius' },
  { value: 'Europe/Kaliningrad', display: '(UTC+02:00) Kaliningrad' },
  { value: 'Europe/London', display: '(UTC) Dublin, Edinburgh, Lisbon, London' },
  { value: 'Europe/Minsk', display: '(UTC+03:00) Minsk' },
  { value: 'Europe/Moscow', display: '(UTC+03:00) Moscow, St. Petersburg, Volgograd' },
  { value: 'Europe/Samara', display: '(UTC+04:00) Izhevsk, Samara' },
  { value: 'Europe/Warsaw', display: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
  { value: 'Indian/Mauritius', display: '(UTC+04:00) Port Louis' },
  { value: 'Pacific/Apia', display: '(UTC+13:00) Samoa' },
  { value: 'Pacific/Auckland', display: '(UTC+12:00) Auckland, Wellington' },
  { value: 'Pacific/Bougainville', display: '(UTC+11:00) Bougainville Island' },
  { value: 'Pacific/Chatham', display: '(UTC+12:45) Chatham Islands' },
  { value: 'Pacific/Easter', display: '(UTC-06:00) Easter Island' },
  { value: 'Pacific/Fiji', display: '(UTC+12:00) Fiji Islands' },
  { value: 'Pacific/Guadalcanal', display: '(UTC+11:00) Solomon Islands, New Caledonia' },
  { value: 'Pacific/Guam', display: '(UTC+10:00) Guam, Port Moresby' },
  { value: 'Pacific/Honolulu', display: '(UTC-10:00) Hawaii' },
  { value: 'Pacific/Kiritimati', display: '(UTC+14:00) Kiritimati Island' },
  { value: 'Pacific/Marquesas', display: '(UTC-09:30) Marquesas Islands' },
  { value: 'Pacific/Norfolk', display: '(UTC+11:00) Norfolk Island' },
  { value: 'Pacific/Pago_Pago', display: '(UTC-11:00) Midway Island, American Samoa' },
  { value: 'Pacific/Tongatapu', display: '(UTC+13:00) Nuku\'alofa' },
];

timezoneConfig.sort((lhs, rhs) => lhs.display.localeCompare(rhs.display));

export default timezoneConfig;

export const timezoneByName = timezoneConfig.reduce((byName, tz) => {
  byName.set(tz.value, tz);
  return byName;
}, new Map());
