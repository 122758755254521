var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "pui-beta-icon" },
    [_c("font-awesome-icon", { attrs: { icon: ["fal", "flask"] } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }