import $bus from '@/platformSettings/bus';

export default {
  namespaced: true,
  state: {
    entityList: [],
    paginationConfig: {
      per_page: 10,
      pages: 1,
      page: 1,
      totalItems: 0,
    },

    selectedEntity: {},
    selectedLabelId: null,

    labelTree: [],
    labelFlattened: [],

    checkedEntityIds: [],

    searchEntityKeyword: null,
    isSearchResult: false,

    showCreateLabelModal: false,
    showCreateLabelModalWithApply: false,
  },

  getters: {
    checkedEntities(state) {
      return state.checkedEntityIds.reduce((obj, id) => {
        const entity = state.entityList.find(entity => entity.id === id);
        if (!entity) return obj;
        obj[id] = state.entityList.find(entity => entity.id === id);
        return obj;
      }, {});
    },
    labelFlattened(state) {
      return state.labelFlattened;
    },
    selectedEntity(state) {
      return state.selectedEntity;
    },

  },

  mutations: {
    setIsSearchResult(state, payload) {
      state.isSearchResult = payload;
    },

    setEntityList(state, payload) {
      state.entityList = payload.entityList;
      state.paginationConfig = {
        ...state.paginationConfig,
        ...payload.paginationConfig,
      };
    },

    setLabelTree(state, payload) {
      state.labelTree = payload.labelTree;
    },

    setLabelFlattened(state, payload) {
      state.labelFlattened = payload.labelFlattened;
    },

    selectEntity(state, payload) {
      state.selectedEntity = payload;
    },

    setSearchEntityKeyword(state, keyword) {
      state.searchEntityKeyword = keyword;
    },

    checkEntity(state, entityIds) {
      if (!Array.isArray(entityIds)) return;
      state.checkedEntityIds = entityIds;
    },

    initCheckEntity(state) {
      state.checkedEntityIds = [];
    },

    checkAllEntities(state) {
      state.checkedEntityIds = state.entityList.map(item => item.id);
    },

    uncheckAllEntities(state) {
      state.checkedEntityIds = [];
    },

    selectLabel(state, labelId) {
      state.selectedLabelId = labelId ?? null;
    },

    setShowCreateLabelModal(state, show) {
      state.showCreateLabelModal = show;
    },

    setShowCreateLabelModalWithApply(state, show) {
      state.showCreateLabelModalWithApply = show;
    },

    setPaginationConfig(state, paginationConfig) {
      state.paginationConfig = paginationConfig;
    },

    resetEntityFilters(state) {
      state.entityList = [];

      state.paginationConfig = {
        per_page: 10,
        pages: 1,
        page: 1,
        totalItems: 0,
      };

      state.selectedLabelId = null;

      state.searchEntityKeyword = null;
      state.isSearchResult = false;

      state.labelTree = [];
      state.labelFlattened = [];
      state.checkedEntityIds = [];
    },
  },

  actions: {
    getEntityList({ state, commit }, routeObj) {
      const {
        companyId,
        botId,
        page = 1,
        per_page,
      } = routeObj;

      if (state.searchEntityKeyword) {
        commit('setIsSearchResult', true);
      } else {
        commit('setIsSearchResult', false);
      }

      $bus.$siniticApi
        .get(`bot/v2/${companyId}/${botId}/entities`, {
          params: {
            page: page ?? state.paginationConfig?.page ?? 1,
            per_page: per_page ?? state.paginationConfig?.per_page,
            ...(!!state.searchEntityKeyword && { search: state.searchEntityKeyword }),
            ...(!!state.selectedLabelId && { label_id: state.selectedLabelId }),
          },
        })
        .then(res => {
          const {
            items, curr_page,
            total_pages,
            items_per_page,
            total_items,
          } = res.data;

          commit('setEntityList', {
            entityList: items,
            paginationConfig: {
              page: curr_page,
              pages: total_pages,
              per_page: items_per_page,
              totalItems: total_items,
            },
          });
        })
        .catch(err => {
          console.log(err);
        });
    },

    getEntity({ commit }, { companyId, botId, entityId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/entity/${entityId}`)
          .then(res => {
            commit('selectEntity', res.data);
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    createEntity({ dispatch }, { companyId, botId, entityName }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`bot/v2/${companyId}/${botId}/entity`, {
            data: { name: entityName },
          })
          .then(res => {
            dispatch('getEntityList', { companyId, botId });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    deleteEntity({ dispatch }, { companyId, botId, entityId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`bot/v2/${companyId}/${botId}/entity/${entityId}`)
          .then(res => {
            dispatch('getEntityList', { companyId, botId });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    updateEntity({ dispatch }, {
      companyId, botId, entityId, entityName,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`bot/v2/${companyId}/${botId}/entity/${entityId}`, {
            data: { name: entityName },
          })
          .then(res => {
            dispatch('getEntity', { companyId, botId, entityId });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    postNoun({ dispatch }, {
      companyId, botId, entityId, nounText, nounType,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`bot/v2/${companyId}/${botId}/entity/${entityId}/nouns`, {
            data: {
              noun: nounText,
              type: nounType,
            },
          })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    deleteNoun({ dispatch }, {
      companyId, botId, entityId, nounId,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`bot/v2/${companyId}/${botId}/entity/${entityId}/nouns`, { data: { nouns_ids: nounId } })
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getNouns({ dispatch }, { companyId, botId, entityId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/entity/${entityId}/nouns`)
          .then(res => {
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    getLabels({ commit }, { companyId, botId }) {
      $bus.$siniticApi
        .get(`bot/v2/${companyId}/${botId}/labels`)
        .then(res => {
          commit('setLabelTree', {
            labelTree: res.data,
          });

          const flattened = function (labelTree, outArray = []) {
            labelTree.map(label => {
              outArray.push(label);
              return flattened(label.children, outArray);
            });
            return outArray;
          };

          commit('setLabelFlattened', {
            labelFlattened: flattened(res.data),
          });
        })
        .catch(err => {
          console.log(err);
        });
    },

    postLabel({ dispatch }, {
      companyId, botId, labelName, labelParentId,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`bot/v2/${companyId}/${botId}/label`, {
            data: {
              label_parent_id: labelParentId,
              name: labelName,
            },
          })
          .then(res => {
            dispatch('getLabels', { companyId, botId });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    deleteLabel({ dispatch }, { companyId, botId, labelId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`bot/v2/${companyId}/${botId}/label/${labelId}`)
          .then(res => {
            dispatch('getLabels', { companyId, botId });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    putLabel({ dispatch }, {
      companyId, botId, labelParentId, labelId, labelName,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`bot/v2/${companyId}/${botId}/label/${labelId}`, {
            data: {
              label_parent_id: labelParentId,
              name: labelName,
            },
          })
          .then(res => {
            dispatch('getLabels', { companyId, botId });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    associateLabels({ dispatch }, {
      companyId, botId, entityIds, labelIds,
    }) {
      if (entityIds.length === 0) return Promise.reject(new Error('Please select some labels.'));

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`bot/v2/${companyId}/${botId}/multi_labels`, {
            data: {
              entity_ids: entityIds,
              label_ids: labelIds,
            },
          })
          .then(res => {
            dispatch('getEntityList', { companyId, botId });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    deassociateLabel({ dispatch }, {
      companyId, botId, entityId, labelId,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`bot/v2/${companyId}/${botId}/entity/${entityId}/deassociate_label`, {
            data: { label_id: labelId },
          })
          .then(res => {
            dispatch('getEntity', { companyId, botId, entityId });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
};
