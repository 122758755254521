<template>
  <div
    :class="classesSui"
    :style="{
      width: width,
      height: height,
    }"
    v-on="$listeners"
  >
    <svg>
      <use v-bind="{ 'xlink:href': '#' + name }" />
    </svg>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PUIIcon',
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
      validator: value => ['xsmall', 'ssmall', 'small', 'medium', 'large', 'x-large'].includes(value),
    },
    color: {
      type: String,
      default: 'primary',
      validator: value => [
        'transparent',
        'primary',
        'secondary',
        'white',
        'grey',
        'grey-light',
        'grey-dark',
        'success',
        'danger',
        'info',
        'none',
      ].includes(value),
    },
    strokeColor: {
      type: String,
      default: 'none',
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
  },
  computed: {
    classesSui() {
      const {
        clickable, rounded, border, disabled, background, color, strokeColor, size,
      } = this;
      return {
        'pui-icon': true,
        [`pui-icon--is-${size}`]: true,
        'pui-icon--rounded': rounded,
        'pui-icon--disabled': disabled,
        [`pui-icon--bg-${background}`]: background,
        'pui-icon--border': border,
        [`pui-icon--${color}`]: color,
        [`pui-icon--stroke-${strokeColor}`]: strokeColor,
        'pui-icon--clickable': clickable,
        'pui-icon--is-loading': this.loading,
      };
    },
  },
};
</script>

<style lang="scss">
@import '~styles/pui/pui-variables.scss';

@mixin pui-icon-border($thickness) {
  box-sizing: content-box;
  padding: $thickness;
}

.pui-icon {
  $root: &;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  width: 100%;
  position: relative;
  vertical-align: middle;

  svg {
    height: 100%;
    width: 100%;
    fill: $color-grey-dark;
  }

  &--is-loading {
    animation: spinAround 500ms infinite linear;
  }

  // Size
  &--is-xsmall {
    width: $size-icon-xsmall;
    min-width: $size-icon-xsmall;
    height: $size-icon-xsmall;
    font-size: $size-icon-xsmall;
  }
  &--is-ssmall {
    width: $size-icon-ssmall;
    min-width: $size-icon-ssmall;
    height: $size-icon-ssmall;
    font-size: $size-icon-ssmall;
  }
  &--is-small {
    width: $size-icon-small;
    min-width: $size-icon-small;
    height: $size-icon-small;
    font-size: $size-icon-small;
  }
  &--is-medium {
    width: $size-icon-medium;
    min-width: $size-icon-medium;
    height: $size-icon-medium;
    font-size: $size-icon-medium;
  }
  &--is-large {
    width: $size-icon-large;
    min-width: $size-icon-large;
    height: $size-icon-large;
    font-size: $size-icon-large;
  }
  &--is-x-large {
    width: $size-icon-xlarge;
    min-width: $size-icon-xlarge;
    height: $size-icon-xlarge;
    font-size: $size-icon-xlarge;
  }

  // Color
  &--primary {
    svg {
      fill: $color-black;
    }
  }
  &--danger {
    svg {
      fill: $color-error;
    }
  }
  &--grey {
    svg {
      fill: $color-grey;
    }
  }
  &--grey-light {
    svg {
      fill: $color-grey-light;
    }
  }
  &--grey-dark {
    svg {
      fill: $color-grey-dark;
    }
  }
  &--white {
    svg {
      fill: $color-white;
    }
  }
  &--secondary {
    svg {
      fill: $color-blue;
    }
  }
  &--success {
    svg {
      fill: $color-green;
    }
  }
  &--info {
    svg {
      fill: $color-blue-lightest;
    }
  }
  &--none {
    svg {
    }
  }

  // Stroke Color
  &--stroke-primary {
    svg {
      stroke: $color-black;
    }
  }
  &--stroke-danger {
    svg {
      stroke: $color-error;
    }
  }
  &--stroke-grey {
    svg {
      stroke: $color-grey;
    }
  }
  &--stroke-grey-light {
    svg {
      stroke: $color-grey-light;
    }
  }
  &--stroke-grey-dark {
    svg {
      stroke: $color-grey-dark;
    }
  }
  &--stroke-white {
    svg {
      stroke: $color-white;
    }
  }
  &--stroke-secondary {
    svg {
      stroke: $color-blue;
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &--bg-primary {
    background-color: $color-yellow;
  }

  &--bg-secondary {
    background-color: $color-black;
  }

  &--bg-danger {
    background-color: $color-error;
  }

  &--bg-light {
    background-color: $color-greyish-blue;
  }

  // Border
  &--border {
    z-index: 1;
    padding: 8px;
    position: relative;
    &:after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;
      z-index: -1;
      border-radius: 50%;
      box-shadow: 0px 1px 5px 0px rgba($color-grey, 0.3);
    }
    &#{$root}--small {
      @include pui-icon-border(4px);
    }
    &#{$root}--small {
      @include pui-icon-border(8px);
    }
    &#{$root}--small {
      @include pui-icon-border(12px);
    }
  }
  &--rounded {
    border-radius: 50%;
    i {
      border-radius: 50%;
    }
    svg {
      border-radius: 50%;
    }
  }

  &--disabled {
    svg {
      fill: $color-grey;
    }
  }
}
</style>
