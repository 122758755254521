import $bus from '@/platformSettings/bus';

export default (msg, $store) => {
  const { rootState } = $store;

  const myUserId = rootState.userv2.user.user_id;

  switch (msg.confirm_status) {
    case 'ACCEPT':
      if ([msg.operator_id, msg.old_agent_id].includes(myUserId)) {
        $bus.$emit('TRANSFER_REQUEST_ACCEPTED', msg);
      }
      break;

    case 'DECLINE':
      if ([msg.operator_id].includes(myUserId)) {
        $bus.$emit('TRANSFER_REQUEST_DECLINED', msg);
      }
      break;

    default:
      break;
  }
};
