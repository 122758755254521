import Vue from 'vue';
import Vuex from 'vuex';
import $bus from '@/platformSettings/bus';
import { CHANNEL_TRAITS } from '@/platformSettings/channels';
import { RESOURCE_STATE, VALID_RESOURCE_STATES } from '@/constants';
import { DEFAULT_STYLE } from '@/webchat2/config';

Vue.use(Vuex);

const MUTATION = {
  SET_CHANNELS: 'SET_CHANNELS',
  SET_CHANNELS_STATE: 'SET_CHANNELS_STATE',
  CLEAR_CHANNELS: 'CLEAR_CHANNELS',
  SET_CURRENT_CHANNEL: 'SET_CURRENT_CHANNEL',
  SET_CURRENT_CHANNEL_STATE: 'SET_CURRENT_CHANNEL_STATE',
  CLEAR_CURRENT_CHANNEL: 'CLEAR_CURRENT_CHANNEL',
  UPDATE_CURRENT_CHANNEL: 'UPDATE_CURRENT_CHANNEL',
  UPDATE_CHANNEL_BY_ID: 'UPDATE_CHANNEL_BY_ID',
  UPDATE_CHANNEL_FIELD: 'UPDATE_CHANNEL_FIELD',
};

function enableGatewayAPI(channelId, forceEnable = false) {
  if (!channelId) throw Error('Missing channel ID');

  return $bus.$siniticApi.put(`gateway/${channelId}`, {
    params: {
      force: forceEnable,
    },
    data: {
      active: true,
    },
  });
}

function disableGatewayAPI(channelId, forceEnable = false) {
  if (!channelId) throw Error('Missing channel ID');

  return $bus.$siniticApi.put(`gateway/${channelId}`, {
    params: {
      force: forceEnable,
    },
    data: {
      active: false,
    },
  });
}

export default {
  namespaced: true,
  state: {
    /**
     * Store all channels
     */
    channels: [],
    channelsState: RESOURCE_STATE.IDLE,

    /**
     * Store current channel
     */
    currentChannel: null,
    currentChannelState: RESOURCE_STATE.IDLE,
  },
  getters: {
    channelIds: state => state.channels.map(channel => channel.id),
    channelsById: state => state.channels.reduce((byId, channel) => {
      byId[channel.id] = channel;
      return byId;
    }, {}),
    currentChannelIsUnhealthy: state => {
      if (state.currentChannel.healthy === false && state.currentChannel.health_checked_at) {
        return true;
      }
      return false;
    },
  },
  mutations: {
    [MUTATION.SET_CHANNELS_STATE](state, value) {
      if (!VALID_RESOURCE_STATES.includes(value)) {
        throw Error(`Invalid mutation payload: expected one of ${VALID_RESOURCE_STATES.join(', ')}`, value);
      }

      state.channelsState = value;
    },
    [MUTATION.SET_CHANNELS](state, channels = []) {
      if (!Array.isArray(channels)) {
        throw Error('Invalid mutation payload: expected an array', channels);
      }

      state.channels = channels;
    },
    [MUTATION.CLEAR_CHANNELS](state) {
      state.channels = [];
      state.channelsState = RESOURCE_STATE.IDLE;
    },

    [MUTATION.SET_CURRENT_CHANNEL_STATE](state, value) {
      if (!VALID_RESOURCE_STATES.includes(value)) {
        throw Error(`Invalid mutation payload: expected one of ${VALID_RESOURCE_STATES.join(', ')}`, value);
      }

      state.currentChannelState = value;
    },
    [MUTATION.SET_CURRENT_CHANNEL](state, channel = {}) {
      if (typeof channel !== 'object' || Array.isArray(channel)) {
        throw Error('Invalid mutation payload: expected an object', channel);
      }

      state.currentChannel = channel;
    },
    [MUTATION.CLEAR_CURRENT_CHANNEL](state) {
      state.currentChannel = null;
      state.currentChannelState = RESOURCE_STATE.IDLE;
    },
    [MUTATION.UPDATE_CURRENT_CHANNEL](state, updateData = {}) {
      if (typeof channel === 'object' && !Array.isArray(updateData)) {
        throw Error('Invalid mutation payload: expected an object', updateData);
      }

      state.currentChannel = {
        ...state.currentChannel,
        ...updateData,
      };
    },
    [MUTATION.UPDATE_CHANNEL_BY_ID](state, { channelId, data, quiet = true }) {
      const index = state.channels.findIndex(channel => channel.id === channelId);

      if (index > -1) {
        state.channels[index] = data;
      }

      if (!quiet) throw Error('Can\'t find channel with id:', channelId);
    },
    [MUTATION.UPDATE_CHANNEL_FIELD](state, { channelId, field, value }) {
      const index = state.channels.findIndex(channel => channel.id === channelId);

      if (index === -1) throw Error('Can\'t find channel with id:', channelId);

      if (!(field in state.channels[index])) throw Error('Unknown field:', field);

      Vue.set(state.channels[index], field, value);
    },
  },
  actions: {
    /**
     * Fetch all channels of a bot
     * @param {*} param0
     * @param {String} botId Bot ID
     * @returns
     */
    fetchBotChannels({ commit }, botId) {
      commit(MUTATION.SET_CHANNELS_STATE, RESOURCE_STATE.PENDING);

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get('livechatgateway/', {
            params: { bot_id: botId },
          })
          .then(response => {
            commit(MUTATION.SET_CHANNELS_STATE, RESOURCE_STATE.RESOLVED);

            const results = response.data.sort((a, b) => b.updated_at.localeCompare(a.updated_at));

            commit(MUTATION.SET_CHANNELS, results);

            resolve(results);
          })
          .catch(err => {
            commit(MUTATION.SET_CHANNELS_STATE, RESOURCE_STATE.REJECTED);

            reject(err);
          });
      });
    },

    clearBotChannels({ commit }) {
      commit(MUTATION.CLEAR_CHANNELS);
    },

    /**
     * Fetch channel by channel ID
     * @param {*} param0
     * @param {String?} channelId
     * @returns
     *
     * if channelId is not given, use the current channel id (if exists)
     */
    fetchChannel({ commit, state }, channelId = null) {
      commit(MUTATION.SET_CURRENT_CHANNEL_STATE, RESOURCE_STATE.PENDING);

      channelId = channelId || state?.currentChannel?.id;

      return $bus.$siniticApi
        .get(`livechatgateway/${channelId}`)
        .then(response => {
          commit(MUTATION.SET_CURRENT_CHANNEL_STATE, RESOURCE_STATE.RESOLVED);

          const channel = response.data;

          commit(MUTATION.SET_CURRENT_CHANNEL, channel);

          return channel;
        })
        .catch(err => {
          commit(MUTATION.SET_CURRENT_CHANNEL_STATE, RESOURCE_STATE.REJECTED);

          throw err;
        });
    },

    fetchCurrentChannel({ commit, state }, channelId = null) {
      channelId = channelId ?? state?.currentChannel?.id;

      return $bus.$siniticApi
        .get(`livechatgateway/${channelId}`)
        .then(response => {
          const channel = response.data;
          commit(MUTATION.SET_CURRENT_CHANNEL, channel);

          return channel;
        })
        .catch(err => {
          throw err;
        });
    },

    updateChannel({ commit }, { channelId, payload }) {
      return $bus.$siniticApi.put(`gateway/${channelId}`, {
        data: payload,
      }).then(res => {
        const updatedChannel = res.data;

        commit(MUTATION.UPDATE_CHANNEL_BY_ID, {
          channelId,
          data: updatedChannel,
          quiet: true,
        });

        return updatedChannel;
      })
        .catch(err => {
          throw err;
        });
    },

    updateCurrentChannel({ state, commit, dispatch }, payload) {
      if (!state.currentChannel?.id) throw Error('Channel does not exist');

      return dispatch('updateChannel', {
        channelId: state.currentChannel.id,
        payload,
      }).then(updatedChannel => {
        commit(MUTATION.SET_CURRENT_CHANNEL, updatedChannel);

        return updatedChannel;
      }).catch(err => {
        throw err;
      });
    },

    updateCurrentWebchatChannelSettings({ state, dispatch }, settings) {
      if (state.currentChannel.type !== CHANNEL_TRAITS.WEBCHAT.name) {
        throw Error('Current channel is not a Webchat channel.');
      }

      const { webchatv2_settings } = state.currentChannel.config;

      return dispatch('updateCurrentChannel', {
        webchatv2_settings: {
          ...webchatv2_settings,
          icon_id: webchatv2_settings.icon_id || '',
          color: webchatv2_settings.color || DEFAULT_STYLE.BRANDING_COLOR,
          color_btn: webchatv2_settings.color_btn || DEFAULT_STYLE.MAIN_BUTTON_COLOR,
          color_quick_reply: webchatv2_settings.color_quick_reply || DEFAULT_STYLE.QUICK_REPLY_COLOR,
          color_notification: webchatv2_settings.color_notification || DEFAULT_STYLE.NOTIFICATION_COLOR,
          position: webchatv2_settings.position || DEFAULT_STYLE.POSITION,
          enable_emojis: !!(webchatv2_settings.enable_emojis ?? DEFAULT_STYLE.ENABLE_EMOJIS),
          mobile_width: webchatv2_settings.mobile_width || DEFAULT_STYLE.MOBILE_WIDTH,
          mobile_height: webchatv2_settings.mobile_height || DEFAULT_STYLE.MOBILE_HEIGHT,

          ...settings,
        },
      });
    },

    enableChannel({ commit }, { channelId, forceEnable = false }) {
      return enableGatewayAPI(channelId, forceEnable).then(res => {
        commit(MUTATION.UPDATE_CHANNEL_BY_ID, { channelId, data: res.data });
      }).catch(error => {
        console.error(error);
        throw error;
      });
    },

    enableCurrentChannel({ state, commit }, forceEnable = false) {
      const channelId = state.currentChannel?.id;
      if (!channelId) throw Error('No current channel');

      return enableGatewayAPI(channelId, forceEnable).then(res => {
        commit(MUTATION.UPDATE_CURRENT_CHANNEL, res.data);
      }).catch(error => {
        console.error(error);
        throw error;
      });
    },

    disableChannel({ commit }, channelId) {
      return disableGatewayAPI(channelId).then(res => {
        commit(MUTATION.UPDATE_CHANNEL_BY_ID, { channelId, data: res.data });
      })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    disableCurrentChannel({ state, commit }) {
      const channelId = state.currentChannel?.id;
      if (!channelId) throw Error('No current channel');

      return disableGatewayAPI(channelId).then(res => {
        commit(MUTATION.UPDATE_CURRENT_CHANNEL, res.data);
      })
        .catch(error => {
          console.error(error);
          throw error;
        });
    },

    clearCurrentChannel({ commit }) {
      commit(MUTATION.CLEAR_CURRENT_CHANNEL);
    },

    reissueCurrentChannelSecret({ state, commit }) {
      return $bus.$siniticApi.post(`gateway/${state.currentChannel.id}/secret`)
        .then(res => {
          console.log(res.data);
          const { gateway_secret: newSecret } = res.data;

          commit(MUTATION.UPDATE_CURRENT_CHANNEL, {
            secret: newSecret,
          });

          return newSecret;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteChannel({ state, getters }, channelId) {
      return $bus.$siniticApi.delete(`gateway/${channelId}`)
        .then(res => {
          console.log(res);

          const index = state.channels.findIndex(channel => channel.id === channelId);

          if (index > -1) {
            delete state.channels[index];
          }

          return res;
        })
        .catch(err => {
          throw err;
        });
    },

    deleteCurrentChannel({ state, dispatch }) {
      if (!state.currentChannel?.id) throw Error('Channel does not exist');

      return dispatch('deleteChannel', state.currentChannel.id)
        .then(res => {
          dispatch('clearCurrentChannel');

          return res;
        }).catch(err => {
          throw err;
        });
    },
  },
};
