var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "textarea",
    _vm._g(
      _vm._b(
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.val,
              expression: "val"
            }
          ],
          ref: "textarea",
          style: _vm.computedStyles,
          attrs: { dir: "auto", maxlength: _vm.maxlength },
          domProps: { value: _vm.val },
          on: {
            focus: _vm.resize,
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.val = $event.target.value
              },
              _vm.resize
            ],
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onEnter($event)
            }
          }
        },
        "textarea",
        _vm.$attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }