let perf = {};
let perfByCategory = {};

const makeId = l => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < l; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

const end = key => {
  if (!perf[key]) {
    console.warn('Performance debugger could not find key', key);
  } else {
    perf[key].end = window.performance.now();
    perf[key].time = perf[key].end - perf[key].start;
  }
};

const start = category => {
  const key = makeId(12);
  perf[key] = {};
  perf[key].start = window.performance.now();
  perf[key].category = category;

  if (perfByCategory[category] == null) {
    perfByCategory[category] = [];
  }
  perfByCategory[category].push(perf[key]);
  return {
    end: () => {
      end(key);
    },
  };
};

const report = () => {
  console.log('-------------------------------');
  console.log('/      PERFORMANCE REPORT      /');
  console.log('-------------------------------');

  const perfReport = {};
  for (const cat in perfByCategory) {
    const perfs = perfByCategory[cat];
    const totalTime = perfs.reduce((acc, item) => acc + item.time, 0);
    perfReport[cat] = {
      'total time': totalTime,
      'avg time': totalTime / perfs.length,
      count: perfs.length,
    };
  }
  console.table(perfReport);
};

const reset = () => {
  perf = {};
  perfByCategory = {};
};

export default {
  start,
  end,
  report,
  reset,
};
