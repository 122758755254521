import { ACTIONS } from '@/webchat2/config/types';

export default (msg, $store) => {
  const { dispatch } = $store;

  dispatch('markMessage', {
    cId: msg.conversation_id,
    message: msg,
    markType: ACTIONS.MESSAGE_READ,
  });
};
