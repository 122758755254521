var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pui-table__view" }, [
    _c(
      "div",
      {
        ref: "table",
        staticClass: "pui-table",
        class: {
          "pui-table--horizontal-scroll": _vm.horizontallyScrollable,
          "pui-table--horizontal-scrolled": _vm.scrolled,
          "pui-table--has-select-all": _vm.hasSelectAll
        }
      },
      [
        _vm._t("toolbar"),
        _vm._v(" "),
        _c("div", { staticClass: "pui-table__container" }, [
          _c(
            "div",
            { staticClass: "pui-table__head" },
            [
              _vm.$slots["customHeaders"]
                ? _c(
                    "PUITableRow",
                    { attrs: { type: "header", clickable: "" } },
                    [_vm._t("customHeaders")],
                    2
                  )
                : _c(
                    "PUITableRow",
                    { attrs: { type: "header", clickable: "" } },
                    [
                      _vm.hasSelectAll
                        ? _c(
                            "PUITableCell",
                            {
                              attrs: {
                                type: "header",
                                checkbox: "",
                                topBorder: _vm.topBorder
                              }
                            },
                            [
                              _c("PUISelectAllIndicator", {
                                attrs: {
                                  options: _vm.itemsId,
                                  checkedOptions: _vm.selectedList
                                },
                                on: {
                                  onSelectAll: _vm.selectAll,
                                  onUnselectAll: _vm.unSelectAll
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.headers, function(col, i) {
                        return _c(
                          "PUITableCell",
                          {
                            key: i,
                            attrs: {
                              sticky: i === 0 && _vm.fixedFirstColumn,
                              type: "header",
                              topBorder: _vm.topBorder
                            }
                          },
                          [
                            _vm._v(
                              "\n            " + _vm._s(col) + "\n          "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
            ],
            1
          ),
          _vm._v(" "),
          _vm.items.length > 0 && !_vm.isLoading
            ? _c(
                "div",
                {
                  staticClass: "pui-table__body",
                  class: { "pui-table__body--loading": _vm.isLoading }
                },
                [_vm._t("default")],
                2
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.isLoading
          ? _c("div", { staticClass: "pui-table-loading" }, [
              _c(
                "div",
                { staticClass: "pui-table-loading__wrapper" },
                [_c("PUILoader")],
                1
              )
            ])
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _vm.items.length > 0 && _vm.showPagination
      ? _c("div", { staticClass: "pui-table__pagination" }, [
          _c("div", { staticClass: "pui-table__pagination__per-page" }, [
            _vm.customizableItemsPerPage
              ? _c(
                  "div",
                  { staticClass: "pui-table__pagination__per-page__container" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "pui-table__pagination__per-page__textfield"
                      },
                      [
                        _c("PUINumberInput", {
                          attrs: { min: 1 },
                          model: {
                            value: _vm.perPage,
                            callback: function($$v) {
                              _vm.perPage = $$v
                            },
                            expression: "perPage"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "pui-table__pagination__per-page__text" },
                      [_vm._v(_vm._s(_vm.itemsPerPageText))]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pui-table__pagination__page-numbers" },
            [
              _vm.pagination.pages
                ? _c("Paginate", {
                    attrs: {
                      pageCount: _vm.pagination.pages,
                      clickHandler: _vm.clickCallback,
                      prevText: _vm.prevText,
                      nextText: _vm.nextText,
                      containerClass:
                        "pui-table__pagination__page-numbers__list"
                    },
                    model: {
                      value: _vm.pagination.page,
                      callback: function($$v) {
                        _vm.$set(_vm.pagination, "page", $$v)
                      },
                      expression: "pagination.page"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pui-table__pagination__text" },
            [_vm._t("paginationText")],
            2
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showEmptyState
      ? _c(
          "div",
          { staticClass: "pui-table__no-results" },
          [[_vm._t("emptyState")]],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }