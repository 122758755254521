import Vue from 'vue';
import Vuex from 'vuex';

import $bus from '@/platformSettings/bus';

import axios from 'axios';

const { CancelToken } = axios;

Vue.use(Vuex);

const VALID_STATUS = ['idle', 'pending', 'resolved', 'rejected'];

const checkStatus = status => {
  if (!VALID_STATUS.includes(status)) throw TypeError(`invalid status: ${status}, allowed values: ${VALID_STATUS.join(', ')}.`);
};

let cancelGetHumans;

export default {
  namespaced: true,
  state: {
    bots: [],
    botsLoading: false,
    botsAreFetched: false,
    currentBotId: null,

    channels: [],

    /* Single human */
    currentHumanStatus: 'idle',
    currentHuman: null,
    humanError: null,

    /* Human list */
    humansStatus: 'idle',
    humans: [],
    humanFilter: {
      type: 'known',
      channel_ids: [],
      tags: [],
      keyword: null,
    },
    pagination: {
      per_page: 10,
      pages: 1,
      page: 1,
      totalItems: 0,
    },
    humansError: null,

    authMethods: [],
    tags: [],
    isFiltering: false,
  },
  getters: {
    botsById: state => {
      const byId = {};

      for (let i = 0; i < state.bots.length; i += 1) {
        const bot = state.bots[i];
        byId[bot.id] = bot;
      }

      return byId;
    },
    currentBot: (state, getters) => getters.botsById[state.currentBotId],
    tagsById: state => {
      const byId = {};

      for (let i = 0; i < state.tags.length; i += 1) {
        const tag = state.tags[i];
        byId[tag.id] = tag;
      }

      return byId;
    },
  },
  mutations: {
    _setBotsLoading(state, bool) {
      state.botsLoading = bool;
    },
    _setBotsAreFetched(state, bool) {
      state.botsAreFetched = bool;
    },
    _setBots(state, bots) {
      state.bots = bots;
    },
    _clearBots(state) {
      state.bots = [];
    },
    _setCurrentBotId(state, botId) {
      state.currentBotId = botId;
    },
    _setChannels(state, channels) {
      state.channels = channels;
    },
    _clearChannels(state) {
      state.channels = [];
    },

    _setCurrentHumanStatus(state, status) {
      checkStatus(status);
      state.currentHumanStatus = status;
    },
    _setCurrentHuman(state, human) {
      state.currentHuman = Object.freeze(human);
    },
    _setHumanError(state, error) {
      state.humanError = error;
    },

    _setHumansStatus(state, status) {
      checkStatus(status);
      state.humansStatus = status;
    },
    _setHumans(state, humans) {
      state.humans = humans;
    },
    _setHumanFilter(state, {
      type, channel_ids, tags, keyword,
    }) {
      state.humanFilter = {
        type, channel_ids, tags, keyword,
      };
    },
    _resetHumanFilter(state) {
      state.humanFilter = {
        type: 'known',
        channel_ids: [],
        tags: [],
        keyword: null,
      };
    },
    _setPagination(state, {
      per_page, pages, page, totalItems,
    }) {
      state.pagination = {
        per_page, pages, page, totalItems,
      };
    },
    _setHumansError(state, error) {
      state.humansError = error;
    },

    _setAuthMethods(state, authMethods) {
      state.authMethods = authMethods;
    },
    _setTags(state, tags) {
      state.tags = tags;
    },
    _setIsFiltering(state, value) {
      state.isFiltering = value;
    },
  },
  actions: {
    getBots({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        if (!companyId) {
          return reject(new Error('Missing company id'));
        }

        commit('_setBotsLoading', true);
        commit('_setBotsAreFetched', false);

        $bus.$siniticApi
          .get(`/bot/v2/${companyId}/`)
          .then(response => {
            const bots = response?.data?.sort((a, b) => (a.updated_at > b.updated_at ? 1 : -1));

            commit('_setBots', bots);
            commit('_setBotsLoading', false);
            commit('_setBotsAreFetched', true);
            resolve(bots);
          })
          .catch(error => {
            commit('_setBots', []);
            commit('_setBotsLoading', false);
            console.error(error);
            reject(error);
          });
      });
    },
    clearBots({ commit }) {
      commit('_clearBots');
      commit('_setBotsLoading', false);
      commit('_setBotsAreFetched', false);
    },
    setCurrentBotId({ commit }, botId) {
      commit('_setCurrentBotId', botId);
    },

    getChannels({ commit }, botId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get('gateway/', {
            params: {
              bot_id: botId,
            },
          })
          .then(resp => {
            const channels = resp.data;
            commit('_setChannels', channels);
            resolve(channels);
          })
          .catch(err => {
            console.error(err);
            reject(err);
          });
      });
    },
    clearChannels({ commit }) {
      commit('_clearChannels');
    },

    setCurrentHuman({ commit, dispatch }, { companyId, botId, humanId }) {
      commit('_setHumanError', null);

      return new Promise((resolve, reject) => {
        if (!humanId || !companyId || !botId) {
          commit('_setCurrentHuman', null);
          commit('_setCurrentHumanStatus', 'idle');
          reject();
          return;
        }

        commit('_setCurrentHumanStatus', 'pending');

        dispatch('getHuman', {
          companyId,
          botId,
          humanId,
        })
          .then(newHuman => {
            commit('_setCurrentHumanStatus', 'resolved');
            commit('_setCurrentHuman', newHuman);
            resolve();
          })
          .catch(error => {
            commit('_setCurrentHumanStatus', 'rejected');
            commit('_setHumanError', error);

            reject(error);
          });
      });
    },
    clearCurrentHuman({ commit }) {
      commit('_setCurrentHumanStatus', 'idle');
      commit('_setCurrentHuman', null);
    },

    getHuman(_, { companyId, botId, humanId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi.get(`gateway/${companyId}/${botId}/crm/human/${humanId}`)
          .then(response => {
            const human = response.data;
            return resolve(human);
          })
          .catch(err => {
            console.error(err);
            reject(err);
          });
      });
    },

    getHumans({ state, commit }, { companyId, botId }) {
      if (!companyId || !botId) throw TypeError('Missing companyId or botId');

      commit('_setHumansStatus', 'pending');

      const { humanFilter, pagination } = state;

      const buildTagFilters = tags => {
        const tagFilter = [];

        tags.forEach(tag => {
          if (Array.isArray(tag.value)) {
            tagFilter.push(...tag.value.map(val => ({ id: tag.id, value: val })));
          } else if (tag.value) {
            tagFilter.push({ id: tag.id, value: tag.value });
          } else {
            tagFilter.push({ id: tag.id });
          }
        });

        return tagFilter;
      };

      const filters = {
        ...(humanFilter.keyword && { keyword: humanFilter.keyword.trim() }),
        ...(humanFilter.type && { type: humanFilter.type.trim() }),
        ...(humanFilter.channel_ids.length > 0 && { channel_ids: humanFilter.channel_ids }),
        ...(humanFilter.tags.length > 0 && { tags: buildTagFilters(humanFilter.tags) }),
      };

      if (filters.keyword || filters.channel_ids || filters.tags) {
        commit('_setIsFiltering', true);
      } else {
        commit('_setIsFiltering', false);
      }

      if (cancelGetHumans) {
        cancelGetHumans();
      }

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`gateway/${companyId}/${botId}/crm/humans`, {
            data: {
              page: pagination.page >= 1 ? pagination.page : 1,
              items_per_page: pagination.per_page,
              ...filters,
            },
            cancelToken: new CancelToken(c => {
              cancelGetHumans = c;
            }),
          })
          .then(res => {
            commit('_setHumansStatus', 'resolved');

            const { items, n_pages, page } = res.data;
            commit('_setHumans', items);
            commit('_setPagination', {
              ...state.pagination,
              pages: n_pages,
              page,
            });
            resolve(items);
          })
          .catch(err => {
            if (axios.isCancel(err)) {
              console.log('Request canceled', err.message);
            } else {
              console.error(err);
              commit('_setHumansStatus', 'rejected');
              commit('_setHumansError', err);
              reject(err);
            }
          });
      });
    },

    setHumans({ commit }, value) {
      commit('_setHumans', value);
    },

    setIsFiltering({ commit }, value) {
      commit('_setIsFiltering', value);
    },
    clearHumans({ commit }) {
      commit('_setHumans', []);
    },
    setHumanFilter({ commit }, value) {
      commit('_setHumanFilter', value);
    },
    resetHumanFilter({ commit }) {
      commit('_setIsFiltering', false);
      commit('_resetHumanFilter');
    },
    getAuthMethods({ commit }, { companyId, botId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/crm/auth_methods`)
          .then(response => {
            const results = response.data;
            if (results.length === 0) {
              return resolve();
            }
            commit('_setAuthMethods', response.data.items);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    clearAuthMethods({ commit }) {
      commit('_setAuthMethods', []);
    },
    getTags({ commit }, { companyId, botId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`/livechatgateway/${companyId}/${botId}/crm/tags`)
          .then(response => {
            const results = response.data;
            if (results.length === 0) {
              return resolve();
            }
            commit('_setTags', response.data.items);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    clearTags({ commit }) {
      commit('_setTags', []);
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
