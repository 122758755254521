import { createMapping, F } from '@/utils.js';

export const Tours = createMapping({
  LIVECHAT_DEFAULT_BOT: {
    key: 'LIVECHAT_DEFAULT_BOT',
    importTour: () => import('./Build/livechat-default-bot.js'),
  },
});

export const TOUR_KEY = Tours |> F.objMap((key, value) => [key, value.key]);
