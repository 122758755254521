import { createEnum } from '@/utils.js';

export const MEGABYTES = 1024 * 1024;
export const KILOBYTES = 1024;

export const NOUN_TYPE = createEnum({
  PROPER_NOUN: 'proper_noun',
  REGEX: 'regular_expression',
});

export const RESOURCE_STATE = createEnum({
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
});
export const VALID_RESOURCE_STATES = Object.values(RESOURCE_STATE);

export const CONFIG_STATES = createEnum({
  DEFAULT: 'default',
  SUCCESS: 'success',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
});
export const VALID_CONFIG_STATES = Object.values(CONFIG_STATES);

export const PAYWALL_TYPE = createEnum({
  LIMIT_CAPACITY: 'limit_capacity',
  LIMIT_TEAM: 'limit_team',
  LIMIT_BOT: 'limit_bot',
});

// Plugin
const Constants = {
  install(Vue, options) {
    Vue.prototype.$const = {
      KILOBYTES,
      MEGABYTES,
      NOUN_TYPE,
      RESOURCE_STATE,
      CONFIG_STATES,
      VALID_RESOURCE_STATES,
      PAYWALL_TYPE,
    };
  },
};

export default Constants;

export const UTM_PARAMS = createEnum({
  CAMPAIGN: 'utm_campaign',
  SOURCE: 'utm_source',
  MEDIUM: 'utm_medium',
  TERM: 'utm_term',
  CONTENT: 'utm_content',
});
export const VALID_UTM_PARAMS = Object.values(UTM_PARAMS);
