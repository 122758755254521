import Vue from 'vue';
import Vuex from 'vuex';

import $bus from '@/platformSettings/bus';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    currentGroup: null,
    currentCase: null,
    currentRespondent: null,
    groupLoading: null,
    groupError: null,
    groups: [],
    cases: [],
    respondents: [],
    agents: [],
  },
  mutations: {
    _setCurrentGroup(state, group) {
      state.currentGroup = Object.freeze(group);
    },
    _setCurrentCase(state, caseObject) {
      state.currentCase = Object.freeze(caseObject);
    },
    _setCurrentRespondent(state, respondent) {
      state.currentRespondent = Object.freeze(respondent);
    },
    _setGroupLoading(state, bool) {
      state.groupLoading = bool;
    },
    _setGroupError(state, error) {
      state.groupError = error;
    },
    _setGroups(state, groups) {
      state.groups = groups;
    },
    _setCases(state, cases) {
      state.cases = cases;
    },
    _setRespondents(state, respondents) {
      state.respondents = respondents;
    },
    _setAgents(state, agents) {
      state.agents = agents;
    },
  },
  actions: {
    setCurrentGroup({ commit, dispatch, state }, { companyId, groupId }) {
      commit('_setGroupError', null);
      commit('_setGroupLoading', true);
      return new Promise((resolve, reject) => {
        if (groupId === null) {
          commit('_setCurrentGroup', null);
          commit('_setGroupLoading', false);
          return resolve();
        }

        if (companyId === null) {
          commit('_setGroupLoading', false);
          return resolve();
        }

        dispatch('getGroup', {
          companyId,
          groupId,
        })
          .then(newGroup => {
            if (newGroup === state.currentGroup) {
              commit('_setGroupLoading', false);
              return resolve();
            }

            commit('_setCurrentGroup', newGroup);
            commit('_setGroupLoading', false);

            return resolve(newGroup);
          })
          .catch(error => {
            if (error.code === 404) {
              commit('_setHumanError', 'This group does not exist');
            } else if (error.code === 403) {
              commit('_setHumanError', 'You cannot access this group');
            }
            commit('_setGroupLoading', false);
            return reject(error);
          });
      });
    },
    getGroup(_, { companyId, groupId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`case/${companyId}/case_model/${groupId}`)
          .then(response => {
            const group = response.data;
            if (!group) {
              const err = {};
              err.code = 404;
              return reject(err);
            }
            return resolve(group);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    getGroups({ commit }, { companyId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`case/case_model?company_id=${companyId}`)
          .then(response => {
            const results = response.data.length ? response.data : [];
            commit('_setGroups', results);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    getRespondents({ commit }, { groupId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`case/institute?casem_id=${groupId}`)
          .then(response => {
            const results = response.data;
            commit('_setRespondents', results.length ? results : []);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    getAgents({ commit }, { companyId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`gateway/agent/company/${companyId}/agents`)
          .then(response => {
            const results = Object.keys(response.data).length ? response.data : [];

            const agents = Object.keys(results).map(val => ({
              id: val,
              ...results[val],
            }));
            commit('_setAgents', agents);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    clearRespondents({ commit }) {
      commit('_setRespondents', []);
    },
    setCurrentCase({ commit, dispatch, state }, { caseId }) {
      return new Promise((resolve, reject) => {
        if (caseId === null) {
          commit('_setCurrentCase', null);
          return resolve();
        }
        dispatch('getCase', {
          caseId,
        })
          .then(newCase => {
            if (newCase === state.currentCase) {
              return resolve();
            }

            commit('_setCurrentCase', newCase);

            return resolve(newCase);
          })
          .catch(error => reject(error));
      });
    },
    setCurrentRespondent({ commit, dispatch, state }, { respondentId }) {
      return new Promise((resolve, reject) => {
        if (respondentId === null) {
          commit('_setCurrentRespondent', null);
          return resolve();
        }
        dispatch('getRespondent', {
          respondentId,
        })
          .then(respondent => {
            if (respondent === state.currentRespondent) {
              return resolve();
            }

            commit('_setCurrentRespondent', respondent);

            return resolve(respondent);
          })
          .catch(error => reject(error));
      });
    },
    getRespondent(_, { respondentId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`case/institute/${respondentId}`)
          .then(response => {
            const respondent = response.data;
            if (!respondent) {
              const err = {};
              err.code = 404;
              return reject(err);
            }
            return resolve(respondent);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    getCase(_, { caseId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`case/case/${caseId}`)
          .then(response => {
            const caseData = response.data;
            if (!caseData) {
              const err = {};
              err.code = 404;
              return reject(err);
            }
            return resolve(caseData);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    // This API is deprecated
    // getCases({ commit }, { groupId }) {
    //   commit('_setCases', []);
    //   return new Promise((resolve, reject) => {
    //     $bus.$siniticApi
    //       .get(`case/case?casem_id=${groupId}`)
    //       .then(response => {
    //         const results = response.data;
    //         commit('_setCases', results.length ? results : []);
    //         return resolve(results);
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         return reject(error);
    //       });
    //   });
    // },
  },
  strict: process.env.NODE_ENV !== 'production',
};
