export default (msg, $store) => {
  const { getters, commit, dispatch } = $store;

  // Deactivate conversation and push CONVERSATION_CLOSED message.
  commit('_setConversationClosed', {
    message: msg,
    agentsIdMap: getters.allAgents,
  });
  // Set a timer for automatically archive conversation
  dispatch('setArchiveConversationTimer', msg);
};
