<template>
  <div
    :class="{
      'pui-table__body__cell': type === 'body',
      'pui-table__head__cell': type === 'header',
      'pui-table__cell--checkbox': checkbox,
      'pui-table__cell--sticky': sticky,
      'pui-table__head__cell__no-top-border': !topBorder,
    }"
    class="pui-table__cell"
  >
    <div ref="editableField">
      <PUITextField
        v-if="editable && editing"
        v-model.trim="inputValue"
        autofocus
        theme="underlined"
        @keydown.enter="save"
        v-on="inputListeners"
      />
      <!-- @slot Use this slot to place the cell (column) content -->
      <div
        v-else-if="editable"
        v-on="inputListeners"
        @click.stop="showEditor"
      >
        <span v-if="text">{{ text }}</span>
        <slot v-else>
          <span class="pui-table__cell__empty-state">-</span>
        </slot>
      </div>
      <div
        v-else
        v-on="inputListeners"
      >
        <span v-if="text">{{ text }}</span>
        <slot v-else>
          <span class="pui-table__cell__empty-state">-</span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import PUITextField from '@/components/PUI/Controls/TextField';

export default {
  name: 'PUITableCell',
  components: {
    PUITextField,
  },
  props: {
    /**
     * Defines the type of the row
     *  @v-model
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * Defines the type of the row
     *  @values body, header
     */
    type: {
      type: String,
      default: 'body',
      validator: type => ['body', 'header'].includes(type),
    },
    /**
     * Specifies whether the column should stick to the
     * same position on an horizontal scrollable table
     */
    sticky: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
    topBorder: {
      type: Boolean,
      default: true,
    },
    /**
     * Defines the type of the row
     *  @values body, header
     */
    checkbox: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows the column to be editable
     *  @values body, header
     */
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    inputListeners() {
      const vm = this;
      // `Object.assign` merges objects together to form a new object
      return {

        // We add all the listeners from the parent
        ...this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.

      };
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    slotDefault() {
      return this.$slots;
    },
  },
  beforeMount() {
    if (this.editable) {
      document.addEventListener('click', this.documentClick);
    }
  },
  beforeDestroy() {
    if (this.editable) {
      document.removeEventListener('click', this.documentClick);
    }
  },
  methods: {
    documentClick(e) {
      if (this.editing) {
        try {
          const el = this.$refs.editableField;
          const { target } = e;
          if (el !== target && !el.contains(target)) {
            this.save();
          }
        } catch (error) {
          // does nothing

        }
      }
    },
    showEditor() {
      if (this.editable) {
        this.editing = true;
      }
    },
    save() {
      if (this.editable) {
        this.editing = false;
        this.$emit('save');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pui-table__cell {
  position: relative;
}
</style>

<docs>
  This is a single cell table component, it allows to align items in a table cell

  ## Usage

  Header row:

  ```jsx
  <PUITableCell type="header">
    // row content
  </PUITableCell>
  ```

  body row:

  ```jsx
  <PUITableCell type="body">
    // row content
  </PUITableCell>

  // or

  <PUITableCell>
    // row content
  </PUITableCell>
  ```

</docs>
