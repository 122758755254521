import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocales from '@/lang/locales/translations-en.json';
import { supportedLanguages } from '@/lang/languages';

export const loadedLanguages = ['en'];

Vue.use(VueI18n);

const isPhraseAppEnabled = () => window.$SiniticConfig && window.$SiniticConfig.phrase && window.$SiniticConfig.phrase.enabled;

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  availableLocales: supportedLanguages,
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  missing: (locale, key, vm, values) => {
    if (isPhraseAppEnabled()) {
      return `{{__phrase_${key}__}}`;
    }

    if (process.env.NODE_ENV === 'production') {
      return '';
    }
    return `[[${key}]]`;
  },
  messages: {
    en: isPhraseAppEnabled() ? {} : enLocales,
  },
});

export const $tobj = (key, fields) => {
  const output = {};
  for (const lang of loadedLanguages) {
    output[lang] = i18n.t(key, lang, fields);
  }

  return output;
};

Vue.mixin({
  methods: {
    $tobj,
  },
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  return lang;
}

export async function loadLanguageAsync(lang, setLangAfterLoaded = true) {
  if (lang == null || lang === 'undefined') {
    lang = 'en';
  }

  const callbackFunc = setLangAfterLoaded ? setI18nLanguage : () => i18n.locale;

  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(callbackFunc(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(callbackFunc(lang));
  }

  // If the language hasn't been loaded yet
  try {
    const messages = await import(
      /* webpackChunkName: "translations-[request]" */ `@/lang/locales/translations-${lang}.json`
    );
    i18n.setLocaleMessage(lang, isPhraseAppEnabled() ? {} : messages.default);
    loadedLanguages.push(lang);
    return callbackFunc(lang);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

export default i18n;
