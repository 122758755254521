import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '@/lang/i18n';
import $bus from '@/platformSettings/bus';
import tools from '@/platformSettings/tools';
import groupBy from 'lodash/groupBy';
import isoCountries from '@/data/iso-3316-2.json';
import { CHANNEL_TRAITS_BY_NAME } from '@/platformSettings/channels';

const eventsDef = tools.block_types.properties.blocks.find(p => p.icon === 'events');

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    series: [],
    ratingOptions: [
      {
        text: i18n.t('analytics:dynamic.rating.good'),
        value: 'GOOD',
      },
      {
        text: i18n.t('analytics:dynamic.rating.bad'),
        value: 'BAD',
      },
      {
        text: i18n.t('analytics:dynamic.rating.not_given'),
        value: 'NOT_GIVEN',
      },
    ],
    isAbandonedOptions: [
      {
        text: i18n.t('analytics:dynamic.is_abandoned.yes'),
        value: true,
      },
      {
        text: i18n.t('analytics:dynamic.is_abandoned.no'),
        value: false,
      },
    ],
    gatewayOptions: [],
    eventTypeOptions: eventsDef.properties
      .filter(item => item.group === 'default')
      .map(item => ({
        text: item.title,
        value: item.type,
        icon: item.icon,
        imgSrc: 'icon',
      })),
    countryOptions: Object.entries(isoCountries)
      .map(([iso, countryData]) => ({
        text: i18n.t(`platform:country.${iso}`),
        value: iso,
      })),
    agentTeamOptions: [],
    caseModelOptions: [],
    agentOptions: [],
  },
  getters: {
    groupedSeries(state) {
      return groupBy(state.series, 'resource');
    },
  },
  mutations: {
    setSeries(state, series) {
      state.series = series;
    },
    insertReport(state, report) {
      state.reports.push(report);
    },
    setGatewayOptions(state, gateways) {
      state.gatewayOptions = gateways;
    },
    setAgentTeamOptions(state, agentTeams) {
      state.agentTeamOptions = agentTeams;
    },
    setAgentOptions(state, agents) {
      state.agentOptions = agents;
    },
    setCaseModelOptions(state, caseModels) {
      state.caseModelOptions = caseModels;
    },
    setCheckpointNames(state, checkpointNames) {
      state.checkpointNames = checkpointNames;
    },
  },
  actions: {
    getSeries({ commit }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get('stat/available_series')
          .then(response => {
            let { data: series } = response;
            if (series.length === 0) {
              return resolve();
            }
            series = series.map(item => ({
              ...item,
              text: i18n.t(`analytics:series.${item.name}`),
            }));
            commit('setSeries', series);
            return resolve(series);
          })
          .catch(error => {
            console.error(error);
            return reject(error);
          });
      });
    },
    getCheckpointNames({ commit }, companyId, resource) {
      const payload = {
        date_begin: null,
        date_end: null,
        relative_date: 'MONTH_TO_DATE',
        timezone: 'Etc/UTC',
        time_grain: 'daily',
        filters: { bot_id: { is_inclusive: true, values: [] }, checkpoint_name: { is_inclusive: true, values: [] } },
        group_by: 'checkpoint_name',
        series: ['n_checkpoints'],
      };
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`stat/${companyId}/get_chart_data:${resource}`, {
            data: payload,
          })
          .then(response => {
            delete response.data.groups.__nogroup__;
            const checkpoints = Object.entries(response.data.groups).map(([key, _]) => (key));
            commit('setCheckpointNames', checkpoints);
            resolve(checkpoints);
          })
          .catch(error => {
            console.error(error);
            return reject(error);
          });
      });
    },
    getReports(_, companyId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`stat/${companyId}/reports`)
          .then(response => resolve(response.data))
          .catch(error => {
            console.error(error);
            return reject(error);
          });
      });
    },
    getCompanyGateways({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`gateway/list_all_gateway/${companyId}`)
          .then(response => {
            const { data } = response;

            const options = data.map(gateway => ({
              text: `${gateway.bot_name} / ${gateway.name}`,
              value: gateway.id,
              icon: CHANNEL_TRAITS_BY_NAME?.[gateway.type]?.iconFileName,
              imgSrc: 'icon',
              raw: gateway,
            }));
            commit('setGatewayOptions', options);
            return resolve(options);
          })
          .catch(error => {
            console.error(error);
            return reject(error);
          });
      });
    },
    getCompanyAgentTeams({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`gateway/agent/company/${companyId}/teams`)
          .then(response => {
            const { data } = response;
            const options = data.map(team => ({
              text: team.name?.['en'],
              value: team.ateam_id,
              users: team.users,
              imgSrc: team.avatar_id,
            }));

            commit('setAgentTeamOptions', options);
            return resolve(options);
          })
          .catch(error => {
            console.error(error);
            return reject(error);
          });
      });
    },
    getCompanyAgents({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`gateway/agent/company/${companyId}/agents`)
          .then(response => {
            const { data } = response;

            const options = Object.values(data).map((agent, index) => ({
              text: agent.profile.fullname,
              value: Object.keys(data)[index],
              imgSrc: agent.profile.profile_pic_url,
            }));

            commit('setAgentOptions', options);
            return resolve(options);
          })
          .catch(error => {
            console.error(error);
            return reject(error);
          });
      });
    },
    getCaseModels({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get('case/case_model', { params: { company_id: companyId } })
          .then(response => {
            const { data } = response;

            const options = data.map(caseModel => ({
              text: caseModel.name,
              value: caseModel.id,
            }));
            commit('setCaseModelOptions', options);
            return resolve(options);
          })
          .catch(error => {
            console.error(error);
            return reject(error);
          });
      });
    },
    createReport(_, { companyId, data }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`stat/${companyId}/report`, { data })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error(error);
            reject(error);
          });
      });
    },
    updateReport(_, { companyId, reportId, data }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`stat/${companyId}/report/${reportId}`, { data })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error(error);
            reject(error);
          });
      });
    },
    deleteReport(_, { companyId, reportId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`stat/${companyId}/report/${reportId}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            console.error(error);
            reject(error);
          });
      });
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
