import Vue from 'vue';
import Vuex from 'vuex';
import { readVersionAsync } from '@/helpers/versionHelper';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    version: {
      build_time: '',
      git_sha: '',
      git_tag: '',
    },
  },
  getters: {},
  mutations: {
    setVersion(state, version) {
      state.version = version;
    },
  },
  actions: {
    async setCurrentVersion({ commit }) {
      try {
        const versionInfo = await readVersionAsync();
        commit('setVersion', versionInfo);
        return versionInfo;
      } catch (err) {
        throw err;
      }
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
