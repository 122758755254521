import { CUSTOMER_STATUS } from '@/webchat2/config/types';

export default (msg, $store) => {
  const { state, commit } = $store;

  // If 'status = typing' that push message to conversation
  const isIdle = msg.customer_status === CUSTOMER_STATUS.IDLE;
  const conversation = state.conversations.find(c => c.id === msg.conversation_id && c.type === msg.conversation_type);
  let newMessages = [];
  if (msg.customer_status === CUSTOMER_STATUS.TYPING) {
    newMessages = [
      ...conversation.messages,
      {
        cid: msg.conversation_id,
        ctype: msg.conversation_type,
        gwid: msg.gateway_id,
        author: { type: 'human' },
        type: 'typing',
      },
    ];
  } else {
    // status: 'ACTIVE' | 'IDLE'
    // Remove 'type = typing' message from conversation
    newMessages = conversation.messages.filter(m => m.type !== 'typing');
  }
  commit('_updateConversation', {
    conversationId: conversation.id,
    data: {
      isIdle,
      messages: newMessages,
    },
  });
};
