import Vue from 'vue';
import VueRouter from 'vue-router';
import v2 from './v2.js';

Vue.use(VueRouter);

const routerConfig = {
  mode: 'history',
  routes: v2,
};

const router = new VueRouter(routerConfig);

// router.beforeEach((to, from, next) => {
//   store.dispatch('ui/changeActivePage', to.name);
//   next();
// });

// /* Route check for developers pages only */
// router.beforeEach((to, from, next) => {
//   const isDev = store.state.user.experimentalFeatures.DEBUG_MENU;
//   if (to.meta.devOnly === true && isDev) {
//     next();
//   } else if (!to.meta.devOnly) {
//     next();
//   } else {
//     next(false);
//   }
// });

export default router;
