import Vue from 'vue';
import Vuex from 'vuex';

import $bus from '@/platformSettings/bus';
import { stringCompareInsensitive } from '@/utils.js';
import { RESOURCE_STATE, VALID_RESOURCE_STATES } from '@/constants';

const Notification = window.Notification || window.webkitNotification;

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    currentTeam: null,
    fetchCurrentTeamStatus: RESOURCE_STATE.IDLE,

    teamLoading: null,
    teamsLoading: null,
    membersLoading: null,
    teamError: null,
    teams: [],
    agents: [],
    invites: [],
    companyInvites: null,
    companyMembers: [],
    companyGateways: {},
    welcomeMessage: null,
    cannedReplies: [],

    // Chat user info
    browsingHistory: {},
    customerInfo: {},
    customerNote: [],

    conversationTags: [],

    browserNotificationGranted: Notification && Notification.permission === 'granted',
    enableBrowserNotification:
      !Notification || Notification.permission !== 'granted'
        ? false
        : localStorage.getItem('chat-enable-browser-notification')
          ? localStorage.getItem('chat-enable-browser-notification') === 'true'
          : true,
  },
  getters: {
    teamsById(state) {
      return state.teams.reduce((byId, team) => {
        byId[team.id] = team;
        return byId;
      }, {});
    },
    visibleTeams(state) {
      return state.teams
        .filter(team => team.i_can_do.manage || team.i_can_do.serve_customer)
        .sort((lhs, rhs) => stringCompareInsensitive(lhs.name, rhs.name));
    },
    visibleTeamsById(state) {
      return state.teams
        .filter(team => team.i_can_do.manage || team.i_can_do.serve_customer)
        .reduce((byId, team) => {
          byId[team.id] = team;
          return byId;
        }, {});
    },
  },
  mutations: {
    _setCompanyGateways(state, payload) {
      const gateways = payload.reduce((_gateways, gateway) => {
        _gateways[gateway.bot_id] = _gateways[gateway.bot_id] || [];
        _gateways[gateway.bot_id].push(gateway);
        return _gateways;
      }, Object.create(null));

      state.companyGateways = { ...state.companyGateways, ...gateways };
    },
    _setCurrentTeam(state, teamObject) {
      state.currentTeam = Object.freeze(teamObject);
    },
    _setCurrentTeamStatus(state, value) {
      if (!VALID_RESOURCE_STATES.includes(value)) {
        throw Error(`Invalid mutation payload: expected one of ${VALID_RESOURCE_STATES.join(', ')}`, value);
      }
      state.fetchCurrentTeamStatus = value;
    },

    _setMembersLoading(state, bool) {
      state.membersLoading = bool;
    },
    _setTeamsLoading(state, bool) {
      state.teamsLoading = bool;
    },
    _setTeamLoading(state, bool) {
      state.teamLoading = bool;
    },
    _setTeamError(state, error) {
      state.teamError = error;
    },
    _setTeams(state, teams) {
      state.teams = teams;
    },
    _setCompanyMembers(state, members) {
      state.companyMembers = members;
    },
    _setInvites(state, invites) {
      state.invites = invites;
    },
    _setCompanyInvites(state, invites) {
      state.companyInvites = invites;
    },
    _setInvite(state, invite) {
      state.invites = [...state.invites, invite];
    },
    _setAgents(state, agents) {
      state.agents = agents;
    },
    _setMessage(state, message) {
      state.welcomeMessage = message;
    },
    _newCannedReplies(state, data) {
      state.cannedReplies = data;
    },
    _addCannedReplies(state, data) {
      state.cannedReplies = [...state.cannedReplies, data];
    },
    _updateCannedReplies(state, data) {
      const index = state.cannedReplies.findIndex(item => item.id === data.id);
      if (index !== -1) {
        state.cannedReplies.splice(index, 1, data);
      }
    },
    _deleteCannedReplies(state, payload) {
      state.cannedReplies = state.cannedReplies.filter(canned => {
        const retainCanned = payload.findIndex(data => data === canned.id);
        if (retainCanned === -1) {
          return canned;
        }
        return false;
      });
    },
    _newConversationTags(state, data) {
      state.conversationTags = data;
    },
    _setCustomerInfo(state, payload) {
      state.customerInfo = payload;
    },
    _setBrowsingHistory(state, payload) {
      state.browsingHistory = payload;
    },
    _addCustomerNote(state, payload) {
      state.customerNote = [...state.customerNote, payload];
    },
    _setCustomerNotes(state, payload) {
      state.customerNote = payload;
    },
    _setBrowserNotificationGranted(state, value) {
      state.browserNotificationGranted = value;
    },
    _setEnableBrowserNotification(state, value) {
      localStorage.setItem('chat-enable-browser-notification', value);
      state.enableBrowserNotification = value;
    },
  },
  actions: {
    toggleBrowserNotification({ state, commit }) {
      function requestPermissionCallback(permission) {
        console.log(`Permission is: ${permission}`);
        // If the user accepts, set to enabled.
        if (permission === 'granted') {
          commit('_setBrowserNotificationGranted', true);
          commit('_setEnableBrowserNotification', true);
        } else {
          commit('_setBrowserNotificationGranted', false);
          commit('_setEnableBrowserNotification', false);
        }
      }
      // Is disabled
      if (!state.enableBrowserNotification) {
        if (!Notification) return;

        try {
          Notification.requestPermission().then(requestPermissionCallback);
        } catch (error) {
          /**
           * Safari version earlier than 15 doesn't return a promise for requestPermissions and it
           * throws a TypeError. It takes a callback as the first argument
           * instead.
           *
           * https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission#browser_compatibility
           */
          if (error instanceof TypeError) {
            Notification.requestPermission(requestPermissionCallback);
          } else {
            throw error;
          }
        }
      } else {
        commit('_setEnableBrowserNotification', false);
      }
    },

    getCompanyGateways({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`livechatgateway/${companyId}/gateways`)
          .then(res => {
            const results = res.data;
            commit('_setCompanyGateways', results);
            return resolve(results);
          })
          .catch(err => {
            console.log(err);
            return reject(err);
          });
      });
    },

    createTeam({ dispatch }, { companyId, teamName, avatarId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`gateway/agent/company/${companyId}`, {
            data: {
              avatar_id: avatarId,
              name: {
                en: teamName,
              },
              welcome_message: '',
              welcome_message_enabled: false,
            },
          })
          .then(response => {
            dispatch('getCompanyTeams', companyId);
            return resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    updateTeam(_, { companyId, teamId, data }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`gateway/agent/company/${companyId}/${teamId}`, { data })
          .then(response => resolve(response.data))
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    deleteTeam({ dispatch, commit }, { companyId, teamId, newTeamId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`gateway/agent/company/${companyId}/${teamId}`, {
            data: {
              new_agent_team_id: newTeamId,
            },
          })
          .then(response => {
            dispatch('getCompanyTeams', companyId);
            return resolve();
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    setCurrentTeam({ commit, dispatch }, { companyId, teamId }) {
      return new Promise((resolve, reject) => {
        if (!companyId || !teamId) {
          return reject(new Error('Please provide companyId and teamId.'));
        }
        commit('_setCurrentTeamStatus', RESOURCE_STATE.PENDING);

        return dispatch('getTeam', { companyId, teamId })
          .then(newTeam => {
            commit('_setCurrentTeam', newTeam);
            commit('_setCurrentTeamStatus', RESOURCE_STATE.RESOLVED);

            return resolve(newTeam);
          })
          .catch(error => {
            commit('_setCurrentTeamStatus', RESOURCE_STATE.REJECTED);

            reject(error);
          });
      });
    },

    clearCurrentTeam({ commit }) {
      commit('_setCurrentTeam', null);
    },

    getTeam(_, { companyId, teamId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`gateway/agent/company/${companyId}/${teamId}`)
          .then(response => {
            const { ateam_id, users, ...team } = response.data;

            return resolve({
              ...team,
              id: ateam_id,
              name: team.name.en,
              agents: users,
            });
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    getCompanyTeams({ commit }, companyId) {
      commit('_setTeamsLoading', true);
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`livechatgateway/agent/company/${companyId}/teams`)
          .then(response => {
            const teams = (response.data ?? []).filter(team => team.is_v2bot);

            const formattedTeams = teams.map(team => {
              const { ateam_id, users, ...props } = team;
              return {
                ...props,
                id: ateam_id,
                name: team.name.en,
                agents: users,
              };
            });
            commit('_setTeams', formattedTeams);

            return resolve(formattedTeams);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          })
          .finally(() => {
            commit('_setTeamsLoading', false);
          });
      });
    },

    getCompanyMembers({ commit }, companyId) {
      commit('_setMembersLoading', true);
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`profile/company/${companyId}/member`)
          .then(response => {
            const results = response.data;
            commit('_setCompanyMembers', results);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          })
          .finally(() => {
            commit('_setMembersLoading', false);
          });
      });
    },

    clearCompanyMembers({ commit }) {
      commit('_setCompanyMembers', []);
    },

    addAgentToTeam({ dispatch }, {
      companyId, teamId, agentId, agentRole,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`gateway/agent/company/${companyId}/${teamId}/${agentId}`, {
            data: {
              role: agentRole,
            },
          })
          .then(response => {
            dispatch('getCompanyTeams', companyId);
            dispatch('setCurrentTeam', { companyId, teamId });
            return resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    removeAgent({ dispatch }, { companyId, teamId, agentId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`gateway/agent/company/${companyId}/${teamId}/${agentId}`)
          .then(response => {
            dispatch('getCompanyTeams', companyId);
            dispatch('setCurrentTeam', { companyId, teamId });
            return resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    deleteCannedReplies({ commit }, { teamId, selectedItems }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`gateway/canned_msg/${teamId}/batch_delete`, {
            data: {
              canned_msg_ids: selectedItems,
            },
          })
          .then(() => {
            commit('_deleteCannedReplies', selectedItems);
            return resolve();
          })
          .catch(err => reject(err));
      });
    },
    updateCannedReplies({ commit }, { cannedReplyId, data }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .patch(`gateway/canned_msg/${cannedReplyId}`, {
            data,
          })
          .then(response => {
            const result = response.data;
            commit('_updateCannedReplies', result);
            return resolve(result);
          })
          .catch(err => reject(err));
      });
    },
    addCannedReplies({ commit }, { currentTeamId, data }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`gateway/canned_msg/${currentTeamId}/shared`, { data })
          .then(response => {
            const result = response.data;
            commit('_addCannedReplies', result);
            return resolve(result);
          })
          .catch(err => reject(err));
      });
    },

    getCannedReplies({ commit }, { teamId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`gateway/canned_msg/${teamId}/shared`)
          .then(response => {
            const result = response.data;
            commit('_newCannedReplies', result);
            return resolve(result);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    getConversationTags({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`livechatgateway/conversation_label/company/${companyId}/labels`)
          .then(response => {
            const result = response.data;
            commit('_newConversationTags', result);
            return resolve(result);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    sendInviteMail({ dispatch, commit }, {
      companyId, teamId, agentEmail, agentRole,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`login/invitation/company/${companyId}/ateam/${teamId}`, {
            data: {
              email: agentEmail,
              role: agentRole,
              valid_days: 14,
            },
          })
          .then(response => {
            dispatch('getPendingInvites', {
              companyId,
              teamId,
            });
            return resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    getPendingInvites({ commit }, { companyId, teamId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`login/invitation/company/${companyId}/ateam/${teamId}/pending`)
          .then(response => {
            const results = response.data;
            commit('_setInvites', results);
            return resolve(results);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    getCompanyPendingInvites({ commit }, companyId) {
      return $bus.$siniticApi.get(`/login/invitation/company/${companyId}/pending`)
        .then(response => {
          commit('_setCompanyInvites', response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },

    setWelcomeMessage({ commit, dispatch, state }, { companyId, teamId }) {
      return new Promise((resolve, reject) => {
        if (teamId === null) {
          commit('__setMessage', null);
          return resolve();
        }
        dispatch('getWelcomeMessage', { companyId, teamId })
          .then(message => {
            commit('_setMessage', message);

            return resolve(message);
          })
          .catch(error => reject(error));
      });
    },

    resendInviteMail({ dispatch, commit }, { company_id, invitation_id }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`login/invitation/${invitation_id}/resend`, {
            data: {
              company_id,
            },
          })
          .then(response => {
            const results = response.data;
            return resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    getWelcomeMessage({ commit }, { companyId, teamId }) {
      console.log('server welcome', companyId, teamId);
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`gateway/agent/company/${companyId}/${teamId}`)
          .then(response => {
            const { data } = response;
            return resolve(data);
          })
          .catch(error => reject(error));
      });
    },

    deleteInvite({ dispatch, commit }, { companyId, invitationId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`login/invitation/${invitationId}`, {
            data: {
              company_id: companyId,
            },
          })
          .then(response => resolve(response.data))
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    getCustomerNotes({ commit }, { companyId, conversationId }) {
      return new Promise((resolve, reject) => {
        if (!conversationId) {
          commit('_setCustomerNotes', []);
          reject();
          return;
        }

        $bus.$siniticApi
          .get(`/livechatgateway/notes/${companyId}/conversation/${encodeURIComponent(conversationId)}`)
          .then(res => {
            // console.log('fetch customer note', res.data);
            commit('_setCustomerNotes', res.data);
            resolve(res.data);
          })
          .catch(err => {
            // console.log('fetch note error', err);
            commit('_setCustomerNotes', []);
            reject(err);
          });
      });
    },
    postCustomerNotes({ commit }, { companyId, conversationId, data }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/gateway3/notes/${companyId}/conversation/${conversationId}/note`, { data })
          .then(res => {
            console.log('post customer note', res.data);
            commit('_addCustomerNote', res.data);
            return resolve(res.data);
          })
          .catch(err => {
            console.log('post note error', err);
            return reject(err);
          });
      });
    },
    getBrowsingHistory({ commit }, { gwId, customerId }) {
      return new Promise((resolve, reject) => {
        if (!gwId || !customerId) {
          commit('_setBrowsingHistory', {});
          reject();
          return;
        }

        $bus.$siniticApi
          .get(`/livechatgateway/${gwId}/t/${encodeURIComponent(customerId)}`)
          .then(res => {
            // console.log('fetch browser history', res.data);
            commit('_setBrowsingHistory', res.data);
            resolve(res.data);
          })
          .catch(err => {
            // console.log('fetch browser error');
            commit('_setBrowsingHistory', {});
            reject(err);
          });
      });
    },

    getGatewayProfile({ commit }, {
      companyId, gwId, conversationId,
    }) {
      return new Promise((resolve, reject) => {
        if (!conversationId) {
          commit('_setCustomerInfo', {});
          reject();
          return;
        }

        $bus.$siniticApi
          .get(`/livechatgateway/${companyId}/crm/gateway_profile/${gwId}/${conversationId}`)
          .then(res => {
            // console.log('fetch customer profile', res.data);
            commit('_setCustomerInfo', res.data);
            resolve(res.data);
          })
          .catch(err => {
            // console.log('fetch profile error');
            commit('_setCustomerInfo', {});
            reject(err);
          });
      });
    },
    clickConvo({ dispatch, rootGetters }, { companyId, conversationId }) {
      return new Promise((resolve, reject) => {
        const conversation = rootGetters['websocketv2/activeConversation'];

        if (conversation) {
          const [botId, gwId, customerId] = [
            conversation.bot_id,
            conversation.gateway_id,
            conversation.customer_id,
          ];

          dispatch('getGatewayProfile', {
            companyId, gwId, conversationId,
          });
          dispatch('getCustomerNotes', { companyId, conversationId });
          dispatch('getBrowsingHistory', { gwId, customerId });
          dispatch('websocketv2/loadConversationMessages', { botId, conversation }, { root: true }).then(() => {
            resolve();
          });
        } else {
          reject(new Error(`Conversation id: ${conversationId} does not exist.`));
        }
      });
    },
    clearChatUserInfo({ commit }) {
      commit('_setBrowsingHistory', {});
      commit('_setCustomerInfo', {});
      commit('_setCustomerNotes', []);
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
