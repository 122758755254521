var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdownMenu,
          expression: "closeDropdownMenu"
        }
      ],
      staticClass: "dropdown2",
      class: ["dropdown2--" + _vm.theme]
    },
    [
      _c(
        "div",
        { ref: "dropdown_field", staticClass: "dropdown2__container" },
        [
          _c(
            "div",
            {
              ref: "reference",
              staticClass: "dropdown2__box",
              class: [
                { "has-error": _vm.error || _vm.errorstate },
                { active: _vm.isOpen }
              ],
              attrs: { disabled: _vm.disabled },
              on: { click: _vm.toggleDropdownMenu }
            },
            [
              _vm.value
                ? _c("div", { staticClass: "dropdown2__inner" }, [
                    _c(
                      "div",
                      { staticClass: "dropdown2__select" },
                      [
                        _vm._t(
                          "label",
                          [
                            _vm._t(
                              "option",
                              [_c("span", [_vm._v(_vm._s(_vm.value))])],
                              { option: _vm.value }
                            )
                          ],
                          { option: _vm.value }
                        )
                      ],
                      2
                    )
                  ])
                : _c("div", { staticClass: "dropdown2__inner" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "dropdown2__select d-flex align-items-center"
                      },
                      [
                        _c("span", { staticClass: "dropdown2__placeholder" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.placeholder ||
                                  _vm.$t("platform:generic.select")
                              ) +
                              " "
                          )
                        ])
                      ]
                    )
                  ]),
              _vm._v(" "),
              _c("font-awesome-icon", {
                staticClass: "dropdown2__arrow",
                class: { active: _vm.isOpen },
                attrs: { icon: ["fal", "angle-down"] }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.isOpen
            ? _c(
                "div",
                {
                  ref: "popper",
                  staticClass: "dropdown2-option",
                  style: { width: _vm.width + "px" }
                },
                [
                  !_vm.options.length
                    ? _c("div", { staticClass: "dropdown2-option__info" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("platform:generic.no-results")))
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.options, function(option, index) {
                    return _c(
                      "span",
                      {
                        key: option,
                        staticClass: "dropdown2-option__item",
                        class: [
                          {
                            "dropdown2-option__item--selected": _vm.isSelected(
                              option
                            )
                          }
                        ],
                        attrs: { id: option },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.select(option)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "dropdown2-option__item__inner" },
                          [
                            _vm._t(
                              "option",
                              [
                                _c(
                                  "div",
                                  { staticClass: "dropdown2-option__text" },
                                  [_vm._v(_vm._s(option))]
                                )
                              ],
                              { option: option, index: index }
                            )
                          ],
                          2
                        )
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.error
        ? [
            _vm._t(
              "error",
              [
                _c(
                  "div",
                  { staticClass: "dropdown2__error help is-danger" },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.error))]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "exclamation-triangle"] }
                    })
                  ],
                  1
                )
              ],
              { option: _vm.value, error: _vm.error }
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }