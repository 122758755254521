<template>
  <span class="pui-beta-icon">
    <font-awesome-icon :icon="['fal', 'flask']" />
  </span>
</template>

<script>
export default {
  name: 'PUIBetaIcon',
  data() {
    return {

    };
  },
};
</script>

<style>

</style>
