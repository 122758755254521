var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        class: _vm.classesSui,
        style: {
          width: _vm.width,
          height: _vm.height
        }
      },
      _vm.$listeners
    ),
    [
      _c("svg", [
        _c("use", _vm._b({}, "use", { "xlink:href": "#" + _vm.name }, false))
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }