import { SEND_STATUS } from '@/helpers/messageHelper';
import { SneakPeekQuickReplyButton } from '@/platformSettings/sneakPeek';

export default (message, $store, { MUTATION }) => {
  const { state, commit, dispatch } = $store;

  const chat = state.chats[message.cid];

  if (!chat) return;

  commit(MUTATION.addMessageToChat, {
    chatId: message.cid,
    message,
    status: SEND_STATUS.received,
  });
  commit(MUTATION.setQuickReply, { message });

  if (message.is_sneakpeek && !state.isChatWindowOpen) {
    dispatch('sneakPeek/set', {
      chatId: message.cid,
      buttons: message.quickreplies.map(qr => new SneakPeekQuickReplyButton({
        label: Object.fromEntries(state.uiSettings.bot_langs.map(lang => [lang, qr.title])) ?? {},
        send_text: Object.fromEntries(state.uiSettings.bot_langs.map(lang => [lang, qr.payload])) ?? {},
      })),
    }, { root: true });
    dispatch('showSneakPeek');
  }
};
