import Vue from 'vue';
import Vuex from 'vuex';
import $bus from '@/platformSettings/bus';

import { RESOURCE_STATE, VALID_RESOURCE_STATES } from '@/constants';

Vue.use(Vuex);

const MUTATION = {
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_CATEGORIES_STATE: 'SET_CATEGORIES_STATE',
  CLEAR_CATEGORIES: 'CLEAR_CATEGORIES',
  SET_CATEGORY: 'SET_CATEGORY',
  CLEAR_CATEGORY: 'CLEAR_CATEGORY',
};

export default {
  namespaced: true,
  state: {
    /**
     * Store categories (in tree structure)
     */
    categories: [],
    categoriesState: RESOURCE_STATE.IDLE,

    currentCategory: null,
  },
  getters: {
    categoryList(state) {
      const flattenCategory = (categoryTree, final = []) => {
        categoryTree.map(category => {
          final.push(category);
          return flattenCategory(category.children, final);
        });

        return final;
      };

      return flattenCategory(state.categories);
    },
    categoriesById(state, getters) {
      return getters.categoryList.reduce((byId, category) => {
        byId[category.id] = category;
        return byId;
      }, {});
    },
  },
  mutations: {
    [MUTATION.SET_CATEGORIES_STATE](state, value) {
      if (!VALID_RESOURCE_STATES.includes(value)) {
        throw Error(`Invalid mutation payload: expected one of ${VALID_RESOURCE_STATES.join(', ')}`, value);
      }

      state.categoriesState = value;
    },
    [MUTATION.SET_CATEGORIES](state, categories = []) {
      if (!Array.isArray(categories)) {
        throw Error('Invalid mutation payload: expected an array', categories);
      }

      state.categories = categories;
    },
    [MUTATION.CLEAR_CATEGORIES](state) {
      state.categories = [];
      state.categoriesState = RESOURCE_STATE.IDLE;
    },
    [MUTATION.SET_CATEGORY](state, category) {
      state.currentCategory = category;
    },
    [MUTATION.CLEAR_CATEGORY](state) {
      state.currentCategory = null;
    },
  },
  actions: {
    /**
     * Fetch all categories of a case group
     * @param {*} param0
     * @param {String} caseGroupId Case group ID
     */
    async fetchCategories({ commit }, caseGroupId) {
      if (!caseGroupId) throw Error('Missing case group id');

      commit(MUTATION.SET_CATEGORIES_STATE, RESOURCE_STATE.PENDING);

      try {
        const { data: categories } = await $bus.$siniticApi.get('/case/category', {
          params: {
            casem_id: caseGroupId,
          },
        });

        commit(MUTATION.SET_CATEGORIES, categories);

        commit(MUTATION.SET_CATEGORIES_STATE, RESOURCE_STATE.RESOLVED);

        return categories;
      } catch (error) {
        commit(MUTATION.SET_CATEGORIES_STATE, RESOURCE_STATE.REJECTED);
        throw error;
      }
    },

    clearCategories({ commit }) {
      commit(MUTATION.CLEAR_CATEGORIES);
    },

    setCategoryById({ getters, commit }, categoryId) {
      if (!getters.categoriesById[categoryId]) {
        throw new Error(`Can't find category of id: ${categoryId}`);
      }

      commit(MUTATION.SET_CATEGORY, getters.categoriesById[categoryId]);
    },

    clearCategory({ commit }) {
      commit(MUTATION.CLEAR_CATEGORY);
    },
    /**
     * Create a new category
     * @param {*} param0
     * @param {Object} param1 {  }
     * @returns New category
     */
    async createCategory({ dispatch }, { caseGroupId, categoryName, categoryParentId }) {
      const { data: newCategory } = await $bus.$siniticApi.post('/case/category', {
        data: {
          casem_id: caseGroupId,
          name: categoryName,
          parent_id: categoryParentId,
        },
      });

      dispatch('fetchCategories', caseGroupId);

      return newCategory;
    },
    /**
     * Update a category
     * @param {*} param0
     * @param {Object} param1 Payload
     * @returns Updated category
     */
    async updateCategory({ dispatch }, {
      caseGroupId, categoryId, categoryName, categoryParentId,
    }) {
      const { data: updatedCategory } = await $bus.$siniticApi.patch(`/case/category/${categoryId}`, {
        data: {
          name: categoryName,
          parent_id: categoryParentId,
        },
      });

      dispatch('fetchCategories', caseGroupId);

      return updatedCategory;
    },
    /**
     * Delete a category
     * @param {*} param0
     * @param {String} categoryId
     * @returns
     */
    async deleteCategory({ dispatch }, { caseGroupId, categoryId }) {
      const { data: result } = await $bus.$siniticApi.delete(`/case/category/${categoryId}`, {
        data: {
          casem_id: caseGroupId,
        },
      });

      dispatch('fetchCategories', caseGroupId);

      if (result.success) {
        return result;
      } else {
        throw new Error('Failed to delete category');
      }
    },
  },
};
