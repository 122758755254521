<template>
  <div
    :class="[`pui-loader--${size}`]"
    class="pui-loader"
  />
</template>

<script>
export default {
  name: 'PUILoader',
  props: {
    size: {
      type: String,
      default: 'normal',
      validator: val => ['small', 'medium', 'normal'].includes(val),
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';

.pui-loader {
  display: inline-block;
  border: 4px solid $color-yellow;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  animation: pui-loader-spin-animation 2s linear infinite;

  &--medium {
    width: 32px;
    height: 32px;
    border: 3px solid $color-yellow;
    border-top: 3px solid transparent;
  }

  &--small {
    width: 20px;
    height: 20px;
    border: 2px solid $color-yellow;
    border-top: 2px solid transparent;
  }
}

@keyframes pui-loader-spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
