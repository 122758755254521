import { createMapping, F } from '@/utils';
import i18n from '@/lang/i18n';

export const CHANNEL_TRAITS = createMapping({
  WEBCHAT: {
    name: 'website',
    translatedName: i18n.t('channels:channel_type.website'),
    iconFileName: 'website',
    iconFileNameForChat: 'website-square',
    featureFlag: null,
    couldRetrieveAvatar: false,
    couldRetrieveLocation: true,
    couldRetrieveEmail: true,
    couldRetrievePhoneNumber: false,
    couldRetrieveGender: false,
    supportPreChatForm: true,
    supportBrowsingHistory: true,
  },
  LINE: {
    name: 'line',
    translatedName: i18n.t('channels:channel_type.line'),
    iconFileName: 'line',
    iconFileNameForChat: 'line',
    featureFlag: null,
    couldRetrieveAvatar: true,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: false,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  FB: {
    name: 'fb',
    translatedName: i18n.t('channels:channel_type.fb'),
    iconFileName: 'fb',
    iconFileNameForChat: 'fb',
    featureFlag: null,
    couldRetrieveAvatar: true,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: false,
    couldRetrieveGender: true,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  SMS: {
    name: 'globelabs',
    translatedName: i18n.t('channels:channel_type.globelabs'),
    iconFileName: 'globelabs',
    iconFileNameForChat: 'globelabs',
    featureFlag: null,
    couldRetrieveAvatar: false,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: true,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  WHATSAPP: {
    name: 'whatsapp',
    translatedName: i18n.t('channels:channel_type.whatsapp'),
    iconFileName: 'twiliosms',
    iconFileNameForChat: 'twiliosms',
    featureFlag: null,
    couldRetrieveAvatar: true,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: true,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  ZALO: {
    name: 'zalo',
    translatedName: i18n.t('channels:channel_type.zalo'),
    iconFileName: 'zalo',
    iconFileNameForChat: 'zalo',
    featureFlag: null,
    couldRetrieveAvatar: true,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: false,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  MS_TEAMS: {
    name: 'msteams',
    translatedName: i18n.t('channels:channel_type.msteams'),
    iconFileName: 'msteams',
    iconFileNameForChat: 'msteams',
    featureFlag: null,
    couldRetrieveAvatar: true,
    couldRetrieveLocation: false,
    couldRetrieveEmail: true,
    couldRetrievePhoneNumber: false,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  MOBILE_SDK: {
    name: 'mobilesdk',
    translatedName: i18n.t('channels:channel_type.mobilesdk'),
    iconFileName: 'mobilesdk',
    iconFileNameForChat: 'mobilesdk',
    featureFlag: 'channel.mobilesdk',
    couldRetrieveAvatar: false,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: false,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  AFRICAS_TALKING: {
    name: 'africastalking',
    translatedName: i18n.t('channels:channel_type.africastalking'),
    iconFileName: 'africastalking',
    iconFileNameForChat: 'africastalking',
    featureFlag: null,
    couldRetrieveAvatar: false,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: true,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  TWILIO_SMS: {
    name: 'twiliosms',
    translatedName: i18n.t('channels:channel_type.twiliosms'),
    iconFileName: 'twiliosms',
    iconFileNameForChat: 'twiliosms',
    featureFlag: null,
    couldRetrieveAvatar: false,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: true,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  VIBER: {
    name: 'viber',
    translatedName: i18n.t('channels:channel_type.viber'),
    iconFileName: 'viber',
    iconFileNameForChat: 'viber',
    featureFlag: 'channel.viber',
    couldRetrieveAvatar: true,
    couldRetrieveLocation: true,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: false,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  TWITTER: {
    name: 'twitter',
    translatedName: i18n.t('channels:channel_type.twitter'),
    iconFileName: 'twitter',
    iconFileNameHighContrast: 'twitter-white',
    iconFileNameForChat: 'twitter',
    featureFlag: 'channel.twitter',
    couldRetrieveAvatar: true,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: false,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  BITRIX24: {
    name: 'bitrix24',
    translatedName: i18n.t('channels:channel_type.bitrix24'),
    iconFileName: 'bitrix24',
    iconFileNameForChat: 'bitrix24',
    featureFlag: 'channel.bitrix24',
    couldRetrieveAvatar: true,
    couldRetrieveLocation: false,
    couldRetrieveEmail: true,
    emailFormatter: data => Object.entries(JSON.parse(data)).map(([type, email]) => `${email} (${type})`).join('\n'),
    couldRetrievePhoneNumber: true,
    phoneFormatter: data => Object.entries(JSON.parse(data)).map(([type, phone]) => `${phone} (${type})`).join('\n'),
    couldRetrieveGender: true,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  WEBHOOK: {
    name: 'webhook',
    translatedName: i18n.t('channels:channel_type.webhook'),
    iconFileName: 'webhook',
    iconFileNameForChat: 'webhook',
    featureFlag: null,
    couldRetrieveAvatar: false,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: false,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  TELEGRAM: {
    name: 'telegram',
    translatedName: i18n.t('channels:channel_type.telegram'),
    iconFileName: 'telegram',
    iconFileNameForChat: 'telegram',
    featureFlag: 'channel.telegram',
    couldRetrieveAvatar: true,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: false,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
  META_WHATSAPP: {
    name: 'metawhatsapp',
    translatedName: i18n.t('channels:channel_type.metawhatsapp'),
    iconFileName: 'whatsapp',
    iconFileNameForChat: 'whatsapp',
    featureFlag: 'channel.metawhatsapp',
    couldRetrieveAvatar: true,
    couldRetrieveLocation: false,
    couldRetrieveEmail: false,
    couldRetrievePhoneNumber: true,
    couldRetrieveGender: false,
    supportPreChatForm: false,
    supportBrowsingHistory: false,
  },
});

export const CHANNEL = CHANNEL_TRAITS |> F.objMap((key, value) => [key, value.name]);

export const CHANNEL_TYPES = Object.values(CHANNEL);

export const CHANNEL_TRAITS_BY_NAME = CHANNEL_TRAITS |> F.objMap((key, value) => [value.name, value]);
