<template>
  <textarea
    ref="textarea"
    v-model="val"
    dir="auto"
    :style="computedStyles"
    :maxlength="maxlength"
    v-bind="$attrs"
    v-on="listeners"
    @focus="resize"
    @input="resize"
    @keydown.enter="onEnter"
  />
</template>

<script>
export default {
  name: 'TextareaAutosize',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    autosize: {
      type: Boolean,
      default: true,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    minHeight: {
      type: [Number],
      default: null,
    },
    maxHeight: {
      type: [Number],
      default: null,
    },
    captureEnterEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
        this.resize();
      },
    },
    listeners() {
      const { input, focus, ...listeners } = this.$listeners;
      return listeners;
    },
    computedStyles() {
      if (!this.autosize) return {};

      return {
        minHeight: this.minHeight,
        maxHeight: this.maxHeight,
      };
    },
  },
  watch: {
    minHeight() {
      this.resize();
    },
    maxHeight() {
      this.resize();
    },
    autosize() {
      this.resize();
    },
    value() {
      this.resize();
    },
  },
  mounted() {
    this.resize();
  },
  methods: {
    focus() {
      this.$el.focus();
    },
    onEnter(e) {
      if (e.shiftKey || e.ctrlKey || e.metaKey || e.altKey) return;
      if (this.captureEnterEvent) {
        e.preventDefault();
      }
    },

    resize() {
      this.$nextTick(() => {
        this.$el.style.height = 'auto';
        this.$el.style.height = `${this.$el.scrollHeight}px`;
      });
    },
  },
};
</script>
