import Vue from 'vue';
import Vuex from 'vuex';
// import LogRocket from 'logrocket';
import get from 'lodash/get';
import $bus from '@/platformSettings/bus';
import rtlLangs from '@/platformSettings/rtlLangs.js';

import * as Sentry from '@sentry/vue';

Vue.use(Vuex);

// For some reason, having it in platform.js is not enough, we need it here as well

// const validPlatformLangs = ['en', 'zh_CN', 'zh_TW'];
const defaultPlatformLang = 'en';

export default {
  namespaced: true,
  state: {
    user: null,
    user_profile: null,
    aliases: [],
    currentAlias: null,
    agents: {},
    agentTeams: null,

    // w.r.t. All companies
    companies: [], // All companies user belongs to
    companiesTfaEnforced: [], // All companies the user is TFA enforced
    companiesSsoEnforced: [], // All companies the user is SSO enforced

    // w.r.t. Current company
    companyQuota: null, // Current company quota
    companyTfaEnforcedUsers: [], // Current company's list of TFA enforced users

    // Deprecated
    team_id: null, // deprecated. Never use it!
    teams: [], // deprecated. Never use it!

    platformLang: defaultPlatformLang,
    experimentalFeatures: {
      TUTORIAL: true,
      DEBUG_MENU: false,
    },
    canCreateBot: false,
    twoFaStatus: false,
    ssoAccountStatus: {},
    paToken: null,
    platformPermission: {
      access_to_admin_panel: false,
    },
    userPreference: null,
    isIpValid: null,
  },
  getters: {
    isLogged: state => state.user != null,
    fullUserProfile: state => ({ ...state.user, ...state.user_profile }),
    companyDetails: state => ({ ...state.companies }),
    companiesById: state => (state.companies.map(co => [co.id, co]) |> Object.fromEntries),
    hasCompany: state => (state.companies && state.companies?.length > 0),
    isUsingAlias: (state, getters) => state.currentAlias && state.currentAlias !== getters.fullUserProfile?.user_id,
    defaultAlias: state => state.aliases.find(a => a.is_active),

    currentCompany: (state, getters, rootState) => getters.companiesById[rootState.route.params.companyId],
    isCompanyAdmin: (state, getters) => getters.currentCompany?.roles.includes('Company.ADMIN'),
    isCompanyManager: (state, getters) => getters.currentCompany?.roles.includes('Company.MANAGER'),
    isCompanyMember: (state, getters) => getters.currentCompany?.roles.includes('Company.MEMBER'),

    lastBotVisited: state => get(state, ['user_profile', 'preferences', 'lastBotVisited'], {}),

    // TFA
    is2FaEnabled: state => state.twoFaStatus !== 'disabled',
    isNeedsToEnableTfa: (state, getters) => {
      if (getters.currentCompany.tfa_enforce === 'GLOBAL_ENFORCE') {
        return !getters.is2FaEnabled;
      } else if (getters.currentCompany.tfa_enforce === 'USER_ENFORCE') {
        return state.companyTfaEnforcedUsers.includes(state.user.user_id) && !getters.is2FaEnabled;
      } else if (getters.currentCompany.tfa_enforce === 'GLOBAL_DISABLE') {
        return false;
      } else {
        // Should not happen
        return false;
      }
    },
    companyNamesRequiringTfa: (state, getters) => {
      const companyNames = state.companies
        .filter(({ id }) => state.companiesTfaEnforced.includes(id))
        .map(({ name }) => name);

      return companyNames;
    },

    // SSO
    isSsoEnabled: (state, getters) => getters.ssoAccountStatus?.google_id || getters.ssoAccountStatus?.microsoft_id,
    ssoAccountStatus: state => (state.ssoAccountStatus.map(sso => [sso.name, sso.linked]) |> Object.fromEntries),
    isNeedsToEnableSso: (state, getters) => {
      if (getters.currentCompany.sso_enforce === 'GLOBAL_ENFORCE') {
        return !getters.isSsoEnabled;
      } else if (getters.currentCompany.sso_enforce === 'GLOBAL_DISABLE') {
        return false;
      } else {
        // Should not happen
        return false;
      }
    },
    companyNamesRequiringSso: (state, getters) => {
      const companyNames = state.companies
        .filter(({ id }) => state.companiesSsoEnforced.includes(id))
        .map(({ name }) => name);

      return companyNames;
    },

    isRTL: state => rtlLangs.includes(state?.user_profile?.lang),
  },
  mutations: {
    _setPlatformUser(state, user) {
      state.user = user;
      if (user != null) {
        /* Sentry user context */

        Sentry.setUser({
          email: user.email,
          id: user.user_id,
        });

        state.userLoaded = true;
      }
    },
    _setPlatformUserProfile(state, userProfile) {
      state.user_profile = userProfile;
    },
    _setCompanies(state, companies) {
      state.companies = companies;
    },
    _setCompanyQuota(state, quota) {
      state.companyQuota = quota;
    },
    _setPersonalAccessToken(state, token) {
      state.paToken = token;
    },
    _set2FaStatus(state, status) {
      state.twoFaStatus = status;
    },
    _setSSOAccountStatus(state, status) {
      state.ssoAccountStatus = status;
    },
    _setCurrentAlias(state, alias) {
      state.currentAlias = alias;
    },
    _setAliases(state, aliases) {
      state.aliases = aliases;
    },
    _clearAliases(state) {
      state.aliases = [];
    },
    _setAgents(state, agents) {
      state.agents = {
        ...agents,
      };
    },
    _setAgentTeams(state, agentTeams) {
      state.agentTeams = agentTeams;
    },
    _setPlatformLang(state, newLang) {
      state.platformLang = newLang;
    },
    _enableExperimentalFeature(state, feature) {
      state.experimentalFeatures[feature] = true;
    },
    _disableExperimentalFeature(state, feature) {
      state.experimentalFeatures[feature] = false;
    },
    _canCreateBot(state, value) {
      state.canCreateBot = value;
    },
    _setPlatformPermission(state, permissions) {
      state.platformPermission = permissions;
    },
    _setUserPreference(state, preference) {
      state.userPreference = preference;
    },
    _setIsIpValid(state, isValid) {
      state.isIpValid = isValid;
    },
    _setCompaniesTfaEnforced(state, companies) {
      state.companiesTfaEnforced = companies;
    },
    _setCompaniesSsoEnforced(state, companies) {
      state.companiesSsoEnforced = companies;
    },
    _setCompanyTfaEnforcedUsers(state, users) {
      state.companyTfaEnforcedUsers = users;
    },
  },
  actions: {
    init() { },
    logout({ commit, rootState }) {
      return $bus.$siniticApi.post('/login/logout').then(() => {
        const lang = rootState.uiv2.platformLang;
        commit('_setPlatformUser', null);
        commit('_setPlatformUserProfile', null);
        window.location.href = `/v2/login?l=${lang}`;
        localStorage.removeItem('chat-enable-browser-notification');
        localStorage.removeItem('token');
        Sentry.configureScope(scope => scope.setUser(null));
      });
    },
    getAgentsByBot({ commit }, botId) {
      return $bus.$siniticApi.get(`gateway/agent/${botId}`).then(({ data }) => {
        const agents = data.reduce((result, obj) => {
          const temp = obj.users.reduce(
            (r, usr) => ({
              ...r,
              [usr.user_id]: {
                ...usr,
              },
            }),
            {}
          );
          return {
            ...result,
            ...temp,
          };
        }, {});
        commit('_setAgents', agents);
        return agents;
      });
    },
    getAliases({ commit }, userId) {
      $bus.$siniticApi
        .get(`/profile/user/${userId}/alias`)
        .then(response => {
          commit('_setAliases', response.data);
        })
        .catch(err => {
          console.error(err);
        });
    },
    clearAliases({ commit }) {
      commit('_clearAliases');
    },
    setCurrentAlias({ commit }, aliasId) {
      commit('_setCurrentAlias', aliasId);
    },
    setActiveAlias({ dispatch, state }, aliasId) {
      const formData = new FormData();
      formData.set('is_active', 'true');
      return $bus.$siniticApi
        .put(`profile/user/${state.user.user_id}/alias/${aliasId}`, {
          data: formData,
        })
        .then(() => dispatch('getAliases', state.user.user_id));
    },
    getCurrentUser({
      state, commit, dispatch, getters,
    }) {
      const setApiTokenIntervalFunc = () => dispatch('_setApiToken');
      const profileAction = () => new Promise((resolve, reject) => {
        // console.log('profileAction');
        const login = $bus.$siniticApi.get('/login/who_am_i');
        const profile = $bus.$siniticApi.get('/profile/user?v=2&include_companies');
        const companypref = $bus.$siniticApi.get('/profile/my_preferences');
        Promise.all([login, profile, companypref])
          .then(([respLogin, respProfile, respCompanyPref]) => {
            const user = respLogin.data;
            const { companies } = respProfile.data;
            const userProfile = respProfile.data.profile;
            const last_used_company_id = respCompanyPref.data;
            // remvoe these logs when done
            // dispatch('getAliases', user.user_id);
            commit('_setPlatformUser', user);
            commit('_setPlatformUserProfile', userProfile);
            commit('_setCompanies', companies);
            commit('_setUserPreference', last_used_company_id);

            const profileLang = (lang => {
              switch (lang) {
                case undefined:
                case null:
                case 'undefined': // legacy value
                  return 'en';
                default:
                  return lang;
              }
            })(userProfile?.lang);

            console.log('profileLang', profileLang);
            dispatch('uiv2/changeLanguage', profileLang, {
              root: true,
            });

            $bus.$notification.requestPermission();

            if (userProfile.preferences !== undefined) {
              /* If user doesn't have saved preferred language
              we keep the language selected on login page */
              dispatch('uiv2/setPreferences', userProfile.preferences, {
                root: true,
              });
            }

            if (!state.userPreference?.last_used_company_id) {
              return resolve({
                fullProfile: getters.fullUserProfile,
                defaultCompanyId: state.companies?.[0]?.id,
              });
            } else {
              return resolve({
                fullProfile: getters.fullUserProfile,
                defaultCompanyId: state.userPreference.last_used_company_id,
              });
            }
          })
          .catch(error => {
            console.error('error when getCurrentUser');
            reject(error);
          });
      });

      return dispatch('_setApiToken')
        .then(() => {
          setInterval(setApiTokenIntervalFunc, 600000); // 10 min
        })
        .then(profileAction);
    },
    updatePreferences({ state, commit }, prefs) {
      return $bus.$siniticApi
        .put(`/profile/my_preferences/${state.user.user_id}`, {
          data: { last_used_company_id: prefs.companyId },
        })
        .then(response => {
          const userPref = response.data;
          commit('_setUserPreference', userPref);
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateFullUserProfile({ state, commit }, { prefs, newPrefs }) {
      /*
        TODO:
          - review implementation of `userv2/fullUserProfile` - combination of user and user_profile
          - this might not follow vuex concept properly
          - `userv2/fullUserProfile` is implemented similar to computed value but now it requires to be overwritten
          - perhaps user and user_profile should just be 1 object in the first place
      */

      const user = {
        email: prefs.email,
        roles: prefs.roles,
        user_id: prefs.user_id,
      };

      const userProfile = {
        details: prefs.details,
        fullname: prefs.fullname,
        lang: newPrefs.platformLang,
        personal_company_id: prefs.personal_company_id,
        profile_pic_url: prefs.profile_pic_url,
        timezone: newPrefs.platformTimezone,
        preferences: newPrefs,
      };

      commit('_setPlatformUser', user);
      commit('_setPlatformUserProfile', userProfile);
    },
    canUserCreateBot({ commit }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get('/bot/can_i_create_bot')
          .then(response => {
            commit('_canCreateBot', true);
            return resolve();
          })
          .catch(() => {
            commit('_canCreateBot', false);
            return resolve();
          });
      });
    },
    // eslint-disable-next-line
    resetPassword({ state, commit }, { old_password, new_password }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post('/login/reset_password', {
            data: {
              old_password,
              new_password,
            },
          })
          .then(response => resolve())
          .catch(error => reject(error));
      });
    },
    sendFeedback({ state, commit }, text) {
      return $bus.$siniticApi.post(`/profile/user/${state.user.user_id}/feedback`, {
        data: { text },
      });
    },
    _setApiToken() {
      return $bus.$siniticApi.get('login/csrftoken').then(({ data }) => {
        $bus.$siniticApi.setToken('X-SINITIC-TOKEN', data.csrftoken);
        localStorage.setItem('token', data.csrftoken);
        return true;
      });
    },
    getCompanyQuota({ commit }, companyId) {
      return $bus.$siniticApi.get(`profile/${companyId}/quota`).then(response => {
        commit('_setCompanyQuota', response.data);
        return response.data;
      });
    },
    getPersonalAccessToken({ commit }) {
      return $bus.$siniticApi.get('/login/access_token/', {})
        .then(response => {
          commit('_setPersonalAccessToken', response.data.token);
        })
        .catch(error => {
          console.log(error);
        });
    },
    setPersonalAccessToken({ commit }, token) {
      return new Promise((resolve, reject) => {
        commit('_setPersonalAccessToken', token);
        return resolve();
      });
    },
    get2FaStatus({ commit }) {
      return $bus.$siniticApi.get('/login/tfa/', {})
        .then(response => {
          // TODO: verify what states response.data.status can have that aren't disabled
          commit('_set2FaStatus', response.data.status);
        })
        .catch(error => {
          console.log(error);
          // Make sure error code is 404, then don't do anything, no token found
        });
    },
    getSSOAccountStatus({ commit }) {
      return $bus.$siniticApi.get('/login/sso_status', {})
        .then(response => {
          commit('_setSSOAccountStatus', response.data.connections);
        })
        .catch(error => {
          console.log(error);
        });
    },
    getPlatformPermission({ commit }) {
      return $bus.$siniticApi.get('login/i_can_do/platform')
        .then(response => {
          commit('_setPlatformPermission', response.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    getIsIpValid({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi.get(`login/${companyId}/ip_allowlist/validate`)
          .then(resp => {
            commit('_setIsIpValid', true);
            resolve(resp);
          })
          .catch(error => {
            commit('_setIsIpValid', false);
            reject(error);
          });
      });
    },
    getCompaniesTfaEnforced({ commit }, userId) {
      // Get list of companies that require the user to enable TFA
      return $bus.$siniticApi.get(`/profile/company/tfa/${userId}/get_all_enforced_companies`)
        .then(response => {
          commit('_setCompaniesTfaEnforced', response.data.company_ids);
          return response.data.company_ids;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getCompaniesSsoEnforced({ commit }, userId) {
      // Get list of companies that require the user to link SSO
      return $bus.$siniticApi.get(`/profile/company/sso/${userId}/get_all_enforced_companies`)
        .then(response => {
          commit('_setCompaniesSsoEnforced', response.data.company_ids);
          return response.data.company_ids;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getCompanyTfaEnforcedUsers({ commit }, companyId) {
      // Gets list of TFA enforced users for given company
      return $bus.$siniticApi.get(`/profile/company/${companyId}/tfa`)
        .then(response => {
          commit('_setCompanyTfaEnforcedUsers', response.data.user_ids);
          return response.data.user_ids;
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
