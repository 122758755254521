<template>
  <div
    :class="classes"
    :disabled="disabled"
    v-on="proxiedListeners"
  >
    <PUIIcon
      v-if="icon"
      :name="icon"
      :loading="loading"
      :disabled="disabled"
      :color="color"
      :size="iconSize"
    />
    <span
      v-if="text"
      :class="{ 'sui2-button__has-icon': icon }"
    >{{ text }}</span>
  </div>
</template>

<script>

export default {
  name: 'Sui2Button',
  props: {
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      default: 'small',
    },
    size: {
      type: String,
      default: 'normal',
    },
    color: {
      type: String,
      default: 'grey',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'neutral',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      return {
        'sui2-button': true,
        [`sui2-is-${this.type}`]: true,
        [`is-${this.size}`]: true,
        'is-icon': this.icon,
      };
    },
    proxiedListeners() {
      const newListeners = { ...this.$listeners };
      /* Iterate over all listeners and proxy click listeners
      (needs to match '!click', '&click', etc.. which are
      vue event modifieres) */
      Object.keys(newListeners).forEach(key => {
        if (key.indexOf('click') > -1 && newListeners[key]) {
          newListeners[key] = this.proxyClickWithLoader(newListeners[key]);
        }
      });
      return newListeners;
    },
  },
  methods: {
    proxyClickWithLoader(handler) {
      // Disabled buttons can't be clicked on
      if (this.disabled) return () => {};

      /* Wrap the original listener handler in a function
      that checks for promise, if a promise is returned
      show loading spinner */
      return () => {
        const isPromise = handler();
        if (isPromise) {
          isPromise
            .then(() => {})
            .catch(error => {
              throw new Error(error);
              // TODO: show an error icon
            });
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~styles/v2/sui/sui-variables';

.sui2-button {
  &__has-icon {
    padding-inline-start: 10px;
  }
}

@mixin sui2-button($bcg, $color, $border) {
  background: $bcg;
  color: $color;
  padding: 8px 10px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  border-radius: $radius-small;
  border: 1px solid $border;
  display: inline-flex;
  width: 100%;
  justify-content: center;

  &:hover {
    background: darken($bcg, 10%);
    border-color: darken($bcg, 10%);
    color: $color;
  }
  &.is-outlined {
    background: none;
    color: $bcg;
    border: 1px solid $bcg;
  }
  &.is-outlined:hover {
    color: darken($bcg, 8%);
    border-color: darken($bcg, 10%);
  }

  &.is-small {
    font-size: $size-6;
    padding: 6px 8px;
  }

  &.is-normal {
    font-size: 14px;
    padding: 8px 12px;
  }

  &.is-large {
    font-size: $size-5;
    padding: 10px 12px;
  }

  &.is-xlarge {
    font-size: $size-5;
    padding: 16px 12px;
  }
}

.sui2-button[disabled] {
  background: $color-light-grey;
  padding: 8px 10px;
  font-size: 14px;
  color: $color-grey;
  text-align: center;
  border: 1px solid $color-grey-c;
  border-radius: $radius-small;
  &:after {
    border-top-color: $color-light-grey;
    border-inline-start-color: $color-light-grey;
  }

  &.is-icon {
    font-size: 0;
  }

  &.is-small {
    font-size: $size-8;
    padding: 6px 8px;
  }

  &.is-normal {
    font-size: $size-6;
    padding: 8px 10px;
  }

  &.is-large {
    font-size: $size-5;
    padding: 10px 12px;
  }
}

.sui2-button.sui2-is-primary:not([disabled]) {
  @include sui2-button($color-primary, $color-white, $color-primary);
}

.sui2-button.sui2-is-secondary:not([disabled]) {
  @include sui2-button($color-secondary, $color-white, $color-secondary);
}

.sui2-button.sui2-is-danger:not([disabled]) {
  @include sui2-button($color-red, $color-white, $color-red);
}

.sui2-button.sui2-is-light:not([disabled]) {
  @include sui2-button($color-white, $color-secondary, $border-ash-blue);
}

.sui2-button.sui2-is-inversed:not([disabled]) {
  @include sui2-button($color-white, $color-white, $color-white);
}

</style>
