// Source: https://github.com/umpirsky/language-list/

// Supported platform UI languages
export const supportedLanguages = [
  // 'af',
  // 'am',
  'ar',
  // 'bn',
  'zh_CN',
  'zh_TW',
  // 'nl',
  'en',
  // 'fi',
  'fr',
  // 'de',
  // 'ha',
  // 'hi',
  // 'id',
  // 'it',
  // 'ja',
  // 'kn',
  // 'ms',
  // 'no',
  // 'ru',
  // 'es',
  // 'sw',
  // 'sv',
  'tl',
  // 'ta',
  // 'te',
  // 'th',
  // 'tr',
  // 'vi'
  // 'kqn',
  // 'loz',
  // 'lue',
  // 'lun',
  // 'ton',

];

export const supportedAmtLanguages = [
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'es',
  'et',
  'fi',
  'fr',
  'ga',
  'hr',
  'hu',
  'it',
  'lt',
  'lv',
  'nl',
  'pl',
  'pt',
  'ro',
  'sk',
  'sl',
  'sv',
];

/* eslint-disable object-curly-newline */
// Supported bot languages list
const unsortedLanguagesV2 = [
  { name: 'Zulu', code: 'zu', nlp: false, icon: 'zu' },
  { name: 'Yoruba', code: 'yo', nlp: false, icon: 'yo' },
  { name: 'Yiddish', code: 'yi', nlp: false, icon: 'yi' },
  { name: 'Xhosa', code: 'xh', nlp: false, icon: 'xh' },
  { name: 'Wolof', code: 'wo', nlp: false, icon: 'wo' },
  { name: 'Welsh', code: 'cy', nlp: false, icon: 'cy' },
  { name: 'Waray', code: 'war', nlp: false, icon: 'war' },
  { name: 'Vietnamese', code: 'vi', nlp: true, icon: 'vi' },
  { name: 'Venda', code: 've', nlp: false, icon: 've' },
  { name: 'Uzbek', code: 'uz', nlp: false, icon: 'uz' },
  { name: 'Urdu', code: 'ur', nlp: false, icon: 'ur' },
  { name: 'Ukrainian', code: 'uk', nlp: false, icon: 'uk' },
  { name: 'Uighur', code: 'ug', nlp: false, icon: 'ug' },
  { name: 'Twi', code: 'tw', nlp: false, icon: 'tw' },
  { name: 'Turkmen', code: 'tk', nlp: false, icon: 'tk' },
  { name: 'Turkish', code: 'tr', nlp: false, icon: 'tr' },
  { name: 'Tswana', code: 'tb', nlp: false, icon: 'tb' },
  { name: 'Tsonga', code: 'ts', nlp: false, icon: 'ts' },
  { name: 'Traditional Chinese', code: 'zh_TW', nlp: true, icon: 'zh_TW' },
  { name: 'Tonga', code: 'ton', nlp: false, icon: 'ton' },
  { name: 'Tibetan', code: 'bo', nlp: false, icon: 'bo' },
  { name: 'Thai', code: 'th', nlp: true, icon: 'th' },
  { name: 'Telugu', code: 'te', nlp: false, icon: 'te' },
  { name: 'Tatar', code: 'tt', nlp: false, icon: 'tt' },
  { name: 'Tamil', code: 'ta', nlp: false, icon: 'ta' },
  { name: 'Tajik', code: 'tg', nlp: false, icon: 'tg' },
  { name: 'Tagalog', code: 'tl', nlp: true, icon: 'tl' },
  { name: 'Swedish', code: 'sv', nlp: false, icon: 'sv' },
  { name: 'Swati', code: 'ss', nlp: false, icon: 'ss' },
  { name: 'Swahili', code: 'sw', nlp: false, icon: 'sw' },
  { name: 'Sundanese', code: 'su', nlp: false, icon: 'su' },
  { name: 'Spanish', code: 'es', nlp: false, icon: 'es' },
  { name: 'Southern Sotho', code: 'st', nlp: false, icon: 'st' },
  { name: 'Southern Ndebele', code: 'nr', nlp: false, icon: 'nr' },
  { name: 'Somali', code: 'so', nlp: false, icon: 'so' },
  { name: 'Slovenian', code: 'sl', nlp: false, icon: 'sl' },
  { name: 'Slovak', code: 'sk', nlp: false, icon: 'sk' },
  { name: 'Singhalese', code: 'si', nlp: false, icon: 'si' },
  { name: 'Sindhi', code: 'sd', nlp: false, icon: 'sd' },
  { name: 'Simplified Chinese', code: 'zh_CN', nlp: true, icon: 'zh_CN' },
  { name: 'Shona', code: 'sn', nlp: false, icon: 'sn' },
  { name: 'Serbian', code: 'sr', nlp: false, icon: 'sr' },
  { name: 'Saraiki', code: 'skr', nlp: false, icon: 'skr' },
  { name: 'Samoan', code: 'sm', nlp: false, icon: 'sm' },
  { name: 'Russian', code: 'ru', nlp: true, icon: 'ru' },
  { name: 'Romanian', code: 'ro', nlp: false, icon: 'ro' },
  { name: 'Punjabi', code: 'pa', nlp: false, icon: 'pa' },
  { name: 'Portuguese', code: 'pt', nlp: true, icon: 'pt' },
  { name: 'Polish', code: 'pl', nlp: false, icon: 'pl' },
  { name: 'Persian', code: 'fa', nlp: false, icon: 'fa' },
  { name: 'Pashto, Pushto', code: 'ps', nlp: false, icon: 'ps' },
  { name: 'Pangasinense', code: 'pag', nlp: false, icon: 'pag' },
  { name: 'Oriya', code: 'or', nlp: false, icon: 'or' },
  { name: 'Nyanja', code: 'ny', nlp: false, icon: 'ny' },
  { name: 'Norwegian', code: 'no', nlp: false, icon: 'no' },
  { name: 'Nepali', code: 'ne', nlp: false, icon: 'ne' },
  { name: 'Mooré', code: 'mos', nlp: false, icon: 'mos' },
  { name: 'Mongolian', code: 'mn', nlp: false, icon: 'mn' },
  { name: 'Marathi', code: 'mr', nlp: false, icon: 'mr' },
  { name: 'Maori', code: 'mi', nlp: false, icon: 'mi' },
  { name: 'Maltese', code: 'mt', nlp: false, icon: 'mt' },
  { name: 'Malayalam', code: 'ml', nlp: false, icon: 'ml' },
  { name: 'Malay', code: 'ms', nlp: false, icon: 'ms' },
  { name: 'Malagasy', code: 'mg', nlp: false, icon: 'mg' },
  { name: 'Macedonian', code: 'mk', nlp: false, icon: 'mk' },
  { name: 'Luxembourgish', code: 'lb', nlp: false, icon: 'lb' },
  { name: 'Luvale', code: 'lue', nlp: false, icon: 'lue' },
  { name: 'Lunda', code: 'lun', nlp: false, icon: 'lun' },
  { name: 'Lozi', code: 'loz', nlp: false, icon: 'loz' },
  { name: 'Lithuanian', code: 'lt', nlp: false, icon: 'lt' },
  { name: 'Latvian, Lettish', code: 'lv', nlp: false, icon: 'lv' },
  { name: 'Latin', code: 'la', nlp: false, icon: 'la' },
  { name: 'Laothian', code: 'lo', nlp: false, icon: 'lo' },
  { name: 'Kurdish', code: 'ku', nlp: false, icon: 'ku' },
  { name: 'Korean', code: 'ko', nlp: false, icon: 'ko' },
  { name: 'Kirghiz', code: 'ky', nlp: false, icon: 'ky' },
  { name: 'Kinyarwanda', code: 'rw', nlp: false, icon: 'rw' },
  { name: 'Kazakh', code: 'kk', nlp: false, icon: 'kk' },
  { name: 'Kapampangan', code: 'pam', nlp: false, icon: 'pam' },
  { name: 'Kaonde', code: 'kqn', nlp: false, icon: 'kqn' },
  { name: 'Kannada', code: 'kn', nlp: false, icon: 'kn' },
  { name: 'Javanese', code: 'jv', nlp: false, icon: 'jv' },
  { name: 'Japanese', code: 'ja', nlp: true, icon: 'ja' },
  { name: 'Italian', code: 'it', nlp: true, icon: 'it' },
  { name: 'Irish', code: 'ga', nlp: false, icon: 'ga' },
  { name: 'Indonesian', code: 'id', nlp: true, icon: 'id' },
  { name: 'Ilocano', code: 'ilo', nlp: false, icon: 'ilo' },
  { name: 'Igbo', code: 'ig', nlp: false, icon: 'ig' },
  { name: 'Icelandic', code: 'is', nlp: false, icon: 'is' },
  { name: 'Hungarian', code: 'hu', nlp: false, icon: 'hu' },
  { name: 'Hmong, Mong', code: 'hmn', nlp: false, icon: 'hmn' },
  { name: 'Hindi', code: 'hi', nlp: false, icon: 'hi' },
  { name: 'Hebrew', code: 'he', nlp: false, icon: 'he' },
  { name: 'Hawaiian', code: 'haw', nlp: false, icon: 'haw' },
  { name: 'Hausa', code: 'ha', nlp: false, icon: 'ha' },
  { name: 'Haitian', code: 'ht', nlp: false, icon: 'ht' },
  { name: 'Gujarati', code: 'gu', nlp: false, icon: 'gu' },
  { name: 'Greek', code: 'el', nlp: false, icon: 'el' },
  { name: 'German', code: 'de', nlp: false, icon: 'de' },
  { name: 'Georgian', code: 'ka', nlp: false, icon: 'ka' },
  { name: 'Galician', code: 'gl', nlp: false, icon: 'gl' },
  { name: 'Gaelic (Scots Gaelic)', code: 'gd', nlp: false, icon: 'gd' },
  { name: 'Frisian', code: 'fy', nlp: false, icon: 'fy' },
  { name: 'French', code: 'fr', nlp: false, icon: 'fr' },
  { name: 'Finnish', code: 'fi', nlp: false, icon: 'fi' },
  { name: 'Estonian', code: 'et', nlp: false, icon: 'et' },
  { name: 'Esperanto', code: 'eo', nlp: false, icon: 'eo' },
  { name: 'Dyula', code: 'dyu', nlp: false, icon: 'dyu' },
  { name: 'Dutch', code: 'nl', nlp: false, icon: 'nl' },
  { name: 'Danish', code: 'da', nlp: false, icon: 'da' },
  { name: 'Czech', code: 'cs', nlp: false, icon: 'cs' },
  { name: 'Croatian', code: 'hr', nlp: false, icon: 'hr' },
  { name: 'Corsican', code: 'co', nlp: false, icon: 'co' },
  { name: 'Cebuano', code: 'ceb', nlp: false, icon: 'ceb' },
  { name: 'Catalan', code: 'ca', nlp: false, icon: 'ca' },
  { name: 'Cambodian', code: 'km', nlp: false, icon: 'km' },
  { name: 'Belarusian', code: 'be', nlp: false, icon: 'be' },
  { name: 'Burmese', code: 'my', nlp: false, icon: 'my' },
  { name: 'Bulgarian', code: 'bg', nlp: false, icon: 'bg' },
  { name: 'Bosnian', code: 'bs', nlp: false, icon: 'bs' },
  { name: 'Bicolano', code: 'bcl', nlp: false, icon: 'bcl' },
  { name: 'Bemba', code: 'bem', nlp: false, icon: 'bem' },
  { name: 'Basque', code: 'eu', nlp: false, icon: 'eu' },
  { name: 'Baoulé', code: 'bci', nlp: false, icon: 'bci' },
  { name: 'Bangla', code: 'bn', nlp: false, icon: 'bn' },
  { name: 'Azerbaijani', code: 'az', nlp: false, icon: 'az' },
  { name: 'Assamese', code: 'as', nlp: false, icon: 'as' },
  { name: 'Armenian', code: 'hy', nlp: false, icon: 'hy' },
  { name: 'Arabic', code: 'ar', nlp: false, icon: 'ar' },
  { name: 'Amharic', code: 'am', nlp: false, icon: 'am' },
  { name: 'Albanian', code: 'sq', nlp: false, icon: 'sq' },
  { name: 'Afrikaans', code: 'af', nlp: false, icon: 'af' },
];
/* eslint-enable object-curly-newline */

unsortedLanguagesV2.sort((a, b) => a.name.localeCompare(b.name));
unsortedLanguagesV2.unshift({
  name: 'English', code: 'en', nlp: true, icon: 'en',
});

export const languagesV2 = [...unsortedLanguagesV2];

export const extractByLang = function (mapping, lang, defaultLang, defaultFactory) {
  if (mapping[lang]) {
    return mapping[lang];
  }

  if (defaultLang && mapping[defaultLang]) {
    return mapping[defaultLang];
  }

  const entries = Object.entries(mapping);

  if (entries.length) {
    return entries[0][1]; // return an arbitary value in mapping
  }

  return defaultFactory && defaultFactory();
};

export const languageCodes = languagesV2.map(lang => lang.code);
