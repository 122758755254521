var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pui-checkbox",
      class: [
        _vm.colorOption,
        {
          "pui-checkbox--disabled": _vm.disabled,
          "pui-checkbox--readonly": _vm.readonly
        }
      ]
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputVal,
            expression: "inputVal"
          }
        ],
        ref: "input",
        staticClass: "pui-checkbox__input",
        class: { "pui-checkbox__input--partial": _vm.partial },
        attrs: {
          id: _vm.id,
          type: "checkbox",
          name: _vm.name,
          disabled: _vm.disabled
        },
        domProps: {
          value: _vm.value,
          checked: Array.isArray(_vm.inputVal)
            ? _vm._i(_vm.inputVal, _vm.value) > -1
            : _vm.inputVal
        },
        on: {
          change: function($event) {
            var $$a = _vm.inputVal,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.value,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.inputVal = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.inputVal = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.inputVal = $$c
            }
          }
        }
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "pui-checkbox__label", attrs: { for: _vm.id } },
        [
          _vm.label
            ? _c(
                "span",
                {
                  staticClass: "pui-checkbox__label__text",
                  class: { "pui-checkbox__label__ellipsis": _vm.maxLabelWidth },
                  style: { maxWidth: _vm.maxLabelWidth }
                },
                [_vm._v(_vm._s(_vm.label))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tooltip
            ? _c("font-awesome-icon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top",
                    value: {
                      content: _vm.tooltip,
                      offset: 10,
                      popperOptions: {
                        modifiers: {
                          preventOverflow: {
                            boundariesElement: _vm.tooltipBoundary
                          }
                        }
                      }
                    },
                    expression:
                      "{\n        content: tooltip,\n        offset: 10,\n        popperOptions: {\n          modifiers: {\n            preventOverflow: { boundariesElement: tooltipBoundary },\n          },\n        },\n      }",
                    modifiers: { top: true }
                  }
                ],
                staticClass: "pui-checkbox__icon",
                attrs: { icon: ["fal", "question-circle"], size: "lg" }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }