// V2 Platform Components
const AnalyticsV2 = () => import('@/components/v2/Platform/BotPages/Analytics/Analytics.vue');
const AnalyticSnapshot = () => import('@/components/v2/Platform/BotPages/Analytics/AnalyticSnapshot.vue');
const AnalyticDynamic = () => import('@/components/v2/Platform/BotPages/Analytics/AnalyticDynamic.vue');
const BotPageRouter = () => import('@/components/v2/Platform/BotPages/Build/BotPageRouter.vue');
const BotsList = () => import('@/components/v2/Platform/BotPages/Editor/List.vue');
const Editor = () => import('@/components/v2/Platform/BotPages/Editor/Editor.vue');
const PaiList = () => import('@/components/v2/Platform/BotPages/Pai/Index.vue');
const PaiPageRouter = () => import('@/components/v2/Platform/BotPages/Pai/Page.vue');
const ChannelsList = () => import('@/components/v2/Platform/BotPages/Channels/Channels.vue');
const ChannelPageRouter = () => import('@/components/v2/Platform/BotPages/Channels/Page.vue');
const PlatformPageRouter = () => import('@/components/v2/Platform/PlatformPageRouter.vue');
const NoCompanyPage = () => import('@/components/v2/Platform/NoCompanyPage.vue');
const AccessDeniedPage = () => import('@/components/v2/Platform/AccessDeniedPage.vue');
const Dashboard = () => import('@/components/v2/Platform/Dashboard.vue');
const PlatformWrapperV2 = () => import('@/components/v2/Platform/PlatformWrapper.vue');
const PlatformWrapperRouter = () => import('@/components/v2/Platform/PlatformWrapperRouter.vue');
const ResetPasswordV2 = () => import('@/components/v2/Platform/ResetPasswordPage.vue');
const BotSettingPageRouter = () => import('@/components/v2/Platform/BotPages/Settings/Settings.vue');
const GeneralSettings = () => import('@/components/v2/Platform/BotPages/Settings/Pages/General.vue');
const LanguageSettings = () => import('@/components/v2/Platform/BotPages/Settings/Pages/LanguageSettingsV2.vue');
const EditLogSettings = () => import('@/components/v2/Platform/BotPages/Settings/Pages/EditLog.vue');
const PublishLogSettings = () => import('@/components/v2/Platform/BotPages/Settings/Pages/PublishLog.vue');
const ReadOnlyBotEditor = () => import('@/components/v2/Platform/BotPages/Settings/Pages/PublishLog/ReadOnlyEditor.vue');
const EntitiesSettings = () => import('@/components/v2/Platform/BotPages/Settings/Pages/Entities.vue');
const EntitiesSettingsDetails = () => import('@/components/v2/Platform/BotPages/Settings/Pages/EntityDetail.vue');
const BotSettingsAPI = () => import('@/components/v2/Platform/BotPages/Settings/Pages/API.vue');
const BotHistory = () => import('@/components/v2/Platform/BotPages/History/History.vue');
const SignupNormal = () => import('@/components/v2/Platform/SignupPage.vue');
const BizSignup = () => import('@/components/v2/Platform/BizSignup/BizSignup.vue');
const Login = () => import('@/components/v2/Platform/LoginPage.vue');
const ProfilePageRouter = () => import('@/components/v2/Platform/UserPages/UserProfile.vue');
const GeneralProfile = () => import('@/components/v2/Platform/UserPages/Partials/UserProfile/General.vue');
const AliasProfile = () => import('@/components/v2/Platform/UserPages/Partials/UserProfile/Alias.vue');
const ApiProfile = () => import('@/components/v2/Platform/UserPages/Partials/UserProfile/Api.vue');
const CompanyPageRouter = () => import('@/components/v2/Platform/UserPages/Company.vue');
const CompanyGeneral = () => import('@/components/v2/Platform/UserPages/Partials/Company/General.vue');
const CompanyUsers = () => import('@/components/v2/Platform/UserPages/Partials/Company/CompanyUsers.vue');
const CompanyBilling = () => import('@/components/v2/Platform/UserPages/Partials/Company/Billing.vue');
const CompanySecurity = () => import('@/components/v2/Platform/UserPages/Partials/Company/Security.vue');
const CompanyUpgradeToPremium = () => import('@/components/v2/Platform/UserPages/Partials/Company/Billing/UpgradeToPremium.vue');
const AdaptiveMTProject = () => import('@/components/v2/Platform/BotPages/AdaptiveMT/Project.vue');
const AdaptiveMTData = () => import('@/components/v2/Platform/BotPages/AdaptiveMT/Data.vue');
const AdaptiveMTPair = () => import('@/components/v2/Platform/BotPages/AdaptiveMT/Pair.vue');
const PreviewPairs = () => import('@/components/v2/Platform/BotPages/AdaptiveMT/PreviewPairs.vue');
const AdaptiveMTModel = () => import('@/components/v2/Platform/BotPages/AdaptiveMT/Model.vue');
const AdaptiveMTTestModel = () => import('@/components/v2/Platform/BotPages/AdaptiveMT/TestModel.vue');
const HumansWrapper = () => import('@/components/v2/Platform/Humans/HumansWrapper.vue');
const HumansProfiles = () => import('@/components/v2/Platform/Humans/Pages/HumansProfiles.vue');
const HumansSettings = () => import('@/components/v2/Platform/Humans/Pages/HumansSettings.vue');
const HumansProfilesDetail = () => import('@/components/v2/Platform/Humans/Pages/HumanProfilesDetail.vue');

const GroupsList = () => import('@/components/v2/Platform/BotPages/Track/Track.vue');
const GroupPageRouter = () => import('@/components/v2/Platform/BotPages/Track/Wrapper.vue');
const GroupSettingPageRouter = () => import('@/components/v2/Platform/BotPages/Track/Settings.vue');
const GroupSettingGeneral = () => import('@/components/v2/Platform/BotPages/Track/Settings/General.vue');
const GroupSettingCategories = () => import('@/components/v2/Platform/BotPages/Track/Settings/Categories.vue');
const GroupSettingCustomFields = () => import('@/components/v2/Platform/BotPages/Track/Settings/CustomFields.vue');
const CreateCustomField = () => import('@/components/v2/Platform/BotPages/Track/Settings/CreateCustomFields.vue');
const EditCustomField = () => import('@/components/v2/Platform/BotPages/Track/Settings/EditCustomField.vue');
const GroupSettingRespondents = () => import('@/components/v2/Platform/BotPages/Track/Settings/Respondents.vue');
const GroupSettingRespondentsPageRouter = () => import('@/components/v2/Platform/BotPages/Track/Settings/RespondentsRouter.vue');
const GroupSettingEmails = () => import('@/components/v2/Platform/BotPages/Track/Settings/CaseNotification.vue');
const GroupSettingNotifications = () => import('@/components/v2/Platform/BotPages/Track/Settings/Notifications.vue');
const GroupSettingNotificationsTemplate = () => import('@/components/v2/Platform/BotPages/Track/Settings/NotificationsTemplate.vue');
const GroupSettingApiRouter = () => import('@/components/v2/Platform/BotPages/Track/Settings/ApiRouter.vue');
const GroupSettingApiSettings = () => import('@/components/v2/Platform/BotPages/Track/Settings/Api/Settings.vue');
const GroupSettingApiErrorLog = () => import('@/components/v2/Platform/BotPages/Track/Settings/Api/ErrorLog.vue');
const GroupReport = () => import('@/components/v2/Platform/BotPages/Track/Report.vue');

const CaseList = () => import('@/components/v2/Platform/BotPages/Track/Cases.vue');
const CreateCase = () => import('@/components/v2/Platform/BotPages/Track/Cases/Create.vue');
const CasePageRouter = () => import('@/components/v2/Platform/BotPages/Track/Cases/Edit.vue');
const CaseNotes = () => import('@/components/v2/Platform/BotPages/Track/Cases/Edit/Notes.vue');
const CaseNoteDetails = () => import('@/components/v2/Platform/BotPages/Track/Cases/Edit/NoteDetails.vue');
const CaseChat = () => import('@/components/v2/Platform/BotPages/Track/Cases/Edit/Chat.vue');
const CaseActivities = () => import('@/components/v2/Platform/BotPages/Track/Cases/Edit/Audit.vue');

const ChatPageRouter = () => import('@/components/v2/Platform/BotPages/Chat/Wrapper');
const LiveChat = () => import('@/components/v2/Platform/BotPages/Chat/LiveChat.vue');
const ChatHistory = () => import('@/components/v2/Platform/BotPages/Chat/History.vue');
const ChatTeams = () => import('@/components/v2/Platform/BotPages/Chat/Teams.vue');
const ChatTeamSettingsWrapper = () => import('@/components/v2/Platform/BotPages/Chat/Teams/Settings/Wrapper.vue');
const ChatTeamSettings = () => import('@/components/v2/Platform/BotPages/Chat/Teams/Settings/Settings.vue');
const ChatReport = () => import('@/components/v2/Platform/BotPages/Chat/Report.vue');
const ReportTeam = () => import('@/components/v2/Platform/BotPages/Chat/Report/Team.vue');
const ReportAgent = () => import('@/components/v2/Platform/BotPages/Chat/Report/Agent.vue');
const ChatSettings = () => import('@/components/v2/Platform/BotPages/Chat/Settings.vue');

const DataPageRouter = () => import('@/components/v2/Platform/Data/DataPageRouter.vue');
const ByodConfiguration = () => import('@/components/v2/Platform/Data/Byod/Configuration.vue');
const ByodHealthCheck = () => import('@/components/v2/Platform/Data/Byod/HealthCheck.vue');
const ByodErrorLog = () => import('@/components/v2/Platform/Data/Byod/ErrorLog.vue');

const LightboxPageRouter = () => import('@/components/v2/Platform/Lightbox/LightboxPageRouter.vue');
const LightboxDashboard = () => import('@/components/v2/Platform/Lightbox/Tutorial.vue');

// PUI Components
const PUIStyleGuideAvatars = () => import('@/components/PUI/StyleGuide/Avatars.vue');
const PUIStyleGuideButtons = () => import('@/components/PUI/StyleGuide/Buttons.vue');
const PUIStyleGuideTextFields = () => import('@/components/PUI/StyleGuide/TextFields.vue');
const PUIStyleGuideDropdowns = () => import('@/components/PUI/StyleGuide/Dropdowns.vue');
const PUIStyleGuideTypographies = () => import('@/components/PUI/StyleGuide/Typographies.vue');
const PUIStyleGuideSelectionControls = () => import('@/components/PUI/StyleGuide/SelectionControls.vue');
const PUIStyleGuideNotifications = () => import('@/components/PUI/StyleGuide/Notifications.vue');
const PUIStyleGuideLabels = () => import('@/components/PUI/StyleGuide/Labels.vue');
const PUIStyleGuideProgressBars = () => import('@/components/PUI/StyleGuide/ProgressBars.vue');

const routes = [
  {
    path: '/',
    redirect: '/v2',
  },
  {
    path: '/v2',
    component: PlatformWrapperV2,
    meta: {
      title: 'platform:header.platform',
    },
    beforeEnter(to, from, next) {
      console.log('beforeEnter of /v2');
      next();
    },

    children: [
      {
        path: '',
        component: PlatformWrapperRouter,
      },
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          title: 'platform:header.login',
        },
        props: true,
        beforeEnter(to, from, next) {
          console.log('beforeEnter of Login');
          next();
        },
      },
      {
        path: 'no-company',
        name: 'NoCompany',
        component: NoCompanyPage,
      },
      {
        path: 'signup',
        name: 'SignupNormal',
        component: SignupNormal,
        meta: {
          title: 'platform:header.signup',
        },
      },
      {
        path: 'signup/:invitationId',
        name: 'SignupByInvitation',
        component: SignupNormal,
        meta: {
          title: 'platform:header.signup_invitation',
        },
      },
      {
        path: 'trial_signup',
        name: 'signupBusinessTrial',
        component: BizSignup,
        props: true,
        meta: {
          pageType: 'trial',
        },
      },
      {
        path: 'livechat_signup',
        name: 'signupBusinessFree',
        component: BizSignup,
        meta: {
          pageType: 'free',
        },
      },
      {
        path: 'reset-password',
        name: 'ResetPasswordV2',
        component: ResetPasswordV2,
        meta: {
          title: 'platform:header.reset_password',
        },
      },
      {
        path: ':companyId',
        component: PlatformPageRouter,
        meta: {
          requiresAuth: true,
          version: 2,
        },
        children: [
          {
            path: '',
            name: 'Dashboard',
            component: Dashboard,
            beforeEnter(to, from, next) {
              console.log('beforeEnter in Dashboard');
              next();
            },
          },
          {
            path: 'lightbox',
            name: 'LightboxPageRouter',
            component: LightboxPageRouter,
            props: true,
            redirect: {
              name: 'LightboxDashboard',
            },
            children: [
              {
                path: '',
                name: 'LightboxDashboard',
                component: LightboxDashboard,
                props: true,
              },
            ],
          },
          {
            path: 'access-denied',
            name: 'AccessDenied',
            component: AccessDeniedPage,
          },
          {
            path: 'edit',
            component: BotsList,
            name: 'BotsList',
            props: true,
            meta: {
              pageType: 'v2BotEditor',
              title: 'platform:header.edit',
              icon: 'icons-build',
            },
          },
          {
            path: 'edit/:botId',
            name: 'BotPageRouter',
            component: BotPageRouter,
            props: true,
            children: [
              {
                path: 'editor/:stateId?/:eventId?',
                component: Editor,
                name: 'Editor',
                props: true,
                meta: {
                  pageType: 'v2BotEditor',
                  title: 'platform:header.edit',
                },
              },
              {
                path: 'apps',
                component: PaiList,
                name: 'PaiList',
                props: true,
                meta: {
                  pageTitle: 'platform:header.nav.edit.apps',
                },
              },
              {
                path: 'apps/:pai',
                component: PaiPageRouter,
                name: 'PaiPageRouter',
                props: true,
                meta: {
                  title: 'platform:header.nav.edit.apps',
                  disablePrimaryHeader: true,
                },
              },
              {
                path: 'channel',
                component: ChannelsList,
                name: 'ChannelsList',
                props: true,
                meta: {
                  pageTitle: 'platform:header.nav.edit.channel',
                },
              },
              {
                path: 'channel/:gateway/:gatewayId/:page?/:subpage?',
                component: ChannelPageRouter,
                name: 'ChannelPageRouter',
                props: true,
                meta: {
                  title: 'platform:header.nav.edit.channel',
                  disablePrimaryHeader: true,
                },
              },
              {
                path: 'settings',
                component: BotSettingPageRouter,
                name: 'BotSettingPageRouter',
                props: true,
                meta: {
                  title: 'platform:header.nav.edit.settings',
                },
                children: [
                  {
                    path: 'general',
                    component: GeneralSettings,
                    name: 'GeneralSettings',
                    props: true,
                  },
                  {
                    path: 'language',
                    component: LanguageSettings,
                    name: 'LanguageSettings',
                    props: true,
                  },
                  {
                    path: 'edit_log',
                    component: EditLogSettings,
                    name: 'EditLogSettings',
                    props: true,
                  },
                  {
                    path: 'deployments',
                    component: PublishLogSettings,
                    name: 'PublishLogSettings',
                    props: true,
                  },
                  {
                    path: 'entities',
                    component: EntitiesSettings,
                    name: 'EntitiesSettings',
                    props: true,
                  },
                  {
                    path: 'api',
                    component: BotSettingsAPI,
                    name: 'BotSettingsAPI',
                    props: true,
                  },
                ],
              },
              // Entity detail page has a different layout, so it's not under BotSettingPageRouter.
              {
                path: 'settings/entities/:entityId',
                component: EntitiesSettingsDetails,
                name: 'EntitiesSettingsDetails',
                props: true,
                meta: {
                  title: 'platform:header.nav.edit.settings',
                  disablePrimaryHeader: true,
                },
              },
              {
                path: 'readonly/:version/:stateId?/:eventId?',
                component: ReadOnlyBotEditor,
                name: 'ReadOnlyBotEditor',
                props: true,
                meta: {
                  disablePrimaryHeader: true,
                },
              },

              {
                path: 'history/:history/:selectedId?',
                component: BotHistory,
                name: 'BotHistory',
                props: true,
                meta: {
                  pageType: 'v2BotEditor',
                  title: 'platform:header.nav.edit.history',
                },
              },
            ],
          },
          {
            path: 'analytics',
            component: AnalyticsV2,
            name: 'BotAnalytics',
            meta: {
              pageType: 'v2BotEditor',
              headerComponent: 'analytics',
              title: 'platform:header.analytics',
            },
            children: [
              // {
              //   path: '',
              //   component: AnalyticsV2,
              //   name: 'BotAnalytics',
              //   props: true,
              //   meta: {
              //     pageType: 'v2BotEditor',
              //     headerComponent: 'analytics',
              //     title: 'platform:header.analytics'
              //   }
              // },
              {
                path: 'dynamics/:reportId?',
                component: AnalyticDynamic,
                name: 'v3AnalyticDynamic',
                props: true,
                meta: {
                  pageType: '',
                  headerComponent: 'analytics',
                  title: 'platform:header.nav.analytic.dynamic',
                },
              },
              // {
              //   path: ':botId/snapshot',
              //   component: AnalyticSnapshot,
              //   name: 'v3AnalyticSnapshot',
              //   props: true,
              //   meta: {
              //     pageType: '',
              //     headerComponent: 'analytics',
              //     title: 'platform:header.nav.analytic.snapshot',
              //   },
              // },
            ],
          },
          // {
          //   path: 'analytics',
          //   component: AnalyticsV2,
          //   name: 'BotAnalytics',
          //   props: true,
          //   meta: {
          //     pageType: 'v2BotEditor',
          //     headerComponent: 'analytics',
          //     title: 'platform:header.analytics'
          //   }
          // },
          // {
          //   path: 'analytics/dynamics/:reportId?',
          //   component: AnalyticDynamic,
          //   name: 'v3AnalyticDynamic',
          //   props: true,
          //   meta: {
          //     pageType: '',
          //     headerComponent: 'analytics',
          //     title: 'platform:header.nav.analytic.dynamic'
          //   }
          // },
          // {
          //   path: 'analytics/:botId/snapshot',
          //   component: AnalyticSnapshot,
          //   name: 'v3AnalyticSnapshot',
          //   props: true,
          //   meta: {
          //     pageType: '',
          //     headerComponent: 'analytics',
          //     title: 'platform:header.nav.analytic.snapshot'
          //   }
          // },
          // {
          //   path: 'adaptive',
          //   component: AdaptiveMTProject,
          //   name: 'AdaptiveMT',
          //   props: true,
          //   meta: {
          //     pageType: 'v2BotEditor',
          //     title: 'platform:header.adaptive',
          //   },
          // },
          // {
          //   path: 'adaptive/:projectId/data/:dataPage',
          //   component: AdaptiveMTData,
          //   name: 'AdaptiveMTData',
          //   props: true,
          //   meta: {
          //     pageType: 'v2BotEditor',
          //     title: 'platform:header.tech_support',
          //   },
          // },
          // {
          //   path: 'adaptive/:projectId/data/:dataPage/:dataItemId',
          //   component: AdaptiveMTPair,
          //   name: 'AdaptiveMTPair',
          //   props: true,
          //   meta: {
          //     pageType: 'v2BotEditor',
          //     title: 'platform:header.tech_support',
          //   },
          // },
          // {
          //   path: 'adaptive/:projectId/data/:dataPage/:dataItemId/preview',
          //   component: PreviewPairs,
          //   name: 'PreviewPairs',
          //   props: true,
          //   meta: {
          //     pageType: 'v2BotEditor',
          //     title: 'platform:header.tech_support',
          //   },
          // },
          // {
          //   path: 'adaptive/:projectId/model',
          //   component: AdaptiveMTModel,
          //   name: 'AdaptiveMTModel',
          //   props: true,
          //   meta: {
          //     pageType: 'v2BotEditor',
          //     title: 'platform:header.tech_support',
          //   },
          // },
          // {
          //   path: 'adaptive/:projectId/model/:modelId/test',
          //   component: AdaptiveMTTestModel,
          //   name: 'AdaptiveMTModelTest',
          //   props: true,
          //   meta: {
          //     pageType: 'v2BotEditor',
          //     title: 'platform:header.tech_support',
          //   },
          // },
          // {
          //   path: 'adaptive/:projectId/benchmark',
          //   component: AdaptiveMTData,
          //   name: 'Benchmark',
          //   props: true,
          //   meta: {
          //     pageType: 'v2BotEditor',
          //     title: 'platform:header.tech_support',
          //   },
          // },
          {
            path: 'humans',
            props: true,
            component: HumansWrapper,
            name: 'v2Humans',
            meta: {
              title: 'platform:header.humans',
              headerComponent: 'human',
              pageType: '', // TODO: ADD.
            },
            children: [
              {
                path: ':botId?/profiles',
                component: HumansProfiles,
                name: 'v2BotHumansProfiles',
                meta: {
                  title: 'platform:header.humans',
                  headerComponent: 'human',
                },
              },
              {
                path: ':botId/profiles/:humanId/:detailPage',
                props: true,
                component: HumansProfilesDetail,
                name: 'HumansProfilesDetail',
                meta: {
                  title: 'platform:header.humans',
                  headerComponent: 'human-profile-detail',
                  disablePrimaryHeader: true,
                },
              },
              {
                path: ':botId?/settings/:page',
                props: true,
                component: HumansSettings,
                name: 'v2BotHumansSettings',
                meta: {
                  title: 'platform:header.humans',
                  headerComponent: 'human',
                },
              },
            ],
          },
          {
            path: 'profile',
            redirect: {
              name: 'GeneralProfile',
            },
            props: true,
            component: ProfilePageRouter,
            meta: {
              title: 'platform:account.title',
              pageType: 'v2BotEditor',
            },
            children: [
              {
                path: 'general',
                component: GeneralProfile,
                name: 'GeneralProfile',
                props: true,
              },
              {
                path: 'alias',
                component: AliasProfile,
                name: 'AliasProfile',
                props: true,
              },
              {
                path: 'api',
                component: ApiProfile,
                name: 'ApiProfile',
                props: true,
              },
            ],
          },
          {
            path: 'company',
            redirect: {
              name: 'CompanyGeneral',
            },
            props: true,
            component: CompanyPageRouter,
            meta: {
              title: 'platform:account.title',
              pageType: 'v2BotEditor',
            },
            children: [
              {
                path: 'general',
                component: CompanyGeneral,
                name: 'CompanyGeneral',
                props: true,
              },
              {
                path: 'members',
                component: CompanyUsers,
                name: 'CompanyUsers',
                props: true,
              },
              {
                path: 'billing',
                component: CompanyBilling,
                name: 'CompanyBilling',
                props: true,
              },
              {
                path: 'security',
                name: 'CompanySecurity',
                component: CompanySecurity,
                props: true,
              },
              {
                path: 'upgrade-to-premium',
                component: CompanyUpgradeToPremium,
                name: 'CompanyUpgradeToPremium',
                props: true,
                meta: {
                  customNav: true,
                },
              },
            ],
          },
          {
            path: 'track',
            component: GroupsList,
            name: 'GroupsList',
          },
          {
            path: 'track/:groupId',
            component: GroupPageRouter,
            children: [
              {
                path: '',
                component: CaseList,
                name: 'CaseList',
              },
              {
                path: 'settings',
                redirect: {
                  name: 'GroupSettingGeneral',
                },
                props: true,
                component: GroupSettingPageRouter,
                meta: {
                  pageType: 'v2BotEditor',
                },
                children: [
                  {
                    path: 'general',
                    props: true,
                    component: GroupSettingGeneral,
                    name: 'GroupSettingGeneral',
                  },
                  {
                    path: 'categories',
                    props: true,
                    component: GroupSettingCategories,
                    name: 'GroupSettingCategories',
                  },
                  {
                    path: 'custom-fields',
                    props: true,
                    component: GroupSettingCustomFields,
                    name: 'GroupSettingCustomFields',
                  },
                  {
                    path: 'respondents',
                    props: true,
                    component: GroupSettingRespondents,
                    name: 'GroupSettingRespondents',
                  },
                  {
                    path: 'emails',
                    props: true,
                    component: GroupSettingEmails,
                    name: 'GroupSettingEmails',
                  },
                  {
                    path: 'notifications',
                    props: true,
                    component: GroupSettingNotifications,
                    name: 'GroupSettingNotifications',
                  },
                  {
                    path: 'api',
                    props: true,
                    component: GroupSettingApiRouter,
                    name: 'GroupSettingApiRouter',
                    redirect: {
                      name: 'GroupSettingApiSettings',
                    },
                    children: [
                      {
                        path: 'settings',
                        props: true,
                        component: GroupSettingApiSettings,
                        name: 'GroupSettingApiSettings',
                      },
                      {
                        path: 'error-log',
                        props: true,
                        component: GroupSettingApiErrorLog,
                        name: 'GroupSettingApiErrorLog',
                      },
                    ],
                  },
                ],
              },
              {
                path: 'settings/notifications/:templateType',
                component: GroupSettingNotificationsTemplate,
                name: 'GroupSettingNotificationsTemplate',
                meta: {
                  disablePrimaryHeader: true,
                },
              },
              {
                path: 'settings/respondents/:respondentId/:page?',
                beforeEnter: (to, from, next) => {
                  if (to.params.respondentId && !to.params.page) {
                    next({
                      name: to.name,
                      params: {
                        ...to.params,
                        page: 'general',
                      },
                      query: to.query,
                    });
                  } else {
                    next();
                  }
                },
                props: true,
                component: GroupSettingRespondentsPageRouter,
                name: 'GroupSettingRespondentsPageRouter',
                meta: {
                  disablePrimaryHeader: true,
                },
              },
              {
                path: 'settings/new-custom-field',
                props: true,
                component: CreateCustomField,
                name: 'CreateCustomField',
                meta: {
                  disablePrimaryHeader: true,
                },
              },
              {
                path: 'settings/edit-custom-field/:fieldId',
                props: true,
                component: EditCustomField,
                name: 'EditCustomField',
                meta: {
                  disablePrimaryHeader: true,
                },
              },
              {
                // TODO: REMOVE
                // path: 'cases',
                // component: TrackCases,
                // name: 'TrackCases',
                path: 'new-case',
                component: CreateCase,
                name: 'CreateCase',
                meta: {
                  title: 'track:cases.create',
                  disablePrimaryHeader: true,
                },
              },
              {
                path: 'case/:caseId',
                redirect: 'case/:caseId/',
                component: CasePageRouter,
                name: 'CasePageRouter',
                children: [
                  {
                    path: '',
                    component: CaseNotes,
                    name: 'CaseNotes',
                    meta: {
                      disablePrimaryHeader: true,
                    },
                  },
                  {
                    path: 'chat',
                    component: CaseChat,
                    name: 'CaseChat',
                    meta: {
                      disablePrimaryHeader: true,
                    },
                  },
                  {
                    path: 'activity',
                    component: CaseActivities,
                    name: 'CaseActivities',
                    meta: {
                      disablePrimaryHeader: true,
                    },
                  },
                  {
                    path: ':noteId',
                    component: CaseNoteDetails,
                    name: 'CaseNoteDetails',
                    meta: {
                      disablePrimaryHeader: true,
                    },
                  },
                ],
              },
              {
                path: 'reports',
                component: GroupReport,
                name: 'GroupReport',
              },
            ],
          },
          {
            path: 'chat',
            component: ChatPageRouter,
            name: 'ChatPageRouter',
            meta: {
              headerComponent: 'pui',
              title: 'platform:sidebar.chat',
              backButtonMatchedRoutes: 4,
              icon: 'icons-chat',
            },
            redirect: { name: 'LiveChat' },
            children: [
              {
                path: 'live-chat/:conversationId?',
                props: true,
                component: LiveChat,
                name: 'LiveChat',
                meta: {
                  pageType: '',
                  headerComponent: 'pui',
                  backButtonMatchedRoutes: 4,
                  title: 'platform:sidebar.chat',
                  icon: 'icons-chat',
                },
              },
              {
                path: 'history/:botId?/:selectedId?',
                component: ChatHistory,
                name: 'ChatHistory',
                meta: {
                  headerComponent: 'pui',
                  title: 'platform:sidebar.chat',
                  backButtonMatchedRoutes: 4,
                  icon: 'icons-chat',
                },
              },
              {
                path: 'teams',
                component: ChatTeams,
                name: 'ChatTeams',
                meta: {
                  headerComponent: 'pui',
                  title: 'platform:sidebar.chat',
                  backButtonMatchedRoutes: 4,
                  icon: 'icons-chat',
                },
                children: [
                  {
                    path: ':teamId',
                    component: ChatTeamSettingsWrapper,
                    name: 'ChatTeamSettingsWrapper',
                    children: [
                      {
                        path: 'settings/:page',
                        props: true,
                        component: ChatTeamSettings,
                        name: 'ChatTeamSettings',
                        meta: {
                          pageType: 'v2BotEditor',
                          disablePrimaryHeader: true,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: 'report',
                component: ChatReport,
                name: 'ChatReport',
                meta: {
                  headerComponent: 'pui',
                  title: 'platform:sidebar.chat',
                  backButtonMatchedRoutes: 4,
                  icon: 'icons-chat',
                },
                children: [
                  {
                    path: 'team/:teamId?',
                    component: ReportTeam,
                    name: 'ReportTeam',
                  },
                  {
                    path: 'agent/:agentId?',
                    component: ReportAgent,
                    name: 'ReportAgent',
                  },
                ],
              },
              {
                path: 'settings',
                component: ChatSettings,
                name: 'ChatSettings',
                meta: {
                  headerComponent: 'pui',
                  title: 'platform:sidebar.chat',
                  backButtonMatchedRoutes: 4,
                  icon: 'icons-chat',
                },
              },
            ],
          },
          {
            path: 'data',
            name: 'DataPageRouter',
            component: DataPageRouter,
            redirect: { name: 'ByodConfiguration' },
            children: [
              {
                path: 'configuration',
                name: 'ByodConfiguration',
                component: ByodConfiguration,
              },
              {
                path: 'health-check',
                name: 'ByodHealthCheck',
                component: ByodHealthCheck,
              },
              {
                path: 'error-log',
                name: 'ByodErrorLog',
                component: ByodErrorLog,
              },
            ],
          },
        ],
      },
    ],
  },
];

/* Routes for testing purposes */
if (process.env.NODE_ENV === 'development') {
  routes.unshift(
    {
      path: '/test',
      component: () => import('@/components/Dev/Test.vue'),
    }
  );

  routes.unshift(
    {
      path: '/pui/avatars',
      component: PUIStyleGuideAvatars,
    },
    {
      path: '/pui/buttons',
      component: PUIStyleGuideButtons,
    },
    {
      path: '/pui/notifications',
      component: PUIStyleGuideNotifications,
    },
    {
      path: '/pui/textfields',
      component: PUIStyleGuideTextFields,
    },
    {
      path: '/pui/dropdowns',
      component: PUIStyleGuideDropdowns,
    },
    {
      path: '/pui/typographies',
      component: PUIStyleGuideTypographies,
    },
    {
      path: '/pui/selection-controls',
      component: PUIStyleGuideSelectionControls,
    },
    {
      path: '/pui/progress-bars',
      component: PUIStyleGuideProgressBars,
    },
    {
      path: '/pui/labels',
      component: PUIStyleGuideLabels,
    },
    {
      path: '/pui/progress-bars',
      component: PUIStyleGuideProgressBars,
    }
  );
}

export default routes;
