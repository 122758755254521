<template>
  <label :class="classes">
    {{ labelBefore }}
    <div
      :class="switchClasses"
      class="sui2-switch"
      @click.stop="onClick"
    >
      <span class="open">{{ openName }}</span>
      <span class="close">{{ closeName }}</span>
    </div>
    {{ labelAfter }}
  </label>
</template>

<script>
/**
 * Inspired by
 * https://github.com/dai-siki/vue-switch
 */

export default {
  name: 'Sui2Switch',
  props: {
    labelBefore: {
      type: String,
      default: null,
    },
    labelAfter: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    /**
     * Valid sizes are 'small', 'normal' and 'large'
     */
    size: {
      type: String,
      default: 'small',
    },
    color: {
      type: String,
      default: 'green',
    },
    openValue: {
      type: Boolean,
      default: true,
    },
    closeValue: {
      type: Boolean,
      default: false,
    },
    openName: {
      type: String,
      default: 'Yes',
    },
    closeName: {
      type: String,
      default: 'No',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => () => {},
    },
  },
  computed: {
    copiedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    classes() {
      return {
        'sui2-switch-label': this.labelBefore || this.labelAfter,
      };
    },
    switchClasses() {
      return {
        'vue-switch': true,
        'z-on': this.value === this.openValue,
        'z-off': this.value !== this.openValue,
        'z-disabled': this.disabled,
        [`s-${ this.size}`]: true,
        [`c-${ this.color}`]: true,
      };
    },
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        if (this.openValue === this.value) {
          this.$emit('input', this.closeValue);
          this.onChange(this.closeValue);
        } else {
          this.$emit('input', this.openValue);
          this.onChange(this.openValue);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~styles/v2/sui/sui-variables';

$md: 48px;
$lg: 60px;
$md: 54px;

.sui2-switch {
  display: inline-block;
  line-height: 1;
  width: $md;
  height: 24px;
  // line-height: 18px;
  border-radius: 20px;
  background-color: $color-grey;
  cursor: pointer;
  transition: all 0.21s ease;
  user-select: none;
  // overflow: hidden;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  transform: rotate(0deg);
  text-align: end;
  box-shadow: 0px 0px 3px 0px rgba(102, 102, 102, 0.5);

  span {
    font-size: 12px;
    margin-top: -3px;
    vertical-align: middle;
    color: #fff;
    padding: 0 10px;

    &.open {
      display: none;
    }

    &.close {
      display: inline;
    }
  }

  &::after {
    position: absolute;
    content: '';
    width: 22px;
    height: 22px;
    box-shadow: $shadow-a;
    border-radius: 18px;
    background-color: #fff;
    cursor: pointer;
    left: 0px;
    top: 1px;
    margin-inline-start: -5px;
    transition: left 0.23s ease, width 0.23s ease, background-color 0.23s ease;
  }

  &.z-on {
    text-align: start;
    span {
      color: #fff;

      &.open {
        display: inline;
        margin-inline-start: -3px;
      }

      &.close {
        display: none;
      }
    }
  }

  &.s-large {
    width: $lg;

    &.z-on {
      &.c-blue {
        background-color: $color-blue;
        border-color: $color-blue;
      }

      &.c-green {
        background-color: $color-green;
        border-color: $color-green;
      }

      &::after {
        left: $lg - 18px;
        z-index: 1;
      }
    }
  }

  &.s-normal {
    width: 100%;
    height: 19px;

    &.z-on {
      &.open {
        padding: 0 13px;
        margin: 0;
      }

      &.close {
        padding: 0 8px;
      }

      &.c-blue {
        background-color: $color-blue;
        border-color: $color-blue;
      }

      &.c-green {
        background-color: $color-green;
        border-color: $color-green;
      }

      &::after {
        // left: 64px;
        z-index: 1;
        width: 20px;
        margin-top: -1.5px;
        height: 20px;
        // right: 0;
        // left: 76%;
        left: 85%;
      }
    }

    &.z-off {
      &::after {
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        box-shadow: $shadow-a;
        border-radius: 18px;
        background-color: #fff;
        cursor: pointer;
        left: 0px;
        top: 0px;
        margin-inline-start: -5px;
        transition: left 0.23s ease, width 0.23s ease, background-color 0.23s ease;
      }
    }
  }

  &.s-small {
    width: 36px;
    height: 10px;
    overflow: visible;

    span {
      display: none;
      position: absolute;
      top: 6px;
    }

    &::after {
      width: 18px;
      height: 18px;
      top: -4px;
      left: -1px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    }

    &.z-disabled {
      background-color: #e5e5e5;
      border-color: #e5e5e5;
      cursor: not-allowed;

      &::after {
        background-color: #d5d5d5;
        box-shadow: none;
        cursor: not-allowed;
      }
    }

    &.z-on {
      &.c-blue {
        background-color: $color-blue;
        border-color: $color-blue;
      }

      &.c-green {
        background-color: $color-green;
        border-color: $color-green;
      }

      span {
        display: none;
      }

      &:active {
        &::after {
          left: 15px;
        }
      }

      &::after {
        left: 19px;
      }

      &.z-disabled {
        &::after {
          box-shadow: none;
        }
      }
    }
  }
}

</style>
