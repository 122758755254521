<template>
  <div :class="['pui-textarea', `pui-textarea--${theme}`, { 'is-disabled': disabled }]">
    <div
      v-if="label || (max && showMax)"
      class="pui-textarea__header"
    >
      <label
        v-if="label"
        class="pui-textarea__label"
      >{{ label }}{{ required ? '*' : '' }}</label>
      <label
        v-if="max && showMax"
        class="pui-textarea__max-label"
      >{{
        $t('pui:textfield:max_characters', { max })
      }}</label>
    </div>
    <div
      class="pui-textarea__main"
      :style="{
        padding: $slots['addons'] ? '1px 11px' : '',
        position: $slots['icon'] ? 'relative': ''}"
    >
      <TextareaAutosize
        ref="textarea"
        v-model="inputValue"
        :placeholder="placeholder"
        :style="{
          resize: resize,
          minHeight: minHeight ? `${minHeight}px` : '40px',
          maxHeight: maxHeight ? `${maxHeight}px` : 'auto',
        }"
        :autosize="autosize"
        :autofocus="autofocus"
        :minHeight="minHeight"
        :maxHeight="maxHeight"
        :rows="rows"
        :maxlength="max"
        :disabled="disabled"
        :captureEnterEvent="captureEnterEvent"
        :class="{
          'pui-textarea__input--italic': italic,
          'has-error': !!error || errorstate,
        }"
        class="pui-textarea__input"
        v-on="inputListeners"
      />
      <slot name="addons" />
      <slot name="icon" />
    </div>
    <div
      v-if="error"
      class="pui-textarea__error help is-danger"
    >
      <span>{{ error }}</span>
      <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
    </div>
  </div>
</template>

<script>
import TextareaAutosize from './TextareaAutosize.vue';

export default {
  name: 'PUITextArea',
  components: {
    TextareaAutosize,
  },
  props: {
    autosize: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    italic: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: null,
    },
    showMax: {
      type: Boolean,
      default: true,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'light',
      validator: theme => ['light', 'dark', 'grey-dark', 'no-border'].indexOf(theme) > -1,
    },
    error: {
      type: String,
      default: '',
    },
    errorstate: {
      type: Boolean,
      default: false,
    },
    resize: {
      type: String,
      default: 'vertical',
      validator: resize => ['vertical', 'horizontal', 'both', 'none'].indexOf(resize) > -1,
    },
    rows: {
      type: String,
      default: null,
    },
    minHeight: {
      type: Number,
      default: null,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    captureEnterEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    inputListeners() {
      const vm = this;
      // `Object.assign` merges objects together to form a new object
      return {
        native: true,
        // We add all the listeners from the parent
        ...this.$listeners,
      };
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        if (!(this.max && val.length > this.max)) {
          this.$emit('input', val);
        }
      },
    },
  },
  methods: {
    focusEditor() {
      this.$refs.textarea.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';

@mixin appearance($main-color, $bg-color) {
  border: $main-color;
  background-color: $bd-color;
}

@mixin pui-textarea__theme(
  $border-color,
  $border-focus-color,
  $color,
  $disabled-color,
  $bg-color,
  $disabled-bg-color,
  $label-color,
  $disabled-label-color,
  $placeholder-color
) {
  .pui-textarea {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 7px;
    }

    &__input {
      width: 100%;
      border: solid 1px $border-color;
      color: $color;
      margin: 0;
      background-color: $bg-color;
      padding: 11px;
      outline: 0;
      font-family: $open-sans;
      font-size: 13px;
      line-height: 1.54;

      &--italic {
        border: solid 1px $color-grey-lighter;
        font-style: italic;
      }

      &:focus:not(.pui-textarea__input--italic):not(.has-error) {
        border: solid 1px $border-focus-color;
      }

      &:disabled {
        color: $disabled-color;
        background-color: $disabled-bg-color;
        cursor: not-allowed;
      }

      &.has-error {
        border: solid 1px $color-error;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $placeholder-color;
        /* Firefox */
        opacity: 1;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $placeholder-color;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $placeholder-color;
      }
    }

    &__label {
      font-family: $montserrat;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.08px;
      color: $label-color;
      height: 14px;
      text-align: start;
    }

    [dir="rtl"] &__max-label {
      float: left;
    }
    &__max-label {
      height: 14px;
      float: right;
      font-family: $montserrat;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.08px;
      color: $label-color;
    }

    &__error {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $open-sans;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      color: $color-error;
    }

    &.is-disabled &__label {
      color: $disabled-label-color;
    }

    &__resize-both {
      resize: both;
    }

    &__resize-x {
      resize: horizontal;
    }

    &__resize-y {
      resize: vertical;
    }
  }
}

.pui-textarea {
  margin: 0;
  padding: 0;
  line-height: 0;

  &--light {
    @include pui-textarea__theme(
      $color-grey,
      $color-black,
      $color-black,
      $color-white,
      $color-white,
      $color-grey-light,
      $color-black,
      $color-grey-like,
      $color-form-placeholder
    );
  }
  &--no-border {
    @include pui-textarea__theme(
      $color-grey,
      $color-black,
      $color-black,
      $color-white,
      $color-white,
      $color-grey-light,
      $color-black,
      $color-grey-like,
      $color-form-placeholder
    );
    .pui-textarea {
      &__input {
        &,
        &:hover,
        &:focus {
          border: none !important;
          background: transparent !important;
        }
      }
    }
  }

  &--dark {
    @include pui-textarea__theme(
      $color-grey,
      $color-grey-light,
      $color-white,
      $color-white,
      $color-black-pearl,
      $color-grey-light,
      $color-white,
      $color-grey-like,
      $color-white
    );
  }

  &--grey-dark {
    @include pui-textarea__theme(
      $color-grey,
      $color-grey-light,
      $color-white,
      $color-white,
      $color-grey-dark,
      $color-grey-light,
      $color-white,
      $color-grey-like,
      #c6c6c6
    );
  }
}
</style>
