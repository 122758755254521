export const clickOutsideDirective = {
  inserted(el, binding, vnode) {
    el.clickOnWindow = event => {
      /* click event is trigger by el or its children */
      if (el.contains(event.target)) {
        return;
      }

      /* el is not rendered */
      const status = window.getComputedStyle(el);
      if (status.display === 'none') {
        return;
      }

      const cb = binding.value;
      cb(event);
    };

    /*
      To make the event be triggered later:
      If one element is displayed upon clicking outside of the element,
      this setTimeout prevents this element is closing right after it's opened
    */
    setTimeout(() => {
      window.addEventListener('click', el.clickOnWindow);
    }, 0);
  },
  unbind(el) {
    window.removeEventListener('click', el.clickOnWindow);
  },
};

export const WIP = () => {};
