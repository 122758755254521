<template>
  <div
    :class="[
      'pui-textfield',
      `pui-textfield--${theme}`,
      {
        'pui-textfield--required': required,
        'pui-textfield--removable': removable,
      },
    ]"
  >
    <div
      v-if="label || (max && showMax)"
      class="pui-textfield__header"
    >
      <label
        v-if="label"
        :disabled="disabled"
        class="pui-textfield__label"
        :class="{ 'pui-textfield__label--bold': boldLabel }"
      >{{ label }}{{ required ? '*' : '' }}</label>
      <label
        v-if="max && showMax"
        class="pui-textfield__max-label"
        :class="{ 'pui-textfield__max-label--bold': boldLabel }"
      >
        {{ $t('pui:textfield:max_characters', { max }) }}
      </label>
      <span
        v-if="clearable"
        class="pui-textfield__clear"
        @click="clearValue"
      >{{ $t('platform:generic.clear') }}</span>
    </div>
    <div
      v-if="description"
      class="pui-textfield__header"
    >
      <label
        :disabled="disabled"
        class="pui-textfield__help-text"
      >{{ description }}</label>
    </div>
    <div
      :class="{ 'pui-textfield--prefix': !!prefixIcon, 'pui-textfield--suffix': !!suffixIcon }"
      class="pui-textfield__inner"
    >
      <template v-if="prefixIcon">
        <font-awesome-icon
          v-if="prefixIconType === 'fa' || prefixIconType === 'fal'"
          :icon="['fal', prefixIcon]"
          class="pui-textfield__prefix"
          :class="{ 'pui-textfield__prefix--focused': focused }"
          size="lg"
          @click="$emit('prefixIconClick')"
        />

        <PUIIcon
          v-else
          :name="prefixIcon"
          class="pui-textfield__prefix"
          :class="{ 'pui-textfield__prefix--focused': focused }"
          size="small"
          @click="$emit('prefixIconClick')"
        />
      </template>

      <input
        ref="inputBox"
        v-model="inputValue"
        dir="auto"
        :placeholder="placeholder"
        :required="required"
        :min="min"
        :class="{ 'has-error': error || errorstate, 'is-italic': italic }"
        :disabled="disabled"
        :readonly="readonly"
        :type="type === 'phone' || type === 'number' ? 'text' : type"
        :style="{
          height: height,
          paddingLeft: prefixIcon ? '28px' : padding,
          fontSize: fontSize,
          paddingRight: suffixIcon ? '28px' : padding,
          ...textStyle,
        }"
        :autocomplete="autocomplete"
        class="pui-textfield__input"
        v-bind="{
          ...$attrs,
          ...maxProp,
        }"
        v-on="inputListeners"
        @focus="onInputFocus()"
        @blur="onInputBlur()"
      />

      <font-awesome-icon
        v-if="removable"
        class="pui-textfield__suffix pui-textfield__delete"
        size="sm"
        :icon="['fal', 'trash-alt']"
        @click.stop="$emit('delete')"
      />

      <template v-if="suffixIcon">
        <font-awesome-icon
          v-if="suffixIconType === 'fa' || suffixIconType === 'fal'"
          :icon="[suffixIconType, suffixIcon]"
          class="pui-textfield__suffix"
          :class="{ 'pui-textfield__suffix--focused': focused }"
          size="lg"
          :disabled="disabled"
          @click.stop="$emit('suffixIconClick')"
        />

        <PUIIcon
          v-else
          :name="suffixIcon"
          class="pui-textfield__suffix"
          :class="{ 'pui-textfield__suffix--focused': focused }"
          size="small"
          :disabled="disabled"
          @click.stop="$emit('suffixIconClick')"
        />
      </template>
    </div>

    <div
      v-if="hasOptions"
      ref="dropdown_field"
      class="pui-textfield__options"
    >
      <DropdownOptions
        v-if="showOptions"
        :value="inputValue"
        :textKey="optionTextKey"
        :valueKey="optionValueKey"
        :options="filteredOptions"
        :absoluteTop="height"
        valueType="value"
        @change="optionSelected"
      >
        <slot
          slot-scope="scope"
          :option="scope.option"
          :index="scope.index"
          name="option"
        />
      </DropdownOptions>
    </div>

    <div
      v-if="error"
      class="pui-textfield__error help is-danger"
    >
      <span class="pui-textfield__error__text">{{ error }}</span>
      <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
    </div>
  </div>
</template>

<script>
const DropdownOptions = () => import('./DropdownOptions.vue');

export default {
  name: 'PUITextField',
  components: {
    DropdownOptions,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator: val => ['text', 'email', 'password', 'url', 'number', 'phone'].includes(val),
    },
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    italic: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    integerOnly: {
      type: Boolean,
      default: null,
    },
    showMax: {
      type: Boolean,
      default: true,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'light',
      validator: theme => ['light', 'grey-light', 'dark', 'grey-dark', 'underlined', 'dashed', 'grey-lighter'].indexOf(theme) > -1,
    },
    error: {
      type: String,
      default: null,
    },
    errorstate: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '40px',
    },
    prefixIcon: {
      type: String,
      default: null,
    },
    prefixIconType: {
      type: String,
      default: 'sui',
      validator(type) {
        return ['sui', 'fa', 'fal'].includes(type);
      },
    },
    suffixIcon: {
      type: String,
      default: null,
    },
    suffixIconType: {
      type: String,
      default: 'sui',
      validator(type) {
        return ['sui', 'fa', 'fal'].includes(type);
      },
    },
    padding: {
      type: String,
      default: '9px',
    },
    fontSize: {
      type: String,
      default: '13px',
    },
    textStyle: {
      type: Object,
      default: null,
    },
    inputRef: {
      type: String,
      default: null,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    boldLabel: {
      type: Boolean,
      default: false,
    },

    // Options
    hasOptions: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionTextKey: {
      type: String,
      default: 'text',
    },
    optionValueKey: {
      type: String,
      default: 'value',
    },
    optionSearchable: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
  },
  data() {
    return {
      focused: false,
      showOptions: false,
    };
  },
  computed: {
    inputListeners() {
      const vm = this;
      // `Object.assign` merges objects together to form a new object
      return {

        // We add all the listeners from the parent
        ...this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        // This ensures that the component works with v-model
        input(event) {
          vm.$emit('input', event.target.value);
        },
      };
    },
    inputValue: {
      get() {
        return this.value;
      },
      set() {},
    },
    maxProp() {
      if (this.max === null) return null;

      if (this.type === 'number') return { max: this.max };
      return { maxLength: this.max };
    },
    filteredOptions() {
      if (this.optionSearchable) {
        return this.options.filter(item => item[this.optionTextKey].toLowerCase().indexOf(this.inputValue.toLowerCase()) > -1);
      }
      return this.options;
    },
  },
  watch: {
    inputValue(newVal, oldVal) {
      if (this.type !== 'number' && this.max && newVal.length > this.max && newVal.length > oldVal.length) {
        this.$emit('input', oldVal);
        return;
      }
      if (this.type === 'number' && newVal !== '-') {
        if (newVal === null || newVal === undefined || newVal === '') {
          this.$emit('input', newVal);
          return;
        }
        if (Number.isNaN(Number(newVal))) {
          this.$emit('input', oldVal);
          return;
        }
        if (this.min != null && this.min > newVal) {
          this.$emit('input', oldVal);
          return;
        }
        if (this.max != null && this.max < newVal) {
          this.$emit('input', oldVal);
          return;
        }
        // if (this.integerOnly === true && !Number.isInteger(parseFloat(newVal))) {
        if (this.integerOnly && String(newVal).indexOf('.') > 0) {
          this.$emit('input', oldVal);
        }
      }
    },
  },
  mounted() {
    if (this.autofocus) {
      const _input = this.$refs.inputBox;
      const cursorPosition = _input.selectionEnd;
      _input.focus();
      this.$nextTick(() => {
        _input.selectionEnd = cursorPosition;
      });
    }

    // if (this.min > this.type === 'number') {
    //     this.$refs.inputBox.min = this.min;
    // }
  },
  methods: {
    onInputFocus() {
      this.focused = true;

      if (this.hasOptions) {
        this.showOptions = true;
      }

      this.$emit('textFieldFocused', this.$refs.inputBox);
    },
    onInputBlur() {
      this.focused = false;

      setTimeout(() => {
        this.showOptions = false;
      }, 100);
    },
    optionSelected(value) {
      this.$emit('input', value);
      this.showOptions = false;
    },
    clearValue() {
      this.$emit('input', '');
      this.$emit('clear');
    },

    focus() {
      if (this.$refs.inputBox) {
        this.focused = true;
        this.$refs.inputBox.focus();
      }
    },
  },
};
</script>

<style lang="scss">
@import '~styles/pui/pui-variables.scss';

@mixin appearance($main-color, $bg-color) {
  border: $main-color;
  background-color: $bd-color;
}

@mixin pui-textfield__theme(
  $border-color,
  $border-focus-color,
  $color,
  $disabled-color,
  $bg-color,
  $disabled-bg-color,
  $label-color,
  $disabled-label-color,
  $placeholder-color
) {
  .pui-textfield {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 7px;
    }

    &__inner {
      position: relative;
    }

    &__input {
      &::placeholder {
        color: $color-grey;
      }

      width: 100%;
      border: solid 1px $border-color;
      color: $color;
      background-color: $bg-color;
      outline: 0;
      font-family: $open-sans;
      line-height: 9px;
      font-size: 13px;
      border-radius: 0;
      box-shadow: none;

      &:focus:not(:disabled):not([readonly]) {
        border: solid 1px $border-focus-color;
      }

      &:disabled {
        color: $color;
        border: 1px solid $color-grey-light;
        background-color: $disabled-bg-color;
        cursor: not-allowed;
        opacity: 0.5;
      }

      &[readonly] {
        background-color: $disabled-bg-color;
      }

      &.has-error {
        border: solid 1px $color-error;
      }

      &.is-italic {
        border: solid 1px #eeeeee;
        font-style: italic;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $placeholder-color;
        /* Firefox */
        opacity: 1;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $placeholder-color;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $placeholder-color;
      }
    }

    &__label {
      font-family: $montserrat;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.08px;
      color: $label-color;
      text-align: start;

      &[disabled]{
        opacity: 0.5;
      }

      &::first-letter {
        text-transform: uppercase;
      }

      &--bold {
        font-weight: 600;
      }
    }

    [dir="rtl"] &__max-label {
      float: left;
    }
    &__max-label {
      height: 14px;
      float: right;
      font-family: $montserrat;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.08px;
      color: $label-color;

      &--bold {
        font-weight: 600;
      }
    }

    &__help-text {
      font-family: $open-sans;
      font-size: 12px;
      color: $color-grey-light-darker;
    }

    &__clear {
      font-family: $open-sans;
      font-size: 11px;
      line-height: 14px;
      color: #6e6e6e;
      cursor: pointer;
    }

    &__error {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-family: $open-sans;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      color: $color-error;
      margin-top: 10px;

      //To remove the left space padding in Firefox
      &__text::before {
        content:"";
        position:absolute;
        top:0;
        left:0;
        right:0;
      }

      &__text:first-letter {
        text-transform: uppercase;
      }

      svg {
        min-width: 14px;
        min-height: 12px;
        margin-inline-start: 10px;
      }
    }

    &--prefix,
    &--suffix {
      position: relative;
    }

    &__prefix,
    &__suffix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $border-color;
      cursor: pointer;
    }
    &__suffix[disabled] {
      cursor: not-allowed;
    }

    &__prefix {
      left: 9px;
      width: 16px;
      height: 16px;

      &--focused {
        color: $border-focus-color;
      }
    }

    &__suffix {
      right: 9px;
      width: 16px;
      height: 16px;

      &--focused {
        color: $border-focus-color;
      }
    }
  }
}

.pui-textfield {
  &__options {
    position: relative;
  }

  &--dashed {
    @include pui-textfield__theme(
      $color-grey-light,
      $color-grey-light,
      $color-black-like,
      $color-white,
      $color-white,
      $color-grey-light,
      $color-black,
      $color-grey-like,
      $color-grey-light
    );

    .pui-textfield {
      &__input {
        &,
        &hover,
        &:focus {
          border: 1px dashed $color-grey-light;
        }
      }
    }
  }

  &--underlined {
    @include pui-textfield__theme(
      $border-color: $color-grey,
      $border-focus-color: $color-black,
      $color: $color-black,
      $disabled-color: $color-white,
      $bg-color: $color-white,
      $disabled-bg-color: $color-grey-light,
      $label-color: $color-black,
      $disabled-label-color: $color-grey-like,
      $placeholder-color: $color-form-placeholder
    );

    .pui-textfield {
      &__input {
        &,
        &hover,
        &:focus {
          border-top: none;
          border-inline-start: none;
          border-inline-end: none;
          background: transparent;
          max-width: 100% !important;
          width: auto !important;
          min-height: auto !important;
          height: auto !important;
          padding: 0 2px !important;
        }
      }
    }
  }

  &--light {
    @include pui-textfield__theme(
      $border-color: $color-grey,
      $border-focus-color: $color-black,
      $color: $color-black,
      $disabled-color: $color-white,
      $bg-color: $color-white,
      $disabled-bg-color: #e5e5e5,
      $label-color: $color-black,
      $disabled-label-color: $color-grey-like,
      $placeholder-color: $color-form-placeholder
    );
  }

  &--dark {
    @include pui-textfield__theme(
      $border-color: $color-grey,
      $border-focus-color: $color-grey-light,
      $color: $color-white,
      $disabled-color: $color-white,
      $bg-color: $color-black,
      $disabled-bg-color: #161616,
      $label-color: $color-white,
      $disabled-label-color: $color-grey-like,
      $placeholder-color: $color-form-placeholder
    );
  }

  &--grey-dark {
    @include pui-textfield__theme(
      $border-color: $color-grey,
      $border-focus-color: $color-grey-light,
      $color: $color-white,
      $disabled-color: $color-white,
      $bg-color: $color-grey-dark,
      $disabled-bg-color: $color-grey-dark,
      $label-color: $color-white,
      $disabled-label-color: $color-grey-like,
      $placeholder-color: $color-form-placeholder
    );
  }

  &--grey-light {
    @include pui-textfield__theme(
      $border-color: $color-grey-light,
      $border-focus-color: $color-grey-dark,
      $color: $color-black,
      $disabled-color: $color-black,
      $bg-color: $color-white,
      $disabled-bg-color: $color-grey-light,
      $label-color: $color-grey-dark,
      $disabled-label-color: $color-grey-like,
      $placeholder-color: $color-form-placeholder
    );
  }

  &--grey-lighter {
    @include pui-textfield__theme(
      $color-grey-lighter,
      $color-black,
      $color-black,
      $color-black,
      $color-white,
      $color-grey-light,
      $color-grey-dark,
      $color-grey-like,
      $color-form-placeholder
    );

    .pui-textfield {
      &__prefix,
      &__suffix {
        color: $color-grey-light;

        &--focused {
          color: $color-black;
        }
      }
    }
  }

  &--grey-lighter {
    @include pui-textfield__theme(
      $color-grey-lighter,
      $color-black,
      $color-black,
      $color-black,
      $color-white,
      $color-grey-light,
      $color-grey-dark,
      $color-grey-like,
      $color-form-placeholder
    );

    .pui-textfield {
      &__prefix,
      &__suffix {
        color: $color-grey-light;

        &--focused {
          color: $color-black;
        }
      }
    }
  }

  &.pui-textfield--removable {
    .pui-textfield__inner {
      .pui-textfield__input {
        padding-inline-start: 16px !important;
      }

      &:hover {
        .pui-textfield__input {
          border-color: $color-grey-lighter;
          background-color: $color-grey-lighter;
          border-inline-start: 6px solid $color-yellow;
          padding-inline-start: 10px !important;
        }

        .pui-textfield__delete {
          display: block;
          top: 16px;
          height: 18px;
        }

        .pui-textfield__suffix:not(.pui-textfield__delete) {
          display: none;
        }
      }

      .pui-textfield__delete {
        display: none;
      }
    }
  }

  // &--required {
  //   .pui-textfield__label::after {
  //     content: '*';
  //   }
  // }
}
</style>
