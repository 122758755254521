<template>
  <div
    :class="[colorOption, { 'pui-checkbox--disabled': disabled, 'pui-checkbox--readonly': readonly }]"
    class="pui-checkbox"
  >
    <input
      :id="id"
      ref="input"
      v-model="inputVal"
      type="checkbox"
      :name="name"
      :value="value"
      :disabled="disabled"
      :class="{ 'pui-checkbox__input--partial': partial }"
      class="pui-checkbox__input"
    />
    <label
      :for="id"
      class="pui-checkbox__label"
    >
      <span
        v-if="label"
        class="pui-checkbox__label__text"
        :class="{'pui-checkbox__label__ellipsis': maxLabelWidth}"
        :style="{'maxWidth': maxLabelWidth }"
      >{{ label }}</span>
      <font-awesome-icon
        v-if="tooltip"
        v-tooltip.top="{
          content: tooltip,
          offset: 10,
          popperOptions: {
            modifiers: {
              preventOverflow: { boundariesElement: tooltipBoundary },
            },
          },
        }"
        :icon="['fal', 'question-circle']"
        class="pui-checkbox__icon"
        size="lg"
      />
    </label>
  </div>
</template>

<script>
export default {
  name: 'PUICheckbox',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    maxLabelWidth: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: [Array, Boolean],
      default: false,
    },
    partial: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tooltipBoundary: 'window',
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.checked;
      },
      set(val) {
        if (!this.readonly) {
          this.$emit('change', val);
        }
      },
    },
    id() {
      return this._uid;
    },
    colorOption() {
      return this.dark ? 'pui-checkbox--dark' : 'pui-checkbox--light';
    },
  },
  mounted() {
    if (document.querySelector('.pui-platform-page__content')) {
      this.tooltipBoundary = document.querySelector('.pui-platform-page__content');
    }
  },
  methods: {
    onCheck() {
      return !this.readonly;
    },
  },
};
</script>
<style lang="scss">
@import '~styles/pui/pui-variables.scss';

@mixin transition() {
  -webkit-transition: 150ms ease-out;
  -moz-transition: 150ms ease-out;
  -o-transition: 150ms ease-out;
  transition: 150ms ease-out;
}

@mixin setBackgroundColor($bg-color) {
  & + label {
    &:before {
      background-color: $bg-color;
      border: none;
    }
  }
}

@mixin setBorderColor($bg-color) {
  & + label {
    &:before {
      border-color: $bg-color;
    }
  }
}

@mixin setArrowColor($arrow-color) {
  & + label {
    &:after {
      @include arrow($arrow-color);
    }
    [dir="rtl"] &:after {
        @include arrow-rtl($arrow-color);
    }
  }
}

@mixin setDashColor($dash-color) {
  & + label {
    &:after {
      @include partial($dash-color);
    }
  }
}

@mixin arrow($color) {
  content: '';
  @include transition();
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 7px;
  height: 11px;
  border: solid 1px $color;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@mixin arrow-rtl($color) {
  content: '';
  @include transition();
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 7px;
  height: 11px;
  border: solid 1px $color;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@mixin partial($color) {
  content: '';
  @include transition();
  display: block;
  position: absolute;
  left: 5px;
  // right: 8.5px;
  top: 8.5px;
  width: 8px;
  height: 2px;
  background-color: $color;
}

@mixin pui-checkbox($main-color, $arrow-color, $border-color) {
  & > .pui-checkbox__input {
    &:checked:not(.pui-checkbox__input--partial) {
      & + label {
        &:before {
          background-color: $main-color;
        }

        &:after {
          @include arrow($arrow-color);
        }

        [dir="rtl"]  &:after {
          @include arrow-rtl($arrow-color);
        }
      }
    }

    &--partial {
      & + label {
        &:before {
          background-color: $main-color;
        }

        &:after {
          @include partial($arrow-color);
        }
      }
    }
    @include setBorderColor($border-color);
  }
}

.pui-checkbox {

  position: relative;
  display: inline-block;
  padding: 0;
  cursor: pointer;

  &--dark {
    @include pui-checkbox($color-white, $color-black, $color-white);
  }

  &--light {
    @include pui-checkbox($color-black, $color-white, $color-black-light);
  }

  &--readonly {
    pointer-events: none;
  }
  &--disabled {
    cursor: not-allowed;

    .pui-checkbox__label {
      color: $color-grey-light;
    }
  }

  &__input {
    position: absolute;
    @include transition();
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: pointer;
    top: -11px;
    inset-inline-start: -11px;
    -webkit-appearance: none;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    outline: none;

    &:hover:not([disabled]) {
      // background: $color-yellow;
      opacity: 0.12;
      outline: none;
    }

    &:not(&--partial) {
      & + label {
        &:before {
          background-color: transparent;
        }
      }
    }

    &:checked:not([disabled]) {
      &:hover,
      &:focus {
        @include setBackgroundColor(#f9c727);
        @include setArrowColor($color-black);
      }

      &:active {
        @include setBackgroundColor(#e1b423);
        @include setArrowColor($color-black);
      }
    }

    &--partial {
      &:hover,
      &:focus {
        @include setBackgroundColor(#f9c727);
        @include setDashColor($color-black);
      }

      &:active {
        @include setBackgroundColor(#e1b423);
        @include setDashColor($color-black);
      }
    }

    &:not([disabled]) {
      &:hover,
      &:focus {
        @include setBorderColor(#f9c727);
      }

      &:active {
        @include setBorderColor(#e1b423);
      }
    }

    &[disabled] {
      cursor: not-allowed;

      & + label {
        cursor: not-allowed;

        &:before {
          opacity: 0.14;
          cursor: not-allowed;
        }
      }
    }
  }

  &__label {
    position: relative;
    font-family: $open-sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    color: $color-black;
    cursor: pointer;

    &::first-letter {
      text-transform: uppercase;
    }

    &__text {
      margin: 0 11px;
      vertical-align: middle;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:before {
      @include transition();
      content: '';
      -webkit-appearance: none;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      border: solid 1.5px;
    }
  }

  &__icon {
    outline: none;
    position: relative;
    line-height: normal;
    margin-top: 4px;
    color: $color-grey;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: $color-black;
    }
  }
}
</style>
