import i18n from '@/lang/i18n';

export default {
  EVENT_ID_PREFIX: 'evt_',
  ACTION_ID_PREFIX: 'act_',
  DEFAULT_PARENT_TYPE: 'CHAT_STARTED',
  BUTTON_CLICKED: 'BUTTON_CLICKED',
  DEFAULT_PARENT_NAME: i18n.t('blocksv2:conversation_start'),
  BLOCK_TYPE_EVENT: 'event',
  BLOCK_TYPE_ACTION: 'action',
  BLOCK_TYPE_NESTED_ACTION: 'nested_action',

  TOOLBOX_TYPE: {
    TEST_CHAT: 'test_chat',
    BLOCK_TYPES: 'block_types',
    BLOCK_DETAIL: 'props',
    DELETE_BLOCK_PROMPT: 'delete_prompt',
    MOVE_EVENT_TO_NEW_PARENT: 'move_event_to_new_parent',
    CHANGE_EVENT_TYPE: 'change_event_node_type',
  },
};
