<template>
  <button
    type="button"
    class="pui-icon-button"
    :class="{ 'loading': loading }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <font-awesome-icon
      v-bind="renderedIcon"
    />
  </button>
</template>

<script>
export default {
  name: 'PUIIconButton',
  props: {
    icon: {
      type: [String, Array],
      required: true,
    },
    iconProps: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },
  computed: {
    renderedIcon() {
      if (this.loading) {
        const loadingIcon = 'spinner-third';

        let iconName;

        if (Array.isArray(this.icon)) {
          iconName = [this.icon[0], loadingIcon];
        } else {
          iconName = loadingIcon;
        }

        return {
          spin: true,
          icon: iconName,
        };
      }

      return {
        ...this.iconProps,
        icon: this.icon,
      };
    },
  },
  methods: {
    focus() {
      this.$el.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';

.pui-icon-button {
  border: unset;
  outline: unset;
  background: transparent;
  color: #aaaaaa;
  padding: 10px 10px;
  margin: -10px;
  cursor: pointer;

  &:not(.loading):not(:disabled) {
    &:focus,
    &:hover {
      color: black;
    }
  }

  &:disabled {
    color: $color-grey-light;
    cursor: not-allowed;
  }

  &.loading {
    color: $color-yellow;
    cursor: progress;
  }

  & + & {
    margin-left: 0px;
  }
}
</style>
