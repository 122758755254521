export default (message, $store) => {
  const { dispatch } = $store;

  dispatch('hideChatInput', message.cid);

  dispatch('setConversationIframeModal', {
    conversationId: message.cid,
    src: message.src,
    iframeId: message.iframe_id,
    onClose: message.on_close,
  });
};
