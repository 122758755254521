import Vue from 'vue';
import Vuex from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import $bus from '@/platformSettings/bus';
import ToolsDef from '@/platformSettings/tools';
import CONSTANTS from '@/platformSettings/editorStaticVerbiages';
import { recursiveFindComponent } from '@/helpers/blocksV2Helper';
import { createMapping } from '@/utils';
import i18n from '@/lang/i18n';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    toolbox: null,
    tool: null,
    toolsError: null,
    enableCanvasMask: false,
    activeCanvas: true,
    liveChatActive: false,
    editorCanvasWidth: 0,
    draggingBlock: {
      blockType: null,
      block: null,
    },

    processingBlocks: {},
  },
  getters: {
    isActiveCanvas: state => state.activeCanvas,

    // Add blocks
    blockMenuIsOpened: state => [CONSTANTS.TOOLBOX_TYPE.BLOCK_TYPES, CONSTANTS.TOOLBOX_TYPE.CHANGE_EVENT_TYPE].includes(state.tool?.toolboxType)
      && state.tool?.meta?.triggeredBlock,

    isToolboxTestChat: state => {
      if (state.tool) {
        return state.tool.component === 'ToolkitTestChat';
      }
      return false;
    },
  },
  mutations: {
    _setToolbox(state, tool) {
      state.tool = Object.freeze(tool);
    },
    _setLiveChat(state, bool) {
      state.liveChatActive = bool;
    },
    _setActiveCanvas(state, bool) {
      if (state.enableCanvasMask) {
        state.activeCanvas = bool;
      }
    },
    _setToolboxPropertyName(state, name) {
      state.tool.properties.name = name;
    },
    _setToolboxProperties(state, params) {
      if (state.tool && state.tool.properties) {
        state.tool.properties.params = params;
      }
    },
    _setEditorCanvasWidth(state, width) {
      state.editorCanvasWidth = `${width}px`;
    },
    _setDraggingBlock(state, { blockType, block }) {
      state.draggingBlock.blockType = blockType;
      state.draggingBlock.block = block;
    },

    _addProcessingBlock(state, blockId) {
      Vue.set(state.processingBlocks, blockId, true);
    },

    _removeProcessingBlock(state, blockId) {
      Vue.delete(state.processingBlocks, blockId);
    },
  },
  actions: {
    toggleLiveChat({ commit, state }, bool) {
      const toggled = bool == null ? !state.liveChatActive : bool;
      commit('_setLiveChat', toggled);
    },

    switchToolbox({ dispatch }, props) {
      const type = props.type && props.type.toLowerCase();

      switch (type) {
        case CONSTANTS.TOOLBOX_TYPE.BLOCK_TYPES:
          return dispatch('_blockToolBox', props);

        case CONSTANTS.TOOLBOX_TYPE.DELETE_BLOCK_PROMPT:
          return dispatch('_deletePrompt', props);

        case CONSTANTS.TOOLBOX_TYPE.BLOCK_DETAIL:
          return dispatch('_propsNode', props);

        // Move event to new parent
        case CONSTANTS.TOOLBOX_TYPE.MOVE_EVENT_TO_NEW_PARENT:
          return dispatch('_moveEventToNewParentPrompt', props);

        case CONSTANTS.TOOLBOX_TYPE.CHANGE_EVENT_TYPE:
          return dispatch('_changeEventType', props);

        default:
          return dispatch('_toolBox', props);
      }
    },
    // If you want to immediately re-open the toolbox, set `setCanvasActive` to `false`,
    // so the page wouldn't flicker.
    closeToolbox({ commit }, setCanvasActive = true) {
      return new Promise(resolve => {
        commit('_setToolbox', null);
        commit('_setActiveCanvas', setCanvasActive);
        return resolve();
      });
    },
    _propsNode({ commit, dispatch, rootGetters }, { type: toolboxType, triggeredBlock, meta = {} }) {
      if (triggeredBlock.type.toUpperCase() === CONSTANTS.DEFAULT_PARENT_TYPE) {
        // Do nothing for now.
      } else {
        const botLang = rootGetters['botv2/currentBotLang'];

        let type = null;
        let properties = {};
        const _meta = {
          ...meta,
          triggeredBlock,
        };

        // Is event block
        if (triggeredBlock.id.startsWith(CONSTANTS.EVENT_ID_PREFIX)) {
          type = CONSTANTS.BLOCK_TYPE_EVENT;

          properties = {
            bot_lang: botLang,
            event_type: triggeredBlock.type,
            target_id: triggeredBlock.target_id,
            event_id: triggeredBlock.id,
            name: triggeredBlock.blocName,
            params: triggeredBlock.params,
          };
        } else if (triggeredBlock.id.startsWith(CONSTANTS.ACTION_ID_PREFIX)) {
          // Is action block

          type = CONSTANTS.BLOCK_TYPE_ACTION;

          properties = {
            bot_lang: botLang,
            event_id: triggeredBlock.event_id,
            action_id: triggeredBlock.id,
            action_type: triggeredBlock.type,
            ancestor_ids: triggeredBlock.ancestor_ids,
            name: triggeredBlock.blocName,
            params: triggeredBlock.params,
          };
        }

        const component = recursiveFindComponent(type, triggeredBlock.type);

        if (Object.keys(component).length === 0) {
          dispatch('closeToolbox');
        } else {
          const props = {
            //
            toolboxType,
            //
            ...component,
            // Toolbox header props
            header: {
              title: component.title,
              class: component.header.class,
              tooltip: component.header.tooltip,
              tooltip_link: component.header.tooltip_link,
              icon: {
                name: component.icon,
              },
            },
            properties,
            // Additional info for this toolbox type
            meta: _meta,
            // Callback function
            close: () => {
              dispatch('closeToolbox');
              $bus.$emit('nodePropsClosed');
            },
          };

          commit('_setActiveCanvas', false);
          commit('_setToolbox', props);
        }
      }
    },
    _toolBox({ commit, dispatch }, props) {
      const { type, meta } = props;
      const toolDef = ToolsDef[type.toLowerCase()];
      if (toolDef == null) {
        console.log('does not exists in ToolsDef option.');
        dispatch('closeToolbox');
      } else {
        const toolboxDef = {
          ...toolDef,
          toolboxType: type,
          close: () => {
            dispatch('closeToolbox');
          },
        };
        toolboxDef.properties.meta = meta;
        commit('_setActiveCanvas', false);
        commit('_setToolbox', toolboxDef);
      }
    },
    _deletePrompt({ commit, dispatch }, props) {
      const toolDef = ToolsDef.delete_node;
      let title = null;
      if (props.mode === 'delete_bot') {
        title = i18n.t('blocksv2:delete.bot');
      } else {
        title = i18n.t('blocksv2:delete.block');
      }

      const object = {
        //
        toolboxType: CONSTANTS.TOOLBOX_TYPE.DELETE_BLOCK_PROMPT,
        //
        ...toolDef,
        // Toolbox header props
        header: {
          title,
        },
        properties: {
          bot_id: props.meta.botId,
          mode: props.meta.mode,
          node_type: props.meta.nodeType,
          block_type: props.meta.blockType,
          block_name: props.meta.blockName,
          action_id: props.meta.actionId,
          event_id: props.meta.eventId,
          isRoot: props.meta.isRoot,
          source_id: props.meta.source_id,
          outer_action_id: props.meta.outer_action_id,
          relation_index_to_outer_action: props.meta.relation_index_to_outer_action,
          prev_action_id: props.meta.prev_action_id,
        },
        // Additional info for this toolbox type
        meta: {
          ...props.meta,
          // The block that triggers to open toolbox
          triggeredBlock: props.triggeredBlock,
        },
        // Callback function
        close: () => {
          dispatch('closeToolbox');
        },
      };
      commit('_setActiveCanvas', false);
      commit('_setToolbox', object);
    },
    _changeEventType({ commit, dispatch }, {
      type, targetBlockType, triggeredBlock,
    }) {
      const toolDef = cloneDeep(ToolsDef.block_types);

      const object = {
        //
        toolboxType: CONSTANTS.TOOLBOX_TYPE.CHANGE_EVENT_TYPE,
        //
        ...toolDef,
        // Toolbox header props
        header: {
          // Title text
          title: i18n.t('blocksv2:change_event_node_type'),
        },
        // Additional info for this toolbox type
        meta: {
          // Block type to add. ('event', 'action', 'nested_action')
          targetBlockType,
          // The block that triggers to open toolbox
          triggeredBlock,
        },
        // Callback function
        close: () => {
          dispatch('closeToolbox');
        },
      };
      commit('_setActiveCanvas', false);
      commit('_setToolbox', object);
    },
    _blockToolBox({ commit, dispatch }, {
      targetBlockType, triggeredBlock, meta = {},
    }) {
      const toolDef = cloneDeep(ToolsDef.block_types);

      /**
        rules:
          events -> events
          actions -> actions
      */
      toolDef.properties.blocks.forEach(blockGroup => {
        if (blockGroup.types.indexOf(targetBlockType) === -1) {
          blockGroup.collapse = true;
          blockGroup.disabled = true;
        }
      });
      const headerTitle = {
        [CONSTANTS.BLOCK_TYPE_EVENT]: i18n.t('blocksv2:add_block_event'),
        [CONSTANTS.BLOCK_TYPE_ACTION]: i18n.t('blocksv2:add_block_action'),
        [CONSTANTS.BLOCK_TYPE_NESTED_ACTION]: i18n.t('blocksv2:add_block_nested_action'),
      };

      const object = {
        //
        toolboxType: CONSTANTS.TOOLBOX_TYPE.BLOCK_TYPES,
        //
        ...toolDef,
        // Toolbox header props
        header: {
          // Title text
          title: headerTitle?.[targetBlockType],
        },
        // Additional info for this toolbox type
        meta: {
          ...meta,
          // Block type to add. ('event', 'action', 'nested_action')
          targetBlockType,
          // The block that triggers to open toolbox
          triggeredBlock,
        },
        // Callback function
        close: () => {
          dispatch('closeToolbox');
        },
      };
      commit('_setActiveCanvas', false);
      commit('_setToolbox', object);
    },

    _moveEventToNewParentPrompt({ commit, dispatch }, props) {
      const toolDef = ToolsDef.move_event;
      const sourceEventId = props.triggeredBlock.id;

      const object = {
        ...toolDef,
        sourceEventId,
        close: () => {
          dispatch('closeToolbox');
        },
      };
      commit('_setActiveCanvas', false);
      commit('_setToolbox', object);
    },

    changeBlocName({ commit, dispatch }, name) {
      commit('_setToolboxPropertyName', name);
    },
    changeProperties({ commit, dispatch }, params) {
      commit('_setToolboxProperties', params);
    },
    setCanvasWidth({ commit }, width) {
      commit('_setEditorCanvasWidth', width);
    },
    startDragBlock({ commit }, { blockType, block }) {
      commit('_setDraggingBlock', { blockType, block });
    },
    endDragBlock({ commit }) {
      commit('_setDraggingBlock', {
        blockType: null,
        block: null,
      });
    },

    addProcessingBlock({ commit }, blockId) {
      if (!blockId) return;
      const blockIds = Array.isArray(blockId) ? blockId : [blockId];
      const validBlockIds = blockIds.filter(id => !!id);

      for (let i = 0; i < validBlockIds.length; i += 1) {
        commit('_addProcessingBlock', validBlockIds[i]);
      }
    },

    removeProcessingBlock({ commit }, blockId) {
      if (!blockId) return;
      const blockIds = Array.isArray(blockId) ? blockId : [blockId];
      const validBlockIds = blockIds.filter(id => !!id);

      for (let i = 0; i < validBlockIds.length; i += 1) {
        commit('_removeProcessingBlock', validBlockIds[i]);
      }
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
