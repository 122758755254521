var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    _vm._g(
      _vm._b(
        {
          staticClass: "pui-icon-button",
          class: { loading: _vm.loading },
          attrs: { type: "button" }
        },
        "button",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "font-awesome-icon",
        _vm._b({}, "font-awesome-icon", _vm.renderedIcon, false)
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }