import isEmpty from 'lodash/isEmpty';

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '@/router/platform';

import $bus from '@/platformSettings/bus';
import { RESOURCE_STATE } from '@/constants.js';

Vue.use(Vuex);

const { CancelToken } = axios;
let cancelGetSurvey;
let cancelGetSurveys;

export default {
  namespaced: true,
  state: {
    currentSurvey: null,
    surveyError: null,
    surveys: [],
    surveyCurrentPage: 1,
    surveyPerPage: 15,
    surveyPages: 0,
    surveyAmount: 0,
    surveyOrderBy: 'desc',
    surveyFilter: null,
    browsingHistory: {},
    customerNotes: [],
    userInfo: null,
    isSearching: false,
    surveyBlocks: [],
    fetchingSurveyState: RESOURCE_STATE.IDLE,
    companyId: null,
    botId: null,
  },
  getters: {
    currentSurveyLogIndex(state) {
      if (state.currentSurvey) {
        return state.surveys.findIndex(item => item.id === state.currentSurvey.id);
      }
      return null;
    },
  },
  mutations: {
    _setCompanyId(state, companyId) {
      state.companyId = companyId;
    },
    _setBotId(state, botId) {
      state.botId = botId;
    },
    _setCurrentSurvey(state, survey) {
      state.currentSurvey = Object.freeze(survey);
    },
    _setSurveyError(state, error) {
      state.surveyError = error;
    },
    _setSurveys(state, surveys) {
      state.surveys = surveys;
    },
    _setSurveyCurrentPage(state, currentPage) {
      state.surveyCurrentPage = currentPage;
    },
    _setSurveyPerPage(state, perPage) {
      state.surveyPerPage = perPage;
    },
    _setSurveyPages(state, pages) {
      state.surveyPages = pages;
    },
    _setSurveyAmount(state, amount) {
      state.surveyAmount = amount;
    },
    _setSurveyOrderBy(state, orderBy) {
      state.surveyOrderBy = orderBy;
    },
    _setSurveyFilter(state, filter) {
      state.surveyFilter = filter;
    },
    _setBrowsingHistory(state, value) {
      state.browsingHistory = value;
    },
    _setCustomerNotes(state, valueArr) {
      state.customerNotes = valueArr;
    },
    _setUserInfo(state, valueObj) {
      state.userInfo = valueObj;
    },

    _setIsSearching(state, payload) {
      state.isSearching = payload;
    },
    _setSurveyBlocks(state, payload) {
      state.surveyBlocks = payload;
    },
    _setFetchingSurveyState(state, resourceState) {
      const {
        IDLE, PENDING, RESOLVED, REJECTED,
      } = RESOURCE_STATE;

      if (![IDLE, PENDING, RESOLVED, REJECTED].includes(resourceState)) {
        throw Error(
          `Invalid payload: only IDLE, PENDING, RESOLVED, and REJECTED are allowed, got ${resourceState}.`
        );
      }

      state.fetchingSurveyState = resourceState;
    },
  },
  actions: {
    getBlocks({ commit }, { companyId, botId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/action:COLLECT_INFO`)
          .then(response => {
            const blocks = response.data;
            commit('_setSurveyBlocks', blocks);
            return resolve(blocks);
          })
          .catch(err => {
            console.error(err);
            return reject(err);
          });
      });
    },
    getSurvey(_, { companyId, botId, surveyId }) {
      if (cancelGetSurvey) {
        cancelGetSurvey();
      }

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`bot/v2/${companyId}/${botId}/survey_log/${surveyId}`, {
            cancelToken: new CancelToken(c => {
              cancelGetSurvey = c;
            }),
          })
          .then(response => {
            const survey = response.data;
            if (!survey) {
              const err = {};
              err.code = 404;
              return reject(err);
            }
            return resolve(survey);
          });
      });
    },
    setCurrentSurveyByIndex({ state, commit }, index) {
      if (index < 0 || index >= state.surveys.length) {
        throw Error('Invalid index');
      }

      if (state.surveys.length > 0) {
        const currentSurvey = state.surveys[index];
        commit('_setCurrentSurvey', currentSurvey);

        router
          .push({
            name: 'BotHistory',
            params: {
              companyId: state.companyId,
              botId: state.botId,
              history: 'survey',
              selectedId: currentSurvey.id,
            },
            query: state.surveyFilter,
          })
          .catch(() => { });
      }
    },
    getPreviousSurveyLog({
      state, rootState, getters, dispatch,
    }) {
      // Is first in not-first page
      if (state.surveyCurrentPage > 1 && getters.currentSurveyLogIndex === 0) {
        // Reduce page number
        dispatch('setSurveyCurrentPage', state.surveyCurrentPage - 1);
        // Get survey
        const { companyId, botId } = rootState.route.params;
        dispatch('getSurveys', { companyId, botId }).then(() => {
          // Set last one as current
          dispatch('setCurrentSurveyByIndex', state.surveys.length - 1);
        });
      } else if (getters.currentSurveyLogIndex > 0 && getters.currentSurveyLogIndex < state.surveyPerPage) {
        // Set previous as current
        dispatch('setCurrentSurveyByIndex', getters.currentSurveyLogIndex - 1);
      }
    },
    getNextSurveyLog({
      state, rootState, getters, dispatch,
    }) {
      if (
        state.surveyCurrentPage < state.surveyPages
        && state.surveyCurrentPage > 0
        && getters.currentSurveyLogIndex === state.surveys.length - 1
      ) {
        // Increase page number
        dispatch('setSurveyCurrentPage', state.surveyCurrentPage + 1);
        // Get survey
        const { companyId, botId } = rootState.route.params;
        dispatch('getSurveys', { companyId, botId }).then(() => {
          // Set first one as current
          dispatch('setCurrentSurveyByIndex', 0);
        });
      } else if (state.surveys[getters.currentSurveyLogIndex + 1]) {
        // Set next as current
        dispatch('setCurrentSurveyByIndex', getters.currentSurveyLogIndex + 1);
      }
    },
    getSurveys({ state, rootState, commit }, { companyId, botId }) {
      commit('_setFetchingSurveyState', RESOURCE_STATE.PENDING);

      return new Promise((resolve, reject) => {
        // Call api if date range is ready
        if (state.surveyFilter && (!state.surveyFilter.date_begin || !state.surveyFilter.date_end)) {
          commit('_setFetchingSurveyState', RESOURCE_STATE.RESOLVED);
          return resolve();
        }

        if (cancelGetSurveys) {
          cancelGetSurveys();
        }

        const formatting = 'YYYY-MM-DD';
        const dateQuery = {
          tz_name: rootState.uiv2.platformTimezone,
        };

        if (state.surveyFilter && state.surveyFilter.date_begin) {
          dateQuery.date_begin = Vue.dayjs(state.surveyFilter.date_begin).format(formatting);
        }

        if (state.surveyFilter && state.surveyFilter.date_end) {
          dateQuery.date_end = Vue.dayjs(state.surveyFilter.date_end).format(formatting);
        }

        const filters = {};
        const newFilter = state.surveyFilter || {};
        Object.entries(newFilter).forEach(([key, val]) => {
          if (val !== null && val !== '') {
            filters[key] = val;
          }
        });

        if (!isEmpty(filters)) {
          commit('_setIsSearching', true);
        } else {
          commit('_setIsSearching', false);
        }

        $bus.$siniticApi
          .post(`gateway/${companyId}/${botId}/survey_logs`, {
            data: {
              page: state.surveyCurrentPage,
              items_per_page: state.surveyPerPage,
              order_by: state.surveyOrderBy,
              ...filters,
              ...dateQuery,
            },
            cancelToken: new CancelToken(c => {
              cancelGetSurveys = c;
            }),
          })
          .then(response => {
            const results = response.data;
            const nonDuplicateItems = Object.values(
              results.items.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
            );
            commit('_setSurveys', nonDuplicateItems);
            commit('_setSurveyPages', results.total_pages);
            commit('_setSurveyAmount', results.total_items);
            commit('_setFetchingSurveyState', RESOURCE_STATE.RESOLVED);
            resolve(results);
          })
          .catch(error => {
            console.log(error);
            commit('_setFetchingSurveyState', RESOURCE_STATE.REJECTED);
            reject(error);
          });
      });
    },
    clearSurveys({ commit }) {
      commit('_setSurveyCurrentPage', 1);
      commit('_setSurveys', []);
    },
    resetSurvey({ commit }) {
      commit('_setCurrentSurvey', null);
      commit('_setSurveys', []);
      commit('_setSurveyCurrentPage', 1);
      commit('_setSurveyPages', 0);
      commit('_setSurveyAmount', 0);
      commit('_setSurveyOrderBy', 'desc');
      commit('_setSurveyFilter', null);
      commit('_setUserInfo', null);
      commit('_setBrowsingHistory', []);
    },
    setSurveyCurrentPage({
      state, commit, dispatch,
    }, page) {
      if (page < 0 || page > state.surveyPages) {
        throw Error('Invalid page');
      }

      commit('_setSurveyCurrentPage', page);

      dispatch('getSurveys', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total_items && data.total_items > 0) {
          dispatch('setCurrentSurveyByIndex', 0);
        }
      });
    },
    setSurveyPerPage({ state, commit, dispatch }, perPage) {
      commit('_setCurrentSurvey', null);
      commit('_setSurveyPerPage', perPage);
      commit('_setSurveyCurrentPage', 1);

      dispatch('getSurveys', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total_items && data.total_items > 0) {
          dispatch('setCurrentSurveyByIndex', 0);
        }
      });
    },
    setSurveyFilter({
      state, getters, commit, dispatch,
    }, filter) {
      commit('_setCurrentSurvey', null);
      commit('_setSurveyFilter', filter);
      commit('_setSurveyCurrentPage', 1);

      dispatch('getSurveys', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total_items && data.total_items > 0) {
          dispatch('setCurrentSurveyByIndex', getters.currentSurveyLogIndex ?? 0);
        }
      });
    },
    setSurveyOrderBy({ state, commit, dispatch }, orderBy) {
      commit('_setCurrentSurvey', null);
      commit('_setSurveyOrderBy', orderBy);
      commit('_setSurveyCurrentPage', 1);

      dispatch('getSurveys', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total_items && data.total_items > 0) {
          dispatch('setCurrentSurveyByIndex', 0);
        }
      });
    },

    getBrowsingHistory({ state, commit }) {
      if (!state.currentSurvey) {
        return;
      }

      const gwId = (state.currentSurvey.gateway || {}).id;
      const customerId = state.currentSurvey.user_id;

      if (gwId && customerId) {
        $bus.$siniticApi
          .get(`/gateway3/${gwId}/t/${encodeURIComponent(customerId)}`)
          .then(res => {
            commit('_setBrowsingHistory', res.data);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
