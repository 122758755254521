var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pui-switch-container" },
    [
      _vm.labelBefore
        ? _c(
            "label",
            {
              staticClass: "pui-switch-label",
              style: {
                color: _vm.labelColor
              },
              attrs: { for: _vm._uid }
            },
            [_vm._v(_vm._s(_vm.labelBefore))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "label",
        {
          class:
            ((_obj = {
              "pui-switch": true
            }),
            (_obj["pui-switch--" + _vm.theme] = !!_vm.theme),
            (_obj["pui-switch--disabled"] = _vm.disabled),
            _obj),
          attrs: { disabled: _vm.disabled }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputVal,
                expression: "inputVal"
              }
            ],
            ref: "el",
            attrs: { id: _vm._uid, disabled: _vm.disabled, type: "checkbox" },
            domProps: {
              value: _vm.value,
              checked: Array.isArray(_vm.inputVal)
                ? _vm._i(_vm.inputVal, _vm.value) > -1
                : _vm.inputVal
            },
            on: {
              change: function($event) {
                var $$a = _vm.inputVal,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = _vm.value,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.inputVal = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.inputVal = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.inputVal = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "pui-switch-slider" }, [
            _c("span", {
              staticClass: "pui-switch-slider-area",
              style: {
                backgroundColor: _vm.isActive
                  ? _vm.activeColor
                  : _vm.inactiveColor
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _vm.labelAfter
        ? _c(
            "label",
            {
              staticClass: "pui-switch-label",
              style: {
                color: _vm.labelColor
              },
              attrs: { for: _vm._uid }
            },
            [_vm._v("\n    " + _vm._s(_vm.labelAfter) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tooltip
        ? _c("font-awesome-icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top",
                value: {
                  content: _vm.tooltip,
                  offset: 10,
                  popperOptions: {
                    modifiers: {
                      preventOverflow: {
                        boundariesElement: _vm.tooltipBoundary
                      }
                    }
                  }
                },
                expression:
                  "{\n      content: tooltip,\n      offset: 10,\n      popperOptions: {\n        modifiers: {\n          preventOverflow: { boundariesElement: tooltipBoundary },\n        },\n      },\n    }",
                modifiers: { top: true }
              }
            ],
            staticClass: "pui-switch__icon",
            attrs: { icon: ["fal", "question-circle"], size: "lg" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }