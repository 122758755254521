var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        staticClass: "pui-table__row",
        class: {
          "pui-table__body__row": _vm.type === "body",
          "pui-table__head__row": _vm.type === "header",
          "pui-table__row__clickable": _vm.clickable
        },
        on: {
          mouseenter: function($event) {
            _vm.hovered = true
          },
          mouseleave: function($event) {
            _vm.hovered = false
          }
        }
      },
      _vm.inputListeners
    ),
    [_vm._t("default", null, { hovered: _vm.hovered })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }