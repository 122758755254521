export const HEARTBEAT_INTERVAL = 2 * 1000;

const RECONNECT_DELAY_SECONDS = [1000, 3000, 5000, 10000, 15000, 20000, 30000];
export const AUTO_RECONNECT_MAX_ATTEMPTS = RECONNECT_DELAY_SECONDS.length;

export const WS_STATUS = {
  OPENING: 'OPENING',
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
};

export const WEBCHAT_STATE = {
  INITIALIZING: 'INITIALIZING',
  READY: 'READY',
  STARTED: 'STARTED',
};

export const CANNED_REPLY_ORDER_BY = {
  MOST_RECENT: 'MOST_RECENT',
  MOST_POPULAR: 'MOST_POPULAR',
  ALPHABETICALLY: 'ALPHABETICALLY',
};

export const getWebchatEndpoints = $sinitic => {
  const env = ($sinitic.env || '').trim();

  let webchatIframeSrc = null;
  let baseApiURL = null;
  let wsURL = null;

  switch (env) {
    case 'local':
      baseApiURL = 'https://app.local.proto.cx:8088/api';
      webchatIframeSrc = 'http://localhost:8080/webchat/webchat2.html';
      wsURL = 'wss://app.local.proto.cx:8088/websocket/chat/V1';
      break;

    case 'local2':
      baseApiURL = 'https://app.local.proto.cx:8088/api';
      webchatIframeSrc = 'http://localhost:8082/webchat/webchat2.html';
      wsURL = 'wss://app.local.proto.cx:8088/websocket/chat/V1';
      break;

    case 'dev':
      baseApiURL = 'https://api.dev.proto.cx';
      webchatIframeSrc = 'https://app.dev.proto.cx/webchat/webchat2.html';
      wsURL = 'wss://ws.dev.proto.cx/websocket-new/chat/V1';
      break;

    case 'staging':
      baseApiURL = 'https://api.staging.proto.cx';
      webchatIframeSrc = 'https://app.staging.proto.cx/webchat/webchat2.html';
      wsURL = 'wss://ws.staging.proto.cx/websocket-new/chat/V1';
      break;

    case 'auto': // for localhost and ngrok
      {
        const { protocol, host } = window.location;
        const apiHost = host.replace(/\bapp|sui\b/, 'api').replace(/\b808[02]/, '8088');
        webchatIframeSrc = `${protocol}//${host}/webchat/webchat2.html`;
        baseApiURL = `${protocol}//${apiHost}/api`;
        const wsProtocol = protocol.replace('http', 'ws');
        wsURL = `${wsProtocol}//${apiHost}/websocket/chat/V1`;
      }
      break;

    case 'prod':
    default:
      baseApiURL = 'https://api.proto.cx';
      webchatIframeSrc = 'https://app.proto.cx/webchat/webchat2.html';
      wsURL = 'wss://ws.proto.cx/websocket-new/chat/V1';
  }
  const output = { baseApiURL, webchatIframeSrc, wsURL };
  // console.log("output", output);
  return output;
};

export const css = {
  displayBlock: {
    display: 'block',
  },
  bottomLeft: {
    left: '0px',
    bottom: '0px',
    top: 'auto',
    right: 'auto',
  },
  bottomRight: {
    right: '0px',
    bottom: '0px',
    top: 'auto',
    left: 'auto',
  },
  topLeft: {
    left: '0px',
    top: '0px',
    bottom: 'auto',
    right: 'auto',
  },
  topRight: {
    right: '0px',
    top: '0px',
    bottom: 'auto',
    left: 'auto',
  },
  button: {
    width: '100px',
    height: '100px',
  },

  mobile: {
    // DO NOT CHANGE {top,left,bottom,right} HERE!

    sneakPeek: {
      width: '100%',
      height: '400px',
    },
    chat: {
      width: '100%',
      height: '100%',
    },
  },
  desktop: {
    // DO NOT CHANGE {top,left,bottom,right} HERE!

    sneakPeek: {
      width: '350px',
      height: '400px',
    },
    chat: {
      width: '422px',
      height: '707px',
    },
  },
};

export const breakpoints = {
  // mobile: 768
  mobile: 540,
};

export const buildQueryString = queryObj => {
  const queryString = Object.entries(queryObj)
    .filter(([key, value]) => value != null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  return queryString;
};

export const getExponentialBackoff = retryAttempts => {
  const delayIndex = retryAttempts >= AUTO_RECONNECT_MAX_ATTEMPTS ? AUTO_RECONNECT_MAX_ATTEMPTS - 1 : retryAttempts;

  return Math.floor(Math.random() * 1000 + RECONNECT_DELAY_SECONDS[delayIndex]);
};

export const DEFAULT_STYLE = {
  BRANDING_COLOR: '#000000',
  MAIN_BUTTON_COLOR: '#F9C927',
  QUICK_REPLY_COLOR: '#000000',
  NOTIFICATION_COLOR: '#D0021B',
  ENABLE_EMOJIS: false,
  POSITION: 'BR',
  MOBILE_WIDTH: '100%',
  MOBILE_HEIGHT: '100%',
};
