var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "dropdown",
      staticClass: "pui-dropdown-option",
      on: {
        scroll: _vm.detectScrollToEnd,
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.$emit("esc")
        }
      }
    },
    [
      _vm.searchable
        ? _c(
            "span",
            {
              staticClass: "pui-dropdown-option__search",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c("font-awesome-icon", {
                staticClass: "pui-dropdown-option__search-icon",
                attrs: { icon: ["fal", _vm.icon], size: "xs" }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.bindSearchKeyword,
                    expression: "bindSearchKeyword"
                  }
                ],
                ref: "searchInput",
                staticClass: "pui-dropdown-option__search-field",
                attrs: {
                  dir: "auto",
                  placeholder: _vm.searchPlaceholder,
                  type: "text"
                },
                domProps: { value: _vm.bindSearchKeyword },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.bindSearchKeyword = $event.target.value
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "pui-dropdown-option__loading" },
            [
              _c("span", [_vm._v(_vm._s(_vm.loadingText))]),
              _vm._v(" "),
              _vm.loadingLoader
                ? _c("PUILoader", { attrs: { size: "small" } })
                : _vm._e()
            ],
            1
          )
        : !_vm.filteredOptions.length
        ? _c("div", { staticClass: "pui-dropdown-option__info" }, [
            _vm.addOption
              ? _c("span", [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.noResultPlaceholder.text) +
                      "\n      "
                  ),
                  _c("a", { on: { click: _vm.addOptionToggle } }, [
                    _vm._v(_vm._s(_vm.noResultPlaceholder.action))
                  ])
                ])
              : _c("span", [_vm._v(_vm._s(_vm.noResultPlaceholder.text))])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? [
            _vm.grouped
              ? _vm._l(_vm.filteredOptions, function(group) {
                  return _c(
                    "div",
                    {
                      key: group.label,
                      staticClass: "pui-dropdown-option__group"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pui-dropdown-option__group__label" },
                        [
                          _c(
                            "span",
                            { staticClass: "pui-dropdown-option__text" },
                            [_vm._v(_vm._s(group.label))]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(group.options, function(item) {
                        return _c(
                          "span",
                          {
                            key: item[_vm.valueKey],
                            staticClass: "pui-dropdown-option__item",
                            class: [
                              {
                                "pui-dropdown-option__item--active":
                                  _vm.getIndexOfAllOptions(
                                    item[_vm.valueKey]
                                  ) === _vm.activeIndex,
                                "pui-dropdown-option__item--selected": _vm.isSelected(
                                  item
                                ),
                                "pui-dropdown-option__item--disabled": _vm.isDisabled(
                                  item
                                )
                              },
                              _vm.customClass
                            ],
                            attrs: { id: item[_vm.valueKey] },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.select(item)
                              },
                              mouseover: function($event) {
                                _vm.activeIndex = _vm.getIndexOfAllOptions(
                                  item[_vm.valueKey]
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pui-dropdown-option__item__inner"
                              },
                              [
                                _vm.multiple
                                  ? _c("PUICheckbox", {
                                      staticClass:
                                        "pui-dropdown-option__item__checkbox",
                                      attrs: {
                                        checked: _vm.selectedOption,
                                        value: item[_vm.valueKey],
                                        disabled: _vm.isDisabled(item)
                                      },
                                      on: {
                                        change: function() {
                                          return _vm.select(item)
                                        }
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return (function() {})($event)
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._t(
                                  "default",
                                  [
                                    item.avatar || item.icon || _vm.showAvatar
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pui-dropdown-option__item__icon"
                                          },
                                          [
                                            (item.imgSrc === "avatar" &&
                                              item.avatar.avatarId) ||
                                            (item.imgSrc === "avatarUrl" &&
                                              item.avatar.avatarUrl) ||
                                            (item.imgSrc === "fallback" &&
                                              item.avatar.fallback) ||
                                            (item.imgSrc === "name" &&
                                              item.avatar.name)
                                              ? _c(
                                                  "PUIAvatar",
                                                  _vm._b(
                                                    {},
                                                    "PUIAvatar",
                                                    item.avatar,
                                                    false
                                                  )
                                                )
                                              : item.icon
                                              ? [
                                                  item.imgSrc === "icon"
                                                    ? _c("PUIIcon", {
                                                        attrs: {
                                                          name: item.icon
                                                        }
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: item.icon
                                                        }
                                                      })
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip",
                                            value: _vm.vTooltip
                                              ? Object.assign(
                                                  {},
                                                  {
                                                    content: item[_vm.textKey]
                                                  },
                                                  _vm.vTooltip
                                                )
                                              : undefined,
                                            expression:
                                              "vTooltip ? {\n                  content: item[textKey],\n                  ...vTooltip,\n                } : undefined"
                                          }
                                        ],
                                        staticClass:
                                          "pui-dropdown-option__text",
                                        attrs: { title: item[_vm.textKey] }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item[_vm.textKey]) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  {
                                    option: item,
                                    index: _vm.getIndexOfAllOptions(
                                      item[_vm.valueKey]
                                    )
                                  }
                                )
                              ],
                              2
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                })
              : _vm._l(_vm.filteredOptions, function(item, index) {
                  return _c(
                    "span",
                    {
                      key: item[_vm.valueKey],
                      staticClass: "pui-dropdown-option__item",
                      class: [
                        {
                          "pui-dropdown-option__item--active":
                            index === _vm.activeIndex,
                          "pui-dropdown-option__item--selected": _vm.isSelected(
                            item
                          ),
                          "pui-dropdown-option__item--disabled": _vm.isDisabled(
                            item
                          )
                        },
                        _vm.customClass
                      ],
                      attrs: { id: item[_vm.valueKey] },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          !item.disabled && _vm.select(item)
                        },
                        mouseover: function($event) {
                          _vm.activeIndex = index
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pui-dropdown-option__item__inner" },
                        [
                          _vm.multiple
                            ? _c("PUICheckbox", {
                                staticClass:
                                  "pui-dropdown-option__item__checkbox",
                                attrs: {
                                  checked: _vm.selectedOption,
                                  value: item[_vm.valueKey],
                                  disabled: _vm.isDisabled(item)
                                },
                                on: {
                                  change: function() {
                                    return _vm.select(item)
                                  }
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return (function() {})($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._t(
                            "default",
                            [
                              item.avatar || item.icon || _vm.showAvatar
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pui-dropdown-option__item__icon"
                                    },
                                    [
                                      item.imgSrc === "avatar" && item.avatar
                                        ? _c(
                                            "PUIAvatar",
                                            _vm._b(
                                              {},
                                              "PUIAvatar",
                                              item.avatar,
                                              false
                                            )
                                          )
                                        : item.icon
                                        ? [
                                            item.imgSrc === "icon"
                                              ? _c("PUIIcon", {
                                                  attrs: { name: item.icon }
                                                })
                                              : item.imgSrc === "langIcon"
                                              ? _c("PUILangIcon", {
                                                  attrs: {
                                                    lang: item.value,
                                                    size: "small",
                                                    color: "primary"
                                                  }
                                                })
                                              : _c("img", {
                                                  attrs: { src: item.icon }
                                                })
                                          ]
                                        : _vm.showAvatar
                                        ? _c("PUIAvatar2", {
                                            attrs: {
                                              name: item[_vm.textKey],
                                              type: _vm.avatarType,
                                              size: "normal"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.vTooltip
                                        ? Object.assign(
                                            {},
                                            { content: item[_vm.textKey] },
                                            _vm.vTooltip
                                          )
                                        : undefined,
                                      expression:
                                        "vTooltip ? {\n                content: item[textKey],\n                ...vTooltip,\n              } : undefined"
                                    }
                                  ],
                                  staticClass: "pui-dropdown-option__text",
                                  style: { fontSize: _vm.fontSize },
                                  attrs: { title: item[_vm.textKey] }
                                },
                                [_vm._v(_vm._s(item[_vm.textKey]))]
                              ),
                              _vm._v(" "),
                              item.numeric_id
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "pui-dropdown-option__side"
                                    },
                                    [_vm._v(_vm._s(item.numeric_id))]
                                  )
                                : _vm._e()
                            ],
                            { option: item, index: index }
                          )
                        ],
                        2
                      )
                    ]
                  )
                })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.innerIsLoadingMore
        ? _c(
            "span",
            { staticClass: "pui-dropdown-option__loading-more" },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("platform:generic.loading")) + " more")
              ]),
              _vm._v(" "),
              _c("PUILoader", { attrs: { size: "small" } })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }