const CUSTOMER_STATUS = {
  ACTIVE: 'ACTIVE',
  IDLE: 'IDLE',
  TYPING: 'TYPING',
};

export const MY_CHATS = 'my_chats';
export const AGENT_CHATS = 'agent_chats';

export const ATeam = {
  MANAGER: {
    name: 'ATeam.MANAGER',
  },
  AGENT: {
    name: 'ATeam.AGENT',
  },
};

const ALLOWED_CHATS = [MY_CHATS, AGENT_CHATS];

const AGENT_STATUS = { ...CUSTOMER_STATUS };

// Message types
const ACTIONS = {
  PONG: 'pong',
  LIST_CONVERSATIONS: 'list_conversations',
  CONVERSATION_ASSIGNED_TO_AGENT: 'conversation_assigned_to_agent',
  CONVERSATION_ASSIGNED_TO_AGENT_TEAM: 'conversation_assigned_to_agent_team',
  CONVERSATION_TERMINATED: 'conversation_terminated',
  CONVERSATION_CLOSED: 'conversation_closed',
  TRANSFER_REQUEST: 'transfer_request',
  TRANSFER_REQUEST_CONFIRM_STATUS_CHANGED: 'transfer_request_confirm_status_changed',
  MESSAGE_READ: 'message_read',
  GW_RECEIVED: 'gw_received',
  AGENT_ACTIVITY_STATUS_CHANGED: 'agent_activity_status_changed',
  CUSTOMER_ACTIVITY_STATUS_CHANGED: 'customer_activity_status_changed',
  CUSTOMER_SENT_RATING: 'customer_sent_rating',
  INIT_WEBCHAT: 'init_webchat',
  AGENT_PERMISSION_CHANGED: 'agent_permission_changed',
  USER_STATUS: 'user_status',
  AGENT_IDLE_TOO_LONG: 'agent_idle_too_long',
};

export {
  CUSTOMER_STATUS, AGENT_STATUS, ACTIONS, ALLOWED_CHATS,
};
