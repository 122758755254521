<template>
  <div
    class="pui-radio"
    :style="{ display: inline ? 'inline-flex' : 'flex' }"
  >
    <input
      :id="id"
      v-model="inputVal"
      type="radio"
      :name="name"
      :value="value"
      :disabled="disabled"
      class="pui-radio__input"
    />
    <label
      :for="id"
      class="pui-radio__label"
      tabindex="0"
    ><slot>{{ label }}</slot></label>
  </div>
</template>

<script>
export default {
  name: 'PUIRadio',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: [String, Number, Boolean],
      default: false,
    },
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: '',
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    id() {
      return this._uid;
    },
  },
};
</script>
<style lang="scss">
@import '~styles/pui/pui-variables.scss';

.pui-radio {
  position: relative;
  align-items: center;
  margin-inline-end: 10px;
}

@mixin transition() {
  -webkit-transition: 150ms ease-out;
  -moz-transition: 150ms ease-out;
  -o-transition: 150ms ease-out;
  transition: 150ms ease-out;
}

@mixin setBackgroundColor($bg-color) {
  @include setBorderColor($bg-color);

  & + label {
    &:after {
      background-color: $bg-color;
    }
  }
}

@mixin arrow() {
  content: '';
  @include transition();
  display: block;
  position: absolute;
  inset-inline-start: 4px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

@mixin setBorderColor($bg-color) {
  & + label {
    &:before {
      border-color: $bg-color;
    }
  }
}

.pui-radio__input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;

  &:checked {
    & + label {
      &:after {
        @include arrow();
      }
    }

    @include setBackgroundColor($color-black);
  }

  &:checked:not([disabled]) {
    &:hover,
    &:focus {
      @include setBackgroundColor(#f9c727);
    }

    &:active {
      @include setBackgroundColor(#e1b423);
    }
  }

  &:not([disabled]) {
    &:hover {
      @include setBorderColor(#f9c727);
    }

    &:active {
      @include setBorderColor(#e1b423);
    }
  }

  &[disabled] {
    & + label {
      opacity: 0.4;
      cursor: default;
    }
  }
}

label.pui-radio__label {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-family: $montserrat;
  font-size: 12px;
  color: black;

  &:before {
    @include transition();
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    // cursor: pointer;
    margin-inline-end: 5px;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    border: solid 1.5px #212121;
  }
}
</style>
