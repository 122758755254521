import { createEnum } from '@/utils';

/**
 * List of element ids that could be focused in different tours.
 * Only the element that are not specific to a certain tour should be listed here.
 *
 * For example, navigation links in sidebar or header,
 * which are not specific to a tour, should be listed here,
 * so we don't need to define them in multiple tours.
 */
export default createEnum({
  // Navigation links in Build primary header.
  BUILD_HEADER_NAV_BOT_SETTINGS: 'primary_header_nav_GeneralSettings',
  BUILD_HEADER_NAV_BOT_CHANNELS: 'primary_header_nav_ChannelsList',

  // Webchat channel settings page.
  WEBCHAT_DEPLOY_SNIPPET: 'webchat_deploy_snippet',

  // Bot editor
  CHAT_START_ADD_ACTION_BUTTON: 'chat_start_add_action_button',
});
