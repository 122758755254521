import Vue from 'vue';
import Vuex from 'vuex';
import $bus from '@/platformSettings/bus';
import CONSTANTS from '@/platformSettings/editorStaticVerbiages';
import perfDebug from '@/helpers/perfDebug';
import cloneDeep from 'lodash/cloneDeep';
import { createBlockMapOfState, recursiveFindMetaByType } from '@/helpers/blocksV2Helper';

window.perfDebug = perfDebug;
Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    bots: [],
    sampleBots: [],
    industries: [],
    deltaQueuePerBot: {},
    currentBot: null,
    currentBotState: null,
    isCurrentBotLoading: false,
    gateways: [],
    currentGateway: {},
    updatedGatewayName: '',
    botError: null,
    currentVersion: null,
    cachedConfigVersions: {},
    botLang: '',
    syncing: false,
    uploadProgress: 0,
    clipboard: [],
    clipboardAction: '',
  },
  getters: {
    currentBot: state => state.currentBot,
    currentBotMasterLang: state => {
      if (!state.currentBot || state.currentBot.langs.length === 0) return null;

      return state.currentBot.langs[0];
    },
    bots: state => state.bots,
    sampleBots: state => state.sampleBots,
    industries: state => state.industries,
    currentBotState: state => state.currentBotState,
    currentBotLang: state => state.botLang,
    currentBotDefaultLang: state => (state.currentBot && state.currentBot.langs ? state.currentBot.langs[0] : null),

    getLangParam: state => (param, defaultValue) => {
      if (param == null) {
        return defaultValue;
      }

      if (param[state.botLang] != null) {
        return param[state.botLang];
      }

      for (const lang of state.currentBot.langs) {
        if (param[lang] != null) {
          return param[lang];
        }
      }

      return defaultValue;
    },
    currentBotStateBlockMap: state => {
      if (!state.currentBotState || !state.currentBotState.source_event) return null;

      return createBlockMapOfState(state.currentBotState);
    },
    botsIdMap: state => {
      const mapped = {};

      state.bots.forEach(bot => {
        mapped[bot.id] = bot;
      });

      return mapped;
    },
  },
  mutations: {
    _setBotLang(state, lang) {
      state.botLang = lang;
    },
    _setCurrentBot(state, bot) {
      state.currentBot = Object.freeze(bot);
    },
    _updateCurrentBot(state, data) {
      state.currentBot = {
        ...state.currentBot,
        ...data,
      };
    },
    _setIsCurrentBotLoading(state, isLoading) {
      state.isCurrentBotLoading = isLoading;
    },
    _setBotState(state, botState) {
      state.currentBotState = botState;
    },
    _setBots(state, newBots) {
      state.bots = newBots;
    },
    _setGateways(state, gateways) {
      state.gateways = gateways;
    },
    _setCurrentGateway(state, gateway) {
      state.currentGateway = gateway;
    },
    _updateCurrentGateway(state, payload) {
      state.currentGateway = {
        ...state.currentGateway,
        ...payload,
      };
    },
    _setUpdatedGatewayName(state, gatewayName) {
      state.updatedGatewayName = gatewayName;
    },
    _setSampleBots(state, sampleBots) {
      state.sampleBots = sampleBots;
    },
    _setSampleBotIndustries(state, industries) {
      state.industries = industries;
    },
    _setBotError(state, error) {
      state.botError = error;
    },
    _setCurrentVersion(state, version) {
      state.currentVersion = version;
    },
    _setCachedConfigVersions(state, { botId, botVersions }) {
      state.cachedConfigVersions[botId] = cloneDeep(botVersions);
    },
  },
  actions: {
    refreshBots({ commit, dispatch, state }, { companyId }) {
      return new Promise((resolve, reject) => {
        if (!companyId) {
          return reject(new Error('Missing company id'));
        }

        $bus.$siniticApi
          .get(`/bot/v2/${companyId}/`)
          .then(response => {
            const newBots = response.data ? response.data : response.data;
            if (state.currentBot) {
              const currentBotRefresh = newBots.find(bot => bot.id === state.currentBot.id);
              dispatch('updateCurrentBot', currentBotRefresh);
            }
            commit('_setBots', newBots);
            resolve(newBots);
          })
          .catch(error => {
            commit('_setBots', []);
            console.error(error);
            return reject(error);
          });
      });
    },
    clearBots({ commit }) {
      commit('_setBots', []);
    },
    setBotLang({ commit, state }, newLang) {
      if (state.currentBot && !state.currentBot.langs.includes(newLang)) {
        commit('_setBotLang', state.currentBot.langs[0]);
      } else {
        commit('_setBotLang', newLang);
      }
    },
    setCurrentBot({ commit, dispatch, state }, {
      botId, companyId, version, stateId = null,
    }) {
      commit('_setBotError', null);
      console.log('setCurrentBot', { botId });
      return new Promise((resolve, reject) => {
        if (botId === null) {
          commit('_setCurrentBot', null);
          return resolve();
        }
        if (companyId === null) {
          return resolve();
        }
        const perf = perfDebug.start('Fetch bot');
        dispatch('getBot', {
          companyId,
          botId,
          version,
          stateId,
        })
          .then(newBot => {
            if (newBot === state.currentBot) {
              console.log('setCurrentBot:: Nothing to do');
              perf.end();
              return resolve();
            }

            commit('_setCurrentBot', newBot);
            commit('_setBotState', newBot.state);
            commit('_setCurrentVersion', version);
            dispatch('setBotLang', state.botLang);
            perf.end();

            return resolve();
          })
          .catch(error => {
            if (error.code === 404) {
              commit('_setBotError', 'This bot does not exist');
            } else if (error.code === 403) {
              commit('_setBotError', 'You cannot access this bot');
            }
            return reject(error);
          });
      });
    },
    setIsCurrentBotLoading({ commit }, isLoading) {
      commit('_setIsCurrentBotLoading', isLoading);
    },
    updateCurrentBot({ commit }, payload) {
      commit('_updateCurrentBot', payload);
    },
    clearCurrentBot({ commit }) {
      commit('_setCurrentBot', null);
    },
    clearCurrentBotState({ commit }) {
      commit('_setBotState', null);
    },
    getBotState(
      { state, commit, dispatch },
      {
        companyId, botId, stateId, withNextState = true, closeToolbox = true, setCanvasActive = true,
      }
    ) {
      return new Promise((resolve, reject) => {
        console.log(`Get bot state, id: ${stateId}`);
        $bus.$siniticApi
          .get(`/bot/v2/${companyId}/${botId}/state/${stateId}?with_next_states=${withNextState}`)
          .then(response => {
            commit('_setBotState', response.data);

            if (closeToolbox) {
              dispatch('editorv2/closeToolbox', setCanvasActive, {
                root: true,
              }).then(() => resolve(response));
            } else {
              return resolve(response);
            }
          })
          .catch(error => {
            // Ideally, we should handle loaders in the actual component.
            // However, the then/catch/finally in parent dispatches are not being triggered
            // via nested reject.
            //
            // ie: setCurrentBot > Promise > dispatch getBot > Promise > then > dispatch getBotState > Promise > catch
            // When {getBotState} rejects, the catch/finally statement for {setCurrentBot} is not triggered
            commit('_setIsCurrentBotLoading', false);

            if (error?.response?.status === 404) {
              const err = new Error('Fatal error: Unable to find bot state');
              err.code = 404;
              return reject(err);
            }
            if (error?.response?.status === 403) {
              const err = new Error('Fatal error: Not allowed to see this bot');
              err.code = 403;
              return reject(err);
            }
            return reject(new Error(`Unable to load the config. ${error}`));
          });
      });
    },
    setCurrentGateway({ commit }, { gateway }) {
      commit('_setCurrentGateway', gateway);
    },
    clearCurrentGateway({ commit, dispatch }) {
      commit('_setCurrentGateway', {});
      dispatch('getUpdatedGatewayName', '');
    },
    setGateways({ commit }, { gateways }) {
      commit('_setGateways', gateways);
    },
    getGateway({ commit, dispatch }, { gwId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`gateway/${gwId}`)
          .then(response => {
            const gateway = response.data;
            commit('_setCurrentGateway', gateway);
            dispatch('getUpdatedGatewayName', gateway.name);
            return resolve(gateway);
          })
          .catch(error => reject(error));
      });
    },
    getGateways({ state, commit }, { botId, gwType }) {
      return new Promise((resolve, reject) => {
        this.loading = true;
        const params = { bot_id: botId };
        if (gwType) {
          params.gw_type = gwType;
        }
        $bus.$siniticApi
          .get('/livechatgateway/', {
            params,
          })
          .then(response => {
            const results = response.data;
            // console.log(gwType, results);
            if (gwType && results.length === 1) {
              console.log(response.data);
              commit('_setCurrentGateway', response.data[0]);
              return resolve(results[0]);
            } if (gwType && results.length === 0) {
              return resolve();
            }
            commit('_setGateways', response.data);
            return resolve(results);
          });
      });
    },
    getUpdatedGatewayName({ state, commit }, newGatewayName) {
      commit('_setUpdatedGatewayName', newGatewayName);
    },
    getSampleBots({ commit }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get('bot/v2/sample_bots')
          .then(response => {
            commit('_setSampleBots', response.data);
            return resolve(response.data);
          });
      });
    },
    getSampleBotIndustries({ commit }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get('bot/v2/sample_bots/industries')
          .then(response => {
            commit('_setSampleBotIndustries', response.data);
            return resolve(response.data);
          });
      });
    },
    publishBot({ dispatch }, { companyId, botId, deploymentNote }) {
      return new Promise((resolve, reject) => {
        const data = { message: deploymentNote };
        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/release`, { data })
          .then(response => {
            dispatch('refreshBots', { companyId });
            return resolve(response.data);
          })
          .catch(error => reject(error));
      });
    },
    getBot({ commit, dispatch }, {
      companyId, botId, version, stateId = null,
    }) {
      return new Promise((resolve, reject) => {
        const perf = perfDebug.start('Download bot from backend');
        $bus.$siniticApi
          .get(`/bot/v2/${companyId}/${botId}?version=${version}`)
          .then(response => {
            perf.end();
            const bot = response.data;
            if (!bot) {
              const err = {};
              err.code = 404;
              return reject(err);
            }

            const rootStateToFetch = stateId || bot.root_state_id;
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: rootStateToFetch,
            }).then(response => {
              bot.state = response.data;
              return resolve(bot);
            });
          })
          .catch(error => {
            // Ideally, we should handle loaders in the actual component.
            // However, the then/catch/finally in parent dispatches are not being triggered
            // via nested reject.
            //
            // ie: setCurrentBot > Promise > dispatch getBot > Promise > catch
            // When {getBot} promise rejects, the catch/finally statement for {setCurrentBot} is not triggered
            commit('_setIsCurrentBotLoading', false);

            perf.end();
            console.log(error);
            if (error.response.status === 404) {
              const err = new Error('Fatal error: Unable to find this bot with this version');
              err.code = 404;
              return reject(err);
            }
            if (error.response.status === 403) {
              const err = new Error('Fatal error: Not allowed to see this bot');
              err.code = 403;
              return reject(err);
            }
            return reject(new Error(`Unable to load the config. ${error}`));
          });
      });
    },
    updateWebhook({ commit, state, dispatch }, { status, urls }) {
      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;
        const companyId = state.currentBot.company_id;

        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/webhook_host`, {
            data: {
              webhook_host_enabled: status,
              webhook_hosts: urls,
            },
          })
          .then(response => {
            commit('_setCurrentBot', response.data);
            return resolve();
          })
          .catch(error => {
            if (error.response.status === 404) {
              const err = new Error('Fatal error: Unable to find this bot with this version');
              err.code = 404;
              return reject(err);
            }

            if (error.response.status === 403) {
              const err = new Error('Fatal error: Not allowed to see this bot');
              err.code = 403;
              return reject(err);
            }
            return reject(new Error(`Unable to load the config. ${error}`));
          });
      });
    },
    updateEmailDomain({ commit, state, dispatch }, { status, urls }) {
      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;
        const companyId = state.currentBot.company_id;

        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/email_domain`, {
            data: {
              email_domain_enabled: status,
              email_domains: urls,
            },
          })
          .then(response => {
            commit('_setCurrentBot', response.data);
            return resolve();
          })
          .catch(error => {
            if (error.response.status === 404) {
              const err = new Error('Fatal error: Unable to find this bot with this version');
              err.code = 404;
              return reject(err);
            }

            if (error.response.status === 403) {
              const err = new Error('Fatal error: Not allowed to see this bot');
              err.code = 403;
              return reject(err);
            }
            return reject(new Error(`Unable to load the config. ${error}`));
          });
      });
    },
    updateBot({ commit, state, dispatch }, {
      companyId, botLangs, botName, avatarId, nlpModelVersion,
    }) {
      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;
        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}`, {
            data: {
              langs: botLangs,
              name: botName,
              avatar_id: avatarId,
              nlp_model_version: nlpModelVersion,
            },
          })
          .then(response => {
            commit('_setCurrentBot', response.data);
            dispatch('refreshBots', { companyId });
            return resolve();
          })
          .catch(error => reject(error));
      });
    },
    updateEvent({
      commit, state, dispatch, rootState,
    }, {
      companyId, params, eventId, targetId = null,
    }) {
      dispatch('editorv2/addProcessingBlock', eventId, { root: true });

      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;

        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/event/${eventId}`, {
            data: {
              params: params || {},
              target_id: targetId,
            },
          })
          .then(response => {
            const updatedEvent = response.data;

            const triggeredBlock = rootState.editorv2.tool?.meta?.triggeredBlock;

            if (updatedEvent.id === triggeredBlock.id) {
              /**
               * Updating event detail
               */
              const cachedBotState = cloneDeep(state.currentBotState);

              if (cachedBotState.source_event.id === updatedEvent.id) {
                // Updating source event
                cachedBotState.source_event.params = updatedEvent.params;
                cachedBotState.source_event.name = updatedEvent.name;
              } else if (cachedBotState.events?.[triggeredBlock._index]?.id === updatedEvent.id) {
                // Updating event of current state
                cachedBotState.events[triggeredBlock._index].params = updatedEvent.params;
                cachedBotState.events[triggeredBlock._index].name = updatedEvent.name;
              } else if (
                cachedBotState.events?.[triggeredBlock._parentIndex]?.target_state?.events?.[triggeredBlock._index]?.id
                === updatedEvent.id
              ) {
                // Updating event of next state
                cachedBotState.events[triggeredBlock._parentIndex].target_state.events[triggeredBlock._index].params = updatedEvent.params;
                cachedBotState.events[triggeredBlock._parentIndex].target_state.events[triggeredBlock._index].name = updatedEvent.name;
              } else {
                return reject(new Error(`Can't find event<${updatedEvent.id}>`));
              }

              dispatch('editorv2/changeProperties', updatedEvent.params, {
                root: true,
              });

              commit('_setBotState', cachedBotState);
            }

            return resolve(updatedEvent);
          })
          .catch(error => {
            console.log(error);

            dispatch('editorv2/removeProcessingBlock', eventId, { root: true });

            return reject(error);
          });
      });
    },
    changeEventType({
      state, commit, rootState,
    }, {
      companyId, eventId, type,
    }) {
      return new Promise((resolve, reject) => {
        if (!companyId) return reject(new Error('companyId is required'));
        if (!eventId) return reject(new Error('eventId is required'));
        if (!type) return reject(new Error('new type is required'));

        const botId = state.currentBot.id;

        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/event/${eventId}/change_type`, {
            data: {
              type,
            },
          })
          .then(response => {
            const updatedEvent = response.data;
            const cachedBotState = cloneDeep(state.currentBotState);
            const { triggeredBlock } = rootState.editorv2.tool.meta;

            if (cachedBotState.source_event.id === updatedEvent.id) {
              // Changing source event type
              cachedBotState.source_event.type = updatedEvent.type;
            } else if (cachedBotState.events?.[triggeredBlock._index]?.id === updatedEvent.id) {
              // Changing event type of current state
              cachedBotState.events[triggeredBlock._index].type = updatedEvent.type;
            } else if (
              cachedBotState.events?.[triggeredBlock._parentIndex]?.target_state?.events?.[triggeredBlock._index]?.id
                === updatedEvent.id
            ) {
              // Changing event type of next state
              cachedBotState.events[triggeredBlock._parentIndex].target_state.events[triggeredBlock._index].type = updatedEvent.type;
            }

            commit('_setBotState', cachedBotState);
            return resolve();
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    moveEventLeft({
      state, commit, getters, dispatch,
    }, { companyId, eventId }) {
      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;
        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/event/${eventId}/move_left`)
          .then(res => {
            if (state.currentBotState.id === res.data.id) {
              commit('_setBotState', res.data);
            } else {
              dispatch('getBotState', {
                companyId,
                botId,
                stateId: getters.currentBotState.id,
                withNextState: true,
                closeToolbox: false,
              });
            }
            return resolve();
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    moveEventRight({
      state, commit, getters, dispatch,
    }, { companyId, eventId }) {
      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;
        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/event/${eventId}/move_right`)
          .then(res => {
            if (state.currentBotState.id === res.data.id) {
              commit('_setBotState', res.data);
            } else {
              dispatch('getBotState', {
                companyId,
                botId,
                stateId: getters.currentBotState.id,
                withNextState: true,
                closeToolbox: false,
              });
            }
            return resolve();
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    moveEventLeftmost({
      state, commit, getters, dispatch,
    }, { companyId, eventId }) {
      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;
        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/event/${eventId}/move_leftmost`)
          .then(res => {
            if (state.currentBotState.id === res.data.id) {
              commit('_setBotState', res.data);
            } else {
              dispatch('getBotState', {
                companyId,
                botId,
                stateId: getters.currentBotState.id,
                withNextState: true,
                closeToolbox: false,
              });
            }
            return resolve();
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    moveEventRightmost({
      state, commit, getters, dispatch,
    }, { companyId, eventId }) {
      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;
        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/event/${eventId}/move_rightmost`)
          .then(res => {
            if (state.currentBotState.id === res.data.id) {
              commit('_setBotState', res.data);
            } else {
              dispatch('getBotState', {
                companyId,
                botId,
                stateId: getters.currentBotState.id,
                withNextState: true,
                closeToolbox: false,
              });
            }
            return resolve();
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },

    moveEventToNewParent({
      state, dispatch,
    }, {
      companyId, sourceEventId, newParentEventId,
    }) {
      dispatch('editorv2/addProcessingBlock', sourceEventId, { root: true });

      return new Promise((resolve, reject) => {
        if (!companyId) return reject(new Error('companyId is required'));
        if (!sourceEventId) return reject(new Error('Source eventId is required'));
        if (!newParentEventId) return reject(new Error('New parent eventId is required'));

        const botId = state.currentBot.id;

        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/event/${sourceEventId}/move`, {
            data: {
              new_parent_event_id: newParentEventId,
            },
          })
          .then(() => {
            const cachedBotState = cloneDeep(state.currentBotState);
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: cachedBotState.id,
              withNextState: true,
              closeToolbox: false,
            });
            return resolve();
          })
          .catch(error => {
            console.log(error);
            dispatch('editorv2/removeProcessingBlock', sourceEventId, { root: true });
            return reject(error);
          });
      });
    },

    updateEventName({
      state, commit, dispatch, rootState,
    }, {
      companyId, stateId, eventId, name,
    }) {
      dispatch('editorv2/addProcessingBlock', eventId, { root: true });

      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;

        $bus.$siniticApi
          .patch(`/bot/v2/${companyId}/${botId}/state/${stateId}/event/${eventId}/name`, {
            data: {
              name,
            },
          })
          .then(response => {
            const updatedEvent = response.data;

            const cachedBotState = cloneDeep(state.currentBotState);

            const { triggeredBlock } = rootState.editorv2.tool.meta;

            if (updatedEvent.id === triggeredBlock.id) {
              if (cachedBotState.source_event.id === updatedEvent.id) {
                // Updating source event
                cachedBotState.source_event.name = updatedEvent.name;
              } else if (cachedBotState.events?.[triggeredBlock._index]?.id === updatedEvent.id) {
                // Updating event of current state
                cachedBotState.events[triggeredBlock._index].name = updatedEvent.name;
              } else if (
                cachedBotState.events?.[triggeredBlock._parentIndex]?.target_state?.events?.[triggeredBlock._index]?.id
                === updatedEvent.id
              ) {
                // Updating event of next state
                cachedBotState.events[triggeredBlock._parentIndex].target_state.events[triggeredBlock._index].name = updatedEvent.name;
              } else {
                return reject(new Error(`Can't find event<${updatedEvent.id}> under state<${updatedEvent.source_id}>`));
              }

              $bus.$emit('clear-search-blocks');

              commit('_setBotState', cachedBotState);
            }

            return resolve(updatedEvent);
          })
          .catch(error => {
            console.log(error);

            dispatch('editorv2/removeProcessingBlock', eventId, { root: true });

            return reject(error);
          });
      });
    },
    updateAction(
      {
        commit, getters, state, dispatch, rootState,
      },
      {
        companyId, params, actionId, actionType, closeToolbox = true,
      }
    ) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;
        const blocName = rootState.editorv2.tool.properties.name;

        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/state/${getters.currentBotState.id}/action/${actionId}`, {
            data: {
              name: blocName,
              params,
              type: actionType,
            },
          })
          .then(response => {
            const updatedEvent = response.data;

            const newBotState = cloneDeep(state.currentBotState);

            if (state.currentBotState.source_event.id === updatedEvent.id) {
              newBotState.source_event = updatedEvent;
            } else {
              const eventIndex = state.currentBotState.events.findIndex(event => event.id === updatedEvent.id);
              newBotState.events[eventIndex] = updatedEvent;
            }

            $bus.$emit('clear-search-blocks');

            dispatch('editorv2/changeProperties', params, {
              root: true,
            });

            commit('_setBotState', newBotState);

            return resolve();
          })
          .catch(error => {
            console.log(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
    updateActionName({
      state, commit, getters, dispatch,
    }, { companyId, actionId, name }) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;

        $bus.$siniticApi
          .patch(`/bot/v2/${companyId}/${botId}/state/${getters.currentBotState.id}/action/${actionId}/name`, {
            data: {
              name,
            },
          })
          .then(response => {
            const updatedEvent = response.data;

            const newBotState = cloneDeep(state.currentBotState);

            if (state.currentBotState.source_event.id === updatedEvent.id) {
              newBotState.source_event = updatedEvent;
            } else {
              const eventIndex = state.currentBotState.events.findIndex(event => event.id === updatedEvent.id);
              newBotState.events[eventIndex] = updatedEvent;
            }

            commit('_setBotState', newBotState);

            return resolve();
          })
          .catch(error => {
            console.log(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
    deleteBot({ dispatch, commit }, { companyId, botId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`/bot/v2/${companyId}/${botId}`)
          .then(response => {
            dispatch('refreshBots', { companyId });
            commit('_setCurrentBot', null);
            commit('_setBotState', null);
            return resolve();
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    deleteBotEvent({
      state, rootState, commit, dispatch, getters,
    }, {
      companyId, botId, eventId, sourceId = null,
    }) {
      dispatch('editorv2/addProcessingBlock', eventId, { root: true });

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`/bot/v2/${companyId}/${botId}/event/${eventId}`)
          .then(response => {
            const cachedBotState = cloneDeep(state.currentBotState);

            const { triggeredBlock } = rootState.editorv2.tool.meta;

            let nextBotStateId = state.currentBotState.id;

            if (eventId === triggeredBlock.id) {
              if (cachedBotState.source_event.id === eventId) {
                // Deleting source event, go back to source state
                nextBotStateId = sourceId;
              } else if (cachedBotState.events?.[triggeredBlock._index]?.id === eventId) {
                // Deleting event of current state
                cachedBotState.events.splice(triggeredBlock._index, 1);
              } else if (
                cachedBotState.events?.[triggeredBlock._parentIndex]?.target_state?.events?.[triggeredBlock._index]?.id
                === eventId
              ) {
                // Deleting event of next state
                cachedBotState.events[triggeredBlock._parentIndex].target_state.events.splice(triggeredBlock._index, 1);
              } else {
                return reject(new Error(`Can't find event<${eventId}>`));
              }

              $bus.$emit('clear-search-blocks');
            }

            return resolve({
              botId,
              stateId: nextBotStateId,
              data: response.data,
              cachedBotState,
            });
          })
          .catch(error => {
            dispatch('editorv2/removeProcessingBlock', eventId, { root: true });

            reject(error);
          });
      });
    },
    deleteAction({ getters, dispatch }, {
      companyId, botId, actionId, eventId, prevActionId,
    }) {
      dispatch('editorv2/addProcessingBlock', [eventId, actionId, prevActionId], { root: true });

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`/bot/v2/${companyId}/${botId}/action/${actionId}`, {
            data: {
              event_id: eventId,
              prev_action_id: prevActionId,
            },
          })
          .then(response => resolve({
            botId,
            stateId: getters.currentBotState.id,
            data: response.data,
          }))
          .catch(error => {
            console.log(error);

            dispatch('editorv2/removeProcessingBlock', [eventId, actionId, prevActionId], { root: true });

            return reject(error);
          });
      });
    },
    createBot({ dispatch }, {
      companyId, botName, avatarId, botLangs,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/`, {
            data: {
              name: botName || 'My Bot',
              avatar_id: avatarId || '',
              langs: botLangs,
            },
          })
          .then(response => {
            dispatch('refreshBots', { companyId });
            return resolve(response.data);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    createAction({ state, dispatch }, { companyId, event, selected_action_bloc }) {
      const botId = state.currentBot.id;
      const eventId = event.id;
      const eventActions = event.actions;
      let prev_action = null;

      if (eventActions.length > 0) {
        prev_action = eventActions[eventActions.length - 1];
      }

      let mount_state = event.source_id;
      if (event._isRoot) {
        mount_state = event.target_id;
      }

      dispatch('editorv2/addProcessingBlock', eventId, { root: true });

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/event/${eventId}/action`, {
            data: {
              params: selected_action_bloc.properties.params,
              type: selected_action_bloc.type,
              name: selected_action_bloc.name || '',
              prev_action_id: prev_action ? prev_action.id : null,
            },
          })
          .then(response => {
            const newAction = response.data;

            return resolve({
              botId,
              stateId: mount_state,
              eventId: newAction.event_id,
              data: newAction,
            });
          })
          .catch(error => {
            dispatch('editorv2/removeProcessingBlock', eventId, { root: true });

            reject(error);
          });
      });
    },
    createNestedAction({ state, dispatch }, {
      companyId, outerAction, event, relationIndexToOuterAction, selected_action_bloc,
    }) {
      const botId = state.currentBot.id;
      const eventId = event.id;
      const actionId = outerAction.id;
      let prev_action = null;

      const sibling_actions = outerAction.relations[relationIndexToOuterAction];
      if (sibling_actions.length > 0) {
        prev_action = sibling_actions[sibling_actions.length - 1];
      }

      let mount_state = event.source_id;
      if (event._isRoot) {
        mount_state = event.target_id;
      }

      dispatch('editorv2/addProcessingBlock', [eventId, actionId, prev_action?.id], { root: true });

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/event/${eventId}/action/${actionId}/nested_action`, {
            data: {
              params: selected_action_bloc.properties.params,
              type: selected_action_bloc.type,
              name: selected_action_bloc.name || '',
              relation_index_to_outer_action: relationIndexToOuterAction,
              prev_action_id: prev_action ? prev_action.id : null,
            },
          })
          .then(response => {
            const newAction = response.data;

            return resolve({
              botId,
              stateId: mount_state,
              eventId: newAction.event_id,
              data: newAction,
            });
          })
          .catch(error => {
            dispatch('editorv2/removeProcessingBlock', [eventId, actionId, prev_action?.id], { root: true });

            reject(error);
          });
      });
    },
    deleteNestedAction({ getters, dispatch }, {
      companyId, botId, eventId, outerActionId, actionId, relationIndexToOuterAction, prevActionId,
    }) {
      dispatch('editorv2/addProcessingBlock', [eventId, actionId, outerActionId, prevActionId], { root: true });

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`/bot/v2/${companyId}/${botId}/action/${actionId}`, {
            data: {
              event_id: eventId,
              outer_action_id: outerActionId,
              relation_index_to_outer_action: relationIndexToOuterAction,
              prev_action_id: prevActionId,
            },
          })
          .then(response => resolve({
            botId,
            stateId: getters.currentBotState.id,
            data: response.data,
          }))
          .catch(error => {
            console.log(error);

            dispatch('editorv2/removeProcessingBlock', [eventId, actionId, outerActionId, prevActionId], { root: true });

            return reject(error);
          });
      });
    },
    createEvent({ dispatch, rootState, state }, { companyId, event, selected_event_bloc }) {
      return new Promise((resolve, reject) => {
        const botId = state.currentBot.id;

        // console.log(`Try to create an event under ${event.id}`);
        if (event.target_id) {
          // triggered event had target state
          // console.log(`${event.id} has target state, id: ${event.target_id}`);

          dispatch('connectEventSources', {
            companyId,
            botId,
            sourceStateId: event.target_id,
            targetStateId: null,
            bloc: selected_event_bloc,
            redirectState: rootState.route.params.stateId ?? event.source_id ?? '__root__',
          })
            .then(newEvent => resolve({
              botId,
              stateId: newEvent.source_id,
              eventId: newEvent.id,
              data: newEvent,
            }))
            .catch(err => reject(err));
        } else {
          // triggered event had no target state
          // console.log(`${event.id} has no target state`);

          // Create a new state
          $bus.$siniticApi
            .post(`/bot/v2/${companyId}/${botId}/state`, {
              data: {
                name: `state_name_${Math.random()}`,
              },
            })
            .then(response => {
              const createdState = response.data;
              // console.log(`Created a new state, id: ${createdState.id}`);

              // console.log(
              //   `Updating target_id of event(${event.id}) to createdState.id: ${createdState.id}`
              // );
              // Update triggered event's target_id
              dispatch('updateEvent', {
                companyId,
                eventId: event.id,
                targetId: createdState.id,
              })
                .then(() => {
                  // console.log(`Set target_id of event(${event.id}) to: ${createdState.id}`);

                  // Create the event under state
                  dispatch('connectEventSources', {
                    companyId,
                    botId,
                    sourceStateId: createdState.id,
                    targetStateId: null,
                    bloc: selected_event_bloc,
                    redirectState: rootState.route.params.stateId ?? event.source_id ?? '__root__',
                  })
                    .then(newEvent => resolve({
                      botId,
                      stateId: newEvent.source_id,
                      eventId: newEvent.id,
                      data: newEvent,
                    }))
                    .catch(err => reject(err));
                })
                .catch(err => reject(err));
            })
            .catch(err => reject(err));
        }
      });
    },
    connectEventSources({ state, dispatch }, {
      companyId, botId, sourceStateId, targetStateId, bloc, redirectState,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/state/${sourceStateId}/event`, {
            data: {
              params: bloc.properties.params,
              type: bloc.type, // selected_event_bloc.type,
              name: bloc.name || '',
            },
          })
          .then(response => {
            const newEvent = response.data;
            // console.log(`Created a new event, id: ${newEvent.id}`);

            // console.log(`Updating target_id of newEvent(${newEvent.id}) to targetStateId: ${targetStateId}`);
            dispatch('updateEvent', {
              companyId,
              eventId: newEvent.id,
              targetId: targetStateId,
            }).then(() => {
              // console.log(`Set target_id of event(${newEvent.id}) to: ${targetStateId}`);
              resolve(newEvent);
            });
          })
          .catch(error => reject(error));
      });
    },
    getAllTriggers(_, { companyId, botId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi.get(`/bot/v2/${companyId}/${botId}/events/all_triggers`)
          .then(response => {
            resolve(response);
          })
          .catch(error => reject(error));
      });
    },
    searchBlockByAction(_, {
      companyId, botId, version, text, page, entries_per_page,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/action/query`, {
            params: {
              page,
              entries_per_page,
              version,
            },
            data: {
              query: text,
            },
          })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    searchBlockByEvent(_, {
      companyId, botId, version, text, page, entries_per_page,
    }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/event/query`, {
            params: {
              page,
              entries_per_page,
              version,
            },
            data: {
              query: text,
            },
          })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    getStateByEvent(_, { companyId, botId, eventId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`/bot/v2/${companyId}/${botId}/state_of_event/${eventId}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            return reject(error);
          });
      });
    },
    moveAction({
      getters, state, commit, dispatch,
    }, {
      companyId, botId, actionId, params,
    }) {
      const {
        event_id,
        new_event_id,
        outer_action_id,
        new_outer_action_id,
      } = params;

      dispatch('editorv2/addProcessingBlock', [event_id, new_event_id, outer_action_id, new_outer_action_id], { root: true });

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/state/${getters.currentBotState.id}/action/${actionId}/move`, {
            data: params,
          })
          .then(response => {
            const { source_event, destination_event } = response.data;

            const newBotState = cloneDeep(state.currentBotState);

            if (state.currentBotState.source_event.id === source_event.id) {
              newBotState.source_event = source_event;
            } else {
              const eventIndex = state.currentBotState.events.findIndex(event => event.id === source_event.id);
              newBotState.events[eventIndex] = source_event;
            }

            if (destination_event.id !== source_event.id) {
              if (state.currentBotState.source_event.id === destination_event.id) {
                newBotState.source_event = destination_event;
              } else {
                const eventIndex = state.currentBotState.events.findIndex(event => event.id === destination_event.id);
                newBotState.events[eventIndex] = destination_event;
              }
            }

            commit('_setBotState', newBotState);

            resolve({
              botId,
              stateId: getters.currentBotState.id,
              data: response.data,
            });
          })
          .catch(error => {
            console.error(error);

            return reject(error);
          })
          .finally(() => {
            dispatch('editorv2/removeProcessingBlock', [event_id, new_event_id, outer_action_id, new_outer_action_id], { root: true });
          });
      });
    },
    createBranchCondition({ state, getters, dispatch }, { companyId, actionId, expression }) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      const botId = state.currentBot.id;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/action:IF_ELSE/${actionId}/condition`, {
            data: {
              expr: expression,
            },
          })
          .then(response => {
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: getters.currentBotState.id,
              withNextState: true,
              closeToolbox: false,
            });

            return resolve(response.data);
          })
          .catch(error => {
            console.error(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
    updateBranchCondition({ state, getters, dispatch }, {
      companyId, actionId, conditionIndex, expression,
    }) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      const botId = state.currentBot.id;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/action:IF_ELSE/${actionId}/condition/${conditionIndex}`, {
            data: {
              expr: expression,
            },
          })
          .then(response => {
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: getters.currentBotState.id,
              withNextState: true,
              closeToolbox: false,
            });

            return resolve(response.data);
          })
          .catch(error => {
            console.error(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
    removeBranchCondition({ state, getters, dispatch }, { companyId, actionId, conditionIndex }) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      const botId = state.currentBot.id;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`/bot/v2/${companyId}/${botId}/action:IF_ELSE/${actionId}/condition/${conditionIndex}`)
          .then(response => {
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: getters.currentBotState.id,
              withNextState: true,
              closeToolbox: false,
            });

            return resolve(response.data);
          })
          .catch(error => {
            console.error(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
    createCarouselButton({ state, getters, dispatch }, {
      companyId, actionId, cardIndex, payload,
    }) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      const botId = state.currentBot.id;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/action:MESSAGE_CAROUSEL/${actionId}/card/${cardIndex}/button`, {
            data: payload,
          })
          .then(response => {
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: getters.currentBotState.id,
              withNextState: true,
              closeToolbox: false,
            });

            return resolve(response.data);
          })
          .catch(error => {
            console.error(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
    updateCarouselButton({ state, getters, dispatch }, {
      companyId, actionId, cardIndex, buttonIndex, payload,
    }) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      const botId = state.currentBot.id;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(
            `/bot/v2/${companyId}/${botId}/action:MESSAGE_CAROUSEL/${actionId}/card/${cardIndex}/button/${buttonIndex}`,
            {
              data: payload,
            }
          )
          .then(response => {
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: getters.currentBotState.id,
              withNextState: true,
              closeToolbox: false,
            });

            return resolve(response.data);
          })
          .catch(error => {
            console.error(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
    removeCarouselButton({ state, getters, dispatch }, {
      companyId, actionId, cardIndex, buttonIndex,
    }) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      const botId = state.currentBot.id;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(
            `/bot/v2/${companyId}/${botId}/action:MESSAGE_CAROUSEL/${actionId}/card/${cardIndex}/button/${buttonIndex}`
          )
          .then(response => {
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: getters.currentBotState.id,
              withNextState: true,
              closeToolbox: false,
            });

            return resolve(response.data);
          })
          .catch(error => {
            console.error(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
    createSurveyField({ state, getters, dispatch }, { companyId, actionId, payload }) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      const botId = state.currentBot.id;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .post(`/bot/v2/${companyId}/${botId}/action:COLLECT_INFO/${actionId}/collection`, {
            data: payload,
          })
          .then(response => {
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: getters.currentBotState.id,
              withNextState: true,
              closeToolbox: false,
            });

            return resolve(response.data);
          })
          .catch(error => {
            console.error(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
    updateSurveyField({ state, getters, dispatch }, {
      companyId, actionId, fieldIndex, payload,
    }) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      const botId = state.currentBot.id;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .put(`/bot/v2/${companyId}/${botId}/action:COLLECT_INFO/${actionId}/collection/${fieldIndex}`, {
            data: payload,
          })
          .then(response => {
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: getters.currentBotState.id,
              withNextState: true,
              closeToolbox: false,
            });

            return resolve(response.data);
          })
          .catch(error => {
            console.error(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
    removeSurveyField({ state, getters, dispatch }, { companyId, actionId, fieldIndex }) {
      dispatch('editorv2/addProcessingBlock', actionId, { root: true });

      const botId = state.currentBot.id;

      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .delete(`/bot/v2/${companyId}/${botId}/action:COLLECT_INFO/${actionId}/collection/${fieldIndex}`)
          .then(response => {
            dispatch('getBotState', {
              companyId,
              botId,
              stateId: getters.currentBotState.id,
              withNextState: true,
              closeToolbox: false,
            });

            return resolve(response.data);
          })
          .catch(error => {
            console.error(error);

            dispatch('editorv2/removeProcessingBlock', actionId, { root: true });

            return reject(error);
          });
      });
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
