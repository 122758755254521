<script>
export default {
  name: 'PUICol',
  props: {
    span: {
      type: Number,
      default: 24,
    },
    tag: {
      type: String,
      default: 'div',
    },
    offset: {
      type: Number,
      default: null,
    },
    pull: {
      type: Number,
      default: null,
    },
    push: {
      type: Number,
      default: null,
    },
  },

  computed: {
    gutter() {
      let parent = this.$parent;
      while (parent && parent.$options.componentName !== 'PUIRow') {
        parent = parent.$parent;
      }
      return parent ? parent.gutter : 0;
    },
  },
  render(h) {
    const classList = [];
    const style = {};

    if (this.gutter) {
      style.paddingLeft = `${this.gutter / 2 }px`;
      style.paddingRight = style.paddingLeft;
    }

    ['span', 'offset', 'pull', 'push'].forEach(prop => {
      if (this[prop] || this[prop] === 0) {
        classList.push(prop !== 'span' ? `pui-col-${prop}-${this[prop]}` : `pui-col-${this[prop]}`);
      }
    });

    return h(
      this.tag,
      {
        class: ['pui-col', classList],
        style,
      },
      this.$slots.default
    );
  },
};
</script>

<style lang="scss" scoped>
[class*='pui-col-'] {
  float: left;
  box-sizing: border-box;
}

.pui-col-0 {
  display: none;
}

@for $i from 0 through 24 {
  .pui-col-#{$i} {
    width: (1 / 24 * $i * 100) * 1%;
  }

  .pui-col-offset-#{$i} {
    margin-inline-start: (1 / 24 * $i * 100) * 1%;
  }

  .pui-col-pull-#{$i} {
    position: relative;
    right: (1 / 24 * $i * 100) * 1%;
  }

  .pui-col-push-#{$i} {
    position: relative;
    left: (1 / 24 * $i * 100) * 1%;
  }
}
</style>
