import isEmpty from 'lodash/isEmpty';

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '@/router/platform';

import $bus from '@/platformSettings/bus';
import { RESOURCE_STATE } from '@/constants.js';

Vue.use(Vuex);

const { CancelToken } = axios;
let cancelGetWebhookLog;
let cancelGetWebhookLogs;

export default {
  namespaced: true,
  state: {
    currentWebhookLog: null,
    webhookLogLoading: null,
    webhookLogError: null,
    webhookLogs: [],
    webhookLogCurrentPage: 1,
    webhookLogPerPage: 15,
    webhookLogPages: 0,
    webhookLogAmount: 0,
    webhookLogOrderBy: 'desc',
    webhookLogFilter: null,

    isSearching: false,
    jsonApiBlocks: [],
    fetchingWebhookLogsState: RESOURCE_STATE.IDLE,
    companyId: null,
    botId: null,
  },
  getters: {
    currentWebhookLogIndex(state) {
      if (state.currentWebhookLog) {
        return state.webhookLogs.findIndex(item => item.id === state.currentWebhookLog.id);
      }
      return null;
    },
  },
  mutations: {
    _setCompanyId(state, companyId) {
      state.companyId = companyId;
    },
    _setBotId(state, botId) {
      state.botId = botId;
    },
    _setCurrentWebhookLog(state, webhookLog) {
      state.currentWebhookLog = Object.freeze(webhookLog);
    },
    _setWebhookLogLoading(state, bool) {
      state.webhookLogLoading = bool;
    },
    _setWebhookLogError(state, error) {
      state.webhookLogError = error;
    },
    _setWebhookLogs(state, webhookLogs) {
      state.webhookLogs = webhookLogs;
    },
    _setWebhookLogCurrentPage(state, currentPage) {
      state.webhookLogCurrentPage = currentPage;
    },
    _setWebhookLogPerPage(state, perPage) {
      state.webhookLogPerPage = perPage;
    },
    _setWebhookLogPages(state, pages) {
      state.webhookLogPages = pages;
    },
    _setWebhookLogAmount(state, amount) {
      state.webhookLogAmount = amount;
    },
    _setWebhookLogOrderBy(state, orderBy) {
      state.webhookLogOrderBy = orderBy;
    },
    _setWebhookLogFilter(state, filter) {
      state.webhookLogFilter = filter;
    },

    _setIsSearching(state, payload) {
      state.isSearching = payload;
    },
    _setJsonApiBlocks(state, payload) {
      state.jsonApiBlocks = payload;
    },
    _setFetchingWebhookLogsState(state, resourceState) {
      const {
        IDLE, PENDING, RESOLVED, REJECTED,
      } = RESOURCE_STATE;

      if (![IDLE, PENDING, RESOLVED, REJECTED].includes(resourceState)) {
        throw Error(
          `Invalid payload: only IDLE, PENDING, RESOLVED, and REJECTED are allowed, got ${resourceState}.`
        );
      }

      state.fetchingWebhookLogsState = resourceState;
    },
  },
  actions: {
    getBlocks({ commit }, { companyId, botId }) {
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/action:JSON_API`)
          .then(response => {
            const blocks = response.data;
            commit('_setJsonApiBlocks', blocks);
            return resolve(blocks);
          })
          .catch(err => {
            console.error(err);
            return reject(err);
          });
      });
    },
    // Webhook
    setCurrentWebhookLog({ commit, dispatch, state }, { companyId, botId, webhookLogId }) {
      commit('_setWebhookLogError', null);
      commit('_setWebhookLogLoading', true);
      return new Promise((resolve, reject) => {
        if (webhookLogId === null) {
          commit('_setCurrentWebhookLog', null);
          commit('_setWebhookLogLoading', false);
          return resolve();
        }

        if (companyId === null) {
          commit('_setWebhookLogLoading', false);
          return resolve();
        }

        if (botId === null) {
          commit('_setWebhookLogLoading', false);
          return resolve();
        }

        dispatch('getWebhookLog', {
          companyId,
          botId,
          webhookLogId,
        })
          .then(webhookLog => {
            if (webhookLog === state.currentWebhookLog) {
              console.log('setCurrentWebhookLog:: Nothing to do');
              commit('_setWebhookLogLoading', false);
              return resolve();
            }

            commit('_setCurrentWebhookLog', webhookLog);
            commit('_setWebhookLogLoading', false);

            return resolve();
          })
          .catch(error => {
            if (error.code === 404) {
              commit('_setWebhookLogError', 'This webhook log does not exist');
            } else if (error.code === 403) {
              commit('_setWebhookLogError', 'You cannot access this webhook log');
            }

            commit('_setWebhookLogLoading', false);
            return reject(error);
          });
      });
    },
    getWebhookLog(_, { companyId, botId, webhookLogId }) {
      if (cancelGetWebhookLog) {
        cancelGetWebhookLog();
      }
      return new Promise((resolve, reject) => {
        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/webhook_log/${webhookLogId}`, {
            cancelToken: new CancelToken(c => {
              cancelGetWebhookLog = c;
            }),
          })
          .then(response => {
            const webhookLog = response.data;
            if (!webhookLog) {
              const err = {};
              err.code = 404;
              return reject(err);
            }
            return resolve(webhookLog);
          });
      });
    },
    setCurrentWebhookLogByIndex({ state, commit }, index) {
      if (index < 0 || index >= state.webhookLogs.length) {
        throw Error('Invalid index');
      }

      if (state.webhookLogs.length > 0) {
        const currentWebhook = state.webhookLogs[index];
        commit('_setCurrentWebhookLog', currentWebhook);

        router
          .push({
            name: 'BotHistory',
            params: {
              companyId: state.companyId,
              botId: state.botId,
              history: 'webhook',
              selectedId: currentWebhook.id,
            },
            query: state.webhookLogFilter,
          })
          .catch(() => {});
      }
    },
    getPreviousWebhookLog({
      state, rootState, getters, dispatch, commit,
    }) {
      // Is first in not-first page
      if (state.webhookLogCurrentPage > 1 && getters.currentWebhookLogIndex === 0) {
        // Reduce page number
        dispatch('setWebhookLogCurrentPage', state.webhookLogCurrentPage - 1);
        // Get webhook_log
        const { companyId, botId } = rootState.route.params;
        dispatch('getWebhookLogs', { companyId, botId }).then(() => {
          // Set last one as current
          dispatch('setCurrentWebhookLogByIndex', state.webhookLogs.length - 1);
        });
      } else if (getters.currentWebhookLogIndex > 0 && getters.currentWebhookLogIndex < state.webhookLogPerPage) {
        // Set previous as current
        dispatch('setCurrentWebhookLogByIndex', getters.currentWebhookLogIndex - 1);
      }
    },
    getNextWebhookLog({
      state, rootState, getters, dispatch,
    }) {
      if (
        state.webhookLogCurrentPage < state.webhookLogPages
        && state.webhookLogCurrentPage > 0
        && getters.currentWebhookLogIndex === state.webhookLogs.length - 1
      ) {
        // Increase page number
        dispatch('setWebhookLogCurrentPage', state.webhookLogCurrentPage + 1);
        // Get webhook_log
        const { companyId, botId } = rootState.route.params;
        dispatch('getWebhookLogs', { companyId, botId }).then(() => {
          // Set first one as current
          dispatch('setCurrentWebhookLogByIndex', 0);
        });
      } else if (state.webhookLogs[getters.currentWebhookLogIndex + 1]) {
        // Set next as current
        dispatch('setCurrentWebhookLogByIndex', getters.currentWebhookLogIndex + 1);
      }
    },
    getWebhookLogs({ state, rootState, commit }, { companyId, botId }) {
      commit('_setFetchingWebhookLogsState', RESOURCE_STATE.PENDING);
      return new Promise((resolve, reject) => {
        // Call api if date range is ready
        if (state.webhookLogFilter && (!state.webhookLogFilter.start_date || !state.webhookLogFilter.end_date)) {
          commit('_setFetchingWebhookLogsState', RESOURCE_STATE.RESOLVED);
          return resolve();
        }

        if (cancelGetWebhookLogs) {
          cancelGetWebhookLogs();
        }

        const today = new Date();
        const formatting = 'YYYY-MM-DD';
        const dateQuery = {
          timezone: rootState.uiv2.platformTimezone,
        };

        if (state.webhookLogFilter && state.webhookLogFilter.start_date) {
          dateQuery.start_date = Vue.dayjs(state.webhookLogFilter.start_date).format(formatting);
        }

        if (state.webhookLogFilter && state.webhookLogFilter.end_date) {
          dateQuery.end_date = Vue.dayjs(state.webhookLogFilter.end_date).format(formatting);
        }

        const filters = {};
        const newFilter = state.webhookLogFilter || {};
        Object.entries(newFilter).forEach(([key, val]) => {
          if (val !== null && val !== '') {
            filters[key] = val;
          }
        });

        if (!isEmpty(filters)) {
          commit('_setIsSearching', true);
        } else {
          commit('_setIsSearching', false);
        }

        $bus.$siniticApi
          .get(`bot/v2/${companyId}/${botId}/webhook_logs`, {
            params: {
              page: state.webhookLogCurrentPage,
              items_per_page: state.webhookLogPerPage,
              order_by: state.webhookLogOrderBy,
              ...state.webhookLogFilter,
              ...dateQuery,
            },
            cancelToken: new CancelToken(c => {
              cancelGetWebhookLogs = c;
            }),
          })
          .then(response => {
            const results = response.data;
            const nonDuplicateItems = Object.values(
              results.items.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
            );
            commit('_setWebhookLogs', nonDuplicateItems);
            commit('_setWebhookLogPages', results.n_pages);
            commit('_setWebhookLogAmount', results.total);
            commit('_setFetchingWebhookLogsState', RESOURCE_STATE.RESOLVED);
            resolve(results);
          })
          .catch(error => {
            console.log(error);
            commit('_setFetchingWebhookLogsState', RESOURCE_STATE.REJECTED);
            reject(error);
          });
      });
    },
    clearWebhookLogs({ commit }) {
      commit('_setWebhookLogCurrentPage', 1);
      commit('_setWebhookLogs', []);
    },
    resetWebhookLog({ commit }) {
      commit('_setCurrentWebhookLog', null);
      commit('_setWebhookLogs', []);
      commit('_setWebhookLogCurrentPage', 1);
      commit('_setWebhookLogPages', 0);
      commit('_setWebhookLogAmount', 0);
      commit('_setWebhookLogOrderBy', 'desc');
      commit('_setWebhookLogFilter', null);
    },
    setWebhookLogCurrentPage({
      state, commit, dispatch,
    }, page) {
      if (page < 0 || page > state.webhookLogPages) {
        throw Error('Invalid page');
      }

      commit('_setWebhookLogCurrentPage', page);

      dispatch('getWebhookLogs', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total && data.total > 0) {
          dispatch('setCurrentWebhookLogByIndex', 0);
        }
      });
    },
    setWebhookLogPerPage({ state, commit, dispatch }, perPage) {
      commit('_setCurrentWebhookLog', null);
      commit('_setWebhookLogPerPage', perPage);
      commit('_setWebhookLogCurrentPage', 1);

      dispatch('getWebhookLogs', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total && data.total > 0) {
          dispatch('setCurrentWebhookLogByIndex', 0);
        }
      });
    },
    setWebhookLogFilter({
      state, getters, commit, dispatch,
    }, filter) {
      commit('_setCurrentWebhookLog', null);
      commit('_setWebhookLogFilter', filter);
      commit('_setWebhookLogCurrentPage', 1);

      dispatch('getWebhookLogs', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total && data.total > 0) {
          dispatch('setCurrentWebhookLogByIndex', getters.currentWebhookLogIndex ?? 0);
        }
      });
    },
    setWebhookLogOrderBy({ state, commit, dispatch }, orderBy) {
      commit('_setCurrentWebhookLog', null);
      commit('_setWebhookLogOrderBy', orderBy);
      commit('_setWebhookLogCurrentPage', 1);

      dispatch('getWebhookLogs', {
        companyId: state.companyId,
        botId: state.botId,
      }).then(data => {
        if (data && data.total && data.total > 0) {
          dispatch('setCurrentWebhookLogByIndex', 0);
        }
      });
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
