import { SEND_STATUS } from '@/helpers/messageHelper';

export default (message, $store, { MUTATION }) => {
  const { commit, dispatch } = $store;

  dispatch('removeBotTypingOfChat', message.cid);

  commit(MUTATION.addMessageToChat, {
    chatId: message.cid,
    message,
    status: SEND_STATUS.received,
  });
};
