<template>
  <div
    v-click-outside="clickOutsideDropdown"
    :class="[`pui-dropdown--${theme}`]"
    class="pui-dropdown"
  >
    <div
      v-if="label"
      class="pui-dropdown__header d-flex justify-content-between align-items-center"
    >
      <label
        :disabled="disabled"
        class="pui-dropdown__label"
      >{{ label }}{{ required ? '*' : '' }}</label>
      <span
        v-if="clearable"
        class="pui-dropdown__clear"
        @click="clear"
      >{{ $t('platform:generic.clear') }}</span>
    </div>
    <div
      ref="dropdown_field"
      class="pui-dropdown__container"
    >
      <div
        ref="reference"
        :disabled="disabled"
        :class="{
          'has-tags': hasValue && multiple,
          'has-error': error || errorstate,
          active: isOpen,
        }"
        :style="[
          multiple ? { minHeight: height } : { height: height },
          {'--padding-inline-end': showInlineClearButton ? 2 : 1},
        ]"
        class="pui-dropdown__box"
        @click="toggleOpenClose"
      >
        <div
          v-if="hasValue && !hideSelection"
          class="pui-dropdown__inner"
        >
          <div class="pui-dropdown__select">
            <!-- Multiple -->
            <template v-if="multiple">
              <div
                v-for="selected in selectedList"
                :key="selected[valueKey]"
                class="pui-dropdown-tag"
              >
                <div class="pui-dropdown-tag__wrapper">
                  <slot
                    :data="selected"
                    name="tag"
                  >
                    <PUIAvatar
                      v-if="selected.imgSrc === 'avatar' && selected.avatar"
                      v-bind="selected.avatar"
                    />
                    <template v-else-if="selected.icon">
                      <PUIIcon
                        v-if="selected.imgSrc === 'icon'"
                        :name="selected.icon"
                        size="small"
                      />
                      <PUILangIcon
                        v-else-if="selected.imgSrc === 'langIcon'"
                        :lang="selected.value"
                        size="small"
                        color="primary"
                      />
                      <img
                        v-else
                        :src="selected.icon"
                        class="pui-dropdown-tag__image"
                      />
                    </template>
                    <span
                      v-tooltip.top="vTooltip ? {
                        content: selected[textKey],
                        ...vTooltip,
                      } : undefined"
                      class="pui-dropdown-tag__content"
                      :style="{ fontSize }"
                    >{{ selected[textKey] }}
                    </span>
                  </slot>

                  <div
                    class="pui-dropdown-tag__remove-container"
                    @click.stop="removeTag(selected[valueKey])"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'times']"
                      class="pui-dropdown-tag__remove"
                    />
                  </div>
                </div>
              </div>
            </template>

            <!-- Single -->
            <slot
              v-else
              :selected="selectedList"
              name="selection"
            >
              <PUIAvatar2
                v-if="showAvatar"
                :type="avatarType"
                :name="selectedList[textKey]"
                size="normal"
                class="pui-dropdown-tag__avatar"
              />
              <template v-if="selectedList.icon">
                <PUIIcon
                  v-if="selectedList.imgSrc === 'icon'"
                  :name="selectedList.icon"
                  size="small"
                  class="pui-dropdown-tag__icon"
                />
                <PUILangIcon
                  v-else-if="selectedList.imgSrc === 'langIcon'"
                  :lang="selectedList.value"
                  size="small"
                  color="primary"
                  style="margin-inline-end: 11px"
                />
                <img
                  v-else
                  :src="selectedList.icon"
                  class="pui-dropdown-tag__image"
                />
              </template>
              <span
                v-tooltip.top="vTooltip ? {
                  content: selectedList[textKey],
                  ...vTooltip,
                } : undefined"
                :style="{ fontSize }"
              >{{ selectedList[textKey] }}
              </span>
            </slot>
          </div>
        </div>

        <div
          v-else
          class="pui-dropdown__inner"
        >
          <div class="pui-dropdown__select d-flex align-items-center">
            <span
              class="pui-dropdown__placeholder"
              :style="{ fontSize }"
            >{{ placeholder }}</span>
          </div>
        </div>

        <PUIIconButton
          v-if="showInlineClearButton"
          :icon="['fal', 'times']"
          class="pui-dropdown__clear-select"
          @click.stop="clear"
        />
        <PUIIconButton
          :icon="['fal', optionPosition === 'top' ? 'angle-up' : 'angle-down']"
          :class="{ active: isOpen }"
          class="pui-dropdown__arrow"
        />
      </div>
      <DropdownOptions
        v-if="isOpen"
        ref="popper"
        :value="selectedOption"
        :valueType="valueType"
        :textKey="textKey"
        :valueKey="valueKey"
        :options="renderedOptions"
        :grouped="grouped"
        :searchable="searchable"
        :searchKeyword.sync="innerSearchKeyword"
        :multiple="multiple"
        :multipleLimit="multipleLimit"
        :searchPlaceholder="searchPlaceholder"
        :noResultPlaceholderGetter="noResultPlaceholderGetter"
        :addOption="addOption"
        :customClass="optionClass"
        :fontSize="fontSize"
        :showAvatar="showAvatar"
        :vTooltip="vTooltip"
        :remote="remote"
        :loading="loading"
        :loadingText="loadingText"
        :loadingLoader="loadingLoader"
        :isOpen="isOpen"
        :lazyLoad="lazyLoad"
        :isLoadingMore="isLoadingMore"
        @esc="isOpen = false"
        @change="updateSelection"
        @update-options="updateOptions"
        @query-changed="handleQueryChanged"
        @scroll-to-end="handleScrollToEnd"
      >
        <slot
          slot-scope="scope"
          :option="scope.option"
          :index="scope.index"
          name="option"
        />
      </DropdownOptions>
    </div>
    <div
      v-if="error"
      class="pui-dropdown__error help is-danger"
    >
      <span>{{ error }}</span>
      <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import union from 'lodash/union';
import { createPopper } from '@popperjs/core';

import PUIAvatar from '@/components/PUI/Layout/Avatar.vue';
import PUIAvatar2 from '@/components/PUI/Layout/Avatar2.vue';
import PUILangIcon from '@/components/PUI/Controls/LangIcon.vue';
import PUIIcon from '@/components/PUI/Controls/Icon.vue';
import PUIIconButton from '@/components/PUI/Controls/IconButton.vue';
import DropdownOptions from './DropdownOptions.vue';

export default {
  name: 'PUIDropdown',
  components: {
    DropdownOptions,
    PUIAvatar,
    PUIAvatar2,
    PUILangIcon,
    PUIIcon,
    PUIIconButton,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Object, Number, Boolean, Array],
      default: null,
    },
    textKey: {
      type: String,
      default: 'text',
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    valueType: {
      type: String,
      default: 'option',
      validator: val => ['option', 'value'].indexOf(val) > -1,
    },
    height: {
      type: String,
      default: '40px',
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    searchPlaceholder: {
      type: String,
      default() {
        return this.$t('platform:generic.search');
      },
    },
    options: {
      type: Array,
      default: null,
      required: false,
    },
    addOption: {
      type: Boolean,
      default: false,
    },
    maxOptions: {
      type: Number,
      default: Infinity,
    },
    optionPosition: {
      type: String,
      default: 'bottom',
      validator: position => ['top', 'bottom'].indexOf(position) > -1,
    },
    optionPopperStrategy: {
      type: String,
      default: 'fixed',
      validator: strategy => ['fixed', 'absolute'].indexOf(strategy) > -1,
    },
    required: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: 'light',
      validator: theme => ['light', 'dark', 'grey-dark', 'grey-light', 'dashed-light', 'primary-outline'].includes(theme),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    multipleLimit: {
      type: Number,
      default: 0,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchKeyword: {
      type: String,
      default: null,
    },
    // invalid: {
    //   type: Boolean,
    //   default: false
    // },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    errorstate: {
      type: Boolean,
      default: false,
    },
    textGetter: {
      type: Function,
      default: null,
    },
    tagGetter: {
      type: Function,
      default: null,
    },
    iconGetter: {
      type: Function,
      default: null,
    },
    noResultPlaceholderGetter: {
      type: Function,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    optionClass: {
      type: String,
      default: null,
    },
    grouped: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: '13px',
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    avatarType: {
      type: String,
      default: 'user',
    },
    vTooltip: {
      type: Object,
      default: null,
    },
    /**
     * whether options are loaded from server
     *
     * set to `true` so `query-change` will be triggered when value in search field changes
     */
    remote: {
      type: Boolean,
      default: false,
    },
    /**
     * whether Dropdown is loading data from server
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * displayed text while loading data from server
     */
    loadingText: {
      type: String,
      default() {
        return this.$t('platform:generic.loading');
      },
    },
    /**
     * whether show loader while loading
     */
    loadingLoader: {
      type: Boolean,
      default: true,
    },
    /**
     * Whether to show selection option(s)
     * Useful if implementing component displays selected options independently
     */
    hideSelection: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether to enable lazy loading
     *
     * If set to `true`, `scroll-to-end` will be triggered when user has scrolled to the end of the options
     */
    lazyLoad: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether to enable loader when loading more options
     */
    isLoadingMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,

      popperInstance: null,

      width: null,
    };
  },
  computed: {
    selectedOption: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    allOptions() {
      if (this.grouped) {
        return union(
          ...this.options.map(group => group.options)
        );
      }
      return cloneDeep(this.options);
    },
    hasValue() {
      if (this.multiple) {
        return Array.isArray(this.selectedList) && this.selectedList.length > 0;
      }
      return this.selectedList != null && this.selectedList !== '';
    },
    renderedOptions() {
      let _options = [];
      if (typeof this.textGetter === typeof Function) {
        _options = this.options.map(this.textGetter);
      } else {
        _options = this.options;
      }

      return _options.slice(0, this.maxOptions);
    },
    renderedTags() {
      if (typeof this.tagGetter === typeof Function) {
        return this.allOptions.map(this.tagGetter);
      }
      return this.allOptions;
    },
    renderedIcons() {
      if (typeof this.iconGetter === typeof Function) {
        return this.allOptions.map(this.iconGetter);
      }
      return this.allOptions;
    },
    selectedList() {
      if (this.valueType === 'value') {
        if (this.multiple) {
          if (this.selectedOption) {
            return this.allOptions.filter(p => this.selectedOption.includes(p[this.valueKey]));
          }
          return [];
        }
        return this.allOptions.find(p => this.selectedOption === p[this.valueKey]);
      }
      if (this.multiple) {
        return this.selectedOption || [];
      }
      return cloneDeep(this.selectedOption);
    },
    innerSearchKeyword: {
      get() {
        return this.searchKeyword;
      },
      set(val) {
        this.$emit('update:searchKeyword', val);
      },
    },
    showInlineClearButton() {
      return this.clearable && this.hasValue && !this.label;
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      async handler(val) {
        await this.$nextTick();

        if (val) {
          this.popperInstance = createPopper(
            this.$refs.reference,
            this.$refs.popper.$el,
            {
              placement: this.optionPosition === 'top' ? 'top-start' : 'bottom-start',
              strategy: this.optionPopperStrategy,
              onFirstUpdate: () => {
                this.width = this.$el.getBoundingClientRect().width;
              },
            }
          );
        } else {
          this.popperInstance?.destroy();
          this.popperInstance = null;
        }

        this.$emit('visible-change', val);
      },
    },
  },
  updated() {
    this.width = this.$el.getBoundingClientRect().width;
  },
  methods: {
    getSelectedOption(selected) {
      if (this.valueType === 'value') {
        if (this.multiple) {
          return this.allOptions.filter(p => this.selectedOption.includes(p[this.valueKey]));
        }
        return this.allOptions.find(p => this.selectedOption === p[this.valueKey]);
      }
      if (this.multiple) {
        return this.selectedOption || [];
      }
      return cloneDeep(this.selectedOption);
    },
    clickOutsideDropdown(event) {
      if (!this.isOpen) return;

      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },
    toggleOpenClose() {
      if (this.disabled) return;

      this.isOpen = !this.isOpen;
    },
    updateSelection(option) {
      if (!this.isOpen) return;

      const optionValue = this.valueType === 'value' ? option : option[this.valueKey];
      const selectedOption = this.allOptions.find(p => p[this.valueKey] === optionValue);

      if (this.multiple && Array.isArray(this.selectedList)) {
        const index = this.selectedList.findIndex(e => e[this.valueKey] === optionValue);
        const isNotSelectedYet = index === -1;

        if (isNotSelectedYet) {
          // const temp = this.selectedList;
          // make explicit copy to not update original
          const temp = [...this.selectedList];
          temp.push(selectedOption);
          this.emitChange(temp);
        } else {
          this.removeTag(optionValue);
        }
      } else {
        this.emitChange(selectedOption);
      }

      // TODO: change to this when DropdownOptions is fixed. DropdownOptions should allow click on label.
      if (!this.multiple) {
        this.toggleOpenClose();
      }

      this.popperInstance.update();
    },
    removeTag(optionValue) {
      const temp = this.selectedList.filter(option => option[this.valueKey] !== optionValue);
      this.emitChange(temp);
      this.popperInstance?.update();
    },
    emitChange(option) {
      this.$emit('option-change', option);

      if (this.valueType === 'value') {
        if (this.multiple) {
          this.$emit(
            'change',
            option.map(p => p[this.valueKey])
          );
        } else {
          this.$emit('change', option?.[this.valueKey]);
        }
      } else {
        this.$emit('change', option);
      }
    },
    updateOptions(e) {
      const newElement = {
        text: e,
      };
      this.renderedOptions.push(newElement);
      this.updateSelection(newElement);
    },
    isSelected(item) {
      if (this.multiple && Array.isArray(this.selectedList)) {
        if (this.valueType === 'value') {
          return this.selectedList.some(element => element === item[this.valueKey]);
        }
        return this.selectedList.some(element => element[this.valueKey] === item[this.valueKey]);
      }
      if (this.valueType === 'value') {
        return this.selectedList && this.selectedList === item[this.valueKey];
      }
      return this.selectedList && this.selectedList[this.valueKey] === item[this.valueKey];
    },
    clear() {
      if (this.valueType === 'value' && this.multiple) {
        this.emitChange([]);
      } else {
        this.emitChange(null);
      }

      this.isOpen = false;
    },
    handleQueryChanged(query) {
      if (!this.searchable || !this.remote) return;

      this.$emit('query-change', query);
    },
    handleScrollToEnd() {
      this.$emit('scroll-to-end');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';

@mixin pui-dropdown(
  $bgc,
  $bgc-disabled,
  $bgc-invalid,
  $font,
  $font-disabled,
  $font-invalid,
  $label,
  $label-disabled,
  $label-invalid,
  $border-color,
  $border-type,
  $border-active,
  $border-disabled,
  $border-invalid,
  $placeholder-color
) {
  .pui-dropdown__header {
    margin-bottom: 7px;
  }

  .pui-dropdown__label {
    display: block;
    height: 14px;
    font-family: $montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    font-size: 12px;
    color: $label;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .pui-dropdown__clear {
    font-family: $open-sans;
    font-size: 11px;
    line-height: 15px;
    color: #979797;
    cursor: pointer;
  }

  .pui-dropdown__placeholder {
    color: $placeholder-color;
    font-family: $open-sans;
  }

  .pui-dropdown__label[disabled] {
    display: block;
    height: 14px;
    font-family: $montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    font-size: 12px;
    opacity: 0.5;
  }

  .pui-dropdown {
    $button-width: 25px;
    $left-gap: 5px;
    $right-gap: 5px;

    &__container {
      position: relative;
    }

    &__box {
      // min-height: 37px;
      position: relative;
      border: 1px $border-type $border-color;
      padding: 0;
      padding-inline-end: calc(#{$left-gap} + #{$button-width} * var(--padding-inline-end) + #{$right-gap});
      padding-inline-start: 10px;
      background: $bgc;
      color: $font;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.has-tags {
        padding: 5px;
        padding-inline-end: 30px;
      }

      &.has-error {
        border: solid 1px $color-error;
      }

      &.active {
        border-color: $border-active;
      }

      .pui-icon-button {
        position: absolute;
        top: 0;
        height: 100%;
        width: $button-width;
        padding: 0;
        margin: 0;
        color: $border-color;
      }

      .pui-dropdown__clear-select {
        inset-inline-end: calc(#{$button-width} + #{$right-gap});
        font-size: 12px;

        &:focus,
        &:hover {
          color: $border-active;
        }
      }

      .pui-dropdown__arrow {
        inset-inline-end: $right-gap;
        font-size: 16px;

        &:focus,
        &:hover,
        &.active {
          color: $border-active;
        }
      }

    }

    &__box[disabled] {
      border: 1px solid $color-grey-light;
      opacity: 0.5;
      cursor: not-allowed;
    }

    &__inner {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    &__select {
      width: 100%;
      font-family: $open-sans;
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &--image {
        width: 18px;
        height: 18px;
        margin-inline-end: 8px;
      }

      span {
        font-size: 13px;
        white-space: nowrap;
      }
    }

    &__error {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $open-sans;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      color: $color-error;
      margin-top: 10px;

      svg {
        width: 14px;
        height: 12px;
      }
    }
  }
}

$placeholder-color: #c6c6c6;
$border-solid: solid;
$border-dashed: dashed;

.pui-dropdown--light {
  @include pui-dropdown(
    $color-white,
    $color-white-like,
    $color-white,
    $color-black,
    $color-white,
    $color-grey-like,
    $color-black,
    $color-grey-like,
    $color-grey-light,
    $color-grey,
    $border-solid,
    $color-black,
    $color-white-like,
    $color-cinnabar-red,
    $placeholder-color
  );
}

.pui-dropdown--grey-light {
  @include pui-dropdown(
    $color-white,
    $color-white-like,
    $color-white,
    $color-black,
    $color-white,
    $color-grey-like,
    $color-grey-light-darker,
    $color-grey-like,
    $color-grey-light,
    $color-grey-light,
    $border-solid,
    $color-grey-light,
    $color-white-like,
    $color-cinnabar-red,
    $placeholder-color
  );
}

.pui-dropdown--dashed-light {
  @include pui-dropdown(
    $color-white,
    $color-white-like,
    $color-white,
    $color-black,
    $color-white,
    $color-grey-like,
    $color-black,
    $color-grey-like,
    $color-grey-light,
    $color-grey,
    $border-dashed,
    $border-dashed,
    $color-white-like,
    $color-cinnabar-red,
    $placeholder-color
  );
}

.pui-dropdown--grey-dark {
  @include pui-dropdown(
    $color-grey-dark,
    $color-white-like,
    $color-black,
    $color-grey-light,
    $color-white,
    $color-grey-like,
    $color-white,
    $color-grey-like,
    $color-grey-light,
    $color-grey,
    $border-solid,
    $color-grey-light,
    $color-white-like,
    $color-cinnabar-red,
    $placeholder-color
  );
}

.pui-dropdown--primary-outline {
  @include pui-dropdown(
    $color-white,
    $color-white-like,
    $color-white,
    $color-black,
    $color-white,
    $color-grey-like,
    $color-black,
    $color-grey-like,
    $color-grey-light,
    $color-black,
    $border-solid,
    $color-black,
    $color-white-like,
    $color-cinnabar-red,
    $color-black
  );
}

.pui-dropdown--dark {
  @include pui-dropdown(
    $color-black,
    $color-white-like,
    $color-black,
    $color-grey-light,
    $color-white,
    $color-grey-like,
    $color-white,
    $color-grey-like,
    $color-grey-light,
    $color-grey,
    $border-solid,
    $color-grey-light,
    $color-white-like,
    $color-cinnabar-red,
    $placeholder-color
  );
}

.pui-dropdown-tag {
  font-family: $open-sans;
  display: inline-block;
  max-width: 100%;

  &__wrapper {
    position: relative;
    padding: 0px;
    padding-inline-start: 5px;
    display: flex;
    align-items: center;
    min-height: 24px;
    margin: 1px;
    border: solid 1px $color-grey-light;
    background-color: $color-grey-lighter;
  }

  &__icon {
    margin-inline-end: 8px;
  }

  &__image {
    margin-inline-end: 8px;
    vertical-align: middle;
    width: 20px;
    background-color: #f9c927;
  }

  &__avatar {
    min-width: 24px;
    margin-inline-end: 5px;
  }

  &__content {
    color: $color-grey-dark;
    padding-inline-end: 2px;
    font-size: 13px;
    flex: 1;
    margin-inline-start: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__remove-container {
    padding: 5px 10px;
    text-align: center;
    height: 30px;
    margin-inline-start: auto;
    color: #bcbec0;
    line-height: 1.54;
    font-size: 13px;
    border: solid 1px transparent;
    margin-inline-end: -1px;
    transition: $default-transition;

    &:hover {
      background-color: $color-yellow;
      cursor: pointer;
      border-color: rgba(0, 0, 0, 0.12);
      color: $color-black;
    }
  }

  // &__remove {
  //   color: $color-grey-light;
  //   position: absolute;
  //   top: 50%;
  //   right: 8px;
  //   transform: translateY(-50%);
  // }
}
</style>
