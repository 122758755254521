/* eslint-disable camelcase */
import CONSTANTS from '@/platformSettings/editorStaticVerbiages';
import getKBLink from '@/platformSettings/KBLinks';
import { PAI } from '@/platformSettings/pai';
import i18n from '@/lang/i18n';

const tools = {
  block_types: {
    component: 'ToolkitBlocMenu',
    header: {
      title: '',
      class: '',
      show: true,
      icon: {
        click_event: '',
        name: '',
      },
    },
    properties: {
      blocks: [
        {
          icon: 'actions',
          title: i18n.t('blocksv2:action.text'),
          types: [CONSTANTS.BLOCK_TYPE_ACTION, CONSTANTS.BLOCK_TYPE_NESTED_ACTION],
          collapse: false,
          disabled: false,
          properties: [
            {
              icon: 'text-message-action',
              title: i18n.t('blocksv2:action.bot_message'),
              type: 'MESSAGE_TEXT',
              component: 'ToolkitActionMessageText',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.bot_message.tooltip'),
                tooltip_link: getKBLink('BOT_MESSAGE_TEXT'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  response: {
                    en: [],
                  },
                  quick_reply: {
                    en: [],
                  },
                },
              },
            },
            {
              icon: 'media-message-events',
              title: i18n.t('blocksv2:action.media'),
              type: 'MESSAGE_VIDEO', // is default, can also have (MESSAGE_AUDIO, MESSAGE_VIDEO)
              component: 'ToolkitActionMedia',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.media.tooltip'),
                tooltip_link: getKBLink('BOT_MESSAGE_MEDIA'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  response: {
                    en: {
                      fileId: '',
                      fileName: '',
                      fileMediaType: '',
                      fileContentType: '',
                    },
                  },
                  type: 'video',
                },
              },
            },
            {
              icon: 'survey',
              title: i18n.t('blocksv2:action.survey'),
              type: 'COLLECT_INFO',
              component: 'ToolkitActionSurvey',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.survey.tooltip'),
                tooltip_link: getKBLink('BOT_COLLECT_INFO'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  email_address: null,
                  email_subject: null,
                  email_body: null,
                  collections: [],
                },
              },
            },
            {
              icon: 'carousel',
              title: i18n.t('blocksv2:action.carousel'),
              type: 'MESSAGE_CAROUSEL',
              component: 'ToolkitActionCarousel',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.carousel.tooltip'),
                tooltip_link: getKBLink('BOT_MESSAGE_CAROUSEL'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  cards: [],
                },
              },
            },
            {
              icon: 'agent-take-over',
              title: i18n.t('blocksv2:action.agent_takeover'),
              type: 'AGENT_TAKEOVER',
              component: 'ToolkitActionAgentTakeover',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.agent_takeover.tooltip'),
                tooltip_link: getKBLink('BOT_AGENT_TAKEOVER'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  default_team_ids: [],
                  language_team_ids: {},
                  use_default: {},
                  agent_availability_check: false,
                },
              },
            },
            {
              icon: 'json-api',
              title: i18n.t('blocksv2:action.json_api'),
              type: 'JSON_API',
              component: 'ToolkitActionJSONApi',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.json_api.tooltip'),
                tooltip_link: getKBLink('BOT_JSON_API'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  http_method: 'get',
                  url: '',
                  is_authorized: false,
                  header: '',
                  payload: '',
                  variable_name: '',
                  // Don't change version value,
                  // editor for payload is determine by this to show PUISyntaxHighlighter or TextArea in ToolkitActionJSONApi.
                  version: 2,
                },
              },
            },
            {
              icon: 'update-variable',
              title: i18n.t('blocksv2:action.update_variable'),
              type: 'UPDATE_VARIABLE',
              component: 'ToolkitActionUpdateVariable',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.update_variable.tooltip'),
                tooltip_link: getKBLink('BOT_UPDATE_VARIABLE'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  name: '',
                  expression: '',
                },
              },
            },
            {
              icon: 'switch-language',
              title: i18n.t('blocksv2:action.switch_language'),
              type: 'UPDATE_LANGUAGE',
              component: 'ToolkitActionChangeLanguage',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.switch_language.tooltip'),
                tooltip_link: getKBLink('BOT_UPDATE_LANGUAGE'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  target_lang: 'en',
                },
              },
            },
            {
              icon: 'branch',
              title: i18n.t('blocksv2:action.branch'),
              type: 'IF_ELSE',
              component: 'ToolkitActionIfElse',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.branch.tooltip'),
                tooltip_link: getKBLink('BOT_IF_ELSE'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  expressions: [],
                  otherwise: true,
                },
              },
            },
            {
              icon: 'send-email',
              title: i18n.t('blocksv2:action.email'),
              type: 'EXPORT_INFO',
              component: 'ToolkitActionExportInformation',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.email.tooltip'),
                tooltip_link: getKBLink('BOT_EXPORT_INFO'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  recipient: [],
                  subject: {
                    en: '',
                  },
                  content: {
                    en: '',
                  },
                },
              },
            },
            {
              icon: 'end',
              title: i18n.t('blocksv2:action.add_tag'),
              type: 'ADD_TAG',
              component: 'ToolkitActionEnd',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.add_tag.tooltip'),
                tooltip_link: getKBLink('BOT_ADD_TAG'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  name: null,
                },
              },
            },
            {
              icon: 'jump',
              title: i18n.t('blocksv2:action.jump'),
              type: 'LINK',
              component: 'ToolkitActionJump',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.jump.tooltip'),
                tooltip_link: getKBLink('BOT_LINK'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  id: '',
                },
              },
            },
            {
              icon: 'authenticate',
              title: i18n.t('blocksv2:action.authenticate'),
              type: 'AUTHENTICATE',
              component: 'ToolkitActionAuthenticate',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.authenticate.tooltip'),
                tooltip_link: getKBLink('BOT_AUTHENTICATE'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  method_id: '',
                },
              },
            },
            {
              icon: 'track-action-icon-create-case',
              title: i18n.t('blocksv2:action.create_case'),
              type: 'CREATE_CASE',
              component: 'ToolkitActionCreateCase',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.create_case.tooltip'),
                tooltip_link: getKBLink('BOT_CREATE_CASE'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {},
              },
            },
            // {
            //   icon: 'import-event',
            //   title: i18n.t('blocksv2:action.import_event'),
            //   type: 'PARSE_INFO',
            //   header: {
            //     title: null,
            //     class: 'property-block',
            //     show: true,
            //     icon: {
            //       click_event: null,
            //       name: null
            //     }
            //   },
            //   properties: {
            //     bot_lang: null,
            //     params: {}
            //   }
            // },
            {
              icon: 'iframe',
              title: i18n.t('blocksv2:action.iframe'),
              type: 'IFRAME',
              component: 'ToolkitActionIframe',
              hidden: true,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.iframe.tooltip'),
                tooltip_link: getKBLink('BOT_IFRAME'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  src: '',
                  on_close: false,
                },
              },
            },
            {
              icon: 'hyperlink',
              title: i18n.t('blocksv2:action.hyperlink'),
              type: 'HYPERLINK',
              component: 'ToolkitActionHyperlink',
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.hyperlink.tooltip'),
                tooltip_link: getKBLink('BOT_HYPERLINK'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  label: {
                    en: '',
                  },
                  url: '',
                },
              },
            },
            {
              icon: 'pai-takeover',
              title: i18n.t('blocksv2:action.pai_takeover'),
              type: 'PAI_TAKEOVER',
              component: 'ToolkitActionPaiTakeover',
              hidden: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:action.pai_takeover.tooltip'),
                tooltip_link: getKBLink('PAI_TAKEOVER'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  pai_type: PAI.PROTO_V3,
                  alert_msg: {
                    en: '',
                  },
                },
              },
            },
          ],
          group_meta: {
            default: null,
          },
        },
        {
          icon: 'events',
          title: i18n.t('blocksv2:event.text'),
          types: [CONSTANTS.BLOCK_TYPE_EVENT],
          collapse: false,
          disabled: false,
          properties: [
            {
              icon: 'text-message-event',
              title: i18n.t('blocksv2:event.human_message'),
              type: 'MESSAGE_TEXT',
              group: 'default',
              component: 'ToolkitEventMessageText',
              with_phrases: true,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:event.human_message.tooltip'),
                tooltip_link: getKBLink('HUMAN_MESSAGE_TEXT'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  positive: {
                    en: [],
                  },
                  negative: {
                    en: [],
                  },
                  human_tag_rules: [],
                },
              },
            },
            {
              icon: 'media-message',
              title: i18n.t('blocksv2:event.media'),
              type: 'MESSAGE_MEDIA',
              group: 'default',
              component: 'ToolkitEventMedia',
              with_phrases: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:event.media.tooltip'),
                tooltip_link: getKBLink('HUMAN_MESSAGE_MEDIA'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                params: {
                  type: [],
                  human_tag_rules: [],
                },
              },
            },
            {
              icon: 'trigger',
              title: i18n.t('blocksv2:event.trigger'),
              type: 'PROG_TRIGGER',
              group: 'default',
              component: 'ToolkitEventTrigger',
              with_phrases: false,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:event.trigger.tooltip'),
                tooltip_link: getKBLink('HUMAN_PROG_TRIGGER'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  human_tag_rules: [],
                },
              },
            },
            {
              icon: 'greeting',
              title: i18n.t('blocksv2:optional_event.greeting'),
              type: 'BUILTIN_GREETING',
              group: 'default',
              component: 'ToolkitOverridableEvent',
              with_phrases: true,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:optional_event.greeting.tooltip'),
                tooltip_link: getKBLink('HUMAN_BUILTIN_GREETING'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  positive: {
                    en: [],
                  },
                  negative: {
                    en: [],
                  },
                },
              },
            },
            {
              icon: 'goodbye',
              title: i18n.t('blocksv2:optional_event.goodbye'),
              type: 'BUILTIN_GOODBYE',
              group: 'default',
              component: 'ToolkitOverridableEvent',
              with_phrases: true,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:optional_event.goodbye.tooltip'),
                tooltip_link: getKBLink('HUMAN_BUILTIN_GOODBYE'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  positive: {
                    en: [],
                  },
                  negative: {
                    en: [],
                  },
                },
              },
            },
            {
              icon: 'intro',
              title: i18n.t('blocksv2:optional_event.intro'),
              type: 'BUILTIN_SELFINTRO',
              group: 'default',
              component: 'ToolkitOverridableEvent',
              with_phrases: true,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:optional_event.intro.tooltip'),
                tooltip_link: getKBLink('HUMAN_BUILTIN_SELFINTRO'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  positive: {
                    en: [],
                  },
                  negative: {
                    en: [],
                  },
                },
              },
            },
            {
              icon: 'negative',
              title: i18n.t('blocksv2:optional_event.negative'),
              type: 'BUILTIN_NEGATIVE',
              group: 'default',
              component: 'ToolkitOverridableEvent',
              with_phrases: true,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:optional_event.negative.tooltip'),
                tooltip_link: getKBLink('HUMAN_BUILTIN_NEGATIVE'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  positive: {
                    en: [],
                  },
                  negative: {
                    en: [],
                  },
                },
              },
            },
            {
              icon: 'positive',
              title: i18n.t('blocksv2:optional_event.positive'),
              type: 'BUILTIN_POSITIVE',
              group: 'default',
              component: 'ToolkitOverridableEvent',
              with_phrases: true,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:optional_event.positive.tooltip'),
                tooltip_link: getKBLink('HUMAN_BUILTIN_POSITIVE'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  positive: {
                    en: [],
                  },
                  negative: {
                    en: [],
                  },
                },
              },
            },
            {
              icon: 'segway',
              title: i18n.t('blocksv2:optional_event.segway'),
              type: 'BUILTIN_FILLER',
              group: 'default',
              component: 'ToolkitOverridableEvent',
              with_phrases: true,
              header: {
                title: null,
                tooltip: i18n.t('blocksv2:optional_event.segway.tooltip'),
                tooltip_link: getKBLink('HUMAN_BUILTIN_FILLER'),
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {
                  positive: {
                    en: [],
                  },
                  negative: {
                    en: [],
                  },
                },
              },
            },
            // TODO: Backend is not supported yet
            // {
            //   icon: 'fallback',
            //   title: 'blocksv2:optional_event.fallback',
            //   type: 'BUILTIN_FALLBACK',
            //   group: 'default',
            //   component: 'ToolkitOverridableEvent',
            //   header: {
            //     title: null,
            //     tooltip: 'blocksv2:optional_event.fallback.tooltip',
            //     tooltip_link: getKBLink('HUMAN_BUILTIN_FALLBACK'),
            //     class: 'property-block',
            //     show: true,
            //     icon: {
            //       click_event: null,
            //       name: null
            //     }
            //   },
            //   properties: {
            //     bot_lang: null,
            //     params: {}
            //   }
            // },
            {
              icon: 'api-success',
              title: i18n.t('blocksv2:derived_event.api_success'),
              type: 'API_SUCCESS',
              group: 'derived',
              component: '',
              header: {
                title: null,
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {},
              },
            },
            {
              icon: 'api-failed',
              title: i18n.t('blocksv2:derived_event.api_failed'),
              type: 'API_FAILED',
              group: 'derived',
              component: '',
              header: {
                title: null,
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                bot_lang: null,
                params: {},
              },
            },
            {
              icon: 'button-click',
              title: i18n.t('blocksv2:derived_event.human_button_click'),
              type: 'BUTTON_CLICKED',
              group: 'derived',
              component: 'ToolkitEventButtonClick',
              disabled: true,
              header: {
                title: null,
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                button_id: null,
                button_label: null,
                button_display_text: null,
                button_link: null,
              },
            },
            {
              icon: 'iframe-closed',
              title: i18n.t('blocksv2:derived_event.iframe_closed'),
              type: 'IFRAME_CLOSED',
              group: 'derived',
              component: 'ToolkitEventIframeClosed',
              disabled: true,
              header: {
                title: null,
                class: 'property-block',
                show: true,
                icon: {
                  click_event: null,
                  name: null,
                },
              },
              properties: {
                iframe_id: null,
              },
            },
          ],
          group_meta: {
            default: null,
            derived: {
              icon: 'derived-events',
              title: 'Derived Events',
              display: false,
            },
            overrideable: {
              icon: 'overridable-events',
              title: i18n.t('blocksv2:optional_event.text'),
            },
          },
        },
      ],
    },
  },
  action_blocks: {
    component: 'ToolkitActionsMenu',
    header: {
      title: i18n.t('blocksv2:action.choose_action'),
      class: 'actions-menu',
      show: true,
      icon: {
        click_event: '',
        name: 'v2_actions',
      },
    },
    properties: {},
  },
  bot_name: {
    component: 'ToolkitBotName',
    header: {
      title: 'Bot Name',
      class: '',
      show: true,
      icon: {
        click_event: '',
        name: '',
      },
    },
    properties: {},
  },
  test_chat: {
    component: 'ToolkitTestChat',
    header: {
      title: i18n.t('blocksv2:test_chat'),
      tooltip: i18n.t('blocksv2:test_chat.tooltip'),
      tooltip_link: getKBLink('TEST_CHAT'),
      class: 'test-chat',
      show: true,
      icon: {
        click_event: '',
        name: '',
      },
    },
    properties: {
      can_close: true,
      settings: {
        hideButtons: true,
      },
    },
  },
  search_node: {
    component: 'ToolkitSearchNode',
    header: {
      title: 'Search Nodes',
      class: '',
      show: true,
      icon: {
        click_event: '',
        name: '',
      },
    },
    properties: {},
  },
  delete_node: {
    component: 'ToolkitDeleteNode',
    header: {
      title: null,
      class: '',
      show: true,
      icon: {
        click_event: null,
        name: null,
      },
    },
    properties: {
      bot_id: null,
      mode: null,
      node_type: null,
      block_type: null,
      action_id: null,
      event_id: null,
    },
  },
  move_event: {
    component: 'MoveEventModal',
    header: {},
    properties: {},
  },
};
export const EVENT_TYPE_WITH_PHRASES = tools.block_types.properties.blocks[1].properties
  .filter(event => event.with_phrases)
  .map(event => event.type);
export const human_tag_types = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  ENUM: 'ENUM',
};
export const human_tag_operators = {
  TEXT: ['eq', 'neq'],
  NUMBER: ['eq', 'lt', 'le', 'gt', 'ge'],
  BOOLEAN: ['istrue', 'isfalse'],
  ENUM: ['eq', 'neq'],
};
export const getEventPropertyFromType = eventType => {
  const eventProperty = tools.block_types.properties.blocks[1].properties
    .find(event => event.type === eventType);
  return eventProperty;
};

export const blockDefByType = {
  actions: tools.block_types.properties.blocks[0].properties.reduce((byType, { type, title, icon }) => {
    byType[type] = { title, icon };
    return byType;
  }, {}),
  events: tools.block_types.properties.blocks[1].properties.reduce((byType, { type, title, icon }) => {
    byType[type] = { title, icon };
    return byType;
  }, {}),
};

export default tools;
