import Vue from 'vue';
import Vuex from 'vuex';
import $bus from '@/platformSettings/bus';

Vue.use(Vuex);

const MUTATION_TYPE = {
  // Interactive tutorials
  SET_INTERACTIVE_TUTORIALS: 'SET_INTERACTIVE_TUTORIALS',
  COMPLETE_INTERACTIVE_TUTORIAL: 'COMPLETE_INTERACTIVE_TUTORIAL',
  SET_ACTIVE_INTERACTIVE_TOUR: 'SET_ACTIVE_INTERACTIVE_TOUR',
};

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    // Interactive tutorials
    interactiveTutorials: [],
    // Active tour key
    activeInteractiveTourKey: '',
  },
  getters: {
    interactiveTutorialKeys: state => state.interactiveTutorials.reduce((byKey, tutorial) => {
      byKey.set(tutorial.key, true);
      return byKey;
    }, new Map()),

    isInteractiveTutorialCompleted: (state, getters) => tourKey => {
      if (!tourKey) throw new Error('Missing required parameters.');

      if (!getters.interactiveTutorialKeys.has(tourKey)) {
        throw new Error(`Cannot find interactive tutorial with key ${tourKey}`);
      }

      return state.interactiveTutorials.find(tutorial => tutorial.key === tourKey)?.completed;
    },

    isInInteractiveTour: state => tourKeys => {
      if (!tourKeys) throw new Error('Missing required parameters.');

      if (typeof (tourKeys) !== 'string' && !Array.isArray(tourKeys)) {
        throw new Error('`tourKeys` must be a string or an array of strings.');
      }

      if (typeof (tourKeys) === 'string') {
        tourKeys = [tourKeys];
      }

      return tourKeys.includes(state.activeInteractiveTourKey);
    },
  },
  mutations: {
    [MUTATION_TYPE.SET_INTERACTIVE_TUTORIALS](state, tutorials) {
      if (typeof tutorials === 'object' && !Array.isArray(tutorials)) {
        throw new Error(`Expected to receive an Array, but got ${typeof tutorials}`);
      }

      state.interactiveTutorials = tutorials;
    },

    [MUTATION_TYPE.COMPLETE_INTERACTIVE_TUTORIAL](state, tourKey) {
      const tutorialIndex = state.interactiveTutorials.findIndex(tutorial => tutorial.key === tourKey);

      state.interactiveTutorials[tutorialIndex].completed = true;
    },

    [MUTATION_TYPE.SET_ACTIVE_INTERACTIVE_TOUR](state, tourKey) {
      if (typeof tourKey !== 'string') {
        throw new Error(`Expected to receive a string, but got ${typeof tourKey}`);
      }

      state.activeInteractiveTourKey = tourKey ?? '';
    },
  },
  actions: {
    fetchInteractiveTutorials({ commit }) {
      return $bus.$siniticApi.get('/profile/interactive_tutorials').then(response => {
        const tutorials = response.data.map(({ key, completed }) => ({
          key,
          completed,
        }));

        commit(MUTATION_TYPE.SET_INTERACTIVE_TUTORIALS, tutorials);

        return Promise.resolve();
      });
    },

    completeInteractiveTutorial({ commit }, tourKey) {
      return $bus.$siniticApi.post('/profile/interactive_tutorials/complete', {
        data: {
          key: tourKey,
        },
      }).then(resp => {
        if (resp.data?.success) {
          commit(MUTATION_TYPE.COMPLETE_INTERACTIVE_TUTORIAL, tourKey);
          return true;
        } else {
          throw new Error('Failed to complete the interactive tutorial, try again.');
        }
      }).catch(err => {
        console.error('Failed to complete the interactive tutorial', err);
        throw err;
      });
    },

    setActiveTour({ commit }, tourKey = '') {
      commit(MUTATION_TYPE.SET_ACTIVE_INTERACTIVE_TOUR, tourKey);
    },
  },
};
