<template>
  <div
    :class="{ 'pui-avatar-container--info': info }"
    class="pui-avatar-container"
  >
    <div
      v-if="info"
      style="background: url('/static/icons/PUI/others/group-50.svg')"
      class="pui-avatar-info"
    />
    <div
      :class="[sizeClass]"
      class="pui-avatar"
    >
      <div
        v-if="name || url"
        :class="{ 'pui-avatar-rounded': rounded }"
        class="pui-avatar-icon"
      >
        <img
          v-if="url && !imageFailedToLoad"
          :src="url"
          class="pui-avatar-picture"
          @load="imageFailedToLoad = false"
          @error="imageFailedToLoad = true"
        />
        <span
          v-else
          :class="[avatarTextColor]"
          class="pui-avatar-text"
        >{{ nameAcronym }}</span>
      </div>
      <div
        v-else
        :style="{
          backgroundImage: `url('${fallbackIcon}')`,
          backgroundSize: backgroundSize ? `${backgroundSize} ${backgroundSize} !important` : null,
          backgroundColor: fallbackBackgroundColor,
          padding: `${fallbackPadding} !important`,
        }"
        :class="{
          'pui-avatar-rounded': rounded,
          [`pui-avatar-fallback--${fallback}`]: true,
        }"
        class="pui-avatar-fallback"
      />
      <span
        v-if="badge"
        :class="[badgeStatusClass]"
        class="pui-avatar-badge"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PUIAvatar',
  props: {
    name: {
      type: String,
      default: '',
    },
    avatarId: {
      type: String,
      default: '',
    },
    avatarUrl: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: null,
      validator: val => ['tiny', 'smaller', 'small', 'medium', 'large', 'xlarge', 'extra', null].includes(val),
    },
    badge: {
      type: String,
      default: null,
      validator: val => ['online', 'away', 'offline'].includes(val),
    },
    fallback: {
      type: String,
      default: 'profile',
      validator: val => ['profile', 'profile-black', 'bot', 'company', 'team', 'bot-black', 'bot-grey'].includes(val),
    },
    fallbackBackgroundColor: {
      type: String,
      default: null,
    },
    fallbackPadding: {
      type: String,
      default: '',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    info: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    backgroundSize: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageFailedToLoad: false,
    };
  },
  computed: {
    nameAcronym() {
      if (!this.name) return null;

      if (this.name === 'N/A') return 'N/A';

      const matches = this.name.match(/\b(\w)/g);
      if (matches && Array.isArray(matches)) {
        const acronym = matches.join('').substring(0, 2);
        return acronym;
      }
      return this.name.split('')[0];
    },
    avatarTextColor() {
      if (this.primary) return 'pui-avatar-text--yellow';

      if (!this.name) return null;

      const colors = ['grey', 'yellow', 'purple'];
      return `pui-avatar-text--${colors[this.name.length % 3]}`;
    },
    url() {
      if (!this.avatarId) return this.avatarUrl;

      return `${window.$SiniticConfig.base_url}/bot/v2/dontcare/attachment/${this.avatarId}/preview`;
    },
    sizeClass() {
      if (!this.size) return null;

      return `pui-avatar--${this.size}`;
    },
    fallbackIcon() {
      switch (this.fallback) {
        case 'profile':
          return '/static/icons/PUI/characters/profile-icon.svg';
        case 'profile-black':
          return '/static/icons/PUI/characters/profile-black-icon.svg';
        case 'bot':
          return '/static/icons/PUI/characters/bot-icon.svg';
        case 'company':
          return '/static/icons/PUI/characters/company-icon.svg';
        case 'team':
          return '/static/icons/PUI/characters/team-icon.svg';
        case 'bot-black':
          return '/static/icons/PUI/characters/bot-black-icon.svg';
        case 'bot-grey':
          return '/static/icons/PUI/characters/bot-grey-icon.svg';
        default:
          return null;
      }
    },
    badgeStatusClass() {
      if (!this.badge) return null;

      return `pui-avatar-badge--${this.badge}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~styles/pui/pui-variables.scss';

@mixin set-size($size) {
  width: $size;
  height: $size;
  line-height: $size;
}

@mixin avatar-text-style($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;
}

.pui-avatar-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0;
  min-width: auto;

  & > .pui-avatar-info {
    position: absolute;
    width: 88%;
    height: 88%;
    z-index: 0;
    top: 0;
    inset-inline-end: 0;
  }

  &--info {
    padding-top: 27px;
    padding-inline-end: 27px;
  }
}

.pui-avatar {
  position: relative;
  @include set-size(40px);
  text-align: center;
  font-family: $montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.1px;
  position: relative;

  &--tiny {
    @include set-size(18px);
    font-size: 7px;
  }

  &--smaller {
    @include set-size(24px);
    font-size: 9px;
  }

  &--small {
    @include set-size(30px);
    font-size: 10px;
  }

  &--medium {
    @include set-size(36px);
    font-size: 10px;
  }

  &--large {
    @include set-size(55px);
    font-size: 16px;
  }

  &--xlarge {
    @include set-size(125px);
    font-size: 48px;
  }

  &--extra {
    @include set-size(168px);
    font-size: 48px;
  }

  & > .pui-avatar-icon {
    width: 100%;
    height: 100%;
    overflow: hidden;

    & > .pui-avatar-picture {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      object-fit: cover;
    }
  }

  & > .pui-avatar-fallback {
    background-size: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    border: 1px solid #c6c6c6;
    background-color: #ffffff;

    &--bot-black {
      border: none;
      background-color: $color-yellow;
    }

    &--profile {
      border-color: #d8d8d8;
      // background-size: 82px 82px;
    }

    &--profile-black {
      border-color: black !important;
      background-color: $color-yellow;
      // background-size: 82px 82px;
    }
  }

  & > .pui-avatar-rounded {
    border-radius: 100%;
  }
}

.pui-avatar-text {
  width: 100%;
  height: 100%;
  display: block;
  text-transform: uppercase;

  &--purple {
    @include avatar-text-style(#9013fe, $color-white);
  }

  &--yellow {
    @include avatar-text-style($color-yellow, $color-black);
  }

  &--grey {
    @include avatar-text-style($color-grey, $color-black);
  }
}

.pui-avatar-badge {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: solid 1px #ffffff;
  bottom: -1px;
  inset-inline-end: -1px;

  &--online {
    background-color: #7ed321;
  }

  &--away {
    background-color: #f5a623;
  }

  &--offline {
    background-color: #c9c9c9;
  }
}

.pui-avatar-group > .pui-avatar {
  margin: 1px -10px;
}
</style>
